<template>
  <div class="mt-2">
    <v-row>
      <v-col cols="4" class="d-flex flex-column">
        <label for="listaEmpresas" class="mb-1">Empresas</label>
        <div class="d-flex align-center">
          <InputText id="listaEmpresas" type="text" readonly v-model="listaEmpresas" class="flex-grow-1" />
          <div class="btn-lista-empresa" @click="empresaDialog.showEmpresasDialog = true">
            <v-icon dark color="white">
              mdi-domain
            </v-icon>
          </div>
        </div>
      </v-col>

      <v-col cols="3" class="d-flex flex-column">
        <label for="dataIntervalo" class="mb-1">Período</label>
        <div class="d-flex align-center">
          <Calendar
            id="dataIntervalo"
            v-model="dataIntervalo"
            selectionMode="range"
            @date-select="handleSearch"
            :showIcon="true"
            dateFormat="dd/mm/yy"
            class="flex-grow-1"
          />
          <BotaoBuscarListas class="ml-2" @buscar="handleSearch" />
        </div>
      </v-col>

<!--      <v-col cols="5" class="d-flex align-end justify-end">-->
<!--        <v-btn @click="exportarMonitoriamento" icon>-->
<!--          <v-icon>mdi-download</v-icon>-->
<!--        </v-btn>-->
<!--      </v-col>-->
    </v-row>


    <!-- SKELETON -->
    <v-row v-if="loadingSkeleton">
      <v-col cols="12">
        <v-skeleton-loader
            :loading="true"
            type="image"
        ></v-skeleton-loader>
      </v-col>
    </v-row>

    <v-row v-if="!loadingSkeleton">
      <v-col cols="12">
        <Chart type="line" :data="grafico" :options="options" class="custom-chart" />
      </v-col>
    </v-row>

    <v-row v-if="!loadingSkeleton">
      <v-col>
        <v-tabs v-model="tab" align-with-title @change="handleSearch">
          <v-tabs-slider color="secondary"></v-tabs-slider>
          <v-tab>
            <v-icon class="mr-3">mdi-format-list-checks</v-icon>
            Analítico
          </v-tab>
          <v-tab>
            <v-icon class="mr-3">mdi-select-group</v-icon>
            Consolidado
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>

    <v-tabs-items v-model="tab" v-if="!loadingSkeleton">

      <v-tab-item>
        <v-row class="mx-auto mt-5" dense>
          <v-col cols="12">
            <div v-for="(dado, i) of dados" :key="i">
              <div style="background-color: #eee" class="border-full">
                <div class="d-flex justify-space-between">
                  <div>
                    <v-card-title class="text-caption">{{ dado.empresa.nome_fantasia }}</v-card-title>
                    <v-card-subtitle>{{ dado.empresa.documento.length > 11 ? $masks.cnpj(dado.empresa.documento) : $masks.cpf(dado.empresa.documento) }} - {{ dado.empresa.primeiroNome }} {{ dado.empresa.ultimoNome }}</v-card-subtitle>
                  </div>
                  <div>
                    <v-btn class="mt-5 mr-5" @click="exportarMonitoriamento(dado)" icon>
                      <v-icon>mdi-download</v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
              <v-data-table hide-default-header hide-default-footer disable-pagination ref="table" class="mb-5">

                <template v-slot:[`header`]>
                  <thead>
                  <tr>
                    <th></th>
                    <th style="background-color: #eee" class="text-center border-right border-left border-bottom" colspan="4">Vendas</th>
                    <th style="background-color: #eee" class="text-center border-right border-left border-bottom" colspan="4">Recebíveis</th>
                    <th style="background-color: #eee" class="text-center border-right border-left border-bottom" colspan="4">Estornos</th>
                  </tr>
                  <tr>
                    <th class="text-center border-bottom border-top border-left">Data</th>
                    <th class="text-center border-bottom border-left">Bruto</th>
                    <th class="text-center border-bottom">Tx Adm</th>
                    <th class="text-center border-bottom">Tx Ant</th>
                    <th class="text-center border-bottom border-right">Líquido</th>
                    <th class="text-center border-bottom">Bruto</th>
                    <th class="text-center border-bottom">Tx Adm</th>
                    <th class="text-center border-bottom">Tx Ant</th>
                    <th class="text-center border-bottom border-right">Líquido</th>
                    <th class="text-center border-bottom">Bruto</th>
                    <th class="text-center border-bottom">Tx Adm</th>
                    <th class="text-center border-bottom">Tx Ant</th>
                    <th class="text-center border-bottom border-right">Líquido</th>
                  </tr>
                  </thead>
                </template>

                <template v-slot:[`body`]>
                  <tr v-for="(item, k) of dado.dados" :key="k">
                    <td class="text-center border-bottom border-left">
                      {{ item.data | formatDate({ dateStyle: "short" }) }}
                    </td>
                    <td class="text-center border-left border-bottom">
                      {{
                        item.vendas.totalBruto
                            | formatMoney({
                          minimumFractionDigits: 2
                        })
                      }}
                    </td>
                    <td class="text-center border-bottom">
                      {{
                        item.vendas.totalTaxaAdm
                            | formatMoney({
                          minimumFractionDigits: 2
                        })
                      }}
                    </td>
                    <td class="text-center border-bottom">
                      {{
                        item.vendas.totalTaxaAnt
                            | formatMoney({
                          minimumFractionDigits: 2
                        })
                      }}
                    </td>
                    <td class="text-center border-right border-bottom">
                      {{
                        item.vendas.totalLiquido
                            | formatMoney({
                          minimumFractionDigits: 2
                        })
                      }}
                    </td>

                    <td class="text-center border-bottom">
                      {{
                        item.recebiveis.totalBruto
                            | formatMoney({
                          minimumFractionDigits: 2
                        })
                      }}
                    </td>
                    <td class="text-center border-bottom">
                      {{
                        item.recebiveis.totalTaxaAdm
                            | formatMoney({
                          minimumFractionDigits: 2
                        })
                      }}
                    </td>
                    <td class="text-center border-bottom">
                      {{
                        item.recebiveis.totalTaxaAnt
                            | formatMoney({
                          minimumFractionDigits: 2
                        })
                      }}
                    </td>
                    <td class="text-center border-right border-bottom">
                      {{
                        item.recebiveis.totalLiquido
                            | formatMoney({
                          minimumFractionDigits: 2
                        })
                      }}
                    </td>

                    <td class="text-center border-bottom">
                      {{
                        item.estornos.totalBruto
                            | formatMoney({
                          minimumFractionDigits: 2
                        })
                      }}
                    </td>
                    <td class="text-center border-bottom">
                      {{
                        item.estornos.totalTaxaAdm
                            | formatMoney({
                          minimumFractionDigits: 2
                        })
                      }}
                    </td>
                    <td class="text-center border-bottom">
                      {{
                        item.estornos.totalTaxaAnt
                            | formatMoney({
                          minimumFractionDigits: 2
                        })
                      }}
                    </td>
                    <td class="text-center border-right border-bottom">
                      {{
                        item.estornos.totalLiquido
                            | formatMoney({
                          minimumFractionDigits: 2
                        })
                      }}
                    </td>
                  </tr>
                  <tr style="background-color: #4f4f4f">
                    <td class="text-center white--text">-</td>
                    <td class="text-center white--text"> {{ totalBrutoVendas(dado.dados) | formatMoney({minimumFractionDigits: 2})}}</td>
                    <td class="text-center white--text"> {{ totalTaxaAdmVendas(dado.dados) | formatMoney({minimumFractionDigits: 2})}}</td>
                    <td class="text-center white--text"> {{ totalTaxaAntVendas(dado.dados) | formatMoney({minimumFractionDigits: 2})}}</td>
                    <td class="text-center white--text"> {{ totalLiquidoVendas(dado.dados) | formatMoney({minimumFractionDigits: 2})}}</td>
                    <td class="text-center white--text"> {{ totalBrutoRecebiveis(dado.dados) | formatMoney({minimumFractionDigits: 2})}}</td>
                    <td class="text-center white--text"> {{ totalTaxaAdmRecebiveis(dado.dados) | formatMoney({minimumFractionDigits: 2})}}</td>
                    <td class="text-center white--text"> {{ totalTaxaAntRecebiveis(dado.dados) | formatMoney({minimumFractionDigits: 2})}}</td>
                    <td class="text-center white--text"> {{ totalLiquidoRecebiveis(dado.dados) | formatMoney({minimumFractionDigits: 2})}}</td>
                    <td class="text-center white--text"> {{ totalBrutoEstornos(dado.dados) | formatMoney({minimumFractionDigits: 2})}}</td>
                    <td class="text-center white--text"> {{ totalTaxaAdmEstornos(dado.dados) | formatMoney({minimumFractionDigits: 2})}}</td>
                    <td class="text-center white--text"> {{ totalTaxaAntEstornos(dado.dados) | formatMoney({minimumFractionDigits: 2})}}</td>
                    <td class="text-center white--text"> {{ totalLiquidoEstornos(dado.dados) | formatMoney({minimumFractionDigits: 2})}}</td>
                  </tr>
                </template>

              </v-data-table>
            </div>
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item>
        <v-row class="mx-auto mt-5" dense>
          <v-col cols="12">
            <div>
              <v-data-table hide-default-header hide-default-footer disable-pagination ref="table" class="my-5">

                <template v-slot:[`header`]>
                  <thead>
                  <tr>
                    <th></th>
                    <th style="background-color: #eee" class="text-center border-full" colspan="4">Vendas</th>
                    <th style="background-color: #eee" class="text-center border-right border-top border-bottom" colspan="4">Recebíveis</th>
                    <th style="background-color: #eee" class="text-center border-right border-top border-bottom" colspan="4">Estornos</th>
                  </tr>
                  <tr>
                    <th class="text-center border-bottom border-top border-left">Empresa</th>
                    <th class="text-center border-bottom border-left">Bruto</th>
                    <th class="text-center border-bottom">Tx Adm</th>
                    <th class="text-center border-bottom">Tx Ant</th>
                    <th class="text-center border-bottom border-right">Líquido</th>
                    <th class="text-center border-bottom">Bruto</th>
                    <th class="text-center border-bottom">Tx Adm</th>
                    <th class="text-center border-bottom">Tx Ant</th>
                    <th class="text-center border-bottom border-right">Líquido</th>
                    <th class="text-center border-bottom">Bruto</th>
                    <th class="text-center border-bottom">Tx Adm</th>
                    <th class="text-center border-bottom">Tx Ant</th>
                    <th class="text-center border-bottom border-right">Líquido</th>
                  </tr>
                  </thead>
                </template>

                <template v-slot:[`body`]>
                  <tr v-for="(dado, i) of dadosConsolidados" :key="i">
                    <td class="text-start border-bottom border-left pa-3">
                      <span>{{ dado.empresa.nome_fantasia }}</span><br/>
                      <span>{{ dado.empresa.documento.length > 11 ? $masks.cnpj(dado.empresa.documento) : $masks.cpf(dado.empresa.documento) }}</span><br/>
                      <span>{{ dado.empresa.primeiroNome }} {{ dado.empresa.ultimoNome }}</span><br/>
                    </td>
                    <td class="text-center border-left border-bottom">
                      {{
                        dado.dados[0].vendas.totalBruto
                            | formatMoney({
                          minimumFractionDigits: 2
                        })
                      }}
                    </td>
                    <td class="text-center border-bottom">
                      {{
                        dado.dados[0].vendas.totalTaxaAdm
                            | formatMoney({
                          minimumFractionDigits: 2
                        })
                      }}
                    </td>
                    <td class="text-center border-bottom">
                      {{
                        dado.dados[0].vendas.totalTaxaAnt
                            | formatMoney({
                          minimumFractionDigits: 2
                        })
                      }}
                    </td>
                    <td class="text-center border-right border-bottom">
                      {{
                        dado.dados[0].vendas.totalLiquido
                            | formatMoney({
                          minimumFractionDigits: 2
                        })
                      }}
                    </td>

                    <td class="text-center border-bottom">
                      {{
                        dado.dados[0].recebiveis.totalBruto
                            | formatMoney({
                          minimumFractionDigits: 2
                        })
                      }}
                    </td>
                    <td class="text-center border-bottom">
                      {{
                        dado.dados[0].recebiveis.totalTaxaAdm
                            | formatMoney({
                          minimumFractionDigits: 2
                        })
                      }}
                    </td>
                    <td class="text-center border-bottom">
                      {{
                        dado.dados[0].recebiveis.totalTaxaAnt
                            | formatMoney({
                          minimumFractionDigits: 2
                        })
                      }}
                    </td>
                    <td class="text-center border-right border-bottom">
                      {{
                        dado.dados[0].recebiveis.totalLiquido
                            | formatMoney({
                          minimumFractionDigits: 2
                        })
                      }}
                    </td>

                    <td class="text-center border-bottom">
                      {{
                        dado.dados[0].estornos.totalBruto
                            | formatMoney({
                          minimumFractionDigits: 2
                        })
                      }}
                    </td>
                    <td class="text-center border-bottom">
                      {{
                        dado.dados[0].estornos.totalTaxaAdm
                            | formatMoney({
                          minimumFractionDigits: 2
                        })
                      }}
                    </td>
                    <td class="text-center border-bottom">
                      {{
                        dado.dados[0].estornos.totalTaxaAnt
                            | formatMoney({
                          minimumFractionDigits: 2
                        })
                      }}
                    </td>
                    <td class="text-center border-right border-bottom">
                      {{
                        dado.dados[0].estornos.totalLiquido
                            | formatMoney({
                          minimumFractionDigits: 2
                        })
                      }}
                    </td>
                  </tr>
                  <tr style="background-color: #4f4f4f">
                    <td class="text-center white--text">-</td>
                    <td class="text-center white--text"> {{ totalBrutoVendasConsolidado | formatMoney({minimumFractionDigits: 2})}}</td>
                    <td class="text-center white--text"> {{ totalTaxaAdmVendasConsolidado | formatMoney({minimumFractionDigits: 2})}}</td>
                    <td class="text-center white--text"> {{ totalTaxaAntVendasConsolidado | formatMoney({minimumFractionDigits: 2})}}</td>
                    <td class="text-center white--text"> {{ totalLiquidoVendasConsolidado | formatMoney({minimumFractionDigits: 2})}}</td>
                    <td class="text-center white--text"> {{ totalBrutoRecebiveisConsolidado | formatMoney({minimumFractionDigits: 2})}}</td>
                    <td class="text-center white--text"> {{ totalTaxaAdmRecebiveisConsolidado | formatMoney({minimumFractionDigits: 2})}}</td>
                    <td class="text-center white--text"> {{ totalTaxaAntRecebiveisConsolidado | formatMoney({minimumFractionDigits: 2})}}</td>
                    <td class="text-center white--text"> {{ totalLiquidoRecebiveisConsolidado | formatMoney({minimumFractionDigits: 2})}}</td>
                    <td class="text-center white--text"> {{ totalBrutoEstornosConsolidado | formatMoney({minimumFractionDigits: 2})}}</td>
                    <td class="text-center white--text"> {{ totalTaxaAdmEstornosConsolidado | formatMoney({minimumFractionDigits: 2})}}</td>
                    <td class="text-center white--text"> {{ totalTaxaAntEstornosConsolidado | formatMoney({minimumFractionDigits: 2})}}</td>
                    <td class="text-center white--text"> {{ totalLiquidoEstornosConsolidado | formatMoney({minimumFractionDigits: 2})}}</td>
                  </tr>
                </template>

              </v-data-table>
            </div>
          </v-col>
        </v-row>
      </v-tab-item>

    </v-tabs-items>

    <EmpresasDialog
      :showDialog="empresaDialog.showEmpresasDialog"
      :multiplaSelecao="true"
      :title="empresaDialog.titleEmpresaDialog"
      @cancela-dialog="cancelaEmpresaDialog"
      @empresa-selecionada="empresaSelecionada"
      :valida="true"
      :mostrarTodasEmpresas="true"
    />

  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import moment from "moment";
import Calendar from "primevue/calendar";
import Chart from "primevue/chart";
import BotaoBuscarListas from "@/components/BotaoBuscarListas.vue";
import InputText from "primevue/inputtext";
import EmpresasDialog from "@/components/Dialogs/EmpresasDialog.vue";

export default {
  name: "Monitoramento",
  components: {
    EmpresasDialog,
    InputText,
    BotaoBuscarListas,
    Calendar,
    Chart

  },
  data() {
    return {
      tab: null,
      empresaDialog: {
        showEmpresasDialog: false,
        titleEmpresaDialog: ""
      },
      loadingSkeleton: false,
      dataIntervalo: [new Date(), new Date()],
      menuDataInicio: false,
      menuDataFim: false,
      dataInicio: new Date().toISOString().substr(0, 10),
      dataFim: new Date().toISOString().substr(0, 10),
      dados: [],
      dadosConsolidados: [],
      empresas: [],
      listaEmpresas: "",
      grafico: {
        labels: [],
        datasets: [],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false
      }
    };
  },
  computed: {
    ...mapState(["empresaAtual", "user"]),
    totalBrutoRecebiveisConsolidado() {
      return this.dadosConsolidados.reduce((acc, item) => {
        return acc + item.dados[0].recebiveis.totalBruto;
      }, 0);
    },
    totalLiquidoRecebiveisConsolidado() {
      return this.dadosConsolidados.reduce((acc, item) => {
        return acc + item.dados[0].recebiveis.totalLiquido;
      }, 0);
    },
    totalTaxaAdmRecebiveisConsolidado() {
      return this.dadosConsolidados.reduce((acc, item) => {
        return acc + item.dados[0].recebiveis.totalTaxaAdm;
      }, 0);
    },
    totalTaxaAntRecebiveisConsolidado() {
      return this.dadosConsolidados.reduce((acc, item) => {
        return acc + item.dados[0].recebiveis.totalTaxaAnt;
      }, 0);
    },

    totalBrutoVendasConsolidado() {
      return this.dadosConsolidados.reduce((acc, item) => {
        return acc + item.dados[0].vendas.totalBruto;
      }, 0);
    },
    totalLiquidoVendasConsolidado() {
      return this.dadosConsolidados.reduce((acc, item) => {
        return acc + item.dados[0].vendas.totalLiquido;
      }, 0);
    },
    totalTaxaAdmVendasConsolidado() {
      return this.dadosConsolidados.reduce((acc, item) => {
        return acc + item.dados[0].vendas.totalTaxaAdm;
      }, 0);
    },
    totalTaxaAntVendasConsolidado() {
      return this.dadosConsolidados.reduce((acc, item) => {
        return acc + item.dados[0].vendas.totalTaxaAnt;
      }, 0);
    },

    totalBrutoEstornosConsolidado() {
      return this.dadosConsolidados.reduce((acc, item) => {
        return acc + item.dados[0].estornos.totalBruto;
      }, 0);
    },
    totalLiquidoEstornosConsolidado() {
      return this.dadosConsolidados.reduce((acc, item) => {
        return acc + item.dados[0].estornos.totalLiquido;
      }, 0);
    },
    totalTaxaAdmEstornosConsolidado() {
      return this.dadosConsolidados.reduce((acc, item) => {
        return acc + item.dados[0].estornos.totalTaxaAdm;
      }, 0);
    },
    totalTaxaAntEstornosConsolidado() {
      return this.dadosConsolidados.reduce((acc, item) => {
        return acc + item.dados[0].estornos.totalTaxaAnt;
      }, 0);
    },


  },
  methods: {
    ...mapMutations([
      "openDialogMessage",
      "openDialogConfirm",
      "closeDialogConfirm"
    ]),
    getDados() {
      this.loadingSkeleton = true;

      if (this.dataIntervalo[0]) {
        this.dataInicio = moment(this.dataIntervalo[0]).format("YYYY-MM-DD");
      }

      if (this.dataIntervalo[1]) {
        this.dataFim = moment(this.dataIntervalo[1]).format("YYYY-MM-DD");
      }

      if (!this.dataIntervalo[0] || !this.dataIntervalo[1]) {
        return;
      }

      let data = [ { id: this.empresaAtual.id } ];


      if(this.empresas.length > 0) {
        data = this.empresas;
      } else {
        this.listaEmpresas = this.empresaAtual.nome_fantasia;
      }

      this.$axios
        .post(
          `/relatorio/transacao/monitoramento-diario-rede`, data,
          {
            params: {
              "data-inicio": this.dataInicio,
              "data-fim": this.dataFim
            }
          }
        )
        .then(response => {
          this.dados = response.data;
          this.gerarGrafico(response.data);
        })
        .catch(error => {
          this.dados = [];
          this.openDialogMessage({
            type: "error",
            title: "Falha ao buscar",
            text: error.response.data
          });
        })
        .finally(() => {
          this.loadingSkeleton = false;
        });
    },
    getDadosConsolidados() {
      this.loadingSkeleton = true;

      if (this.dataIntervalo[0]) {
        this.dataInicio = moment(this.dataIntervalo[0]).format("YYYY-MM-DD");
      }

      if (this.dataIntervalo[1]) {
        this.dataFim = moment(this.dataIntervalo[1]).format("YYYY-MM-DD");
      }

      if (!this.dataIntervalo[0] || !this.dataIntervalo[1]) {
        return;
      }

      let data = [ { id: this.empresaAtual.id } ];


      if(this.empresas.length > 0) {
        data = this.empresas;
      } else {
        this.listaEmpresas = this.empresaAtual.nome_fantasia;
      }

      this.$axios
          .post(
              `/relatorio/transacao/monitoramento-diario-rede-consolidado`, data,
              {
                params: {
                  "data-inicio": this.dataInicio,
                  "data-fim": this.dataFim
                }
              }
          )
          .then(response => {
            this.dadosConsolidados = response.data;
          })
          .catch(error => {
            this.dados = [];
            this.openDialogMessage({
              type: "error",
              title: "Falha ao buscar",
              text: error.response.data
            });
          })
          .finally(() => {
            this.loadingSkeleton = false;
          });
    },

    gerarGrafico(dados) {

      const colors = [
        "#2C9D83", "#82FF13", "#0DE110", "#D50263", "#05BF02", "#90DE42", "#1F0698", "#D74441",
        "#C861A5", "#EFDBCB", "#98BDCE", "#80C360", "#75B883", "#14D1FC", "#FB9C10", "#3DA82D",
        "#6EE8D8", "#1AF8AF", "#8A30ED", "#1443B6", "#EDD469", "#0B706A", "#B5C2CF", "#9DCD3E",
        "#6278E9", "#239933", "#F6B292", "#6924B2", "#0E40D3", "#17F4C3", "#EEB30F", "#B7AF31",
        "#2ED300", "#C63189", "#4C0518", "#A71CF5", "#5B1A3E", "#21CE06", "#1538DE", "#F67595",
        "#01E93A", "#9E4AF5", "#5598C5", "#479544", "#5C2574", "#DC6E34", "#774DE0", "#EDCCB9",
        "#94328F", "#7D28D5", "#704AF5", "#70AC5D", "#5FF2B5", "#9F4232", "#086387", "#CCD5BD",
        "#0A1AEB", "#0C1974", "#BAE796", "#165DF9", "#E31904", "#047EDD", "#EDF7FB", "#007DC2",
        "#86204C", "#1FCD3E", "#665BE9", "#A9795E", "#B0B4F1", "#C75462", "#F338B0", "#5E85D2",
        "#ED7322", "#413CE0", "#07DE32", "#09D8B2", "#E09EDE", "#EE12CC", "#145823", "#C091F8",
        "#A176BA", "#5B9A4B", "#C54E89", "#890067", "#855D65", "#E3E37D", "#4D6895", "#CDF876",
        "#7393D6", "#B6BF7E", "#728138", "#5058C6", "#E9022A", "#80F9F9", "#8A9417", "#59AD10",
        "#D9E88B", "#9FC25F", "#F8197A", "#1027F4"
      ];


      this.grafico.labels = dados[0].dados.map(obj => {
        return `${obj.data.split("-")[1]}/${obj.data.split("-")[0]}`;
      });

      this.grafico.datasets = dados.map((element, i) => {
        return {
          label: element.empresa.nome_fantasia,
          data: element.dados.map((obj) => {
            return obj.vendas.totalLiquido;
          }),
          borderColor: colors[i],
          tension: 0.4
        }
      });

    },


    exportarMonitoriamento(dados) {
        const copy = dados.dados.map(item => {
            const obj = {};
            obj.dados_data = item.data;
            obj.dados_vendas_totalBruto = item.vendas.totalBruto;
            obj.dados_vendas_totalLiquido = item.vendas.totalLiquido;
            obj.dados_vendas_totalTaxaAdm = item.vendas.totalTaxaAdm;
            obj.dados_vendas_totalTaxaAnt = item.vendas.totalTaxaAnt;
            obj.dados_recebiveis_totalBruto = item.recebiveis.totalBruto;
            obj.dados_recebiveis_totalLiquido = item.recebiveis.totalLiquido;
            obj.dados_recebiveis_totalTaxaAdm = item.recebiveis.totalTaxaAdm;
            obj.dados_recebiveis_totalTaxaAnt = item.recebiveis.totalTaxaAnt;
            obj.dados_estornos_totalBruto = item.estornos.totalBruto;
            obj.dados_estornos_totalLiquido = item.estornos.totalLiquido;
            obj.dados_estornos_totalTaxaAdm = item.estornos.totalTaxaAdm;
            obj.dados_estornos_totalTaxaAnt = item.estornos.totalTaxaAnt;

            return obj;
          });

          this.toCSVeDownload(`relatorios_${dados.empresa.nome_fantasia}.csv`, copy);
    },
    empresaSelecionada(empresaCallBack) {

      this.listaEmpresas = empresaCallBack.map(item => item.nome_fantasia).join(", ");
      this.empresas = empresaCallBack.map((item) => {
        return { id: item.id }
      });

      const msg = empresaCallBack.length > 1 ? `${empresaCallBack.length} Empresas selecionadas com sucesso!` : "Empresa selecionada com sucesso!";

      this.$toast.add({
        severity: "success",
        summary: msg,
        life: 2000
      });

      this.empresaDialog.showEmpresasDialog = false;
      this.handleSearch();

    },
    cancelaEmpresaDialog() {
      this.empresaDialog.showEmpresasDialog = false;
    },

    totalBrutoVendas(dados) {
      return dados.reduce((acc, item) => acc + item.vendas.totalBruto, 0);
    },

    totalBrutoRecebiveis(dados) {
      return dados.reduce((acc, item) => acc + item.recebiveis.totalBruto, 0);
    },
    totalLiquidoRecebiveis(dados) {
      return dados.reduce((acc, item) => acc + item.recebiveis.totalLiquido, 0);
    },
    totalTaxaAdmRecebiveis(dados) {
      return dados.reduce((acc, item) => acc + item.recebiveis.totalTaxaAdm, 0);
    },
    totalTaxaAntRecebiveis(dados) {
      return dados.reduce((acc, item) => acc + item.recebiveis.totalTaxaAnt, 0);
    },

    totalLiquidoVendas(dados) {
      return dados.reduce((acc, item) => acc + item.vendas.totalLiquido, 0);
    },
    totalTaxaAdmVendas(dados) {
      return dados.reduce((acc, item) => acc + item.vendas.totalTaxaAdm, 0);
    },
    totalTaxaAntVendas(dados) {
      return dados.reduce((acc, item) => acc + item.vendas.totalTaxaAnt, 0);
    },

    totalBrutoEstornos(dados) {
      return dados.reduce((acc, item) => acc + item.estornos.totalBruto, 0);
    },
    totalLiquidoEstornos(dados) {
      return dados.reduce((acc, item) => acc + item.estornos.totalLiquido, 0);
    },
    totalTaxaAdmEstornos(dados) {
      return dados.reduce((acc, item) => acc + item.estornos.totalTaxaAdm, 0);
    },
    totalTaxaAntEstornos(dados) {
      return dados.reduce((acc, item) => acc + item.estornos.totalTaxaAnt, 0);
    },

    handleSearch() {
      if (this.tab === 0) {
        this.getDados();
      } else if (this.tab === 1) {
        this.getDadosConsolidados();
      }
    },

  },
  mounted() {
      this.getDados();
  }
};
</script>

<style scoped>
.border-right {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}
.border-left {
  border-left: 1px solid rgba(0, 0, 0, 0.2);
}
.border-full {
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.border-top {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}
.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.custom-chart {
  height: 200px;
}

</style>
