<template>
  <div class="mt-2">
    <v-row>
      <v-col class="d-flex justify-start">
        <Calendar
            v-model="dataIntervalo"
            selectionMode="range"
            @date-select="getDados"
            :showIcon="true"
            dateFormat="dd/mm/yy"
        />
        <BotaoBuscarListas class="ml-3" @buscar="getDados" />
      </v-col>

      <v-col>
        <div class="text-end">
          <v-btn type="button" @click="exportar" icon>
            <v-icon> mdi-download </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <!-- SKELETON -->
    <v-row v-if="loadingSkeleton">
      <v-col cols="12">
        <v-skeleton-loader
            :loading="true"
            type="image"
        ></v-skeleton-loader>
      </v-col>
    </v-row>

    <v-row v-else class="mx-auto mt-5" dense>
      <v-col cols="12">
        <v-data-table hide-default-header hide-default-footer disable-pagination ref="table" :items="dados">

          <template v-slot:[`header`]>
            <thead>
            <tr>
              <th style="background-color: #eee" class="text-center border-top border-right border-left">Venda</th>
              <th style="background-color: #eee" class="text-center border-top border-right">Criada</th>
              <th style="background-color: #eee" class="text-center border-top border-right">Finalizada</th>
              <th style="background-color: #eee" class="text-center border-top border-right">Cliente</th>
              <th style="background-color: #eee" class="text-center border-top border-right">Operador</th>
              <th style="background-color: #eee" class="text-center border-top border-right">Pago</th>
              <th style="background-color: #eee" class="text-center border-top">Total</th>
              <th style="background-color: #eee" class="text-center border-top">Descontos</th>
              <th style="background-color: #eee" class="text-center border-top">Pago Bruto</th>
              <th style="background-color: #eee" class="text-center border-top border-right">Pago Liquido</th>
            </tr>
            </thead>
          </template>

          <template v-slot:[`item`]="{ item }" v-if="$vuetify.breakpoint.smAndUp">
              <tr>
                  <td class="header text-center border-bottom border-left">
                    {{ item.id }}
                  </td>
                  <td class="header text-center border-bottom ">
                    {{ item.data_criacao | formatDate({ day: '2-digit', month: '2-digit', year: '2-digit' }) }}
                  </td>
                  <td class="header text-center border-bottom">
                    {{ item.data_finalizada | formatDate({ day: '2-digit', month: '2-digit', year: '2-digit' }) }}
                  </td>
                  <td class="header text-center border-bottom truncate">
                    {{ item.cliente?.nome }}
                  </td>
                  <td class="header text-center border-bottom truncate">
                    {{ item.operador_criacao.nome }}
                  </td>
                  <td class="header text-center border-bottom ">
                    {{ item.pago? 'Sim':'Não' }}
                  </td>
                  <td class="header text-center border-bottom">
                    {{ item.total | formatMoney({minimumFractionDigits: 2 }) }}
                  </td>
                  <td class="header text-center border-bottom">
                    {{ item.descontos | formatMoney({minimumFractionDigits: 2 }) }}
                  </td>
                  <td class="header text-center border-bottom">
                    {{ item.totalPagoBruto | formatMoney({minimumFractionDigits: 2 }) }}
                  </td>
                  <td class="header text-center border-bottom border-right">
                    {{ item.totalPagoLiquido | formatMoney({minimumFractionDigits: 2 }) }}
                  </td>
              </tr>
              <tr>
                <td class="border-right border-left" colspan="4">Itens</td>
                <td class="border-right " colspan="6">Pagamentos</td>
              </tr>
              <tr>
                <td colspan="4" class="border-bottom border-right border-left">
                    <ul>
                      <li v-for="obj in item.itens" :key="obj.servico?obj.servico.id:obj.produto.id">
                        <div>
                          {{ obj.quantidade }} X {{ obj.servico? obj.servico.nome:obj.produto.nome }}
                          | {{ obj.servico?obj.servico.categoria?.nome:obj.produto.categoria?.nome }}
                          R$ {{ obj.total  | formatMoney({minimumFractionDigits: 2 }) }}
                        </div>
                      </li>
                    </ul>
                  </td>

                  <td colspan="6" class="border-bottom border-right">
                    <ul>
                      <li v-for="obj in item.pagamentos" :key="obj.id">
                        <div>
                          <div>{{obj.forma_pagamento}} R$ {{ obj.valor  | formatMoney({minimumFractionDigits: 2 }) }}</div>
                          <div style="display: block;" v-if="obj.transacao">ID {{obj.transacao?.id}}  TRM {{obj.transacao?.numero_terminal}} PDV {{obj.transacao?.pdv_adquirente}}</div>
                        </div>
                      </li>
                    </ul>
                  </td>
              </tr>
          </template>

        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col>

      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import moment from "moment";
import Calendar from "primevue/calendar";
import BotaoBuscarListas from "@/components/BotaoBuscarListas.vue";

export default {
  name: "VendaAnalitico",
  components: {
    BotaoBuscarListas,
    Calendar

  },
  data() {
    return {
      loadingSkeleton: false,
      dataIntervalo: [new Date(), new Date()],
      menuDataInicio: false,
      menuDataFim: false,
      dataInicio: new Date().toISOString().substr(0, 10),
      dataFim: new Date().toISOString().substr(0, 10),
      dados: [],
      grafico: {
        labels: [],
        datasets: [],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false
      }
    };
  },
  computed: {
    ...mapState(["empresaAtual", "user"]),
  },
  methods: {
    ...mapMutations(["openDialogMessage"]),
    getDados() {
      this.loadingSkeleton = true;

      if (this.dataIntervalo[0]) {
        this.dataInicio = moment(this.dataIntervalo[0]).format("YYYY-MM-DD");
      }

      if (this.dataIntervalo[1]) {
        this.dataFim = moment(this.dataIntervalo[1]).format("YYYY-MM-DD");
      }

      if(!this.dataIntervalo[0] || !this.dataIntervalo[1]){
        return;
      }

      if (!this.empresaAtual || !this.empresaAtual.id) return;

      this.$axios
          .get(
              `/relatorio/venda/empresa/${this.empresaAtual.id}/analitico`,
              {
                params: {
                  "data-inicio": this.dataInicio,
                  "data-fim": this.dataFim
                }
              }
          )
          .then(response => {
            this.dados = response.data.data;
            this.loadingSkeleton = false;
          })
        .catch(error => {
          this.dados = [];
          this.openDialogMessage({
            type: "error",
            title: "Falha na consulta",
            text: error.response.data
          });
        })
        .finally(() => {
          this.loadingSkeleton = false;
        });
    },
    exportar() {
      const copy = this.dados.map(json => {
        const obj = {
            id: json.id,
            pago: json.pago?'PAGO':'PENDENTE',
            dataCriacao: json.data_criacao,
            dataAtualizacao: json.data_atualizacao,
            dataFinalizada: json.data_finalizada,
            valorTotal: this.parseFloatToMoney(json.total),
            totalPagoBruto: this.parseFloatToMoney(json.totalPagoBruto),
            totalPagoLiquido: this.parseFloatToMoney(json.totalPagoLiquido),
            totalPagoServico: this.parseFloatToMoney(json.totalPagoServico),
            totalPagoProduto: this.parseFloatToMoney(json.totalPagoProduto),
            valorTotalServico: this.parseFloatToMoney(json.valorTotalServico),
            valorTotalProduto: this.parseFloatToMoney(json.valorTotalProduto),
            empresaId: json.empresa.id,
            empresaCNPJ: json.empresa.cnpj,
            empresaCPF: json.empresa.cpf,
            empresaRazaoSocial: json.empresa.razao_social,
            empresaNome: json.empresa.nome,
            empresaNomeFantasia: json.empresa.nome_fantasia,
            clienteId: json.cliente?.id,
            clienteTipo: json.cliente?.tipo,
            clienteNome: json.cliente?.nome,
            clienteDocumento: json.cliente?.documento,
            clienteEmail: json.cliente?.email,
            clienteTelefoneCelular: json.cliente?.telefone_celular,
            clienteEndereco: json.cliente?`${json.cliente?.endereco}, ${json.cliente?.numero}, ${json.cliente?.bairro}, ${json.cliente?.cidade}, ${json.cliente?.uf}`:'',
            operadorCriacaoId: json.operador_criacao.id,
            operadorCriacaoNome: json.operador_criacao.nome,
            operadorAtualizacaoId: json.operador_atualizacao?.id,
            operadorAtualizacaoNome: json.operador_atualizacao?.nome,
            pagamento: json.pagamentos.map(pagamento =>
              {
                if(pagamento.transacao){
                  return `${pagamento.forma_pagamento} R$ ${this.parseFloatToMoney(pagamento.valor)} ID ${pagamento.transacao?.id} TRM ${pagamento.transacao?.numero_terminal} PDV ${pagamento.transacao?.pdv_adquirente} `
                }
                return `${pagamento.forma_pagamento} R$ ${this.parseFloatToMoney(pagamento.valor)} `
              }
            ),
            itens: json.itens.map(item =>
              `${item.quantidade} X ${item.servico?item.servico.nome:item.produto.nome} - ${item.servico?item.servico.categoria?.nome:item.produto.categoria?.nome} R$ ${this.parseFloatToMoney(item.valor)} Total R$ ${this.parseFloatToMoney(item.total)} `
            )
        };

        return this.replaceUndefinedWithEmptyString(obj);
      });

      this.toCSVeDownload("vendas-analitico.csv", copy);
    },

    replaceUndefinedWithEmptyString(obj) {
      const newObj = {};
      Object.keys(obj).forEach(key => {
        newObj[key] = obj[key] === undefined ? "" : obj[key];
      });
      return newObj;
    },

  },
  mounted() {
    this.getDados();
  }
};
</script>

<style scoped>
td {
  padding: 0px 2px 0px 2px;
}
.header{
  background: #f1f1f1;
}
.border-right {
  border-right: 1px solid #e5e5e5;
}
.border-left {
  border-left: 1px solid #e5e5e5;
}
.border-full {
  border: 1px solid #e5e5e5;
}
.border-top {
  border-top: 1px solid #e5e5e5;
}
.border-bottom {
  border-bottom: 1px solid #e5e5e5;
}
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
  padding-left: 1rem;
}


</style>
