<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-stepper alt-labels v-model="step">
          <v-stepper-header v-if="step < 5">
            <v-stepper-step
              :complete="step > 1"
              step="1"
              :color="step > 1 ? 'green' : 'primary'"
            >
              <div class="d-flex align-center">
                <v-icon class="mr-2">mdi-office-building</v-icon> Dados
              </div>
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              :complete="step > 2"
              step="2"
              :color="step > 2 ? 'green' : 'primary'"
            >
              <div class="d-flex align-center">
                <v-icon class="mr-2">mdi-format-list-bulleted</v-icon> Itens
              </div>
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              :complete="step > 3"
              step="3"
              :color="step > 3 ? 'green' : 'primary'"
            >
              <div class="d-flex align-center">
                <v-icon class="mr-2">mdi-call-split</v-icon> Split
              </div>
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              :complete="step > 4"
              step="4"
              :color="step > 3 ? 'green' : 'primary'"
            >
              <div class="d-flex align-center">
                <v-icon class="mr-2">mdi-wallet</v-icon> Forma Pagto
              </div>
            </v-stepper-step>

          </v-stepper-header>
          <v-stepper-items style="min-height: 32rem;">
            <v-stepper-content step="1">
              <v-container fluid>
                <v-row>
                  <v-col>
                    <v-row>
                      <v-col>
                        <span class="text-subtitle-2">Quem está cobrando?</span>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col class="d-flex">
                        <AutoComplete
                          class="autocomplete-input"
                          v-model="empresaSelecionada"
                          :suggestions="empresasFiltradas"
                          @complete="searchEmpresa"
                          forceSelection
                          :field="itemTextEmpresa"
                          placeholder="Pesquise aqui..."
                        >
                          <template #item="slotProps">
                            <div>
                              <div>{{ slotProps.item.nome }}</div>
                              <div>{{ slotProps.item.razao_social }}</div>
                              <div>
                                CPF/CNPJ: {{ $masks.cpf(slotProps.item.cpf) }}
                                {{ $masks.cnpj(slotProps.item.cnpj) }} Tel:
                                {{
                                  $masks.telefone(
                                      slotProps.item.telefone_celular
                                  )
                                }}
                                E-mail: {{ slotProps.item.email }}
                              </div>
                            </div>
                          </template>
                        </AutoComplete>
                        <div v-if="empresaSelecionada" class="div-clearable" @click="empresaSelecionada = null">
                          <v-icon>mdi-close</v-icon>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col>
                        <div
                            v-if="empresaSelecionada"
                          class="d-flex flex-column custom-card"
                        >
                          <span>
                            {{ empresaSelecionada.nome_fantasia }}
                          </span>
                          <span v-if="empresaSelecionada.razao_social">
                            {{ empresaSelecionada.razao_social }}
                          </span>
                          <span v-if="empresaSelecionada.nome">
                            {{ empresaSelecionada.nome }}
                          </span>
                          <span v-if="empresaSelecionada.cnpj" class="text-caption">
                            CNPJ {{ empresaSelecionada.cnpj }}
                          </span>
                          <span v-if="empresaSelecionada.cpf" class="text-caption">
                            CPF {{ empresaSelecionada.cpf }}
                          </span>
                          <span class="text-caption">
                            {{ empresaSelecionada.telefone }}
                          </span>
                          <span class="text-caption">
                            {{ empresaSelecionada.endereco }},
                            {{ empresaSelecionada.numero }} -
                            {{ empresaSelecionada.cidade }} ,
                            {{ empresaSelecionada.uf }}
                          </span>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-divider vertical />
                  <v-col>
                    <v-row>
                      <v-col class="text-start">
                        <span class="text-subtitle-2">Quem vai pagar?</span>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col class="d-flex">
                        <AutoComplete
                            :disabled="!empresaSelecionada || naoInformarCliente"
                            class="autocomplete-input"
                            v-model="clienteSelecionado"
                            :suggestions="clientesFiltrados"
                            @complete="searchCliente"
                            forceSelection
                            :field="itemTextCliente"
                            placeholder="Pesquise aqui..."
                        >
                          <template #item="slotProps">
                            <div>
                              <div>{{ slotProps.item.nome }}</div>
                              <div>{{ slotProps.item.razao_social }}</div>
                              <div>
                                CPF/CNPJ: {{ $masks.cpf(slotProps.item.cpf) }}
                                {{ $masks.cnpj(slotProps.item.cnpj) }} Tel:
                                {{
                                  $masks.telefone(
                                      slotProps.item.telefone_celular
                                  )
                                }}
                                E-mail: {{ slotProps.item.email }}
                              </div>
                            </div>
                          </template>
                        </AutoComplete>
                        <div v-if="clienteSelecionado" class="div-clearable" @click="clienteSelecionado = null">
                          <v-icon>mdi-close</v-icon>
                        </div>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                fab
                                dark
                                small
                                color="primary"
                                :class="clienteSelecionado ? 'ml-10' : 'ml-3'"
                                class="mt-1"
                                @click="openDialogCliente('create')"
                            >
                              <v-icon>mdi-plus</v-icon>
                            </v-btn>
                          </template>
                          <span>Criar</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col>
                        <v-checkbox
                            v-if="!clienteSelecionado"
                            v-model="naoInformarCliente"
                            label="Não quero informar o cliente."
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row dense v-if="clienteSelecionado && clienteSelecionado.nome">
                      <v-col>
                        <div class="d-flex flex-column custom-card">
                          <span>
                            {{ clienteSelecionado.nome }}
                          </span>
                          <span class="text-caption">
                            CPF/CNPJ {{ clienteSelecionado.cpf }}
                            {{ clienteSelecionado.cnpj }}
                          </span>
                          <span class="text-caption">
                            {{
                              $masks.telefone(
                                clienteSelecionado.telefone_celular
                              )
                            }}
                          </span>
                          <span class="text-caption">
                            {{ clienteSelecionado.email }}
                          </span>
                          <span
                            class="text-caption"
                            v-if="clienteSelecionado.endereco"
                          >
                            {{ clienteSelecionado.endereco }},
                            {{ clienteSelecionado.numero }} -
                            {{ clienteSelecionado.cidade }} ,
                            {{ clienteSelecionado.uf }}
                          </span>
                        </div>
                      </v-col>
                      <v-col cols="2" class="d-flex align-end">
                        <v-tooltip bottom v-if="user.permissoes.adm_cliente">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              fab
                              text
                              small
                              color="primary"
                              @click="
                                openDialogCliente('edit', clienteSelecionado)
                              "
                            >
                              Editar
                              <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                          </template>
                          <span>Editar Cliente</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row dense class="mt-4">
                  <v-col class="text-end s">
                    <v-btn class="primary" :disabled="!avancaStep1" @click="avancar">
                      Avançar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-stepper-content>
            <v-stepper-content step="2">
              <v-container fluid>
                <v-row dense>
                  <v-col>
                    <span class="p-input-icon-left">
                      <i class="pi pi-search" />
                      <InputText
                        type="text"
                        v-model="filtroServicoProdutos"
                        placeholder="Pesquisar"
                      />
                    </span>
                  </v-col>
                  <v-col class="text-end">
                    <p>Itens adicionados</p>
                  </v-col>
                </v-row>
                <v-row dense style="min-height: 25rem;">
                  <v-col>
                    <TabView class="tabview-custom">
                      <TabPanel header="Serviços">
                        <v-skeleton-loader
                            v-if="servicosFiltrados.length === 0"
                            :loading="true"
                            type="table"
                        ></v-skeleton-loader>
                        <DataTable
                          v-else
                          :value="servicosFiltrados"
                          @row-select="onServicoSelect"
                          selectionMode="single"
                          :paginator="true"
                          :rows="5"
                          dataKey="id"
                        >
                          <Column
                            field="sku"
                            header="SKU"
                            :styles="{ width: '6rem' }"
                          >
                            <template #body="slot">
                              <p class="sku">{{ slot.data.sku }}</p>
                            </template>
                          </Column>
                          <Column field="nome" header="Nome"></Column>
                          <Column
                            field="valor"
                            header="Valor"
                            :styles="{ width: '6rem' }"
                          >
                            <template #body="slot">
                              {{
                                slot.data.valor
                                  | formatMoney({ minimumFractionDigits: 2 })
                              }}
                            </template>
                          </Column>
                        </DataTable>
                      </TabPanel>
                      <TabPanel header="Produtos">
                        <v-skeleton-loader
                            v-if="produtosFiltrados.length === 0"
                            :loading="true"
                            type="heading"
                        ></v-skeleton-loader>
                        <DataTable
                          v-else
                          :value="produtosFiltrados"
                          @row-select="onProdutoSelect"
                          selectionMode="single"
                          :paginator="true"
                          :rows="5"
                          dataKey="id"
                        >
                          <Column
                            field="codigo_barras"
                            header="SKU"
                            :styles="{ width: '6rem' }"
                          >
                            <template #body="slot">
                              <p class="sku">{{ slot.data.codigo_barras }}</p>
                            </template>
                          </Column>
                          <Column field="nome" header="Nome"></Column>
                          <Column
                            field="valor"
                            header="Valor"
                            :styles="{ width: '6rem' }"
                          >
                            <template #body="slot">
                              {{
                                slot.data.valor
                                  | formatMoney({ minimumFractionDigits: 2 })
                              }}
                            </template>
                          </Column>
                        </DataTable>
                      </TabPanel>
                    </TabView>
                  </v-col>
                  <v-col>
                    <DataTable
                      :value="itens"
                      responsiveLayout="stack"
                      breakpoint="960px"
                    >
                      <Column header="Quantidade" :styles="{ width: '9rem' }">
                        <template #body="slot">
                          <InputNumber
                            mode="decimal"
                            :min="1"
                            v-model="slot.data.quantidade"
                            showButtons
                            buttonLayout="horizontal"
                            :step="1"
                            decrementButtonClass="p-button-danger"
                            incrementButtonClass="p-button-success"
                            incrementButtonIcon="pi pi-plus"
                            decrementButtonIcon="pi pi-minus"
                          />
                        </template>
                      </Column>

                      <Column header="Item">
                        <template #body="slot">
                          <p v-if="slot.data.servico">
                            {{ slot.data.servico.nome }}
                          </p>
                          <p v-if="slot.data.produto">
                            {{ slot.data.produto.nome }}
                          </p>
                        </template>
                      </Column>

                      <Column
                        header="Valor unitário"
                        :styles="{ width: '9rem' }"
                      >
                        <template #body="slot">
                          <InputNumber
                            :disabled="!user.permissoes.alterar_valor_item"
                            v-model="slot.data.valor"
                            :min="0"
                            mode="currency"
                            currency="BRL"
                            locale="pt-BR"
                            :minFractionDigits="2"
                            :maxFracionDigits="2"
                          />
                        </template>
                      </Column>

                      <Column header="Total" :styles="{ width: '9rem' }">
                        <template #body="slot">
                          <p>
                            R$
                            {{
                              (slot.data.quantidade * slot.data.valor)
                                | formatMoney({ minimumFractionDigits: 2 })
                            }}
                          </p>
                        </template>
                      </Column>

                      <Column header="Remover" :styles="{ width: '1rem' }">
                        <template #body="slot">
                          <Button
                            icon="pi pi-trash"
                            class="p-button-rounded p-button-outlined"
                            @click="() => removeItem(slot.data)"
                          />
                        </template>
                      </Column>
                    </DataTable>

                    <v-row dense>
                      <v-col class="text-right" style="margin-top: 1rem;">
                        <span class="text-caption">Total</span>
                        <span class="text-caption" style="display:inline;"
                          >R$</span
                        >
                        <span class="text-h3">{{
                          totalCobranca
                            | formatMoney({ minimumFractionDigits: 2 })
                        }}</span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row dense class="mt-4">
                  <v-col>
                    <v-btn class="btn_cancela" @click="() => this.step--">
                      Voltar
                    </v-btn>
                  </v-col>
                  <v-col class="text-end">
                    <v-btn
                      class="primary"
                      :disabled="!avancaStep2"
                      @click="() => this.step++"
                    >
                      Avançar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-stepper-content>
            <v-stepper-content step="3">
              <v-container fluid>
                <v-row class="d-flex justify-center">
                  <v-col cols="10">
                    <v-row dense>
                      <v-col class="text-center text-subtitle-2">
                        Valor da Cobrança
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col class="text-center">
                        <span class="text-caption" style="display:inline;"
                          >R$</span
                        >
                        <span class="text-h3">{{
                          totalCobranca
                            | formatMoney({ minimumFractionDigits: 2 })
                        }}</span>
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <v-col>
                        <span class="text-h5"> Splits </span>
                      </v-col>
                    </v-row>
                    <v-row dense v-if="!user.permissoes.adm_split">
                      <v-col>
                        <v-alert colored-border type="info" elevation="2">
                          Seu usuário não possui permissão para configurar
                          splits, clique em avançar para pular esta etapa.
                        </v-alert>
                      </v-col>
                    </v-row>

                    <v-row dense v-if="user.permissoes.adm_split">
                      <v-col cols="10">
                        <v-autocomplete
                          :items="empresasMarketPlace"
                          item-value="id"
                          item-text="razao_social"
                          v-model="destinatarioSplit"
                          label="Destinatarios"
                          dense
                          return-object
                        >
                          <template v-slot:selection="{ item }">
                            <span v-if="item.razao_social">
                              {{ $masks.cnpj(item.cnpj) }} -
                              {{ item.razao_social }}
                            </span>
                            <span v-if="item.nome">
                              {{ $masks.cnpj(item.cpf) }} -
                              {{ item.nome }}
                            </span>
                          </template>
                          <template v-slot:item="{ item }">
                            <v-list-item-content>
                              <v-list-item-title>{{
                                item.nome_fantasia
                              }}</v-list-item-title>
                              <v-list-item-subtitle v-if="item.razao_social">
                                {{ $masks.cnpj(item.cnpj) }} -
                                {{ item.razao_social }}
                              </v-list-item-subtitle>
                              <v-list-item-subtitle v-if="item.nome">
                                {{ $masks.cpf(item.cpf) }} -
                                {{ item.nome }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              fab
                              dark
                              small
                              color="primary"
                              @click="addSplit"
                            >
                              <v-icon>mdi-plus</v-icon>
                            </v-btn>
                          </template>
                          <span>Adicionar Split</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                    <v-row dense v-if="user.permissoes.adm_split">
                      <v-col>
                        <v-simple-table>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left" width="450">Empresa</th>
                                <th class="text-left" width="120">
                                  Percentual
                                </th>
                                <th class="text-left">Paga Taxa</th>
                                <th class="text-left">Responsavel Divisão</th>
                                <th class="text-left">Total</th>
                                <th class="text-left">Remover</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(split, index) in splits"
                                :key="split.id"
                              >
                                <td>
                                  <div>
                                    <div v-if="split.recebedor.cnpj">
                                      {{ split.recebedor.cnpj }} -
                                      <span class="nome-fantasia">{{
                                        split.recebedor.nome_fantasia
                                      }}</span>
                                    </div>
                                    <div v-if="split.recebedor.cpf">
                                      {{ split.recebedor.cpf }} -
                                      <span class="nome-fantasia">{{
                                          split.recebedor.nome_fantasia
                                        }}</span>
                                    </div>
                                    <div v-if="split.recebedor.razao_social">
                                      {{ split.recebedor.razao_social }}
                                    </div>
                                    <div v-if="split.recebedor.nome">
                                      {{ split.recebedor.nome }}
                                    </div>
                                    <div>
                                      <span class="endereco">{{
                                        split.recebedor.enderecoCompleto
                                      }}</span>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <InputNumber
                                    mode="decimal"
                                    :minFractionDigits="2"
                                    :maxFractionDigits="2"
                                    v-model="split.valor"
                                    suffix=" %"
                                    :max="100"
                                    :min="0"
                                    :allowEmpty="false"
                                  />
                                </td>
                                <td>
                                  <v-switch
                                    v-model="split.paga_taxa_transacao"
                                    :label="
                                      split.paga_taxa_transacao ? 'Sim' : 'Não'
                                    "
                                  />
                                </td>
                                <td>
                                  <v-switch
                                    v-model="split.paga_resto_divisao_taxa"
                                    :label="
                                      split.paga_resto_divisao_taxa
                                        ? 'Sim'
                                        : 'Não'
                                    "
                                  />
                                </td>
                                <td>
                                  <div>
                                    R$
                                    {{
                                      valorSplit(split)
                                        | formatMoney({
                                          minimumFractionDigits: 2
                                        })
                                    }}
                                  </div>
                                </td>
                                <td>
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        v-bind="attrs"
                                        v-on="on"
                                        text
                                        small
                                        @click="
                                          openModalRemoveSplit(split, index)
                                        "
                                      >
                                        <v-icon>mdi-delete</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Remover Item</span>
                                  </v-tooltip>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col
                    cols="2"
                    class="d-flex align-self-center"
                    v-if="user.permissoes.adm_split"
                  >
                    <v-progress-circular
                      :rotate="360"
                      :size="200"
                      :width="10"
                      :value="totalPercSplit"
                      :color="totalPercSplit <= 100 ? 'primary' : 'red'"
                    >
                      <span class="text-h5 font-weight-bold">
                        {{ totalPercSplit }}%
                      </span>
                    </v-progress-circular>
                  </v-col>
                </v-row>
                <v-row dense class="mt-4">
                  <v-col>
                    <v-btn class="btn_cancela" @click="() => this.step--">
                      Voltar
                    </v-btn>
                  </v-col>
                  <v-col class="text-end">
                    <v-btn
                      class="primary"
                      :disabled="!avancaStep3"
                      @click="() => this.step++"
                    >
                      Avançar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-stepper-content>
            <v-stepper-content step="4">
              <v-container fluid>
                <v-row>
                  <v-col cols="8">
                    <span>Opções de pagamento</span>

                    <v-row v-if="user.permissoes.receber_boleto" dense>
                      <v-col cols="2">
                        <v-checkbox v-model="boleto" label="Boleto" />
                      </v-col>

                      <v-col cols="2">
                        <span class="text-caption">Max Parcelas</span>
                        <Dropdown
                          v-model="max_parcelas_boleto"
                          :options="maxParcelasOptions"
                          optionLabel="text"
                          optionValue="value"
                          :disabled="!boleto"
                        />
                      </v-col>
                      <v-col cols="3">
                        <span class="text-caption">Vencimento</span>
                        <Calendar
                          v-model="data_vencimento_boleto"
                          dateFormat="dd/mm/yy"
                          :disabled="!boleto"
                          :minDate="data_vencimento_minima"
                          :showIcon="true"
                          :manualInput="false"
                          :touchUI="true"
                        />
                        <span class="text-caption"
                          >*Parcelados serão subsequentes</span
                        >
                      </v-col>

                      <v-col cols="2">
                        <span class="text-caption">Juros a.m. %</span>
                        <InputNumber
                          :disabled="!boleto"
                          mode="decimal"
                          :minFractionDigits="2"
                          :maxFractionDigits="2"
                          v-model="juros_atraso_boleto"
                          suffix=" %"
                          :max="100"
                          :min="0"
                          :allowEmpty="true"
                        />
                      </v-col>

                      <v-col cols="2">
                        <span class="text-caption">Multa %</span>
                        <InputNumber
                          :disabled="!boleto"
                          mode="decimal"
                          :minFractionDigits="2"
                          :maxFractionDigits="2"
                          v-model="multa_atraso_boleto"
                          suffix=" %"
                          :max="100"
                          :min="0"
                          :allowEmpty="true"
                        />
                      </v-col>
                    </v-row>

                    <v-row v-if="user.permissoes.receber_credito || user.permissoes.receber_debito" dense>
                      <v-col cols="2">
                        <v-checkbox v-model="cartao" label="Cartão" />
                      </v-col>

                      <v-col cols="2">
                        <span class="text-caption">Max Parcelas</span>
                        <Dropdown
                          v-model="max_parcelas_cartao"
                          :options="maxParcelasOptions"
                          optionLabel="text"
                          optionValue="value"
                          :disabled="!cartao"
                        />
                      </v-col>
                    </v-row>

                    <v-row v-if="user.permissoes.receber_pix" dense>
                      <v-col cols="2">
                        <v-checkbox v-model="pix" label="Pix" />
                      </v-col>
                    </v-row>

                    <v-row
                        v-if="!user.permissoes.receber_pix && !user.permissoes.receber_boleto && !user.permissoes.receber_credito && !user.permissoes.receber_debito"
                    >
                      <v-col>
                        <span class="text-caption grey--text"><b>Esse usuário não tem permissão de forma de pagamento.</b></span>
                      </v-col>
                    </v-row>

                  </v-col>

                  <v-divider vertical />

                  <v-col cols="3" dense>
                    <span>Detalhes da cobrança</span>

                    <v-row dense>
                      <v-col>
                        <span class="text-caption"
                          >Validade do link de pagamento</span
                        >

                        <Calendar
                          v-model="dataLimitePagto"
                          dateFormat="dd/mm/yy"
                          :minDate="data_vencimento_minima"
                          :showIcon="true"
                          :manualInput="false"
                          :touchUI="true"
                        />
                      </v-col>
                    </v-row>

                    <v-row
                      dense
                      v-if="
                        empresaSelecionada &&
                          empresaSelecionada.emite_nfse &&
                          user.permissoes.emissao_nfs
                      "
                    >
                      <v-col>
                        <v-checkbox v-model="emitirNfse" label="Emitir NFS-e" />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <v-col style="margin-top:1rem;">
                        <v-textarea
                          outlined
                          rows="3"
                          counter="70"
                          label="Observações"
                          v-model="observacoes"
                        >
                        </v-textarea>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row dense class="mt-4">
                  <v-col>
                    <v-btn class="btn_cancela" @click="() => this.step--">
                      Voltar
                    </v-btn>
                  </v-col>
                  <v-col class="text-end">
                    <v-btn
                      class="primary"
                      :disabled="!avancaStep4"
                      @click="openModalConfirmCobranca"
                    >
                      Confirmar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-stepper-content>

          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>

    <DialogCliente
        v-if="empresaSelecionada"
        :showDialog="clienteDialog.showDialog"
        :id-empresa="empresaSelecionada.id"
        :id="clienteDialog.id"
        :mode="clienteDialog.mode"
        @salva-dados="saveCliente"
        @cancela-dialog="clienteDialog.showDialog = false"
    />

  </v-container>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import InputNumber from "primevue/inputnumber";
import Calendar from "primevue/calendar";
import Dropdown from "primevue/dropdown";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import InputText from "primevue/inputtext";
import AutoComplete from "primevue/autocomplete";
import DialogCliente from "@/components/Dialogs/DialogCliente.vue";

export default {
  name: "CriaCobranca",
  props: {
    cliente: Object,
    id: String
  },
  components: {
    InputNumber,
    Calendar,
    DataTable,
    Column,
    Dropdown,
    Button,
    TabView,
    TabPanel,
    InputText,
    AutoComplete,
    DialogCliente
  },
  data() {
    return {
      step: 1,
      loading: false,
      cobranca: {},
      naoInformarCliente: false,
      empresasUsuario: [],
      empresasFiltradas: [],
      empresasMarketPlace: [],
      empresaSelecionada: null,
      clientesUsuario: [],
      clientesFiltrados: [],
      clienteSelecionado: null,
      servicos: [],
      produtos: [],
      filtroServicoProdutos: "",
      filtroCliente:"",
      itens: [],
      nota: {},
      destinatarioSplit: null,
      splits: [],
      observacoes: "",
      showNota: false,
      dataLimitePagto: new Date(),
      snackbar: false,
      cartao: false,
      max_parcelas_cartao: 1,
      max_parcelas_boleto: 1,
      boleto: false,
      pix: false,
      emitirNfse: false,
      showPagamentoBoleto: false,
      juros_atraso_boleto: 0,
      multa_atraso_boleto: 0,
      data_vencimento_boleto: new Date(),
      data_vencimento_minima: new Date(),
      maxParcelasOptions: [
        {
          value: 1,
          text: "À Vista"
        },

        {
          value: 2,
          text: "2 vezes"
        },
        {
          value: 3,
          text: "3 vezes"
        },
        {
          value: 4,
          text: "4 vezes"
        },
        {
          value: 5,
          text: "5 vezes"
        },
        {
          value: 6,
          text: "6 vezes"
        },
        {
          value: 7,
          text: "7 vezes"
        },
        {
          value: 8,
          text: "8 vezes"
        },
        {
          value: 9,
          text: "9 vezes"
        },
        {
          value: 10,
          text: "10 vezes"
        },
        {
          value: 11,
          text: "11 vezes"
        },
        {
          value: 12,
          text: "12 vezes"
        }
      ],
      erros: [],
      pesquisando:false,
      clienteDialog: {
        id: null,
        showDialog: false,
        mode: ""
      },
    };
  },
  async created() {
    await this.getEmpresas();

    if (this.cliente) {
      this.clienteSelecionado = this.cliente;
      await this.getEmpresa(this.clienteSelecionado.empresa_id);
    } else {
      await this.getEmpresa(this.empresaAtual.id);
    }
  },
  computed: {
    ...mapState(["user", "empresaAtual"]),

    avancaStep1() {

      if(this.naoInformarCliente) {
        if(!this.empresaSelecionada) {
          return false;
        } else {
          return true;
        }
      } else {
        if(!this.empresaSelecionada || !this.clienteSelecionado) {
          return false;
        } else {
          return true;
        }
      }


    },
    avancaStep2() {
      if (this.totalCobranca <= 0) {
        return false;
      } else {
        return true;
      }
    },
    avancaStep3() {
      if (!this.user.permissoes.adm_split) return true;

      if (this.splits == null || this.splits.length == 0) return true;

      if (this.totalPercSplit > 100.0 || this.totalPercSplit < 100.0) {
        return false;
      }
      let pagaTaxa = false;
      let pagaDivisao = false;
      this.splits.forEach(split => {
        pagaTaxa = split.paga_taxa_transacao ? true : pagaTaxa;
        pagaDivisao = split.paga_resto_divisao_taxa ? true : pagaDivisao;
      });

      if (!pagaTaxa || !pagaDivisao) return false;

      return true;
    },
    avancaStep4() {
      if (!this.cartao && !this.boleto && !this.pix) return false;

      if (this.boleto) {
        if (!this.max_parcelas_boleto || this.max_parcelas_boleto <= 0)
          return false;

        if (!this.data_vencimento_boleto) return false;

        if (this.juros_atraso_boleto < 0) return false;

        if (this.multa_atraso_boleto < 0) return false;
      }

      if (this.cartao) {
        if (this.max_parcelas_cartao <= 0) return false;
      }

      if (this.observacoes.length > 70) return false;

      return true;
    },
    totalCobranca() {


      let totalCobranca = 0;
      this.itens.map(item => {
        if (!item.quantidade) {
          item.total = 0;
        } else {

          if (!this.user.permissoes.alterar_valor_item) {
            item.valor = item.valor_original;
          }

          item.total = item.quantidade * this.parseToFloat(item.valor);
        }

        totalCobranca += item.total;

        return item;
      });

      return parseFloat(totalCobranca.toFixed(2));
    },
    totalPercSplit() {
      if (!this.splits) return 0;

      const total = this.splits
        .reduce((accumulator, obj) => {
          return accumulator + this.parseToFloat(obj.valor ? obj.valor : 0);
        }, 0.0)
        .toFixed(2);

      return total == 100.0 ? 100 : total;
    },
    totalItens() {
      if (!this.cobranca.itens) return 0;

      const total = this.cobranca.itens.reduce(
        (prev, curr) => (prev += curr.quantidade * curr.valor),
        0
      );
      return total.toFixed(2);
    },
    estaValidoParaPagamento() {
      if (!this.cobranca) return false;

      if (this.cobranca.forma_pagamento) {
        return false;
      }

      if (
        this.cobranca.status == "CANCELADO" ||
        this.cobranca.status == "EXPIRADO"
      ) {
        return false;
      }

      return true;
    },
    produtosFiltrados() {
      return this.produtos
        .filter(
          objeto =>
            !this.itens.some(
              item => item.produto != null && item.produto.id === objeto.id
            )
        )
        .filter(
          obj =>
            !this.filtroServicoProdutos ||
            obj.codigo_barras
              .toLowerCase()
              .includes(this.filtroServicoProdutos.toLowerCase()) ||
            obj.nome
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .includes(this.filtroServicoProdutos.toLowerCase()) ||
            obj.descricao
              .toLowerCase()
              .includes(this.filtroServicoProdutos.toLowerCase())
        );
    },
    servicosFiltrados() {
      return this.servicos
        .filter(
          objeto =>
            !this.itens.some(
              item => item.servico != null && item.servico.id === objeto.id
            )
        )
        .filter(
          item =>
            !this.filtroServicoProdutos ||
            (item.sku &&
              item.sku
                .toLowerCase()
                .includes(this.filtroServicoProdutos.toLowerCase())) ||
            (item.nome &&
              item.nome
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(this.filtroServicoProdutos.toLowerCase())) ||
            (item.descricao &&
              item.descricao
                .toLowerCase()
                .includes(this.filtroServicoProdutos.toLowerCase()))
        );
    }
  },
  methods: {
    ...mapMutations([
      "openDialogMessage",
      "openDialogConfirm",
      "closeDialogConfirm"
    ]),

    searchEmpresa(event) {
      if (event.query.trim()) {
        const queryText = event.query.toLowerCase();
        this.empresasFiltradas = this.empresasUsuario.filter(item => {
          const razaoSocial = item.razao_social
              ? item.razao_social.toLowerCase()
              : "";
          const cnpj = item.cnpj
              ? this.$masks.cnpj(item.cnpj).toLowerCase()
              : "";
          const nomeFantasia = item.nome_fantasia
              ? item.nome_fantasia.toLowerCase()
              : "";
          const nome = item.nome ? item.nome.toLowerCase() : "";
          const cpf = item.cpf ? this.$masks.cpf(item.cpf).toLowerCase() : "";

          return (
              razaoSocial.includes(queryText) ||
              cnpj.includes(queryText) ||
              nomeFantasia.includes(queryText) ||
              nome.includes(queryText) ||
              cpf.includes(queryText)
          );
        });
      } else {
        this.empresasFiltradas = [...this.empresasUsuario];
      }

      // const search = event.query.trim();
      //
      // if(search) {
      //   this.getEmpresas(search);
      // }

    },

    searchCliente(event) {

      const search = event.query.trim();

      if(search) {
        this.getClientes(search);
      }


    },

    avancar(){
      this.step++;

      if(this.step == 2){
        this.getServicos();
        this.getProdutos();
      }

    },
    itemTextEmpresa(item) {
      if (item.cpf) {
        return `${item.nome ? item.nome : item.razao_social} - ${this.$masks.cpf(item.cpf)}`;
      }

      if (item.cnpj) {
        return `${item.razao_social} - ${this.$masks.cnpj(item.cnpj)}`;
      }

      //return `${item.nome} ${item.razao_social} ${item.cpf} ${item.cnpj} ${item.telefone_celular} ${item.email} ${item.id}`;
    },

    itemTextCliente(item) {
      if (item.cpf) {
        return `${item.nome} - ${this.$masks.cpf(item.cpf)}`;
      }

      if (item.cnpj) {
        return `${item.nome} - ${this.$masks.cnpj(item.cnpj)}`;
      }

    },
    async onEmpresaSelected() {
      if(this.empresaSelecionada) {
        //this.getClientes();
        this.getEmpresasMarketPLace();
        this.clienteSelecionado = null;
        this.servicoSelecionado = null;
        this.itens = [];
      }
    },

    async getEmpresa(id) {
      this.$axios
        .get(`/empresa/${id}`)
        .then(response => {
          this.empresaSelecionada = response.data;
          this.onEmpresaSelected();
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Consultar Empresa",
            text: error.response.data
          });
        });
    },
    async getEmpresas() {
      return this.$axios
        .get(`/usuario/${this.user.id}/empresas`)
        .then(response => {
          this.empresasUsuario = response.data
            .map(empresa => {
              empresa.telefone = this.$masks.telefone(empresa.telefone);
              empresa.cnpj = this.$masks.cnpj(empresa.cnpj);
              return empresa;
            });

          this.empresasFiltradas = this.empresasUsuario;

        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Consultar Empresas",
            text: error.response.data
          });
        });
    },
    async getEmpresasMarketPLace() {
      if (!this.empresaSelecionada || !this.empresaSelecionada.id) {
        this.empresasMarketPlace = [];
      }

      return this.$axios
        .get(`/empresa/${this.empresaSelecionada.id}/marketplace?status=ATIVA`)
        .then(response => {
          this.empresasMarketPlace = response.data.map(empresa => {
            empresa.telefone = this.$masks.telefone(empresa.telefone);
            empresa.cnpj = this.$masks.cnpj(empresa.cnpj);
            return empresa;
          });
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Consultar Empresas",
            text: error.response.data
          });
        });
    },

    getClientes(filtro) {
      if(!this.empresaSelecionada) return;
      this.pesquisando = true;
      return this.$axios
        .get(`/cliente/empresa/${this.empresaSelecionada.id}/listar`,{
          headers: {
            filtros: JSON.stringify({
              cliente: filtro ? [filtro.trim()]: [],
              status: ['ATIVO']
            }),
            noloading: true
          },
          params: {
            first: 0,
            limit: 20,
            ordem: "DESC"
          }
        })
        .then(response => {
          this.clientesUsuario = response.data.data;
          this.clientesFiltrados = response.data.data;
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Consultar Clientes",
            text: error.response.data
          });
        }).finally(() =>{
          this.pesquisando = false;
        });
    },
    getServicos() {
      this.servicos = [];
      return this.$axios
        .get(`/servico/empresa/${this.empresaSelecionada.id}/listar`,{
          headers: {
            filtros: JSON.stringify({
              servico: [],
              status: ['ATIVO']
            })
          },
          params: {
            first: 0,
            limit: 900000,
            ordem: "DESC"
          }
        })
        .then(response => {
          this.servicos = response.data.data.map((item) => {
            return {
              ...item,
              valor_original: item.valor
            };
          });
        });
    },
    getProdutos() {
      this.produtos = [];
      return this.$axios
        .get(`/produto/empresa/${this.empresaSelecionada.id}/listar`,{
          headers: {
            filtros: JSON.stringify({
              produto: [],
              status: ['ATIVO']
            })
          },
          params: {
            first: 0,
            limit: 900000,
            ordem: "DESC"
          }
        })
        .then(response => {
          this.produtos = response.data.data.map((item) => {
            return {
              ...item,
              valor_original: item.valor
            };
          });
        });
    },
    onServicoSelect(event) {
      const findItem = this.itens.some(
        item => item.servico != null && item.servico.id === event.data.id
      );

      if (findItem) {
        this.$toast.add({
          severity: "warn",
          summary: `Serviço ${event.data.nome} já foi adicionado!`,
          life: 2000
        });
        return;
      }

      const itemData = {
        servico: event.data,
        produto: null,
        quantidade: 1,
        valor: Number(parseFloat(event.data.valor).toFixed(2)),
        valor_original: Number(parseFloat(event.data.valor_original).toFixed(2))
      };

      this.itens.push(itemData);

      this.$toast.add({
        severity: "success",
        summary: `Serviço ${event.data.nome} adicionado!`,
        life: 2000
      });
    },
    onProdutoSelect(event) {
      const findItem = this.itens.some(
        item => item.produto != null && item.produto.id === event.data.id
      );
      if (findItem) {
        this.$toast.add({
          severity: "warn",
          summary: `Produto ${event.data.nome} já foi adicionado!`,
          life: 2000
        });
        return;
      }

      const itemData = {
        produto: event.data,
        servico: null,
        quantidade: 1,
        valor: Number(parseFloat(event.data.valor).toFixed(2)),
        valor_original: Number(parseFloat(event.data.valor_original).toFixed(2))
      };
      this.itens.push(itemData);

      this.$toast.add({
        severity: "success",
        summary: `Produto ${event.data.nome} adicionado!`,
        life: 2000
      });
    },
    addSplit() {
      if (!this.destinatarioSplit || !this.destinatarioSplit.id) {
        this.openDialogMessage({
          type: "warning",
          title: "Atenção",
          text: "Selecione a empresa de destino do split"
        });
        return;
      }

      const findSplit = this.splits.some(
        split => split.recebedor.id === this.destinatarioSplit.id
      );
      if (findSplit) {
        this.openDialogMessage({
          type: "warning",
          title: "Atenção",
          text: "Empresa já está adicionada à lista de splits."
        });
        return;
      }
      const splitData = {
        recebedor: {
          id: this.destinatarioSplit.id,
          nome: this.destinatarioSplit.nome,
          razao_social: this.destinatarioSplit.razao_social,
          nome_fantasia: this.destinatarioSplit.nome_fantasia,
          cnpj: this.destinatarioSplit.cnpj,
          cpf: this.destinatarioSplit.cpf,
          enderecoCompleto: this.destinatarioSplit.enderecoCompleto
        },
        valor: 0,
        metodo: "PERCENTUAL",
        paga_taxa_transacao: this.destinatarioSplit.id == this.empresaAtual.id,
        paga_resto_divisao_taxa:
          this.destinatarioSplit.id == this.empresaAtual.id
      };
      this.splits.push(splitData);
      this.destinatarioSplit = null;
    },
    async save() {
      this.closeDialogConfirm();

      const formasPagto = [];
      if (this.boleto) {
        formasPagto.push("BOLETO");
      }
      if (this.cartao) {
        formasPagto.push("CARTAO");
      }
      if (this.pix) {
        formasPagto.push("PIX");
      }

      const data = {
        // cliente: {
        //   id: this.clienteSelecionado.id
        // },
        empresa: {
          id: this.empresaSelecionada.id
        },
        data_limite_pagamento: this.dataLimitePagto,
        opcoes_pagamento: formasPagto,
        max_parcelas_cartao: this.max_parcelas_cartao,
        max_parcelas_boleto: this.max_parcelas_boleto,
        data_vencimento_boleto: this.data_vencimento_boleto,
        juros_atraso_boleto: this.parseToFloat(
          this.juros_atraso_boleto ? this.juros_atraso_boleto : 0
        ),
        multa_atraso_boleto: this.parseToFloat(
          this.multa_atraso_boleto ? this.multa_atraso_boleto : 0
        ),
        valor_total: this.totalCobranca,
        itens: this.itens.map(item => {

          if (!this.user.permissoes.alterar_valor_item) {
            item.valor = item.valor_original;
          }

          (item.servico = item.servico ? { id: item.servico.id } : null),
            (item.produto = item.produto ? { id: item.produto.id } : null),
            (item.valor = this.parseToFloat(item.valor));
          item.total = (item.quantidade * item.valor).toFixed(2);
          return item;
        }),
        splits: this.splits,
        emitir_nfse: this.emitirNfse,
        observacoes: this.observacoes
      };

      if(this.clienteSelecionado && this.clienteSelecionado.id) {
        data.cliente = {
          id: this.clienteSelecionado.id
        };
      }

      this.$axios
        .post(`/cobranca`, data)
        .then(response => {
          this.cobranca = response.data;
          this.$router.push({ name: "VerCobranca", params: { id: response.data.id }});

        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Criar cobrança",
            text: error.response.data
          });
        });
    },

    openModalConfirmCobranca() {

      let text;

      if(this.clienteSelecionado) {
        text = `Confirma a emissão da cobrança de ${
            this.empresaSelecionada.razao_social ? this.empresaSelecionada.razao_social : this.empresaSelecionada.nome
        } para ${this.clienteSelecionado.nome}
        no valor de ${this.totalCobranca.toFixed(2)}?`;
      } else {
        text = `Confirma a emissão da cobrança no valor de ${this.totalCobranca.toFixed(2)}?`;
      }

      this.openDialogConfirm({
        title: "Atenção",
        text: text,
        confirma: this.save,
        cancela: this.closeDialogConfirm
      });
    },
    openModalRemoveSplit(split, index) {
      this.openDialogConfirm({
        title: "Remover Split",
        text: `Deseja realmente o split da empresa ${split.recebedor.razao_social ? split.recebedor.razao_social : split.recebedor.nome}?`,
        confirma: this.removeSplit.bind(this, index),
        cancela: this.closeDialogConfirm.bind()
      });
    },
    removeItem(item) {
      this.itens.splice(this.itens.indexOf(item), 1);
      this.$toast.add({
        severity: "info",
        summary: `Item ${
          item.servico ? item.servico.nome : item.produto.nome
        } removido!`,
        life: 2000
      });
    },
    removeSplit(index) {
      this.splits.splice(index, 1);
      this.closeDialogConfirm();
    },

    valorSplit(split) {
      if (!split || !split.valor) return 0.0;
      return ((split.valor * this.totalCobranca) / 100).toFixed(2);
    },
    openDialogCliente(mode, item) {
      this.clienteDialog = {
        id: item ? item.id : null,
        showDialog: true,
        mode: mode
      };
    },
    saveCliente(cliente) {
      this.clienteSelecionado = cliente;
      this.clienteDialog.showDialog = false;
    }
  },
  watch: {
      async filtroCliente (val) {
        this.getClientes(val)
      },
    empresaSelecionada() {
      this.clienteSelecionado = null;
    }
  }
};
</script>

<style scoped>
.container {
  min-height: 32rem;
}
.fa-menu-icon {
  font-size: 1.2em;

  margin-right: 10px;
}

.autocomplete-input {
  width: 100%;
  height: 60px;
}

.div-clearable {
  margin-left: -50px;
  margin-top: 18px;
  cursor: pointer;
}

.custom-card {
  border-left: 2px solid black;
  padding: 10px;
}

.text-caption {
  display: block;
}

.sku {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 6rem;
}
.titulo {
  font-size: 1.3rem;
}

i {
  font-size: 1.3rem;
}
.nome-fantasia {
  font-weight: 500;
}
.endereco {
  font-size: 0.8rem;
}

.inline {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.billing-container {
  padding: 10px;
  position: relative;
  margin: 20px;
  background: #fff;
}

.billing-title {
  background: #fff;
  padding: 0 10px;
  position: absolute;
  top: -10px;
  left: 20px;
}

.card-recebedores {
  max-height: 460px;
  overflow-y: auto;
}

.card-recebedores::-webkit-scrollbar {
  width: 8px;
  background-color: #e1e1e1;
}

.card-recebedores::-webkit-scrollbar-thumb {
  background-color: #bebebe;
  border-radius: 10px;
}

@media (max-width: 900px) {
  .col,
  .col-4 {
    flex: none;
    max-width: 25rem;
  }
}
</style>
