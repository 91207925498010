<template>
  <div class="btn">
    <Button type="button" label="" icon="pi pi-search" @click="handleClick" />
  </div>
</template>

<script>
import Button from 'primevue/button';

export default {
  components:{
    Button
  },
  methods: {
    handleClick() {
      this.$emit("buscar")
    }
  }
};
</script>
<style>
.btn{
  display: flex;
  flex-direction: column;
  justify-content: end;
}
</style>
