<template>
  <v-dialog v-model="showDialog" persistent max-width="400">
    <v-card style="overflow: hidden;">

      <v-row dense>
        <v-col class="text-start">
          <v-card-title>Pagamento</v-card-title>
        </v-col>
        <v-col class="text-end mt-3 mr-5">
          <v-icon @click="cancelar">mdi-close</v-icon>
        </v-col>
      </v-row>

      <div v-if="step === 1">
        <form @submit.prevent="save">
          <v-row class="mx-auto">
            <v-col cols="12">
              <v-text-field
                @keyup="form.desconto = $masks.dinheiro(form.desconto)"
                dense
                outlined
                v-model="form.desconto"
                label="Desconto"
                @blur="handleChangeDesconto"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" class="mt-n7">
              <v-text-field
                  maxlength="10"
                  dense
                  outlined
                  v-model="form.valorPago"
                  label="Valor pago"
                  @keyup="form.valorPago = $masks.dinheiro(form.valorPago)"
                  required
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" class="mt-n7">
              <v-text-field
                  disabled
                  dense
                  outlined
                  :value="
              new Intl.NumberFormat('pt-BR', {
                minimumFractionDigits: 2
              }).format(this.totalVenda)
            "
                  label="Total dos produtos"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" class="mt-n7">
              <v-text-field
                  disabled
                  dense
                  outlined
                  :value="
              new Intl.NumberFormat('pt-BR', {
                minimumFractionDigits: 2
              }).format(this.totalPagar)
            "
                  label="Total a pagar"
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row class="mx-auto mb-2 mt-n9">
            <v-col>
              <v-btn
                  :disabled="!user.permissoes.receber_dinheiro"
                  block
                  color="green"
                  class="white--text"
                  type="submit"
              >
                <v-icon
                    left
                    dark
                >
                  mdi-cash
                </v-icon>
                Dinheiro
              </v-btn>
              <span v-if="!user.permissoes.receber_dinheiro" class="text-caption grey--text"><b>Esse usuário não tem permissão.</b></span>
            </v-col>
          </v-row>
        </form>
      </div>

      <div v-if="step === 2">
        <v-row class="mx-auto mt-n8 mb-3">
          <v-col>
            <v-card class="text-center" elevation="0">
              <v-card-text>
                <img style="width: 100px" :src="require(`@/assets/img/concluido.png`)"/>
              </v-card-text>
              <v-card-title style="color: #228527" class="d-flex justify-center mt-n8">PAGAMENTO CONCLUÍDO</v-card-title>
              <v-card-title style="color: #228527" class="d-flex justify-center mt-n8">R$ {{ totalPagar | formatMoney({ minimumFractionDigits: 2 }) }} </v-card-title>
              <v-card-title v-if="troco !== 0" style="color: #810000" class="d-flex justify-center mt-n8">TROCO R$ {{ troco | formatMoney({ minimumFractionDigits: 2 }) }} </v-card-title>
            </v-card>
          </v-col>
        </v-row>
      </div>


    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  props: {
    showDialog: {
      type: Boolean,
      required: true
    },
    totalVenda: Number
  },
  computed: {
    ...mapState(["user", "empresaAtual"]),
  },
  data() {
    return {
      step: 1,
      form: {
        valorPago: null,
        desconto: null
      },
      troco: null,
      totalPagar: this.totalVenda,
      rules: {
        required: value => !!value || "Esse Campo é Obrigatório!."
      }
    };
  },
  watch: {
    showDialog() {
      this.step = 1;
    },
    totalVenda(newValue) {
      this.totalPagar = newValue;
    }
  },
  methods: {
    ...mapMutations(["openDialogMessage"]),
   async save() {
      const total = this.totalPagar;
      const valor = parseFloat(this.form.valorPago.replace(/\./g, "").replace(",", "."));
      const desconto = this.form.desconto ? parseFloat(this.form.desconto.replace(/\./g, "").replace(",", ".")) : 0

      if(total > valor) {
        this.openDialogMessage({
          type: "warning",
          title: "Atenção",
          text: "O valor pago tem que ser maior que o Total!"
        });
        return;
      }

      this.troco = valor - total;

      // const data = [
      //   {
      //     forma_pagamento: "DINHEIRO",
      //     desconto: desconto,
      //     valor: total
      //   }
      // ];
      //
      // this.$emit("salva-dados", data);
      // this.step = 2;

     const data = {
       forma_pagamento: "DINHEIRO",
       desconto: desconto,
       valor: total
     };

     this.$emit("salva-dados", data);
     this.step = 2;



    },
    cancelar() {
      this.$emit("cancela-dialog");
    },
    handleChangeDesconto() {
      const desconto = this.parseToFloat(this.form.desconto);
      const totalVenda = this.totalVenda;

      if(desconto >= totalVenda) {
        this.openDialogMessage({
          type: "warning",
          title: "Atenção",
          text: "O Desconto não pode ser maior ou igual que o Total!"
        });
        return;
      }

      this.totalPagar = this.totalVenda - desconto;
    }
  }
};
</script>

<style>

</style>
