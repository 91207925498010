<template>
  <v-container>
    <DialogDadosClientePagamento
      v-if="step === 1"
      titulo="Dados do Titular do Cartão"
      :show="true"
      :cliente="cliente"
      @dadosCliente="clienteInformado"
      @fechar-dialog="$emit('cancela-pagamento-cartao')"
    />
    <div v-if="step === 2" class="card-form">
      <div class="card-list">
        <v-btn
            fab
            small
            text
            style="float: right"
            @click="$emit('cancela-pagamento-cartao')"
        ><v-icon>mdi-close</v-icon></v-btn
        >
        <Card
            :fields="fields"
            :labels="formData"
            :isCardNumberMasked="isCardNumberMasked"
        />
      </div>
      <div class="card-form__inner">
        <form @submit.prevent="confirma()">
          <div class="card-input">
            <label for="cardNumber" class="card-input__label"
            >Numero do Cartão</label
            >
            <input
                type="tel"
                ref="numero"
                :id="fields.cardNumber"
                @input="changeNumber"
                @focus="focusCardNumber"
                @blur="blurCardNumber"
                class="card-input__input"
                :value="formData.cardNumber"
                :maxlength="cardNumberMaxLength"
                data-card-field
                required
                autocomplete="off"
            />
            <button
                class="card-input__eye"
                :class="{ '-active': !isCardNumberMasked }"
                title="Show/Hide card number"
                tabindex="-1"
                :disabled="formData.cardNumber === ''"
                @click="toggleMask"
            ></button>
          </div>
          <div class="card-input">
            <label for="cardName" class="card-input__label">Nome Titular</label>
            <input
                type="text"
                :id="fields.cardName"
                v-letter-only
                @input="changeName"
                class="card-input__input"
                :value="formData.cardName"
                data-card-field
                required
                autocomplete="off"
            />
          </div>
          <div class="card-form__row">
            <div class="card-form__col">
              <div class="card-form__group">
                <label for="cardMonth" class="card-input__label">Validade</label>
                <select
                    class="card-input__input -select"
                    :id="fields.cardMonth"
                    v-model="formData.cardMonth"
                    @change="changeMonth"
                    data-card-field
                    required
                >
                  <option value disabled selected>Mês</option>
                  <option
                      v-bind:value="n < 10 ? '0' + n : n"
                      v-for="n in 12"
                      v-bind:disabled="n < minCardMonth"
                      v-bind:key="n"
                  >
                    {{ generateMonthValue(n) }}
                  </option>
                </select>
                <select
                    class="card-input__input -select"
                    :id="fields.cardYear"
                    v-model="formData.cardYear"
                    @change="changeYear"
                    data-card-field
                    required
                >
                  <option value disabled selected>Ano</option>
                  <option
                      v-bind:value="$index + minCardYear"
                      v-for="(n, $index) in 12"
                      v-bind:key="n"
                  >
                    {{ $index + minCardYear }}
                  </option>
                </select>
              </div>
            </div>
            <div class="card-form__col -cvv">
              <div class="card-input">
                <label for="cardCvv" class="card-input__label">CVV</label>
                <input
                    type="tel"
                    class="card-input__input"
                    v-number-only
                    :id="fields.cardCvv"
                    maxlength="4"
                    :value="formData.cardCvv"
                    @input="changeCvv"
                    data-card-field
                    autocomplete="off"
                    required
                />
              </div>
            </div>
            <div class="card-form__col">
              <div class="card-input">
                <label for="cardParcels" class="card-input__label">Parcelas</label>
                <select
                    class="card-input__input -select"
                    v-model="formData.parcels"
                    :id="fields.cardParcels"
                    data-card-field
                    required
                >

                  <option disabled value="">Selecione</option>
                  <option
                      v-bind:value="n"
                      v-for="n in maxParcelas"
                      v-bind:key="n">
                    <span v-if="n === 1"> À Vista </span>
                    <span v-else> {{ n }} vezes </span>
                  </option>
                </select>
              </div>
            </div>
          </div>

          <button class="card-form__button btConfirma" type="submit" :disabled="isProcessing">
            Confirmar
          </button>
        </form>
      </div>
    </div>
  </v-container>
</template>

<script>
import Card from "@/components/Cartao/Card";
import {mapMutations} from "vuex";
import DialogDadosClientePagamento from "@/components/Dialogs/DialogDadosClientePagamento.vue";

export default {
  name: "CardForm",
  directives: {
    "number-only": {
      bind(el) {
        function checkValue(event) {
          event.target.value = event.target.value.replace(/[^0-9]/g, "");
          if (event.charCode >= 48 && event.charCode <= 57) {
            return true;
          }
          event.preventDefault();
        }
        el.addEventListener("keypress", checkValue);
      },
    },
    "letter-only": {
      bind(el) {
        function checkValue(event) {
          if (event.charCode >= 48 && event.charCode <= 57) {
            event.preventDefault();
          }
          return true;
        }
        el.addEventListener("keypress", checkValue);
      },
    },
  },
  props: {
    cliente: {
      type: Object,
    },
    formData: {
      type: Object,
      default: () => {
        return {
          cardName: "",
          cardNumber: "",
          cardNumberNotMask: "",
          cardMonth: "",
          cardYear: "",
          cardCvv: "",
          parcels: "",
        };
      },
    },
    maxParcelas: {
      type: Number,
      default: 1,
    },
  },
  components: {
    Card,
    DialogDadosClientePagamento
  },
  data() {
    return {
      erros: [],
      step: 1,
      isProcessing: false,
      fields: {
        cardNumber: "v-card-number",
        cardName: "v-card-name",
        cardMonth: "v-card-month",
        cardYear: "v-card-year",
        cardCvv: "v-card-cvv",
        cardParcels: "v-card-parcels",
      },
      minCardYear: new Date().getFullYear(),
      isCardNumberMasked: true,
      mainCardNumber: this.cardNumber,
      cardNumberMaxLength: 19,
    };
  },
  computed: {
    minCardMonth() {
      if (this.formData.cardYear === this.minCardYear)
        return new Date().getMonth() + 1;
      return 1;
    },
  },
  watch: {
    cardYear() {
      if (this.formData.cardMonth < this.minCardMonth) {
        this.formData.cardMonth = "";
      }
    },
  },
  mounted() {
    this.maskCardNumber();
  },
  methods: {
    ...mapMutations([
      "openDialogMessage",
      "openDialogConfirm",
      "closeDialogConfirm"
    ]),
    generateMonthValue(n) {
      return n < 10 ? `0${n}` : n;
    },
    changeName(e) {
      this.formData.cardName = e.target.value;
      this.$emit("input-card-name", this.formData.cardName);
    },
    changeNumber(e) {
      this.formData.cardNumber = e.target.value;
      const value = this.formData.cardNumber.replace(/\D/g, "");
      // american express, 15 digits
      if (/^3[47]\d{0,13}$/.test(value)) {
        this.formData.cardNumber = value
          .replace(/(\d{4})/, "$1 ")
          .replace(/(\d{4}) (\d{6})/, "$1 $2 ");
        this.cardNumberMaxLength = 17;
      } else if (/^3(?:0[0-5]|[68]\d)\d{0,11}$/.test(value)) {
        // diner's club, 14 digits
        this.formData.cardNumber = value
          .replace(/(\d{4})/, "$1 ")
          .replace(/(\d{4}) (\d{6})/, "$1 $2 ");
        this.cardNumberMaxLength = 16;
      } else if (/^\d{0,16}$/.test(value)) {
        // regular cc number, 16 digits
        this.formData.cardNumber = value
          .replace(/(\d{4})/, "$1 ")
          .replace(/(\d{4}) (\d{4})/, "$1 $2 ")
          .replace(/(\d{4}) (\d{4}) (\d{4})/, "$1 $2 $3 ");
        this.cardNumberMaxLength = 19;
      }
      // eslint-disable-next-line eqeqeq
      if (e.inputType == "deleteContentBackward") {
        const lastChar = this.formData.cardNumber.substring(
          this.formData.cardNumber.length,
          this.formData.cardNumber.length - 1
        );
        // eslint-disable-next-line eqeqeq
        if (lastChar == " ") {
          this.formData.cardNumber = this.formData.cardNumber.substring(
            0,
            this.formData.cardNumber.length - 1
          );
        }
      }
      this.$emit("input-card-number", this.formData.cardNumber);
    },
    changeMonth() {
      this.$emit("input-card-month", this.formData.cardMonth);
    },
    changeYear() {
      this.$emit("input-card-year", this.formData.cardYear);
    },
    changeCvv(e) {
      this.formData.cardCvv = e.target.value;
      this.$emit("input-card-cvv", this.formData.cardCvv);
    },
    clienteInformado(cliente) {
      if(cliente) {
        this.formData.cliente = cliente;
        this.step = 2;
      }
    },
    confirma() {
      if (this.isProcessing) return;
      this.isProcessing = true;
      const number = this.formData.cardNumberNotMask.replace(/ /g, "");

      this.erros = [];

      if (!number) {
        this.erros.push("Informe o número do cartão");
        return;
      }

      if (!this.isCartaoValido(number)) {
        this.erros.push("Número do Cartão Inválido");
        return;
      }

      if (!this.formData.cardName) {
        this.erros.push("Informe o nome do titular do cartão");
        return;
      }
      if (!this.formData.cardMonth) {
        this.erros.push("Informe o mês de expiração");
        return;
      }
      if (!this.formData.cardYear) {
        this.erros.push("Informe o ano de expiração");
        return;
      }
      if (!this.formData.cardCvv) {
        this.erros.push("Informe o numero do CVV");
        return;
      }

      if (this.erros.length > 0) {
        this.isProcessing = false;
        return;
      }

      this.$emit("cartao", this.formData);

      setTimeout(() => {
        this.isProcessing = false;
      }, 2000);
    },

    blurCardNumber() {
      if (this.isCardNumberMasked) {
        this.maskCardNumber();
      }
    },
    maskCardNumber() {
      this.formData.cardNumberNotMask = this.formData.cardNumber;
      this.mainCardNumber = this.formData.cardNumber;
      const arr = this.formData.cardNumber.split("");
      arr.forEach((element, index) => {
        if (index > 4 && index < 14 && element.trim() !== "") {
          arr[index] = "*";
        }
      });
      this.formData.cardNumber = arr.join("");
    },
    unMaskCardNumber() {
      this.formData.cardNumber = this.mainCardNumber;
    },
    focusCardNumber() {
      this.unMaskCardNumber();
    },
    toggleMask() {
      this.isCardNumberMasked = !this.isCardNumberMasked;
      if (this.isCardNumberMasked) {
        this.maskCardNumber();
      } else {
        this.unMaskCardNumber();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/cartao.scss";
</style>
