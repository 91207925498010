<template>
  <div>
    <div class="grey lighten-5">
      <v-skeleton-loader
          elevation="0"
          height="80"
          :loading="loadingSkeleton"
          type="image"
      >
        <v-row class="mx-auto align-center">
          <v-col cols="3" class="text-start">
            <p class="headline font-weight-bold">#{{ transacao?.id }}</p>
            <p class="caption">
              <span>Efetuada em</span>
              {{ transacao?.data_criacao | formatDate({ dateStyle: "short" }) }} às
              {{ transacao?.data_criacao | formatDate({ timeStyle: "short" }) }}
              <br />
              <span>Atualizada em</span>
              {{
                transacao?.data_atualizacao | formatDate({ dateStyle: "short" })
              }}
              às
              {{
                transacao?.data_atualizacao | formatDate({ timeStyle: "short" })
              }}
            </p>
          </v-col>

          <v-divider vertical></v-divider>
          <v-col class="text-center">
            <span class="caption font-weight-bold green--text">R$</span>
            <span class="text-h2 font-weight-bold">
            {{ transacao?.valor | formatMoney({ minimumFractionDigits: 2 }) }}
          </span>

            <div class="caption" style="margin-top: 0.2rem;">
            <span
            >MDR R$
              {{
                transacao?.valor_taxa_administracao
                    | formatMoney({ minimumFractionDigits: 2 })
              }}</span
            >
              <span class="justify-end">
              Antecipação R$
              {{
                  transacao?.valor_taxa_antecipacao
                      | formatMoney({ minimumFractionDigits: 2 })
                }}
            </span>
            </div>
          </v-col>

          <v-divider vertical></v-divider>

          <v-col cols="2" class="text-center" v-if="transacao?.antifraude">
            <div class="field col-12 md:col-4">
              <Knob
                  v-model="transacao.antifraude.score"
                  valueTemplate="{value}%"
                  valueColor="#ff4032"
                  rangeColor="#3b82f6"
                  readonly
              />

              <span>Análise de risco</span>
            </div>
          </v-col>

          <v-col cols="3" class="text-center">
            <v-chip
                :color="statusStyle(transacao?.status)"
                text-color="white"
                style="justify-content: center; min-width: 10rem"
                class="ma-2 text-center font-weight-bold"
            >
              {{ transacao?.status }}
            </v-chip>
            <v-row>
              <v-col>
                <v-btn dark outlined color="purple" @click="dialogConfirmaEstorno"
                       v-if="transacao?.status === 'PAGO' && user.permissoes.cancelar_transacao && transacao?.tipo !== 'PIX'">
                  Estornar
                </v-btn>
                <v-btn dark outlined color="purple" @click="dialogConfirmaEstorno" v-if="permiteCancelamento">
                  Cancelar
                </v-btn>
                <v-btn dark outlined color="green" @click="dialogConfirmaReprocessamento" v-if="permiteReprocessar">
                  Reprocessar
                </v-btn>
                <v-btn dark outlined color="blue" @click="generateCancelationLetter" v-if="transacao?.status === 'ESTORNADO'">
                  Carta de Estorno
                </v-btn>
              </v-col>

            </v-row>

          </v-col>
        </v-row>
      </v-skeleton-loader>
    </div>

    <div>
      <v-row class="mx-auto">
        <v-col class="text-center">
          <v-skeleton-loader
              :loading="loadingSkeleton"
              type="image"
          >
            <p class="text-h6 font-weight-bold">
              <v-icon class="mr-2"> mdi-arrow-expand </v-icon>DETALHES DA TRANSAÇÃO
            </p>
            <v-divider></v-divider>

            <v-row>
              <v-col class="text-start px-4 py-2" v-if="transacao?.usuario_criacao">
                <span class="overline font-italic">Criado por</span>
                <v-divider></v-divider>
                <span class="text-subtitle-2">{{ transacao?.usuario_criacao?.nome }}</span>
              </v-col>
              <v-col class="text-start px-4 py-2" v-if="transacao?.usuario_atualizacao">
                <span class="overline font-italic">Atualizado por</span>
                <v-divider></v-divider>
                <span class="text-subtitle-2">{{ transacao?.usuario_atualizacao?.nome }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-start px-4 py-2" v-if="transacao?.nsu">
                <span class="overline font-italic">NSU</span>
                <v-divider></v-divider>
                <span class="text-subtitle-2">{{ transacao?.nsu }}</span>
              </v-col>
              <v-col class="text-start px-3 py-2">
                <span class="overline font-italic">PDV</span>
                  <v-divider></v-divider>

                  <span class="text-subtitle-2">
                    {{ transacao?.pdv_adquirente }}
                </span>
              </v-col>

            </v-row>
            <v-row>

              <v-col class="text-start px-3 py-2" v-if="transacao?.nsu_terminal">
                <span class="overline font-italic">NSU TERMINAL</span>
                <v-divider></v-divider>

                <span class="text-subtitle-2">{{ transacao?.nsu_terminal }}</span>
              </v-col>

              <v-col class="text-start px-3 py-2" v-if="transacao?.numero_terminal">
                <span class="overline font-italic">Terminal</span>
                <v-divider></v-divider>

                <span class="text-subtitle-2">
                  {{ transacao?.numero_terminal }}
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-start px-4 py-2" v-if="transacao?.mid">
                <span class="overline font-italic">MID</span>
                <v-divider></v-divider>
                <span class="text-subtitle-2">{{ transacao?.mid }}</span>
              </v-col>
              <v-col class="text-start px-4 py-2" v-if="transacao?.codigo_autorizacao">
                <span class="overline font-italic">AUTORIZAÇÃO</span>
                <v-divider></v-divider>
                <span class="text-subtitle-2">{{
                    transacao?.codigo_autorizacao
                  }}</span>
              </v-col>
              <v-col class="text-start px-4 py-2" v-if="transacao?.codigo_aid">
                <span class="overline font-italic">CÓDIGO AID</span>
                <v-divider></v-divider>
                <span class="text-subtitle-2">{{ transacao?.codigo_aid }}</span>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6" class="text-start px-4 py-2" v-if="carteira">
                <span class="overline font-italic">Carteira</span>
                <v-divider></v-divider>
                <span class="text-subtitle-2">{{ transacao?.carteira_id }}</span>
              </v-col>
              <v-col cols="3" class="text-start px-4 py-2" v-if="carteira">
                <span class="overline font-italic">Tipo</span>
                <v-divider></v-divider>
                <span class="text-subtitle-2">{{ carteira.tipo }}</span>
              </v-col>
              <v-col cols="3" class="text-start px-4 py-2" v-if="carteira">
                <span class="overline font-italic">TAG</span>
                <v-divider></v-divider>
                <span class="text-subtitle-2">{{ carteira.tag }}</span>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="text-start px-4 py-2" v-if="carteira">
                <span class="overline font-italic">Cliente</span>
                <v-divider></v-divider>
                <span class="text-subtitle-2">{{ carteira.cliente.nome }}</span>
              </v-col>
              <v-col class="text-start px-4 py-2" v-if="carteira">
                <span class="overline font-italic">Telefone</span>
                <v-divider></v-divider>
                <span class="text-subtitle-2">{{ carteira.cliente.telefone_celular }}</span>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="text-start px-4 py-2" v-if="transacao?.cobranca_id">
                <span class="overline font-italic">Cobrança</span>
                <v-divider></v-divider>
                <span class="text-subtitle-2">{{ transacao?.cobranca_id }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-start px-4 py-2" v-if="transacao?.assinatura_id">
                <span class="overline font-italic">Assinatura</span>
                <v-divider></v-divider>
                <span class="text-subtitle-2">{{ transacao?.assinatura_id }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-start px-4 py-2" v-if="transacao?.client_ip">
                <span class="overline font-italic">IP Cliente</span>
                <v-divider></v-divider>
                <span class="text-subtitle-2">{{ transacao?.client_ip }}</span>
              </v-col>
              <v-col class="text-start px-4 py-2" v-if="transacao?.remote_ip">
                <span class="overline font-italic">IP Remoto</span>
                <v-divider></v-divider>
                <span class="text-subtitle-2">{{ transacao?.remote_ip }}</span>
              </v-col>
              <v-col
                  class="text-start px-4 py-2"
                  v-if="transacao?.remote_ip_cancelamento"
              >
                <span class="overline font-italic">IP Cancelamento</span>
                <v-divider></v-divider>
                <span class="text-subtitle-2">{{
                    transacao?.remote_ip_cancelamento
                  }}</span>
              </v-col>
            </v-row>
            <div v-if="transacao?.nota_fiscal">
              <v-row dense>
                <v-col class="overline font-italic">Nota Fiscal</v-col>
              </v-row>
              <v-row>
                <v-col class="text-start px-4 py-2"
                ><span class="overline font-italic">Status</span>
                  <v-divider></v-divider>
                  <span class="text-subtitle-2">{{
                      STATUS_NOTA[transacao?.nota_fiscal.status].texto
                    }}</span>
                </v-col>
                <v-col class="text-start px-4 py-2">
                  <span class="overline font-italic">Número</span>
                  <v-divider></v-divider>
                  <span class="text-subtitle-2">{{
                      transacao?.nota_fiscal?.numero
                    }}</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="text-start px-4 py-2"
                ><span class="overline font-italic">RPS</span>
                  <v-divider></v-divider>

                  <span class="text-subtitle-2"
                  >{{ transacao?.nota_fiscal?.numero_rps }}
                </span>
                </v-col>
                <v-col class="text-start px-4 py-2">
                  <span class="overline font-italic">Série</span>
                  <v-divider></v-divider>
                  <span class="text-subtitle-2">{{
                      transacao?.nota_fiscal?.serie_rps
                    }}</span>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="text-start px-4 py-2">
                  <span class="overline font-italic">Código Verificação</span>
                  <v-divider></v-divider>
                  <span class="text-subtitle-2">{{
                      transacao?.nota_fiscal?.codigo_verificao
                    }}</span>
                </v-col>

                <v-col class="text-start px-4 py-2" v-if="transacao?.nota_fiscal?.url">
                  <span class="overline font-italic">Links</span>
                  <v-divider></v-divider>
                  <Button
                      icon="pi pi-file"
                      v-if="transacao?.nota_fiscal?.url"
                      @click="verNota(transacao?.nota_fiscal)"
                      class="p-button-link"
                      label="Abrir"
                  />
                </v-col>
              </v-row>
            </div>
            <div v-if="transacao?.nsu_cancelamento">
              <v-row>
                <v-col class="text-start px-4 py-2">
                  <span class="overline font-italic">NSU Cancelamento</span>
                  <v-divider></v-divider>

                  <span class="text-subtitle-2">{{
                      transacao?.nsu_cancelamento
                    }}</span>
                </v-col>
                <v-col class="text-start px-4 py-2">
                  <span class="overline font-italic">Data Cancelamento</span>
                  <v-divider></v-divider>
                  <span class="text-subtitle-2">
                  {{
                      transacao?.data_atualizacao
                          | formatDate({ dateStyle: "short" })
                    }}
                  às
                  {{
                      transacao?.data_atualizacao
                          | formatDate({ timeStyle: "short" })
                    }}
                </span>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                    class="text-start px-4 py-2"
                    v-if="transacao?.codigo_autorizacao_cancelamento"
                >
                  <span class="overline font-italic">Cód. Aut. Cancelamento</span>
                  <v-divider></v-divider>
                  <span class="text-subtitle-2">{{
                      transacao?.codigo_autorizacao_cancelamento
                    }}</span>
                </v-col>
                <v-col
                    class="text-start px-4 py-2"
                    v-if="transacao?.nsu_terminal_cancelamento"
                >
                <span class="overline font-italic"
                >NSU Terminal Cancelamento</span
                >
                  <v-divider></v-divider>
                  <span class="text-subtitle-2">{{
                      transacao?.nsu_terminal_cancelamento
                    }}</span>
                </v-col>
              </v-row>
            </div>
            <v-row>
              <v-col class="text-start px-4 py-2">
              <span
                  class="overline font-italic"
                  v-if="transacao?.descricao || transacao?.erro_msg"
              >INFORMAÇÕES</span
              >
                <v-divider></v-divider>
                <span class="text-subtitle-2" v-if="transacao?.descricao">{{
                    transacao?.descricao
                  }}</span>
                <span class="text-subtitle-2" v-if="transacao?.erro_msg">
                {{ transacao?.erro_code }} - {{ transacao?.erro_msg }}
              </span>
              </v-col>
            </v-row>
            <v-row v-if="transacao?.id_externo">
              <v-col class="text-start px-4 py-2">
                <span class="overline font-italic">ID Externo</span>
                <v-divider></v-divider>
                <span class="text-subtitle-2">{{ transacao?.id_externo }}</span>
              </v-col>
            </v-row>
          </v-skeleton-loader>
        </v-col>
        <v-col cols="7" class="text-center">
          <v-skeleton-loader
              :loading="loadingSkeleton"
              type="image"
          >
            <p class="text-h6 font-weight-bold">
              <i
                  v-if="transacao?.tipo === 'CREDITO' || transacao?.tipo === 'DEBITO'"
                  class="far fa-credit-card"
              ></i>
              <i v-if="transacao?.tipo === 'BOLETO'" class="far fa-file-alt"></i>
              {{ TIPO_TRANSACAO[transacao?.tipo] }}
            </p>
            <v-divider class="mb-4"></v-divider>

            <v-card class="cartao" style="width: 100%;"
                    v-if="transacao?.tipo === 'CREDITO' || transacao?.tipo === 'DEBITO'">
              <!-- Card Cartão -->
              <v-card-text class="grey lighten-4 pa-0">
                <v-row dense>
                  <v-col>
                    <v-row dense>
                      <v-col class="text-start pl-6 py-2">
                        <i
                            class="far fa-credit-card green--text"
                            style="font-size: 2.5em"
                        ></i>
                      </v-col>
                    </v-row>

                    <v-row dense class="text-start">
                      <v-col class="text-start px-4">
                        <span class="text-subtitle-2">
                          {{ transacao?.card_first_digits }} **** ****
                          {{ transacao?.card_last_digits }}
                        </span>
                      </v-col>
                    </v-row>

                    <v-row dense class="text-start">
                      <v-col class="text-start px-4">
                        <span class="text-subtitle-1 font-weight-bold">
                          {{transacao?.card_holder_name}}
                        </span>
                      </v-col>
                    </v-row>

                    <v-row dense class="text-start">
                      <v-col class="text-start px-4 py-2">
                        <span class="overline font-italic">
                          {{ tipoTransDesc }}
                        </span>
                          <span v-if="transacao?.tipo === 'CREDITO'"
                              class="text-h5 font-weight-bold">
                            {{transacao?.parcelas == 1? "": transacao?.parcelas + "X"}}
                        </span>
                      </v-col>

                      <v-col dense class="text-end">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <span v-bind="attrs" v-on="on">
                                <img
                                    style="width: 4rem;margin: 5px;"
                                    :title="transacao?.card_brand"
                                    :src="
                                    transacao?.card_brand
                                      ? require(`@/assets/img/${transacao?.card_brand
                                          .split(' ')[0]
                                          .toUpperCase()}.jpeg`)
                                      : require(`@/assets/img/no-credcard.png`)
                                  "
                                />
                              </span>
                            </template>
                            <span>{{
                                transacao?.card_brand
                                    ? transacao?.card_brand.toUpperCase()
                                    : ""
                              }}</span>
                          </v-tooltip>
                      </v-col>

                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <!-- Card Boleto -->
            <v-card
                outlined
                v-if="transacao?.tipo === 'BOLETO' || transacao?.tipo === 'PIX'"
            >
              <v-card-text class="grey lighten-4 pa-0">
                <v-row>
                  <v-col v-if="transacao?.tipo === 'BOLETO'">
                    <v-row dense class="text-start">
                      <v-col class="text-start px-4" cols="3">
                      <span class="text-subtitle-1 font-italic"
                      >Vencimento</span
                      >
                        <v-divider width="200px" class="mb-2"></v-divider>
                        <span class="text-subtitle-2 font-weight-bold">
                        {{
                            transacao?.vencimento_boleto
                                | formatDate({ dateStyle: "short" })
                          }}
                      </span>
                      </v-col>
                      <v-col
                          class="text-start px-4"
                          cols="3"
                          v-if="transacao?.data_pagamento_boleto"
                      >
                        <span class="text-subtitle-1 font-italic">Pagamento</span>
                        <v-divider width="200px" class="mb-2"></v-divider>
                        <span class="text-subtitle-2 font-weight-bold">
                        {{
                            transacao?.data_pagamento_boleto
                                | formatDate({ dateStyle: "short" })
                          }}
                      </span>
                      </v-col>
                      <v-col
                          class="text-start px-1"
                          cols="3"
                          v-if="transacao?.valor_pago_juros_multa"
                      >
                        <span class="text-subtitle-1 font-italic">J.M. Pago</span>
                        <v-divider width="100px" class="mb-2"></v-divider>
                        <span class="text-subtitle-2 font-weight-bold">
                        R$ {{ transacao?.valor_pago_juros_multa }}
                      </span>
                      </v-col>
                      <v-col class="text-start px-4" cols="2">
                        <span class="text-subtitle-1 font-italic">Juros</span>
                        <v-divider width="100px" class="mb-2"></v-divider>
                        <span class="text-subtitle-2 font-weight-bold">
                        {{ transacao?.juros_atraso_boleto }}%
                      </span>
                      </v-col>
                      <v-col class="text-start px-4" cols="2">
                        <span class="text-subtitle-1 font-italic">Multa</span>
                        <v-divider width="100px" class="mb-2"></v-divider>
                        <span class="text-subtitle-2 font-weight-bold">
                        {{ transacao?.multa_atraso_boleto }}%
                      </span>
                      </v-col>
                    </v-row>

                    <v-row dense class="text-start">
                      <v-col class="text-start px-4" cols="4">
                        <span class="text-subtitle-1 font-italic">Documento</span>
                        <v-divider width="200px" class="mb-2"></v-divider>
                        <span class="text-subtitle-2 font-weight-bold">
                        {{ transacao?.cliente?.cpf }}{{ transacao?.cliente?.cnpj }}
                      </span>
                      </v-col>
                      <v-col class="text-start px-4">
                        <span class="text-subtitle-1 font-italic">Cliente</span>
                        <v-divider class="mb-2 mr-2"></v-divider>
                        <span class="text-subtitle-2 font-weight-bold">
                        {{ transacao?.cliente?.nome }}
                      </span>
                      </v-col>
                    </v-row>

                    <v-row dense class="text-start">
                      <v-col class="text-start px-4" cols="4">
                        <span class="text-subtitle-1 font-italic">Telefone</span>
                        <v-divider class="mb-2"></v-divider>
                        <span class="text-subtitle-2 font-weight-bold">
                        {{ transacao?.cliente?.telefone_celular }}
                      </span>
                      </v-col>

                      <v-col class="text-start px-4">
                        <span class="text-subtitle-1 font-italic">E-mail</span>
                        <v-divider class="mb-2 mr-2"></v-divider>
                        <span class="text-subtitle-2 font-weight-bold">
                        {{ transacao?.cliente?.email }}
                      </span>
                      </v-col>
                    </v-row>

                    <v-row
                        dense
                        class="text-start"
                        v-if="transacao?.linha_digitavel_boleto"
                    >
                      <v-col
                          class="text-start px-4"
                          @click="
                        () => copyToClipBoard(transacao?.linha_digitavel_boleto)
                      "
                      >
                      <span class="text-subtitle-1 font-italic"
                      >Linha digitavel</span
                      >
                        <v-divider class="mb-2 mr-2"></v-divider>
                        <span class="text-subtitle-2 font-weight-bold">
                        {{ transacao?.linha_digitavel_boleto }}
                      </span>
                        <span class="fa fa-copy"></span>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <Button v-if="transacao?.status != 'FALHA'"
                                icon="fa fa-barcode"
                                label="Ver boleto"
                                @click="() => openUrl(transacao?.url_boleto)"
                                class="p-button-outlined" >
                        </Button>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col
                      cols="4"
                      v-if="transacao?.tipo === 'PIX' || transacao?.qrcode"
                  >
                    <VueQr :text="transacao?.qrcode" />
                    <v-row dense>
                      <v-col class="text-center px-4">
                        <Button
                            icon="pi pi-copy"
                            label="Link Copia e cola"
                            @click="() => copyToClipBoard(transacao?.qrcode)"
                            class="p-button-outlined"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-skeleton-loader>
        </v-col>
      </v-row>

      <v-skeleton-loader
          :class="loadingSkeleton ? 'mt-10' : ''"
          :loading="loadingSkeleton"
          type="image"
      >
        <v-row>
          <v-col class="text-center" cols="5">
            <v-row v-if="transacao?.cliente">
              <v-col>
                <p class="text-h6 font-weight-bold">
                  <v-icon> mdi-account </v-icon>
                  CLIENTE
                </p>
                <v-divider></v-divider>

                <v-row dense>
                  <v-col class="text-start px-4 py-2" cols="4"
                  ><span class="overline font-italic">DOCUMENTO</span>
                    <v-divider></v-divider>
                    <span class="text-subtitle-2">{{
                        transacao?.cliente?.tipo === "PESSOA_FISICA"
                            ? $masks.cpf(transacao?.cliente?.cpf)
                            : $masks.cnpj(transacao?.cliente?.cnpj)
                      }}</span>
                  </v-col>
                  <v-col class="text-start px-4 py-2"
                  ><span class="overline font-italic">NOME</span>
                    <v-divider></v-divider>
                    <span class="text-subtitle-2">{{ transacao?.cliente?.nome }}</span>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col class="text-start px-4 py-2" cols="4">
                    <span class="overline font-italic">TELEFONE</span>
                    <v-divider></v-divider>

                    <span class="text-subtitle-2">{{
                        $masks.telefone(transacao?.cliente?.telefone_celular)
                      }}</span>
                  </v-col>
                  <v-col class="text-start px-4 py-2"
                  ><span class="overline font-italic">EMAIL</span>
                    <v-divider></v-divider>

                    <span class="text-subtitle-2">{{
                        transacao?.cliente?.email
                      }}</span></v-col
                  >
                </v-row>
                <v-row dense v-if="transacao?.cliente?.cep">
                  <v-col class="text-start px-4 py-2" cols="4">
                    <span class="overline font-italic">CEP</span>
                    <v-divider></v-divider>
                    <span class="text-subtitle-2">{{
                        $masks.cep(transacao?.cliente?.cep)
                      }}</span>
                  </v-col>
                  <v-col class="text-start px-4 py-2"
                  ><span class="overline font-italic">Endereço</span>
                    <v-divider></v-divider>
                    <span class="text-subtitle-2"
                    >{{ transacao?.cliente?.endereco }},
                {{ transacao?.cliente?.numero }}</span
                    >
                  </v-col>
                </v-row>
                <v-row dense v-if="transacao?.cliente?.bairro">
                  <v-col class="text-start px-4 py-2">
                    <span class="overline font-italic">Bairro</span>
                    <v-divider></v-divider>
                    <span class="text-subtitle-2">{{
                        transacao?.cliente?.bairro
                      }}</span>
                  </v-col>
                  <v-col class="text-start px-4 py-2">
                    <span class="overline font-italic">Cidade</span>
                    <v-divider></v-divider>
                    <span class="text-subtitle-2"
                    >{{ transacao?.cliente?.cidade }} -
                {{ transacao?.cliente?.uf }}</span
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="transacao?.itens && transacao?.itens?.length > 0">
                <v-col class="text-center">
                  <p class="text-h6 font-weight-bold">
                    <v-icon> mdi-cube-send </v-icon>
                    Itens
                  </p>
                  <v-divider></v-divider>

                  <v-row class="mt-1" dense>
                    <v-col>
                      <DataTable
                          :value="transacao?.itens"
                          responsiveLayout="stack"
                          sortField="data_criacao"
                          :sortOrder="-1"
                          breakpoint="960px"
                          scrollHeight="flex-row"
                      >
                        <Column field="nome" header="Descrição">
                          <template #body="slot">
                      <span v-if="slot.data.servico">
                        {{ slot.data.servico.nome }}
                      </span>
                            <span v-if="slot.data.produto">
                        {{ slot.data.produto.nome }}
                      </span>
                          </template>
                        </Column>

                        <Column field="quantidade" header="Qtd.">
                          <template #body="slot">
                            {{ slot.data.quantidade }}
                          </template>
                        </Column>

                        <Column field="valor" header="Valor">
                          <template #body="slot">
                            {{ slot.data.valor | formatMoney({ minimumFractionDigits: 2 }) }}
                          </template>
                        </Column>

                        <Column field="desconto" header="Desconto">
                          <template #body="slot">
                            {{ slot.data.desconto | formatMoney({ minimumFractionDigits: 2 }) }}
                          </template>
                        </Column>

                        <Column field="total" header="Total">
                          <template #body="slot">
                            {{ slot.data.total | formatMoney({ minimumFractionDigits: 2 }) }}
                          </template>
                        </Column>

                      </DataTable>
                    </v-col>
                  </v-row>

                </v-col>
              </v-col>
            </v-row>
          </v-col>

          <v-col class="text-center" cols="7" v-if="recebedoresComputed">
            <p class="text-h6 font-weight-bold">
              <v-icon>mdi-axis-arrow</v-icon>RECEBEDORES /
              {{ recebedoresComputed.length }}
            </p>
            <v-divider class="mb-4"></v-divider>
            <v-row>
              <v-col>
                <div
                    class="green white--text rounded-lg pa-5 ml-2"
                    style="width: 320px"
                >
                <span style="font-size: 2.7rem">
                  R$
                  {{
                    transacao?.valor
                        | formatMoney({
                      minimumFractionDigits: 2
                    })
                  }}
                </span>
                  <div class="text-h6">
                    {{ tipoTransDesc }}
                    <span v-if="transacao?.tipo === 'CREDITO'"
                    >{{ transacao?.parcelas }}X</span
                    >
                  </div>
                </div>

                <v-timeline dense class="align-start ml-2">
                  <v-timeline-item
                      color="green"
                      v-for="recebedor in recebedoresComputed"
                      :key="recebedor.id"
                  >
                    <template v-slot:icon></template>
                    <v-card>
                      <v-card-text>
                        <v-row>
                          <v-col class="text-center">
                            <h5 class="text-subtitle-1 font-weight-bold">
                              {{ recebedor.nome_fantasia }}
                            </h5>
                            <span v-if="recebedor.razao_social">{{ recebedor.cnpj }} - {{ recebedor.razao_social }}</span>
                            <span v-if="recebedor.nome">{{ recebedor.cpf }} - {{ recebedor.cpf }}</span>
                          </v-col>
                        </v-row>
                        <v-simple-table>
                          <template v-slot:default>
                            <thead>
                            <tr>
                              <th class="text-center">Parc</th>
                              <th class="text-center">Compensação</th>
                              <th class="text-center">Bruto</th>
                              <th class="text-center">Tx. Adm</th>
                              <th class="text-center">Tx. Ant</th>
                              <th class="text-center">Liquido</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                style="border: 1px solid green"
                                v-for="pagamento in recebedor.pagamentos"
                                class="text-caption"
                                :key="pagamento.id"
                            >
                              <td class="text-center">
                                {{ pagamento.parcela }}
                              </td>
                              <td>
                                {{
                                  pagamento.data_compensacao
                                      | formatDate({ dateStyle: "short" })
                                }}
                              </td>
                              <td class="text-center pa-0">
                                <span class="text-caption"
                                >R$
                                  {{
                                    pagamento.valor
                                        | formatMoney({
                                      minimumFractionDigits: 2
                                    })
                                  }}
                                </span>
                              </td>
                              <td class="text-center pa-0">
                                R$
                                {{
                                  pagamento.valor_taxa_administracao
                                      | formatMoney({
                                    minimumFractionDigits: 2
                                  })
                                }}
                              </td>
                              <td class="text-center pa-0">
                                R$
                                {{
                                  pagamento.valor_taxa_antecipacao
                                      | formatMoney({
                                    minimumFractionDigits: 2
                                  })
                                }}
                              </td>
                              <td class="text-end pa-0">
                                R$
                                {{
                                  pagamento.valor_liquido
                                      | formatMoney({
                                    minimumFractionDigits: 2
                                  })
                                }}
                              </td>
                            </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-card-text>
                    </v-card>
                  </v-timeline-item>
                </v-timeline></v-col
              >
            </v-row>
          </v-col>
        </v-row>
      </v-skeleton-loader>

      <DialogNotaFiscal
          :nota="nota"
          v-if="showNota"
          :showDialog="showNota"
          :close="() => (showNota = false)"
      />
      <div class="d-none">
            <CartaEstorno
              ref="cartaEstorno"
              :transacao="transacao"
            />
      </div>
      <v-snackbar v-model="snackbar" top right>
        Link copiado para a área de transferência
        <template v-slot:action="{ attrs }">
          <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
            Fechar
          </v-btn>
        </template>
      </v-snackbar>
    </div>

  </div>
</template>
<script>
import VueQr from "vue-qr";
import { mapMutations, mapState } from "vuex";
import Button from "primevue/button";
import DialogNotaFiscal from "@/components/Dialogs/DialogNotaFiscal.vue";
import Knob from "primevue/knob";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import CartaEstorno from "@/views/Transacao/CartaEstorno.vue";

export default {
  props: {
    idTransacao: String
  },
  components: {
    CartaEstorno,
    Column, DataTable,
    VueQr,
    Button,
    Knob,
    DialogNotaFiscal
  },
  mounted() {
    this.getTransacao();
  },

  computed: {
    ...mapState(["user"]),
    tipoTransDesc() {
      let tipoTransText;
      switch (this.transacao?.tipo) {
        case "DEBITO":
          tipoTransText = "Débito";
          break;
        case "CREDITO":
          tipoTransText = "Crédito ";
          break;
        case "BOLETO":
          tipoTransText = "Boleto";
          break;
        case "PIX":
          tipoTransText = "PIX";
          break;
        case "ABASTECEAI":
          tipoTransText = "Abastece Ai";
          break;
      }

      return tipoTransText;
    },

    recebedoresComputed() {
      const recebedores = [];
      const pagamentos = this.transacao?.pagamentos;
      if (!pagamentos) return;

      pagamentos.forEach(pagamento => {
        const checkRecebedores = recebedores.some(
          recebedor => recebedor.id == pagamento.recebedor.id
        );
        if (!checkRecebedores) {
          const pagamentosFilter = pagamentos.filter(
            pagamentoFilter =>
              pagamentoFilter.recebedor.id == pagamento.recebedor.id
          );
          recebedores.push({
            ...pagamento.recebedor,
            pagamentos: pagamentosFilter
          });
        }
      });

      return recebedores;
    },
    permiteCancelamento() {
      if (this.transacao?.tipo != "BOLETO") return false;
      if (!this.user.permissoes.cancelar_transacao) return false;
      if (this.transacao?.status === "CANCELADO") return false;
      if (this.transacao?.status === "FALHA") return false;

      return this.transacao?.status != "PAGO";
    },
    permiteReprocessar() {
      if (this.transacao?.origen === "OFF") return false;
      if (this.transacao?.status !== "FALHA" && this.transacao?.status !== "NEGADA") return false;
      return true;
    }
  },
  data() {
    return {
      loadingSkeleton: true,
      transacao: {},
      carteira: null,
      nota: {},
      showNota: false,
      snackbar: false,
      showCarta: false,
    };
  },
  methods: {
    ...mapMutations([
      "openDialogMessage",
      "openDialogConfirm",
      "closeDialogConfirm"
    ]),
    async getCarteira(id) {
      await this.$axios
        .get(`/carteira/${id}`)
        .then(response => {
          this.carteira = response.data;
          this.carteira.cliente.telefone_celular = this.$masks.telefone(this.carteira.cliente.telefone_celular);
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Falha - Consultar Carteira",
            text: error.response.data
          });
        });
    },
    async getTransacao() {
      await this.$axios
        .get(`/transacao/${this.idTransacao}`)
        .then(response => {
          this.transacao = response.data;
          this.loadingSkeleton = false;
          console.log(this.transacao)
          if(this.transacao?.carteira_id) {
            this.getCarteira(this.transacao?.carteira_id);
          }

        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Falha - Consultar Transações",
            text: error.response.data
          });
          this.showDialogEditPagto = false;
        });
    },
    verNota(nota) {
      this.nota = nota;
      this.showNota = true;
    },
    dialogConfirmaEstorno() {
      this.openDialogConfirm({
        title: "Atenção",
        text: `Esta ação não pode ser desfeita, deseja realmente cancelar?`,
        confirma: this.estornarTransacao,
        cancela: this.closeDialogConfirm
      });
    },
    dialogConfirmaReprocessamento() {
      this.openDialogConfirm({
        title: "Atenção",
        text: `Tem certeza que deseja reprocessar esta transação?`,
        confirma: this.reprocessarTransacao,
        cancela: this.closeDialogConfirm
      });
    },
    estornarTransacao() {
      this.closeDialogConfirm();

      this.$axios
        .put(`/transacao/estorna/${this.idTransacao}`)
        .then(() => {
          this.getTransacao();
        })
        .catch(error => {
          let { falha } = error.response.data
          if(!falha){
            falha = error.response.data
          }
          this.openDialogMessage({
              type: "error",
              title: "Falha - Reprocessar Transação",
              text: falha
            });
        });
    },
    reprocessarTransacao() {
      this.closeDialogConfirm();

      this.$axios
        .put(`/transacao/reprocessa/${this.idTransacao}`)
        .then(() => {
          this.getTransacao();
        })
        .catch(error => {
          let { falha } = error.response.data
          if(!falha){
            falha = error.response.data
          }
          this.openDialogMessage({
              type: "error",
              title: "Falha - Reprocessar Transação",
              text: falha
            });
        });
    },
    copyToClipBoard(value) {
      navigator.clipboard.writeText(value);
      this.snackbar = true;
    },
    openUrl(url) {
      window.open(url, "_blank");
    },
    generateCancelationLetter() {
      this.$refs.cartaEstorno.generatePdf();
    },
  }
};
</script>

<style scoped>
.boleto-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  border: solid rgba(0, 0, 0, 0.3);
  border-width: 1px 2px 2px 1px;
  border-radius: 10px;
  height: 150px;
  margin: 0 auto 20px auto;
  padding: 20px;
}

.boleto {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.7)
    ),
    url(../../assets/img/boleto.png) no-repeat;
  background-size: 100% 100%;

  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.p-progressbar {
  margin: 1rem;
}
.p-progressbar-label {
  font-size: 0.7rem;
}
.p-progressbar .p-progressbar-value {
  background: #e51e1e !important;
}
.cartao {
  max-width: 30rem;
  display: inline-block;
}

.cols-itens {
  margin-top: -100px;
}

@media (min-width: 1264px) {
  .container {
    max-width: 1300px;
  }
}
@media (max-width: 900px) {
  .col,
  .col-4 {
    flex: none;
    max-width: 25rem;
  }
}
</style>
