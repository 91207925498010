<template>
  <v-container fluid>
    <form @submit.prevent="getVendasPdv(true)">
      <v-row>
        <div class="espacado">
          <Calendar
            v-model="dataIntervalo"
            selectionMode="range"
            :headers="headerTableVendasPdv"
            @date-select="getVendasPdv(true)"
            :showIcon="true"
            dateFormat="dd/mm/yy"
            :showButtonBar="true"
            placeholder="Filtro por data de criação"
            @clear-click="limparFiltro"
          />
        </div>
        <div class="espacado">
          <Chips
            ref="chipcomponente"
            v-model="filtros.cliente"
            separator=","
            @add="getVendasPdv(true)"
            @remove="getVendasPdv(true)"
            placeholder="Filtro por nome, email, evento, estande"
          />
        </div>
        <div class="espacado">
          <BotaoBuscarListas @buscar="buscar" />
        </div>
        <v-col class="text-end">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  v-bind="attrs"
                  v-on="on"
                  fab
                  dark
                  small
                  color="primary"
                  :to="{ name: 'addVendasPdv' }"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Adicionar</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row class="ml-n12">
        <div class="espacado">
          <v-tabs v-model="tab" align-with-title @change="getVendasPdv(true)">
            <v-tabs-slider color="secondary"></v-tabs-slider>
            <v-tab>
              <v-icon class="mr-3">mdi-exclamation</v-icon>
              Pendentes
            </v-tab>
            <v-tab>
              <v-icon class="mr-3">mdi-cash-usd</v-icon> Pagas
            </v-tab>
            <v-tab>
              <v-icon class="mr-3">mdi-cancel</v-icon>
              Canceladas
            </v-tab>
          </v-tabs>
        </div>
      </v-row>
    </form>
    <v-row>
      <v-col>
        <v-skeleton-loader
            :loading="loadingSkeleton"
            type="table"
        >
          <v-data-table
              :fixed-header="true"
              :headers="headerTableVendasPdv"
              :items="vendas"
              :options="{ itemsPerPage: pagination.limit }"
              hide-default-footer
          >
            <template #[`item.data_criacao`]="{ item }">
            <span :class="tab === 2 ? 'red--text' : ''">
              {{ item.data_criacao | formatDate({ dateStyle: "short", timeStyle: "short" }) }}
            </span>
            </template>

            <template v-slot:[`item.acoes`]="{ item }">
              <div>
                <v-tooltip v-if="item.pago && !item.notasfiscais.some(nf => nf.tipo === 'NFSE')" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <button
                        class="mr-3"
                        v-bind="attrs"
                        v-on="on"
                        @click="gerarNFSe(item)"
                        type="button"
                    >
                      <v-icon>mdi-note</v-icon>
                    </button>
                  </template>
                  <span>Gerar NFSe</span>
                </v-tooltip>
                <v-tooltip v-if="item.pago && !item.notasfiscais.some(nf => nf.tipo === 'NFCE')" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <button
                        class="mr-3"
                        v-bind="attrs"
                        v-on="on"
                        @click="gerarNFCe(item)"
                        type="button"
                    >
                      <v-icon>mdi-note-outline</v-icon>
                    </button>
                  </template>
                  <span>Gerar NFCe</span>
                </v-tooltip>

                <v-tooltip v-if="item.pago && item.notasfiscais.some(nf => nf.tipo === 'NFSE') && item.notasfiscais.find(nf => nf.tipo === 'NFSE').url" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <button
                        class="mr-3"
                        v-bind="attrs"
                        v-on="on"
                        @click="openUrl(item.notasfiscais.find(nf => nf.tipo === 'NFSE').url)"
                        type="button"
                    >
                      <v-icon>mdi-file-eye-outline</v-icon>
                    </button>
                  </template>
                  <span>Ver NFSe</span>
                </v-tooltip>

                <v-tooltip v-if="item.pago && item.notasfiscais.some(nf => nf.tipo === 'NFCE') && item.notasfiscais.find(nf => nf.tipo === 'NFCE').url" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <button
                        class="mr-3"
                        v-bind="attrs"
                        v-on="on"
                        @click="openUrl(item.notasfiscais.find(nf => nf.tipo === 'NFCE').url)"
                        type="button"
                    >
                      <v-icon>mdi-file-eye-outline</v-icon>
                    </button>
                  </template>
                  <span>Ver NFCe</span>
                </v-tooltip>



                <v-tooltip v-if="item.data_cancelamento || item.pago" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <button
                        v-bind="attrs"
                        v-on="on"
                        @click="goToConsultPage(item)"
                        type="button"
                    >
                      <v-icon class="mr-3">mdi-magnify</v-icon>
                    </button>
                  </template>
                  <span>Consultar</span>
                </v-tooltip>
                <v-tooltip v-else bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <button
                        v-bind="attrs"
                        v-on="on"
                        @click="goToEditPage(item)"
                        type="button"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </button>
                  </template>
                  <span>Editar</span>
                </v-tooltip>
              </div>
            </template>

            <template #[`item.id`]="{ item }">
            <span :class="tab === 2 ? 'red--text' : ''">
              {{ item.id }}
            </span>
            </template>
            <template #[`item.cliente.nome`]="{ item }">
            <span :class="tab === 2 ? 'red--text' : ''" v-if="item.cliente">
              {{ item.cliente.nome }}
            </span>
            </template>
            <template #[`item.cliente.telefone_celular`]="{ item }">
            <span :class="tab === 2 ? 'red--text' : ''" v-if="item.cliente">
              {{ $masks.telefone(item.cliente.telefone_celular) }}
            </span>
            </template>
            <template #[`item.prestador.nome_fantasia`]="{ item }">
            <span :class="tab === 2 ? 'red--text' : ''" v-if="item.prestador">
              {{ item.prestador.nome_fantasia }}
            </span>
            </template>
            <template #[`item.pago`]="{ item }">
            <span :class="tab === 2 ? 'red--text' : ''">
              {{ item.pago ? "Sim" : "Não" }}
            </span>
            </template>
            <template #[`item.adicional_prestador`]="{ item }">
              <span :class="tab === 2 ? 'red--text' : ''">
                R$ {{ item.adicional_prestador | formatMoney({ minimumFractionDigits: 2 }) }}
              </span>
            </template>
            <template #[`item.taxa_entrega`]="{ item }">
              <span :class="tab === 2 ? 'red--text' : ''">
                R$ {{ item.taxa_entrega | formatMoney({ minimumFractionDigits: 2 }) }}
              </span>
            </template>
            <template #[`item.valor_total`]="{ item }">
              <span :class="tab === 2 ? 'red--text' : ''">
                R$ {{ item.total | formatMoney({ minimumFractionDigits: 2 }) }}
              </span>
            </template>
            <template #[`item.total_bruto`]="{ item }">
            <span :class="tab === 2 ? 'red--text' : ''">
              R$ {{item.totalPagoBruto | formatMoney({ minimumFractionDigits: 2 })}}
            </span>
            </template>
            <template #[`item.total_pago`]="{ item }">
            <span :class="tab === 2 ? 'red--text' : ''">
              R$ {{item.totalPagoLiquido | formatMoney({ minimumFractionDigits: 2 })}}
            </span>
            </template>
          </v-data-table>
        </v-skeleton-loader>
      </v-col>
    </v-row>
    <v-pagination
      v-model="pagination.page"
      :length="totalPaginas"
      @input="next"
      :total-visible="10"
      :loading="carregando"
    ></v-pagination>
  </v-container>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Calendar from "primevue/calendar";
import Chips from "primevue/chips";
import moment from "moment";
import BotaoBuscarListas from "@/components/BotaoBuscarListas.vue";

export default {
  name: "VendasPdv",
  components: {
    Calendar,
    Chips,
    BotaoBuscarListas
  },
  data() {
    return {
      loadingSkeleton: false,
      tab: this.$store.state.filtrosVendasPdv.tab,
      headerTableVendasPdv: [
        {
          text: "Data",
          value: "data_criacao",
          align: "center"
        },

        {
          text: "Id",
          value: "id",
          align: "center"
        },
        {
          text: "Cliente",
          value: "cliente.nome",
          align: "center"
        },
        {
          text: "Celular",
          value: "cliente.telefone_celular",
          align: "center"
        },
        {
          text: "Prestador",
          value: "prestador.nome_fantasia",
          align: "center"
        },
        {
          text: "Pago",
          value: "pago",
          align: "center"
        },
        {
          text: "Adicional",
          value: "adicional_prestador",
          align: "center"
        },
        {
          text: "TX Entrega",
          value: "taxa_entrega",
          align: "center"
        },
        {
          text: "Valor total",
          value: "valor_total",
          align: "center"
        },
        {
          text: "Total Bruto",
          value: "total_bruto",
          align: "center"
        },
        {
          text: "Total pago",
          value: "total_pago",
          align: "center"
        },
        {
          text: "Ações",
          value: "acoes"
        }
      ],
      filtros:{
        cliente: this.$store.state.filtrosVendasPdv.cliente
      },
      pagination: {
        page: 1,
        total: 0,
        limit: 9,
        first: 0
      },
      dataInicio: new Date().toISOString().substr(0, 10),
      dataFim: new Date().toISOString().substr(0, 10),
      dataIntervalo: this.$store.state.filtrosVendasPdv.dataIntervalo,
      vendas: [],
      carregando: false,
    };
  },
  mounted() {
    this.getVendasPdv();
  },
  computed: {
    ...mapState(["empresaAtual"]),
    totalPaginas() {
      return Math.ceil(this.pagination.total / this.pagination.limit);
    }
  },
  methods: {
    ...mapMutations([
      "openDialogMessage",
      "openDialogConfirm",
      "closeDialogConfirm"
    ]),
    async getVendasPdv(filtro = false) {

      this.loadingSkeleton = true;

      if(filtro) {
        this.pagination.first = 0;
        this.pagination.page = 1;
      }

      this.$store.commit("setFiltrosVendasPdv", {
        cliente: this.filtros.cliente,
        dataIntervalo: this.dataIntervalo,
        tab: this.tab
      });

      if(this.dataIntervalo[0] && !this.dataIntervalo[1]){
        return;
      }

      this.vendas = [];
      if (this.dataIntervalo && this.dataIntervalo[0]) {
        this.dataInicio = moment(this.dataIntervalo[0]).format("YYYY-MM-DD");
      } else {
        this.dataInicio = new Date().toISOString().substr(0, 10);
      }

      if (this.dataIntervalo && this.dataIntervalo[1]) {
        this.dataFim = moment(this.dataIntervalo[1]).format("YYYY-MM-DD");
      } else {
        this.dataFim = new Date().toISOString().substr(0, 10);
      }

      if (!this.empresaAtual || !this.empresaAtual.id) return;

      const params = {
        "data-inicio": this.dataInicio,
        "data-fim": this.dataFim,
        cancelada: false,
        first: this.pagination.first,
        limit: this.pagination.limit,
        ordem: "DESC"
      };

      if (this.tab === 0) params.pago = false;
      if (this.tab === 1) params.pago = true;
      if (this.tab === 2) params.cancelada = true;

      this.$axios
        .get(`/venda/empresa/${this.empresaAtual.id}/listar`, {
          headers: {
            filtros: JSON.stringify(this.filtros.cliente)
          },
          params: params
        })
        .then(response => {
          this.vendas = response.data.data;
          this.pagination.total = response.data.count;
        })
        .catch(error => {
          this.vendas = [];
          this.openDialogMessage({
            type: "error",
            title: "Falha na consulta",
            text: error.response.data,
          });
        })
        .finally(() => {
          this.loadingSkeleton = false;
        });
    },
    next(page) {
      this.pagination.page = page;
      this.pagination.first = (page - 1) * this.pagination.limit;
      this.getVendasPdv();
    },
    limparFiltro() {
      this.$store.commit("setFiltrosVendasPdv", {
        cliente: this.filtros.cliente,
        dataIntervalo: [],
        tab: this.tab
      });
      this.getVendasPdv(true);
    },

    buscar() {
      const chip = this.$refs.chipcomponente;
      const newValue = chip.$refs.input.value;

      if(newValue) {
        this.filtros.cliente.push(newValue);
        chip.$refs.input.value = "";
        chip.$emit("add", newValue);
      } else {
        chip.$emit("add");
      }
    },

    goToEditPage(item) {
      this.$router.push({
        name: "editVenda",
        params: {
          idVenda: item.id
        }
      });
    },

    goToConsultPage(item) {
      this.$router.push({
        name: "viewVenda",
        params: {
          idVenda: item.id
        }
      });
    },

  async gerarNFSe(item) {

    if(this.empresaAtual.emite_nfse) {
      await this.$axios.post(`/venda/${item.id}/nfse`)
          .then(() => {
            this.getVendasPdv();
          })
          .catch(error => {
            console.log("NFSE------>",error);
          });
    }

    },

   async gerarNFCe(item) {

     if(this.empresaAtual.emite_nfce) {
       await this.$axios.post(`/venda/${item.id}/nfce`)
           .then(() => {
             this.getVendasPdv();
           })
           .catch(error => {
             console.log("NFCE------>",error);
           });
     }


    },

    openUrl(url) {
      const win = window.open(url, "_blank");
      if (win) {
        win.focus();
      } else {
        alert("Desabilite o bloqueio de pop-ups para abrir a nota");
      }
    },

  }
};
</script>
<style scoped></style>
