<template>
    <v-container fluid>
    <v-row>
      <v-col cols="12">
        <form @submit.prevent="getDados()">
          <div class="d-flex justify-start">
            <div class="espacado col-5 d-flex">
              <div style="width: 100%">
                <label for="listaEmpresas">Empresas</label>
                <div class="d-flex">
                  <InputText id="listaEmpresas" type="text" readonly v-model="listaEmpresas" />
                  <div class="btn-lista-empresa" @click="empresaDialog.showEmpresasDialog = true">
                    <v-icon dark color="white">
                      mdi-domain
                    </v-icon>
                  </div>
                </div>
              </div>
            </div>
            <div class="espacado col-2 d-flex ">
              <div class="d-flex flex-column">
                <label for="intervalo">Data transação</label>
                <Calendar v-model="dataIntervalo" selectionMode="range"
                          @date-select="getDados" :showIcon="true"
                          dateFormat="dd/mm/y" id="intervalo"/>
              </div>
            </div>
            <div class="espacado col d-flex">
              <BotaoBuscarListas @buscar="getDados" />
            </div>
            <div class="espacado col d-flex text-end" style="align-self: end;justify-content: end;">
              <v-btn type="button" @click="exportar" icon>
                <v-icon> mdi-download </v-icon>
              </v-btn>
            </div>
          </div>
        </form>

      </v-col>

    </v-row>

    <!-- SKELETON -->
    <v-row v-if="loadingSkeleton">
      <v-col cols="12">
        <v-skeleton-loader
            :loading="true"
            type="image"
        ></v-skeleton-loader>
      </v-col>
    </v-row>

    <v-row v-if="loadingSkeleton == false">
      <v-col>
        <v-tabs v-model="tab" align-with-title>
          <v-tabs-slider color="secondary"></v-tabs-slider>
          <v-tab>
            <v-icon class="mr-3">mdi-format-list-checks</v-icon>
            Analítico
          </v-tab>
          <v-tab>
            <v-icon class="mr-3">mdi-select-group</v-icon>
            Consolidado
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-tabs-items v-model="tab" v-if="loadingSkeleton == false">

        <v-tab-item>
          <v-row class="mx-auto mt-5" dense>
            <v-col cols="12">
              <v-data-table hide-default-header hide-default-footer disable-pagination ref="table" :items="dados">

                <template v-slot:[`header`]>
                  <thead>
                    <tr>
                      <th class="text-center border-top border-right border-left" colspan="5">Empresa</th>
                      <th class="text-center border-top border-right">Transação</th>
                      <th class="text-center border-top border-right">Método</th>
                      <th class="text-center border-top border-right">Parcelas</th>
                      <th class="text-center border-top border-right">Valor</th>
                    </tr>
                  </thead>
                </template>

                <template v-slot:[`item`]="{ item }" >
                    <tr>
                        <td class="header border-bottom border-left" colspan="5">
                          <span>{{ item.empresa.nome_fantasia }}</span>
                          <span>{{ item.empresa.documento }} -  {{ item.empresa.primeiroNome }}{{ item.empresa.ultimoNome }}</span>
                        </td>
                        <td class="header text-center border-bottom ">
                          {{ item.transacao }}
                        </td>
                        <td class="header text-center border-bottom ">
                          {{ TIPO_TRANSACAO[item.tipo] }}
                        </td>
                        <td class="header text-center border-bottom truncate">
                          {{ item.parcelas }}
                        </td>
                        <td class="header text-end border-bottom truncate">
                          R$ {{ item.valor | formatMoney({minimumFractionDigits: 2 }) }}
                        </td>

                    </tr>

                    <tr>
                      <td class="border-right border-left" colspan="5">Recebedores <v-icon style="transform: rotate(90deg);">mdi-call-split</v-icon></td>
                      <td class="text-center border-right ">Valor</td>
                      <td class="text-center border-right ">MDR</td>
                      <td class="text-center border-right ">Antecipação</td>
                      <td class="text-center border-right ">Líquido</td>
                    </tr>
                    <tr v-for="obj in item.recebedores" :key="item.transacao+`_`+obj.recebedor.id">
                      <td colspan="5" class="border-bottom border-right border-left">
                          <span>{{ obj.recebedor.documento }} -  {{ obj.recebedor.primeiroNome }}{{ obj.recebedor.ultimoNome }}</span>
                      </td>

                      <td class="border-bottom border-right text-end">
                        R$ {{ obj.valor | formatMoney({minimumFractionDigits: 2 }) }}
                      </td>
                      <td class="border-bottom border-right text-end">
                        R$ {{ obj.valor_taxa_mdr | formatMoney({minimumFractionDigits: 2 }) }}
                      </td>
                      <td class="border-bottom border-right text-end">
                        R$ {{ obj.valor_taxa_antecipacao | formatMoney({minimumFractionDigits: 2 }) }}
                      </td>
                      <td class="border-bottom border-right text-end">
                        R$ {{ obj.valor_liquido | formatMoney({minimumFractionDigits: 2 }) }}
                      </td>
                    </tr>

                    <tr style="background-color: #525252">
                      <td colspan="5" class="text-end white--text">
                        Totais
                      </td>
                      <td class="text-end white--text">
                        R$ {{ item.valor | formatMoney({minimumFractionDigits: 2})}}
                      </td>

                      <td class="text-end white--text">
                        R$ {{ item.valor_taxa_mdr | formatMoney({minimumFractionDigits: 2})}}
                      </td>

                      <td class="text-end white--text">
                        R$ {{ item.taxa_antecipacao | formatMoney({minimumFractionDigits: 2})}}
                      </td>

                      <td class="text-end white--text">
                        {{ item.valor_liquido | formatMoney({minimumFractionDigits: 2})}}
                      </td>
                    </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>

        </v-tab-item>

        <v-tab-item>
          <v-row class="mx-auto mt-5" dense>
              <v-col cols="12">
                <v-data-table hide-default-header hide-default-footer disable-pagination ref="consolidado"
                                  :items="resumoConsolidadoPorEmpresa">

                      <template v-slot:[`header`]>
                        <thead>
                          <tr>
                            <th class="text-center border-top border-right border-left" colspan="6">Empresa</th>
                            <th class="text-center border-top border-right" colspan="2">Totais</th>
                          </tr>
                        </thead>
                      </template>

                    <template v-slot:[`item`]="{ item }">
                      <tr>
                        <td class="header border-bottom border-left" colspan="6">
                          <span>{{ item.empresa.nome_fantasia }}</span>
                        </td>
                        <th class="header text-center border-top border-right border-left">Bruto</th>
                        <th class="header text-center border-top border-right">Líquido</th>
                      </tr>

                      <tr v-for="obj in item.recebedores" :key="obj.recebedor.id+`_`+item.empresa.id">
                          <td colspan="6" class="border-bottom border-right border-left">
                              <span>{{ obj.recebedor.documento }} -  {{ obj.recebedor.primeiroNome }}{{ obj.recebedor.ultimoNome }}</span>
                          </td>

                          <td class="border-bottom border-right text-center">
                            R$ {{ obj.valor | formatMoney({minimumFractionDigits: 2 }) }}
                          </td>

                          <td class="border-bottom border-right text-center">
                            R$ {{ obj.valor_liquido | formatMoney({minimumFractionDigits: 2 }) }}
                          </td>
                      </tr>

                      <tr>
                        <td colspan="6" class="text-end border-bottom border-right border-left">
                            <span style="font-weight: 500;">Totais</span>
                        </td>
                        <td class="text-center border-bottom border-right">
                          <span style="font-weight: 500;">R$ {{ item.total.valor | formatMoney({minimumFractionDigits: 2})}}</span>
                        </td>
                        <td class="text-center border-bottom border-right">
                          <span style="font-weight: 500;">R$ {{ item.total.valor_liquido | formatMoney({minimumFractionDigits: 2})}}</span>
                        </td>
                      </tr>

                    </template>


                </v-data-table>
              </v-col>
          </v-row>
        </v-tab-item>
    </v-tabs-items>

    <EmpresasDialog
        :showDialog="empresaDialog.showEmpresasDialog"
        :multiplaSelecao="true"
        :title="empresaDialog.titleEmpresaDialog"
        @cancela-dialog="cancelaEmpresaDialog"
        @empresa-selecionada="empresaSelecionada"
        :valida="true"
        :mostrarTodasEmpresas="true"
    />

    </v-container>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import moment from "moment";
import Calendar from "primevue/calendar";
import BotaoBuscarListas from "@/components/BotaoBuscarListas.vue";
import EmpresasDialog from "@/components/Dialogs/EmpresasDialog.vue";
import InputText from 'primevue/inputtext';

export default {
  name: "VendaRede",
  components: {
    BotaoBuscarListas,
    Calendar,
    EmpresasDialog,
    InputText,
  },
  data() {
    return {
      empresaDialog: {
        showEmpresasDialog: false,
        titleEmpresaDialog: ""
      },
      tab: null,
      loadingSkeleton: false,
      dataIntervalo: [new Date(), new Date()],
      dados: [],
      empresas: [],
      listaEmpresas: "",
      options: {
        responsive: true,
        maintainAspectRatio: false
      }
    };
  },
  computed: {
    ...mapState(["empresaAtual", "user"]),
    resumoConsolidado(){
      const totaisAgrupados = {};
      this.dados.forEach(item => {

        item.recebedores.forEach(entry => {
            const id = entry.recebedor.id;

            if (!totaisAgrupados[id]) {
              totaisAgrupados[id] = {
                recebedor: entry.recebedor,
                valor: 0,
                valor_liquido: 0,
                valor_taxa_mdr: 0,
                valor_taxa_antecipacao: 0,
              };
            }
            totaisAgrupados[id].valor += entry.valor;
            totaisAgrupados[id].valor_liquido += entry.valor_liquido;
            totaisAgrupados[id].valor_taxa_mdr += entry.valor_taxa_mdr;
            totaisAgrupados[id].valor_taxa_antecipacao += entry.valor_taxa_antecipacao;
          });
      });
      return Object.values(totaisAgrupados);
    },
    resumoConsolidadoPorEmpresa(){
        const resultado = {};
        this.dados.forEach(item => {
          // Identifica a empresa a partir do objeto 'empresa'
          const empresaId = item.empresa.id;

          // Se ainda não existe o agrupamento para esta empresa, inicializa
          if (!resultado[empresaId]) {
            resultado[empresaId] = {
              empresa: item.empresa,
              total: {
                valor: 0,
                valor_liquido: 0,
                valor_taxa_mdr: 0,
                valor_taxa_antecipacao: 0
              },
              recebedores: {}
            };
          }

          // Acumula os repasses do próprio item na empresa (caso necessário)
          resultado[empresaId].total.valor += item.valor;
          resultado[empresaId].total.valor_liquido += item.valor_liquido;
          resultado[empresaId].total.valor_taxa_mdr += item.valor_taxa_mdr;
          resultado[empresaId].total.valor_taxa_antecipacao += item.valor_taxa_antecipacao;

          // Percorre cada recebedor do item e agrupa os valores
          item.recebedores.forEach(entry => {
            const recebedorId = entry.recebedor.id;

            // Se ainda não existe agrupamento para este recebedor dentro da empresa, inicializa
            if (!resultado[empresaId].recebedores[recebedorId]) {
              resultado[empresaId].recebedores[recebedorId] = {
                recebedor: entry.recebedor,
                valor: 0,
                valor_liquido: 0,
                valor_taxa_mdr: 0,
                valor_taxa_antecipacao: 0
              };
            }

            // Acumula os valores do repasse deste recebedor
            resultado[empresaId].recebedores[recebedorId].valor += entry.valor;
            resultado[empresaId].recebedores[recebedorId].valor_liquido += entry.valor_liquido;
            resultado[empresaId].recebedores[recebedorId].valor_taxa_mdr += entry.valor_taxa_mdr;
            resultado[empresaId].recebedores[recebedorId].valor_taxa_antecipacao += entry.valor_taxa_antecipacao;
          });
        });

      return Object.values(resultado).map(i => {
        i.recebedores = Object.values(i.recebedores);
        return i;
      })
    },
  },
  methods: {
    ...mapMutations(["openDialogMessage"]),
    getDados() {
      this.loadingSkeleton = true;

      if(this.dataIntervalo.length < 2 || this.dataIntervalo[0] == null || this.dataIntervalo[1] == null){
        return;
      }

      this.$axios
          .post(`/relatorio/venda/rede-analitico`, this.empresas,
              {
                params: {
                  "data-inicio": moment(this.dataIntervalo[0]).format("YYYY-MM-DD"),
                  "data-fim": moment(this.dataIntervalo[1]).format("YYYY-MM-DD")
                }
              }
          )
          .then(response => {
            this.dados = response.data;
          })
        .catch(error => {
          this.dados = [];
          this.openDialogMessage({
            type: "error",
            title: "Falha na consulta",
            text: error.response.data
          });
        })
        .finally(() => {
          this.loadingSkeleton = false;
        });
    },
    exportar() {
      const copy = this.dados.map(json => {
        const obj = {

        };
        return this.replaceUndefinedWithEmptyString(obj);
      });

      this.toCSVeDownload("vendas-rede-consolidado.csv", copy);
    },
    cancelaEmpresaDialog() {
      this.empresaDialog.showEmpresasDialog = false;
    },
    empresaSelecionada(empresaCallBack) {
      this.listaEmpresas = empresaCallBack.map(item => item.nome_fantasia).join(", ");
      this.empresas = empresaCallBack.map((item) => {
        return { id: item.id }
      });
      const msg = empresaCallBack.length > 1 ? `${empresaCallBack.length} Empresas selecionadas com sucesso!` : "Empresa selecionada com sucesso!";
      this.$toast.add({
        severity: "success",
        summary: msg,
        life: 2000
      });
      this.empresaDialog.showEmpresasDialog = false;
      this.getDados();
    },
    replaceUndefinedWithEmptyString(obj) {
      const newObj = {};
      Object.keys(obj).forEach(key => {
        newObj[key] = obj[key] === undefined ? "" : obj[key];
      });
      return newObj;
    },

  },
  mounted() {
    this.listaEmpresas = this.empresaAtual.nome_fantasia;
    this.empresas = [{id:this.empresaAtual.id}];
    this.getDados();
  }
};
</script>

<style scoped>
td {
  padding: 0px 2px 0px 2px;
}
td span {
  display: block;
  padding: 5px;
}
.header{
  background: #f1f1f1;
}
.footer{
  background-color: var(--primary-color);
}
.border-right {
  border-right: 1px solid #e5e5e5;
}
.border-left {
  border-left: 1px solid #e5e5e5;
}
.border-full {
  border: 1px solid #e5e5e5;
}
.border-top {
  border-top: 1px solid #e5e5e5;
}
.border-bottom {
  border-bottom: 1px solid #e5e5e5;
}
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
  padding-left: 1rem;
}


.billing-title {
  background: #fff;
  padding: 0 10px;
  position: absolute;
  top: -10px;
  left: 20px;
}

</style>
