<template>
  <v-container fluid>

    <v-row>
      <v-col cols="12">
        <form @submit.prevent="getExtrato()">
          <div class="d-flex justify-start">
            <div class="espacado col-5 d-flex">
              <div style="width: 100%">
                <label for="listaEmpresas">Empresas</label>
                <div class="d-flex">
                  <InputText id="listaEmpresas" type="text" readonly v-model="listaEmpresas" />
                  <div class="btn-lista-empresa" @click="empresaDialog.showEmpresasDialog = true">
                    <v-icon dark color="white">
                      mdi-domain
                    </v-icon>
                  </div>
                </div>
              </div>
            </div>
            <div class="espacado col-2 d-flex">
              <div style="width: 100%;" class="d-flex flex-column">
                <label for="buscarPorLiquidacao">Data liquidação</label>
                <Calendar v-model="dataIntervaloLiquidacao" selectionMode="range"
                          @date-select="buscarPorLiquidacao" :showIcon="true"
                          dateFormat="dd/mm/yy" id="buscarPorLiquidacao"/>
              </div>
            </div>
            <div class="espacado col-2 d-flex ">
              <div style="width: 100%;" class="d-flex flex-column">
                <label for="buscarPorLiquidacaoOriginal">Data liquidação original</label>
                <Calendar v-model="dataIntervaloLiquidacaoOriginal" selectionMode="range"
                          @date-select="buscarPorLiquidacaoOriginal" :showIcon="true"
                          dateFormat="dd/mm/yy" id="buscarPorLiquidacaoOriginal"/>
              </div>
            </div>
            <div class="espacado col-2 d-flex">
              <div style="width: 100%;" class="d-flex flex-column">
                <label for="buscarPorCriacao">Data transação</label>
                <Calendar v-model="dataIntervaloCriacao" selectionMode="range"
                          @date-select="buscarPorCriacao" :showIcon="true"
                          dateFormat="dd/mm/yy" id="buscarPorCriacao"/>
              </div>
            </div>
            <div class="espacado col d-flex">
              <BotaoBuscarListas @buscar="getExtrato" style="max-width: 2.5rem;" />
            </div>
          </div>

        </form>
      </v-col>

    </v-row>

    <v-row class="mt-n8 mx-auto">
      <v-col cols="12">
        <div class="d-flex justify-start">
          <div class="mr-5">
            <v-switch v-model="boletoPendente"
                      @change="getExtrato"
                      :label="boletoPendente ? 'boleto pendente' : 'boleto pendente'">
            </v-switch>
          </div>
          <div class="ml-5 mt-3">
            <v-btn type="button" @click="getCSV" icon>
              <v-icon> mdi-download </v-icon>
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>


    <!-- SKELETON -->
    <v-row v-if="loadingSkeleton" class="px-16 mt-10">
      <v-col cols="12">
        <v-skeleton-loader
            :loading="true"
            type="image"
        ></v-skeleton-loader>
      </v-col>
    </v-row>

    <v-row v-if="extrato.length > 0 && !loadingSkeleton" >
      <v-col class="custom-table rounded">


        <v-data-table group-by="data_compensacao" :items="extrato"
                      hide-default-header hide-default-footer disable-pagination ref="table">
          <!-- group custom header -->
          <template #[`group.header`]="{ items, toggle }">

            <td @click="!items[0].totalizador ? handleToggleHeader(toggle, items[0]) : ''" class="titulo white">
              <div v-if="!items[0].totalizador">
                <v-icon style="font-size: 12px" v-if="!items[0].isOpen && $vuetify.breakpoint.smAndUp"> mdi-arrow-right </v-icon>

                <v-icon style="font-size: 12px" v-else> mdi-arrow-down </v-icon>
              </div>
              <span v-if="!items[0].totalizador">{{ items[0].data_compensacao | formatDate({ dateStyle: "short" })  }}</span>
              <span v-else><b>Total {{ getStringMes(items[0].data_compensacao)  }}</b></span>
            </td>

            <td @click="!items[0].totalizador ? handleToggleHeader(toggle, items[0]) : ''" colspan="2" class="white titulo">
              <span class="text-caption">Bruto</span>
<!--              <span class="green&#45;&#45;text" style="display: block;">-->
<!--                R$ {{calculaSubTotal(items).valor | formatMoney({ minimumFractionDigits: 2, })}}-->
<!--              </span>-->
              <span class="green--text" style="display: block;">
                R$ {{ items[0].valor_total_bruto | formatMoney({ minimumFractionDigits: 2, })}}
              </span>
            </td>
            <td @click="!items[0].totalizador ? handleToggleHeader(toggle, items[0]) : ''" colspan="2" class="white titulo">
              <span class="text-caption">MDR</span>
<!--              <span class="gray&#45;&#45;text" style="display: block;">-->
<!--                R$ {{ calculaSubTotal(items).totalAdm | formatMoney({ minimumFractionDigits: 2, }) }}-->
<!--              </span>-->
              <span class="green--text" style="display: block;">
                R$ {{ items[0].valor_total_taxa_mdr | formatMoney({ minimumFractionDigits: 2, })}}
              </span>
            </td>
            <td @click="!items[0].totalizador ? handleToggleHeader(toggle, items[0]) : ''" colspan="2" class="white titulo">
              <span class="text-caption">Antecipação</span>
<!--              <span class="gray&#45;&#45;text" style="display: block;">-->
<!--                R$ {{ calculaSubTotal(items).totalAntec | formatMoney({ minimumFractionDigits: 2,  })}}-->
<!--              </span>-->
              <span class="green--text" style="display: block;">
                R$ {{ items[0].valor_total_taxa_antecipacao | formatMoney({ minimumFractionDigits: 2, })}}
              </span>
            </td>
             <td @click="!items[0].totalizador ? handleToggleHeader(toggle, items[0]) : ''" colspan="2" class="white titulo">
              <span class="text-caption">Liquido</span>
<!--              <span class="green&#45;&#45;text"  style="display: block;">-->
<!--                R$ {{ calculaSubTotal(items).totalLiquido | formatMoney({ minimumFractionDigits: 2, }) }}-->
<!--              </span>-->
               <span class="green--text" style="display: block;">
                R$ {{ items[0].valor_total_liquido | formatMoney({ minimumFractionDigits: 2, })}}
              </span>
            </td>
            <td @click="!items[0].totalizador ? handleToggleHeader(toggle, items[0]) : ''" colspan="2" class="white titulo">
              <span class="text-caption">Multas/Juros</span>
<!--              <span class="green&#45;&#45;text"  style="display: block;">-->
<!--                R$ {{ calculaSubTotal(items).totalMultas | formatMoney({ minimumFractionDigits: 2, }) }}-->
<!--              </span>-->
              <span class="green--text" style="display: block;">
                R$ {{ items[0].valor_total_juros_multa | formatMoney({ minimumFractionDigits: 2, })}}
              </span>
            </td>
            <td @click="!items[0].totalizador ? handleToggleHeader(toggle, items[0]) : ''" colspan="2" class="white titulo">
              <span class="text-caption">Compensado</span>
<!--              <span class="green&#45;&#45;text" style="display: block;">-->
<!--               R$ {{ calculaSubTotal(items).totalCompensado | formatMoney({ minimumFractionDigits: 2, })}}-->
<!--              </span>-->
              <span class="green--text" style="display: block;">
                R$ {{ items[0].valor_total_compensado | formatMoney({ minimumFractionDigits: 2, })}}
              </span>
            </td>
          </template>

          <template v-slot:[`item`]="{ index, item }" v-if="$vuetify.breakpoint.smAndUp">
            <tr v-if="index === 0 && item.isOpen" style="cursor: default">
              <th class="text-center">Status</th>
              <th class="text-center">Transacao</th>
              <th class="text-center">Valor</th>
              <th class="text-center">Tipo</th>
              <th class="text-center">Emissor</th>
              <th class="text-center">Efetuada</th>
              <th class="text-center">Comp. Ori.</th>
              <th class="text-center">Pgto</th>
              <th class="text-center">Parcela</th>
              <th class="text-center">Bruto</th>
              <th class="text-center">MDR</th>
              <th class="text-end">Antecipação</th>
              <th class="text-end">Juros/Multa</th>
              <th class="text-end">Liquido</th>
            </tr>

            <template v-for="(detalhe, key) in item.detalhes">
              <tr :key="'detalhe-' + key" style="cursor: default">
                <td class="text-center">
                  <v-chip class="ma-2 text-center" :color="STATUS_RECEBIVEIS[detalhe.status].cor"
                          style="justify-content: center" label text-color="white">
                    {{ STATUS_RECEBIVEIS[detalhe.status].texto }}
                  </v-chip>
                </td>
                <td class="text-center">{{ detalhe.transacao?.id }}</td>
                <td class="text-center">{{ detalhe.transacao?.valor | formatMoney({ minimumFractionDigits: 2, }) }}</td>
                <td class="text-center">{{ detalhe.transacao? TIPO_TRANSACAO[detalhe.transacao.tipo] :'' }}</td>
                <td class="text-Center">{{ detalhe.transacao?.empresa.cnpj }}</td>
                <td class="text-center">
                  {{ detalhe.transacao?.data_criacao | formatDate({ dateStyle: "short" }) }}
                </td>
                <td class="text-center">
                  {{ detalhe.data_compensacao_original | formatDate({ dateStyle: "short" }) }}
                </td>
                <td class="text-center">
                  {{ detalhe.transacao?.data_pagamento_boleto | formatDate({ dateStyle: "short" }) }}
                </td>
                <td class="text-center">
                  {{ detalhe.parcela }}/{{ detalhe.transacao?.parcelas }}
                </td>
                <td class="text-end">
                  {{ detalhe.valor | formatMoney({ minimumFractionDigits: 2, }) }}
                </td>
                <td class="text-end">
                  {{ detalhe.valor_taxa_administracao | formatMoney({ minimumFractionDigits: 2, }) }}
                </td>
                <td class="text-end">
                  {{ detalhe.valor_taxa_antecipacao | formatMoney({ minimumFractionDigits: 2, }) }}
                </td>
                <td class="text-end">
                  {{ detalhe.transacao?.valor_pago_juros_multa | formatMoney({ minimumFractionDigits: 2, }) }}
                </td>
                <td class="text-end">
                  {{ detalhe.valor_liquido | formatMoney({ minimumFractionDigits: 2, }) }}
                </td>
              </tr>

              <tr :key="'cliente-' + key" style="cursor: default" v-if="detalhe.transacao?.cliente">
                <td class="text-center"><i class="pi pi-reply" style="transform: rotateX(180deg);"></i></td>
                <td class="text-end"><i class="pi pi-user"></i></td>
                <td class="text-start dados-cliente" colspan="2">{{ detalhe.transacao?.cliente ? detalhe.transacao?.cliente?.nome: '' }}</td>
                <td class="text-start dados-cliente">{{ detalhe.transacao?.cliente ? (detalhe.transacao?.cliente?.cpf || detalhe.transacao?.cliente?.cnpj): ''}}</td>
                <td class="text-start dados-cliente" colspan="3">{{ detalhe.transacao?.cliente ? detalhe.transacao?.cliente?.email: '' }}</td>
              </tr>
            </template>

          </template>


        </v-data-table>

      </v-col>
    </v-row>

<!--    <v-row>-->
<!--      <v-col>-->
<!--        <v-pagination-->
<!--            v-model="pagination.page"-->
<!--            :length="totalPaginas"-->
<!--            @input="next"-->
<!--            :total-visible="10"-->
<!--        ></v-pagination>-->
<!--      </v-col>-->
<!--    </v-row>-->

    <EmpresasDialog
        :showDialog="empresaDialog.showEmpresasDialog"
        :multiplaSelecao="true"
        :title="empresaDialog.titleEmpresaDialog"
        @cancela-dialog="cancelaEmpresaDialog"
        @empresa-selecionada="empresaSelecionada"
        :valida="true"
        :mostrarTodasEmpresas="true"
    />

  </v-container>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import Calendar from 'primevue/calendar';
import InputText from 'primevue/inputtext';
import moment from 'moment';
import BotaoBuscarListas from "@/components/BotaoBuscarListas.vue";
import EmpresasDialog from "@/components/Dialogs/EmpresasDialog.vue";

export default {
  name: "ExtratoRecebiveis",
  components:{
    Calendar,
    BotaoBuscarListas,
    InputText,
    EmpresasDialog
  },
  data() {
    return {
      empresaDialog: {
        showEmpresasDialog: false,
        titleEmpresaDialog: ""
      },
      loadingSkeleton: false,
      menuDataInicio: false,
      menuDataFim: false,
      showErrorDialog: false,
      dataIntervaloLiquidacao: [new Date(), new Date()],
      dataIntervaloCriacao: [],
      dataIntervaloLiquidacaoOriginal: [],
      competencia:'LIQUIDACAO',
      dataInicio: null,
      dataFim: null,
      extrato: [],
      empresas: [],
      listaEmpresas: "",
      boletoPendente: false,
      pagination: {
        page: 1,
        total: 0,
        limit: 10,
        first: 0
      },
      isOpenDetails: false,
    };
  },
  mounted() {
    this.buscarPorLiquidacao();
  },

  methods: {
    ...mapMutations([
      "openDialogMessage",
      "openDialogConfirm",
      "closeDialogConfirm"
    ]),
    buscarPorCriacao(){
      this.competencia = 'CRIACAO';
      this.dataIntervaloLiquidacao = [];
      this.dataIntervaloLiquidacaoOriginal = [];
      this.getExtrato();
    },
    buscarPorLiquidacao(){
      this.competencia = 'LIQUIDACAO';
      this.dataIntervaloLiquidacaoOriginal = [];
      this.dataIntervaloCriacao = [];
      this.getExtrato();
    },
    buscarPorLiquidacaoOriginal(){
      this.competencia = 'LIQUIDACAO_ORIGINAL';
      this.dataIntervaloCriacao = [];
      this.dataIntervaloLiquidacao = [],
      this.getExtrato();
    },

   async handleToggleHeader(toggle, item) {

      if(!item.detalhes) {
        let data = [ { id: this.empresaAtual.id } ];

        if(this.empresas.length > 0) {
          data = this.empresas;
        }

        await this.$axios.post(`/relatorio/transacao/recebiveis-detalhado` , data,
            {
              params: {
                "data-inicio": this.dataInicio,
                "data-fim":  this.dataFim,
                "data-compensacao":  moment(item.data_compensacao).format('YYYY-MM-DD'),
                "boleto-pendente": this.boletoPendente,
                "competencia": this.competencia
              },
            }
        )
            .then((response) => {
              item.detalhes = response.data
            })
            .catch(error => {
              this.openDialogMessage({
                type: "error",
                title: "Falha na consulta",
                text: error.response.data,
              });
            })
      }

      if(item.isOpen === undefined) {
        toggle();
        item.isOpen = true;
      } else {
        item.isOpen = !item.isOpen;
      }

      toggle();
    },


    formatandoExtratos(dados) {

      const criarTotalizador = (mes, total) => {

        return {
          data_compensacao: mes + '-32',
          valor_total_bruto: total.valor_total_bruto,
          valor_total_juros_multa: total.valor_total_juros_multa,
          valor_total_liquido: total.valor_total_liquido,
          valor_total_taxa_antecipacao: total.valor_total_taxa_antecipacao,
          valor_total_taxa_mdr: total.valor_total_taxa_mdr,
          totalizador: true
        };
      };

      const agrupados = dados.reduce((acc, item) => {
        const mes = item.data_compensacao.substring(0, 7);
        if (!acc[mes]) {
          acc[mes] = {
            valor_total_bruto: 0,
            valor_total_juros_multa: 0,
            valor_total_liquido: 0,
            valor_total_taxa_antecipacao: 0,
            valor_total_taxa_mdr: 0,
            ultimaData: item.data_compensacao
          };
        }
        acc[mes].valor_total_bruto += item.valor_total_bruto;
        acc[mes].valor_total_juros_multa += item.valor_total_juros_multa;
        acc[mes].valor_total_liquido += item.valor_total_liquido;
        acc[mes].valor_total_taxa_antecipacao += item.valor_total_taxa_antecipacao;
        acc[mes].valor_total_taxa_mdr += item.valor_total_taxa_mdr;
        acc[mes].ultimaData = acc[mes].ultimaData < item.data_compensacao ? item.data_compensacao : acc[mes].ultimaData;

        return acc;
      }, {});

      Object.entries(agrupados).forEach(([mes, total]) => {
        const totalizador = criarTotalizador(mes, total);
        const indiceInsercao = dados.findIndex(item => item.data_compensacao > total.ultimaData) - 1;
        if (indiceInsercao >= 0) {
          dados.splice(indiceInsercao + 1, 0, totalizador);
        } else {
          dados.push(totalizador);
        }
      });

      return dados;

    },


    async getExtrato() {

      this.loadingSkeleton = true;

      if(!this.empresaAtual || !this.empresaAtual.id) return;

      switch(this.competencia){
        case 'CRIACAO':
          if(!this.dataIntervaloCriacao ||
            !this.dataIntervaloCriacao[0] ||
            !this.dataIntervaloCriacao[1]){
            return;
          }

          if(this.dataIntervaloCriacao[0]){
            this.dataInicio = moment(this.dataIntervaloCriacao[0]).format('YYYY-MM-DD');
          }

          if(this.dataIntervaloCriacao[1]){
            this.dataFim = moment(this.dataIntervaloCriacao[1]).format('YYYY-MM-DD');
          }
        break;
        case 'LIQUIDACAO_ORIGINAL':
          if(!this.dataIntervaloLiquidacaoOriginal ||
            !this.dataIntervaloLiquidacaoOriginal[0] ||
            !this.dataIntervaloLiquidacaoOriginal[1]){
            return;
          }

          if(this.dataIntervaloLiquidacaoOriginal[0]){
            this.dataInicio = moment(this.dataIntervaloLiquidacaoOriginal[0]).format('YYYY-MM-DD');
          }

          if(this.dataIntervaloLiquidacaoOriginal[1]){
            this.dataFim = moment(this.dataIntervaloLiquidacaoOriginal[1]).format('YYYY-MM-DD');
          }

        break;
        default:

          if(!this.dataIntervaloLiquidacao ||
            !this.dataIntervaloLiquidacao[0] ||
            !this.dataIntervaloLiquidacao[1]){
            return;
          }

          if(this.dataIntervaloLiquidacao[0]){
            this.dataInicio = moment(this.dataIntervaloLiquidacao[0]).format('YYYY-MM-DD');
          }

          if(this.dataIntervaloLiquidacao[1]){
            this.dataFim = moment(this.dataIntervaloLiquidacao[1]).format('YYYY-MM-DD');
          }

      }


      let data = [ { id: this.empresaAtual.id } ];


      if(this.empresas.length > 0) {
        data = this.empresas;
      } else {
        this.listaEmpresas = this.empresaAtual.nome_fantasia;
      }


      return this.$axios.post(`/relatorio/transacao/recebiveis-consolidado`, data,
          {
            params: {
              "data-inicio": this.dataInicio,
              "data-fim": this.dataFim,
              "boleto-pendente": this.boletoPendente,
              "competencia": this.competencia
            },
          }
        )
        .then((response) => {
          this.extrato = this.formatandoExtratos(response.data);
          this.loadingSkeleton = false;
        })
        .catch(error => {
          this.extrato = [];
          this.dataIntervaloLiquidacaoOriginal = [];
          this.dataIntervaloLiquidacao = [];
          this.dataIntervaloCriacao = [];
          this.openDialogMessage({
            type: "error",
            title: "Falha na consulta",
            text: error.response.data,
          });
        })
        .finally(() => {
          this.loadingSkeleton = false;
        });
    },
    calculaSubTotal(items = []) {
      let valor = 0;
      let totalAdm = 0;
      let totalAntec = 0;
      let totalCompensado = 0;
      let totalLiquido = 0;
      let totalMultas = 0;

      items.forEach((item) => {
        valor += item.valor;
        totalAdm += item.valor_taxa_administracao;
        totalAntec += item.valor_taxa_antecipacao;
        totalLiquido += item.valor_liquido
        totalMultas += item.transacao?.valor_pago_juros_multa ? item.transacao?.valor_pago_juros_multa: 0.00

        if(item.status == 'LIQUIDADO' || item.status == 'PAGO'){
          totalCompensado += item.valor_liquido;
        }

      });

      return {
        valor,
        totalAdm,
        totalAntec,
        totalCompensado,
        totalLiquido,
        totalMultas
      };
    },
    // converteCSV() {
    //
    //   const copyExtrato = this.extrato.map((item) => {
    //     const obj = { ... item};
    //
    //     obj.data_compensacao = obj.data_compensacao.split('T')[0];
    //
    //     if(obj.totalizador) {
    //       obj.data_compensacao = `Total - ${this.getStringMes(obj.data_compensacao)}`;
    //     }
    //
    //     obj.valor_total_bruto = this.parseFloatToMoney(obj.valor_total_bruto);
    //     obj.valor_total_taxa_mdr = this.parseFloatToMoney(obj.valor_total_taxa_mdr);
    //     obj.valor_total_taxa_antecipacao = this.parseFloatToMoney(obj.valor_total_taxa_antecipacao);
    //     obj.valor_total_liquido = this.parseFloatToMoney(obj.valor_total_liquido);
    //     obj.valor_total_juros_multa = this.parseFloatToMoney(obj.valor_total_juros_multa);
    //     obj.valor_total_compensado = this.parseFloatToMoney(obj.valor_total_compensado);
    //
    //     delete obj.detalhes;
    //     delete obj.isOpen;
    //
    //     // obj.data_compensacao = obj.data_compensacao.split('T')[0];
    //     // obj.data_compensacao_original = obj.data_compensacao_original.split('T')[0];
    //     //
    //     // obj.id_transacao = obj.transacao?.id;
    //     // obj.tipo_transacao = obj.transacao?.tipo;
    //     // obj.status_transacao = obj.transacao?.status;
    //     //
    //     //
    //     //
    //     //
    //     // obj.data_transacao = obj.transacao?.data_criacao.split('T')[0];
    //     // obj.hora_transacao = obj.transacao?.data_criacao.split('T')[1];
    //     // obj.valor_transacao = this.parseFloatToMoney(obj.transacao?.valor);
    //     // obj.parcelas_transacao = obj.transacao?.parcelas;
    //     // obj.data_pagamento_boleto = obj.transacao?.data_pagamento_boleto?.split('T')[0];
    //     // obj.valor_pago_juros_multa = this.parseFloatToMoney(obj.transacao?.valor_pago_juros_multa)
    //     //
    //     //
    //     //
    //     //
    //     // obj.valor = this.parseFloatToMoney(obj.valor);
    //     // obj.valor_taxa_administracao = this.parseFloatToMoney(obj.valor_taxa_administracao);
    //     // obj.valor_taxa_antecipacao = this.parseFloatToMoney(obj.valor_taxa_antecipacao);
    //     // obj.valor_liquido = this.parseFloatToMoney(obj.valor_liquido);
    //     //
    //     // obj.cnpj_empresa_transacao = obj.transacao?.empresa.cnpj;
    //     // obj.cpf_empresa_transacao = obj.transacao?.empresa.cpf;
    //     // obj.nome_empresa_transacao = obj.transacao?.empresa.razao_social ? obj.transacao?.empresa.razao_social : obj.transacao?.empresa.nome;
    //     // obj.documento_cliente_transacao = (obj.transacao?.cliente ? (obj.transacao?.cliente?.cpf || obj.transacao?.cliente?.cnpj):'');
    //     // obj.nome_cliente_transacao = (obj.transacao?.cliente ? obj.transacao?.cliente?.nome:'');
    //     //
    //     // delete obj.id;
    //     // delete obj.recebedor;
    //     // delete obj.transacao;
    //     // delete obj.ur_id;
    //
    //     return obj;
    //   });
    //
    //   this.toCSVeDownload("extrato-recebiveis.csv", copyExtrato);
    // },

    async getCSV() {

      this.loadingSkeleton = true;

      if(!this.empresaAtual || !this.empresaAtual.id) return;

      switch(this.competencia){
        case 'CRIACAO':
          if(!this.dataIntervaloCriacao ||
            !this.dataIntervaloCriacao[0] ||
            !this.dataIntervaloCriacao[1]){
            return;
          }

          if(this.dataIntervaloCriacao[0]){
            this.dataInicio = moment(this.dataIntervaloCriacao[0]).format('YYYY-MM-DD');
          }

          if(this.dataIntervaloCriacao[1]){
            this.dataFim = moment(this.dataIntervaloCriacao[1]).format('YYYY-MM-DD');
          }
          break;
        case 'LIQUIDACAO_ORIGINAL':
          if(!this.dataIntervaloLiquidacaoOriginal ||
            !this.dataIntervaloLiquidacaoOriginal[0] ||
            !this.dataIntervaloLiquidacaoOriginal[1]){
            return;
          }

          if(this.dataIntervaloLiquidacaoOriginal[0]){
            this.dataInicio = moment(this.dataIntervaloLiquidacaoOriginal[0]).format('YYYY-MM-DD');
          }

          if(this.dataIntervaloLiquidacaoOriginal[1]){
            this.dataFim = moment(this.dataIntervaloLiquidacaoOriginal[1]).format('YYYY-MM-DD');
          }

          break;
        default:

          if(!this.dataIntervaloLiquidacao ||
            !this.dataIntervaloLiquidacao[0] ||
            !this.dataIntervaloLiquidacao[1]){
            return;
          }

          if(this.dataIntervaloLiquidacao[0]){
            this.dataInicio = moment(this.dataIntervaloLiquidacao[0]).format('YYYY-MM-DD');
          }

          if(this.dataIntervaloLiquidacao[1]){
            this.dataFim = moment(this.dataIntervaloLiquidacao[1]).format('YYYY-MM-DD');
          }

      }


      let data = [ { id: this.empresaAtual.id } ];


      if(this.empresas.length > 0) {
        data = this.empresas;
      } else {
        this.listaEmpresas = this.empresaAtual.nome_fantasia;
      }


      return this.$axios.post(`/relatorio/transacao/exportar-extrato-recebiveis`, data,
        {
          params: {
            "data-inicio": this.dataInicio,
            "data-fim": this.dataFim,
            "competencia": this.competencia
          },
        }
      )
        .then((response) => {
          const dados = response.data;
          if (!dados || dados.length === 0) {
            this.openDialogMessage({
              type: "warning",
              title: "Nenhum dado encontrado",
              text: "Nenhuma transação foi encontrada no período selecionado.",
            });
            return;
          }

          const bom = "\uFEFF";
          const csvContent = bom + dados;

          const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `extrato_transacoes_${this.dataInicio}_a_${this.dataFim}.csv`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.loadingSkeleton = false;
        })
        .catch(error => {
          this.extrato = [];
          this.dataIntervaloLiquidacaoOriginal = [];
          this.dataIntervaloLiquidacao = [];
          this.dataIntervaloCriacao = [];
          this.openDialogMessage({
            type: "error",
            title: "Falha na consulta",
            text: error.response.data,
          });
        })
        .finally(() => {
          this.loadingSkeleton = false;
        });
    },

    next(page) {
      this.pagination.page = page;
      this.pagination.first = (page - 1) * this.pagination.limit;
      this.getExtrato();
    },

    cancelaEmpresaDialog() {
      this.empresaDialog.showEmpresasDialog = false;
    },
    empresaSelecionada(empresaCallBack) {

      this.listaEmpresas = empresaCallBack.map(item => item.nome_fantasia).join(", ");
      this.empresas = empresaCallBack.map((item) => {
        return { id: item.id }
      });

      const msg = empresaCallBack.length > 1 ? `${empresaCallBack.length} Empresas selecionadas com sucesso!` : "Empresa selecionada com sucesso!";

      this.$toast.add({
        severity: "success",
        summary: msg,
        life: 2000
      });

      this.empresaDialog.showEmpresasDialog = false;
      this.getExtrato();

    },

    getStringMes(date) {
      const nomesDosMeses = [
        'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
      ];

      const mes = parseInt(date.substring(5, 7)) - 1;

      const nomeDoMes = nomesDosMeses[mes];

      return nomeDoMes;
    }

  },
  computed: {
    ...mapState(["empresaAtual","user"]),
    totalPaginas() {
      return Math.ceil(this.pagination.total / this.pagination.limit);
    }
  },
};
</script>

<style scoped>
.titulo{
  font-size: 1.2rem !important;
}
.dados-cliente{
  font-size: 0.8rem !important;
}
.v-row-group__header td {
  background-color: #f5f2f2 !important;
  /* color: #495057; */
}

.export-icon {
  font-size: 25px;
  transition: all 0.2s linear;
}

.export-icon:hover {
  transform: scale(1.3);
}

.col, .col-2, .col-5{
  flex-direction: column;
  justify-content: end;
}

label {
    font-size: 0.8rem;
    color: #4b4b4b;
}


@media (max-width: 900px) {
  .col-2,
  .col-5,
  .col-3 {
    flex: none;
    max-width: 25rem;
  }

  tr {
    max-width: 100%;
  }
  td {
    display: flex;
    justify-content: space-between;
  }

}
</style>
