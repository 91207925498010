<template>
  <div>

    <!-- SKELETON -->
    <v-container v-if="loadingSkeleton" fluid>
      <v-row class="grey lighten-5 px-16 mt-10">
        <v-col cols="12">
          <v-skeleton-loader
              :loading="true"
              type="image"
              height="70"
              class="mb-3"
          ></v-skeleton-loader>
          <v-skeleton-loader
              :loading="true"
              type="heading"
          ></v-skeleton-loader>
          <v-skeleton-loader
              class="mt-3"
              :loading="true"
              type="text@3"
          ></v-skeleton-loader>
          <v-skeleton-loader
              :loading="true"
              type="image"
              height="80"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-else>
      <v-row>
        <v-col>
          <v-tabs v-model="tab" align-with-title e @change="validaForm">
            <v-tabs-slider color="secondary"></v-tabs-slider>
            <v-tab>
              <v-icon class="mr-3">mdi-account-box-outline</v-icon> Dados Gerais
            </v-tab>
            <v-tab>
              <v-icon class="mr-3">mdi-warehouse</v-icon>
              Empresas
            </v-tab>
            <v-tab :disabled="!user.permissoes.adm_usuario">
              <v-icon class="mr-3">mdi-security</v-icon>
              Permissões
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <form @submit.prevent="handleSave">
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-container>
                  <v-row dense>

                    <v-col cols="1">
                      <v-switch
                          :disabled="mode === 'view'"
                          v-model="usuario.status"
                          :label="usuario.status ? 'Ativo' : 'Inativo'"
                      ></v-switch>
                    </v-col>
                    <v-col class="text-end">
                      <v-btn v-if="mode === 'edit' && user.permissoes.adm_usuario" @click="confirmResetSenha" >
                        Resetar Senha
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row dense class="box_mobile">
                    <v-col cols="1" v-if="usuario.id != null" class="box_mobile_info">
                      <v-text-field
                          readonly
                          dense
                          prepend-icon="mdi-barcode"
                          required
                          label="Código"
                          :value="usuario.codigo"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="2" class="box_mobile_info">
                      <v-select
                          item-text="texto"
                          item-value="valor"
                          label="Tipo Usuário"
                          dense
                          :items="tiposUsuario"
                          v-model="usuario.tipo"
                          :prepend-icon="
                        usuario.tipo === 'USUARIO'
                          ? 'mdi-account'
                          : 'mdi-application'
                      "
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="3" v-if="usuario.tipo === 'USUARIO'" class="box_mobile_info">
                      <v-text-field
                          :readonly="mode !== 'create'"
                          dense
                          prepend-icon="mdi-passport"
                          required
                          label="CPF"
                          @keyup="usuario.cpf = $masks.cpf(usuario.cpf)"
                          :value="maskedCpf"
                          maxlength="14"
                          @input="handleInputCpf"
                          @blur="getUsuarioByCPF"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                          dense
                          :readonly="mode == 'view'"
                          prepend-icon="mdi-account-check"
                          v-model="usuario.nome"
                          label="Nome"
                          required
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="box_mobile">
                    <v-col cols="5" class="box_mobile_info">
                      <v-text-field
                          required
                          :readonly="mode == 'view'"
                          dense
                          prepend-icon="mdi-cellphone-basic"
                          @focus="
                        usuario.telefone = $masks.telefone(usuario.telefone)
                      "
                          @keyup="
                        usuario.telefone = $masks.telefone(usuario.telefone)
                      "
                          v-model="usuario.telefone"
                          label="Telefone"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                          prepend-icon="mdi-email"
                          :readonly="mode !== 'create'"
                          dense
                          :value="maskedEmail"
                          @input="handleInputEmail"
                          required
                          label="E-mail"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                </v-container>
              </v-tab-item>

              <v-tab-item v-if="user.permissoes.adm_usuario">
                <v-container>
                  <v-row>
                    <v-col class="text-end">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                              v-bind="attrs"
                              v-on="on"
                              fab
                              dark
                              small
                              color="primary"
                              @click="empresaDialog.showEmpresasDialog = true"
                              v-if="mode !== 'view'"
                          >
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                        </template>
                        <span>Adicionar Empresa</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-simple-table >
                        <template v-slot:default>
                          <thead>
                          <tr>
                            <th scope="col" class="text-left">CNPJ</th>
                            <th scope="col" class="text-left">Nome Fantasia</th>
                            <th scope="col" class="text-left">Razão Social</th>
                            <th
                                scope="col"
                                class="text-end"
                                v-if="mode != 'view'"
                            >
                              Ações
                            </th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="empresa in empresasNaPagina" :key="empresa.id">
                            <td v-if="empresa.cnpj">
                              {{ empresa.cnpj }}
                            </td>
                            <td v-if="empresa.cpf">
                              {{ empresa.cpf }}
                            </td>
                            <td>
                              {{ empresa.nome_fantasia }}
                            </td>
                            <td v-if="empresa.razao_social">
                              {{ empresa.razao_social }}
                            </td>
                            <td v-if="empresa.nome">
                              {{ empresa.nome }}
                            </td>
                            <td class="text-end" v-if="mode != 'view'">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                      v-bind="attrs"
                                      v-on="on"
                                      icon
                                      small
                                      color="primary"
                                      @click="openModalRemoveEmpresa(empresa)">
                                    <v-icon>mdi-delete</v-icon>
                                  </v-btn>
                                </template>
                                <span>Remover Empresa</span>
                              </v-tooltip>
                            </td>
                          </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                      <v-divider></v-divider>
                      <v-pagination
                          class="mt-5"
                          v-model="pagination.page"
                          :length="totalPaginas"
                          @input="setPagina"
                          :total-visible="10"
                      ></v-pagination>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>

              <v-tab-item v-if="user.permissoes.adm_usuario">
                <v-container>
                  <v-row>
                    <v-col cols="6">
                      <v-row>
                        <v-col>
                          <span>Máximo de desconto permitido</span>
                        </v-col>
                        <v-col cols="4">
                          <InputNumber v-model.number="usuario.max_desconto"  suffix=" %" :min="0" :max="100" :disabled="mode === 'view'"/>
                          <Slider v-model="usuario.max_desconto" :step="10" :disabled="mode === 'view'"/>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <span>Criação e edição de usuários</span>
                          <p style="font-size: 13px;">*Esta permissão viola a segurança das demais permissões</p>
                        </v-col>
                        <v-col cols="4">
                          <v-switch :disabled="mode === 'view'"
                                    v-model="usuario.permissoes.adm_usuario"
                                    :label="usuario.permissoes.adm_usuario ? 'Autorizado' : 'Não autorizado'"/>
                        </v-col>

                      </v-row>

                      <v-row>
                        <v-col>
                          <span>Criação de cobranças</span>
                        </v-col>
                        <v-col cols="4">
                          <v-switch :disabled="mode === 'view'"
                                    v-model="usuario.permissoes.adm_cobranca"
                                    :label="usuario.permissoes.adm_cobranca ? 'Autorizado' : 'Não autorizado'"/>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col>
                          <span>Cancelar cobranças</span>
                        </v-col>
                        <v-col cols="4">
                          <v-switch :disabled="mode === 'view'"
                                    v-model="usuario.permissoes.cancelar_cobranca"
                                    :label="usuario.permissoes.cancelar_cobranca ? 'Autorizado' : 'Não autorizado'"/>
                        </v-col>

                      </v-row>

                      <v-row>
                        <v-col>
                          <span>Criação e edição de empresas</span>
                        </v-col>
                        <v-col cols="4">
                          <v-switch :disabled="mode === 'view'"
                                    v-model="usuario.permissoes.adm_empresa"
                                    :label="usuario.permissoes.adm_empresa ? 'Autorizado' : 'Não autorizado'"/>
                        </v-col>

                      </v-row>

                      <v-row>
                        <v-col>
                          <span>Criação e edição de cliente</span>
                        </v-col>
                        <v-col cols="4">
                          <v-switch :disabled="mode === 'view'"
                                    v-model="usuario.permissoes.adm_cliente"
                                    :label="usuario.permissoes.adm_cliente ? 'Autorizado' : 'Não autorizado'"/>
                        </v-col>

                      </v-row>

                      <v-row>
                        <v-col>
                          <span>Criação e edição de serviços</span>
                        </v-col>
                        <v-col cols="4">
                          <v-switch :disabled="mode === 'view'"
                                    v-model="usuario.permissoes.adm_servico"
                                    :label="usuario.permissoes.adm_servico ? 'Autorizado' : 'Não autorizado'"/>
                        </v-col>

                      </v-row>

                      <v-row>
                        <v-col>
                          <span>Reforço de caixa</span>
                        </v-col>

                        <v-col cols="4">
                          <v-switch :disabled="mode === 'view'"
                                    v-model="usuario.permissoes.reforco_caixa"
                                    :label="usuario.permissoes.reforco_caixa ? 'Autorizado' : 'Não autorizado'"/>
                        </v-col>

                      </v-row>

                      <v-row>
                        <v-col>
                          <span>Fechamento de caixa</span>
                        </v-col>
                        <v-col cols="4">
                          <v-switch :disabled="mode === 'view'"
                                    v-model="usuario.permissoes.fechamento_caixa"
                                    :label="usuario.permissoes.fechamento_caixa ? 'Autorizado' : 'Não autorizado'"/>
                        </v-col>

                      </v-row>

                      <v-row>
                        <v-col>
                          <span>Extrato conta</span>
                        </v-col>
                        <v-col cols="4">
                          <v-switch :disabled="mode === 'view'"
                                    v-model="usuario.permissoes.extrato_conta"
                                    :label="usuario.permissoes.extrato_conta ? 'Autorizado' : 'Não autorizado'"/>
                        </v-col>

                      </v-row>

                    </v-col>

                    <v-col cols="6">

                      <v-row>
                        <v-col>
                          <span>Receber dinheiro</span>
                        </v-col>
                        <v-col cols="4">
                          <v-switch :disabled="mode === 'view'"
                                    v-model="usuario.permissoes.receber_dinheiro"
                                    :label="usuario.permissoes.receber_dinheiro ? 'Autorizado' : 'Não autorizado'"/>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col>
                          <span>Receber pix</span>
                        </v-col>
                        <v-col cols="4">
                          <v-switch :disabled="mode === 'view'"
                                    v-model="usuario.permissoes.receber_pix"
                                    :label="usuario.permissoes.receber_pix ? 'Autorizado' : 'Não autorizado'"/>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col>
                          <span>Receber débito</span>
                        </v-col>
                        <v-col cols="4">
                          <v-switch :disabled="mode === 'view'"
                                    v-model="usuario.permissoes.receber_debito"
                                    :label="usuario.permissoes.receber_debito ? 'Autorizado' : 'Não autorizado'"/>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col>
                          <span>Receber crédito</span>
                        </v-col>
                        <v-col cols="4">
                          <v-switch :disabled="mode === 'view'"
                                    v-model="usuario.permissoes.receber_credito"
                                    :label="usuario.permissoes.receber_credito ? 'Autorizado' : 'Não autorizado'"/>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col>
                          <span>Receber boleto</span>
                        </v-col>
                        <v-col cols="4">
                          <v-switch :disabled="mode === 'view'"
                                    v-model="usuario.permissoes.receber_boleto"
                                    :label="usuario.permissoes.receber_boleto ? 'Autorizado' : 'Não autorizado'"/>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col>
                          <span>Alterar valor do item</span>
                        </v-col>
                        <v-col cols="4">
                          <v-switch :disabled="mode === 'view'"
                                    v-model="usuario.permissoes.alterar_valor_item"
                                    :label="usuario.permissoes.alterar_valor_item ? 'Autorizado' : 'Não autorizado'"/>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col>
                          <span>Criação e edição de planos</span>
                        </v-col>
                        <v-col cols="4">
                          <v-switch :disabled="mode === 'view'"
                                    v-model="usuario.permissoes.adm_plano"
                                    :label="usuario.permissoes.adm_plano ? 'Autorizado' : 'Não autorizado'"/>
                        </v-col>

                      </v-row>

                      <v-row>
                        <v-col>
                          <span>Cancelar ou estornar transações</span>
                        </v-col>
                        <v-col cols="4">
                          <v-switch :disabled="mode === 'view'"
                                    v-model="usuario.permissoes.cancelar_transacao"
                                    :label="usuario.permissoes.cancelar_transacao ? 'Autorizado' : 'Não autorizado'"/>
                        </v-col>

                      </v-row>

                      <v-row>
                        <v-col>
                          <span>Emitir notas fiscais</span>
                        </v-col>
                        <v-col cols="4">
                          <v-switch :disabled="mode === 'view'"
                                    v-model="usuario.permissoes.emissao_nfs"
                                    :label="usuario.permissoes.emissao_nfs ? 'Autorizado' : 'Não autorizado'"/>
                        </v-col>

                      </v-row>

                      <v-row>
                        <v-col>
                          <span>Cancelar notas fiscais</span>
                        </v-col>
                        <v-col cols="4">
                          <v-switch :disabled="mode === 'view'"
                                    v-model="usuario.permissoes.cancelar_nfs"
                                    :label="usuario.permissoes.cancelar_nfs ? 'Autorizado' : 'Não autorizado'"/>
                        </v-col>

                      </v-row>

                      <v-row>
                        <v-col>
                          <span>Alterar split de pagamento</span>
                        </v-col>
                        <v-col cols="4">
                          <v-switch :disabled="mode === 'view'"
                                    v-model="usuario.permissoes.adm_split"
                                    :label="usuario.permissoes.adm_split ? 'Autorizado' : 'Não autorizado'"/>
                        </v-col>

                      </v-row>

                      <v-row>
                        <v-col>
                          <span>Abertura de caixa</span>
                        </v-col>
                        <v-col cols="4">
                          <v-switch :disabled="mode === 'view'"
                                    v-model="usuario.permissoes.abertura_caixa"
                                    :label="usuario.permissoes.abertura_caixa ? 'Autorizado' : 'Não autorizado'"/>
                        </v-col>

                      </v-row>

                      <v-row>
                        <v-col>
                          <span>Sangria de caixa</span>
                        </v-col>
                        <v-col cols="4">
                          <v-switch :disabled="mode === 'view'"
                                    v-model="usuario.permissoes.sangria_caixa"
                                    :label="usuario.permissoes.sangria_caixa ? 'Autorizado' : 'Não autorizado'"/>
                        </v-col>

                      </v-row>

                      <v-row>
                        <v-col>
                          <span>Flash de caixa</span>
                        </v-col>

                        <v-col cols="4">
                          <v-switch :disabled="mode === 'view'"
                                    v-model="usuario.permissoes.flash_caixa"
                                    :label="usuario.permissoes.flash_caixa ? 'Autorizado' : 'Não autorizado'"/>
                        </v-col>

                      </v-row>

                    </v-col>

                  </v-row>
                  <v-row>
<!--                    <v-col cols="6">-->
<!--                      <v-row>-->
<!--                        <v-col>-->
<!--                          <span>Criação e edição de usuários</span>-->
<!--                          <p style="font-size: 13px;">*Esta permissão viola a segurança das demais permissões</p>-->
<!--                        </v-col>-->
<!--                        <v-col cols="4">-->
<!--                          <v-switch :disabled="mode === 'view'"-->
<!--                                    v-model="usuario.permissoes.adm_usuario"-->
<!--                                    :label="usuario.permissoes.adm_usuario ? 'Autorizado' : 'Não autorizado'"/>-->
<!--                        </v-col>-->

<!--                      </v-row>-->

<!--                      <v-row>-->
<!--                        <v-col>-->
<!--                          <span>Criação de cobranças</span>-->
<!--                        </v-col>-->
<!--                        <v-col cols="4">-->
<!--                          <v-switch :disabled="mode === 'view'"-->
<!--                                    v-model="usuario.permissoes.adm_cobranca"-->
<!--                                    :label="usuario.permissoes.adm_cobranca ? 'Autorizado' : 'Não autorizado'"/>-->
<!--                        </v-col>-->
<!--                      </v-row>-->

<!--                      <v-row>-->
<!--                        <v-col>-->
<!--                          <span>Cancelar cobranças</span>-->
<!--                        </v-col>-->
<!--                        <v-col cols="4">-->
<!--                          <v-switch :disabled="mode === 'view'"-->
<!--                                    v-model="usuario.permissoes.cancelar_cobranca"-->
<!--                                    :label="usuario.permissoes.cancelar_cobranca ? 'Autorizado' : 'Não autorizado'"/>-->
<!--                        </v-col>-->

<!--                      </v-row>-->

<!--                      <v-row>-->
<!--                        <v-col>-->
<!--                          <span>Criação e edição de empresas</span>-->
<!--                        </v-col>-->
<!--                        <v-col cols="4">-->
<!--                          <v-switch :disabled="mode === 'view'"-->
<!--                                    v-model="usuario.permissoes.adm_empresa"-->
<!--                                    :label="usuario.permissoes.adm_empresa ? 'Autorizado' : 'Não autorizado'"/>-->
<!--                        </v-col>-->

<!--                      </v-row>-->

<!--                      <v-row>-->
<!--                        <v-col>-->
<!--                          <span>Criação e edição de cliente</span>-->
<!--                        </v-col>-->
<!--                        <v-col cols="4">-->
<!--                          <v-switch :disabled="mode === 'view'"-->
<!--                                    v-model="usuario.permissoes.adm_cliente"-->
<!--                                    :label="usuario.permissoes.adm_cliente ? 'Autorizado' : 'Não autorizado'"/>-->
<!--                        </v-col>-->

<!--                      </v-row>-->

<!--                      <v-row>-->
<!--                        <v-col>-->
<!--                          <span>Criação e edição de serviços</span>-->
<!--                        </v-col>-->
<!--                        <v-col cols="4">-->
<!--                          <v-switch :disabled="mode === 'view'"-->
<!--                                    v-model="usuario.permissoes.adm_servico"-->
<!--                                    :label="usuario.permissoes.adm_servico ? 'Autorizado' : 'Não autorizado'"/>-->
<!--                        </v-col>-->

<!--                      </v-row>-->

<!--                      <v-row>-->
<!--                        <v-col>-->
<!--                          <span>Reforço de caixa</span>-->
<!--                        </v-col>-->

<!--                        <v-col cols="4">-->
<!--                          <v-switch :disabled="mode === 'view'"-->
<!--                                    v-model="usuario.permissoes.reforco_caixa"-->
<!--                                    :label="usuario.permissoes.reforco_caixa ? 'Autorizado' : 'Não autorizado'"/>-->
<!--                        </v-col>-->

<!--                      </v-row>-->

<!--                      <v-row>-->
<!--                        <v-col>-->
<!--                          <span>Fechamento de caixa</span>-->
<!--                        </v-col>-->
<!--                        <v-col cols="4">-->
<!--                          <v-switch :disabled="mode === 'view'"-->
<!--                                    v-model="usuario.permissoes.fechamento_caixa"-->
<!--                                    :label="usuario.permissoes.fechamento_caixa ? 'Autorizado' : 'Não autorizado'"/>-->
<!--                        </v-col>-->

<!--                      </v-row>-->

<!--                      <v-row>-->
<!--                        <v-col>-->
<!--                          <span>Extrato conta</span>-->
<!--                        </v-col>-->
<!--                        <v-col cols="4">-->
<!--                          <v-switch :disabled="mode === 'view'"-->
<!--                                    v-model="usuario.permissoes.extrato_conta"-->
<!--                                    :label="usuario.permissoes.extrato_conta ? 'Autorizado' : 'Não autorizado'"/>-->
<!--                        </v-col>-->

<!--                      </v-row>-->

<!--                    </v-col>-->


<!--                    <v-col cols="6">-->

<!--                      <v-row>-->
<!--                        <v-col>-->
<!--                          <span>Criação e edição de planos</span>-->
<!--                        </v-col>-->
<!--                        <v-col cols="4">-->
<!--                          <v-switch :disabled="mode === 'view'"-->
<!--                                    v-model="usuario.permissoes.adm_plano"-->
<!--                                    :label="usuario.permissoes.adm_plano ? 'Autorizado' : 'Não autorizado'"/>-->
<!--                        </v-col>-->

<!--                      </v-row>-->

<!--                      <v-row>-->
<!--                        <v-col>-->
<!--                          <span>Cancelar ou estornar transações</span>-->
<!--                        </v-col>-->
<!--                        <v-col cols="4">-->
<!--                          <v-switch :disabled="mode === 'view'"-->
<!--                                    v-model="usuario.permissoes.cancelar_transacao"-->
<!--                                    :label="usuario.permissoes.cancelar_transacao ? 'Autorizado' : 'Não autorizado'"/>-->
<!--                        </v-col>-->

<!--                      </v-row>-->

<!--                      <v-row>-->
<!--                        <v-col>-->
<!--                          <span>Emitir notas fiscais</span>-->
<!--                        </v-col>-->
<!--                        <v-col cols="4">-->
<!--                          <v-switch :disabled="mode === 'view'"-->
<!--                                    v-model="usuario.permissoes.emissao_nfs"-->
<!--                                    :label="usuario.permissoes.emissao_nfs ? 'Autorizado' : 'Não autorizado'"/>-->
<!--                        </v-col>-->

<!--                      </v-row>-->

<!--                      <v-row>-->
<!--                        <v-col>-->
<!--                          <span>Cancelar notas fiscais</span>-->
<!--                        </v-col>-->
<!--                        <v-col cols="4">-->
<!--                          <v-switch :disabled="mode === 'view'"-->
<!--                                    v-model="usuario.permissoes.cancelar_nfs"-->
<!--                                    :label="usuario.permissoes.cancelar_nfs ? 'Autorizado' : 'Não autorizado'"/>-->
<!--                        </v-col>-->

<!--                      </v-row>-->

<!--                      <v-row>-->
<!--                        <v-col>-->
<!--                          <span>Alterar split de pagamento</span>-->
<!--                        </v-col>-->
<!--                        <v-col cols="4">-->
<!--                          <v-switch :disabled="mode === 'view'"-->
<!--                                    v-model="usuario.permissoes.adm_split"-->
<!--                                    :label="usuario.permissoes.adm_split ? 'Autorizado' : 'Não autorizado'"/>-->
<!--                        </v-col>-->

<!--                      </v-row>-->

<!--                      <v-row>-->
<!--                        <v-col>-->
<!--                          <span>Abertura de caixa</span>-->
<!--                        </v-col>-->
<!--                        <v-col cols="4">-->
<!--                          <v-switch :disabled="mode === 'view'"-->
<!--                                    v-model="usuario.permissoes.abertura_caixa"-->
<!--                                    :label="usuario.permissoes.abertura_caixa ? 'Autorizado' : 'Não autorizado'"/>-->
<!--                        </v-col>-->

<!--                      </v-row>-->

<!--                      <v-row>-->
<!--                        <v-col>-->
<!--                          <span>Sangria de caixa</span>-->
<!--                        </v-col>-->
<!--                        <v-col cols="4">-->
<!--                          <v-switch :disabled="mode === 'view'"-->
<!--                                    v-model="usuario.permissoes.sangria_caixa"-->
<!--                                    :label="usuario.permissoes.sangria_caixa ? 'Autorizado' : 'Não autorizado'"/>-->
<!--                        </v-col>-->

<!--                      </v-row>-->

<!--                      <v-row>-->
<!--                        <v-col>-->
<!--                          <span>Flash de caixa</span>-->
<!--                        </v-col>-->

<!--                        <v-col cols="4">-->
<!--                          <v-switch :disabled="mode === 'view'"-->
<!--                                    v-model="usuario.permissoes.flash_caixa"-->
<!--                                    :label="usuario.permissoes.flash_caixa ? 'Autorizado' : 'Não autorizado'"/>-->
<!--                        </v-col>-->

<!--                      </v-row>-->

<!--                    </v-col>-->
                  </v-row>
                </v-container>
              </v-tab-item>
            </v-tabs-items>

            <v-row>
              <v-col class="text-end">
                <v-btn :dark="checkPassword"
                       color="primary"
                       type="submit"
                       v-if="mode !== 'view' && user.permissoes.adm_usuario"
                       :disabled="!checkPassword">
                  Salvar
                </v-btn>
              </v-col>
            </v-row>
          </form>
        </v-col>
      </v-row>
      <EmpresasDialog
          :showDialog="empresaDialog.showEmpresasDialog"
          :multiplaSelecao="true"
          :title="empresaDialog.titleEmpresaDialog"
          :empresasVinculadas="todasEmpresas"
          @cancela-dialog="cancelaEmpresaDialog"
          @empresa-selecionada="empresaSelecionada"
          :valida="true"
      />
    </v-container>
  </div>
</template>

<script>
import EmpresasDialog from "@/components/Dialogs/EmpresasDialog.vue";
import Slider from 'primevue/slider';
import InputNumber from 'primevue/inputnumber';

import { mapMutations, mapState } from "vuex";
export default {
  components: {
    EmpresasDialog, Slider, InputNumber
  },
  props: {
    mode: String,
    idUsuario: String
  },
  mounted() {
    if (this.mode != "create") this.getUsuario();
  },
  data() {
    return {
      loadingSkeleton: false,
      tab: null,
      previousTab: 0,
      usuario: {
        status:true,
        tipo: "USUARIO",
        cpf: null,
        telefone: null,
        permissoes:{},
        empresas: []
      },
      empresaDialog: {
        showEmpresasDialog: false,
        titleEmpresaDialog: ""
      },
      pagination: {
        page: 1,
        total: 0,
        limit: 10,
        first: 0
      },
      todasEmpresas: [],
      empresasNaPagina: []
    };
  },

  computed: {
    ...mapState(["config","user"]),
    maskedEmail() {
      const email = this.usuario.email;
      const mode = this.mode;
      let maskedEmail = email;

      if(email && mode !== 'create') {
        const emailParts = email?.split("@");
        const localPart = emailParts[0];
        const domainPart = emailParts[1];

        const count = Math.floor(localPart.length / 2);

        const maskedLocalPart = localPart.slice(0, count) + "*****";
        maskedEmail = maskedLocalPart + "@" + domainPart;
      }


      return maskedEmail;
    },
    maskedCpf() {
      const cpf = this.usuario.cpf;
      const mode = this.mode;
      let masked = this.$masks.cpf(cpf);

      if(cpf && mode !== 'create') {
        masked = cpf?.slice(0, 2) + "******" + cpf?.slice(-3);
      }

      return masked;
    },
    checkPassword() {
      return this.usuario.password === this.usuario.repassword ? true : false;
    },
    tiposUsuario() {
      const result = Object.keys(this.TIPO_USUARIO).map(k => {
        return {
          valor: k,
          texto: this.TIPO_USUARIO[k]
        };
      });

      return result;
    },
    totalPaginas() {
      return Math.ceil(this.pagination.total / this.pagination.limit);
    }
  },
  methods: {
    ...mapMutations([
      "openDialogMessage",
      "openDialogConfirm",
      "closeDialogConfirm"
    ]),
    cancelaEmpresaDialog() {
      this.empresaDialog.showEmpresasDialog = false;
    },
    empresaSelecionada(empresaCallBack) {

      const empresaAux = empresaCallBack.filter(empresa => {
        const checkExistente = this.todasEmpresas.some(
          currentEmpresa => currentEmpresa.id === empresa.id
        );
        return !checkExistente;
      });

      this.todasEmpresas.push(...empresaAux);
      this.todasEmpresas.reverse();
      this.pagination.total = this.todasEmpresas?.length;
      this.setPagina(this.pagination.page);

      const msg = empresaCallBack.length > 1 ? `${empresaCallBack.length} Empresas selecionadas com sucesso!` : "Empresa selecionada com sucesso!";

      this.$toast.add({
        severity: "success",
        summary: msg,
        life: 2000
      });

      this.empresaDialog.showEmpresasDialog = false;
    },
    handleInputEmail($event) {
      this.usuario.email = $event;
    },
    handleInputCpf($event) {
      this.usuario.cpf = $event;
    },
    async handleSave() {
      if (!this.customRulesForm()) return;

      const data = {
        marketplace: {
          id: this.user.marketplace.id
        },
        tipo: this.usuario.tipo,
        codigo: this.usuario.codigo,
        status: this.usuario.status ? "ATIVO" : "INATIVO",
        id: this.usuario.id,
        nome: this.usuario.nome,
        email: this.usuario.email,
        cpf: this.usuario.cpf,
        telefone: this.usuario.telefone,
        senha: this.usuario.password,
        permissoes: this.usuario.permissoes,
        max_desconto: this.usuario.max_desconto
      };

      data.empresas = this.todasEmpresas.map(empresa => {
        return { id: empresa.id };
      });

      if (this.mode === "create") {
        await this.save(data);
      } else {
        await this.edit(data);
      }
    },
    customRulesForm() {
      let errorMessage = null;
      if (this.todasEmpresas.length === 0) {
        errorMessage =
          "É necessário informar ao menos uma empresa para o usuário.";
      }
      if (errorMessage !== null) {
        this.openDialogMessage({
          type: "error",
          title: "Erro - Cadastrar Usuário",
          text: errorMessage
        });
        return false;
      }

      return true;
    },
    async save(data) {
      this.$axios
        .post("/usuario", data)
        .then(() => {
          this.openDialogMessage({
            type: "success",
            title: "Cadastrar Usuário",
            text: `Usuário Cadastrado com sucesso. <br />
            A senha de acesso foi enviada para o e-mail ${this.usuario.email}.`
          });
          this.$router.push({
            name: "Usuarios"
          });
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Cadastrar Usuário",
            text: error.response.data
          });
        });
    },
    async edit(data) {
      await this.$axios
        .put("/usuario", data)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: `Usuário atualizado com sucesso.`,
            life: 2000
          });
          this.$router.push({
            name: "Usuarios"
          });
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Atualizar Usuário",
            text: error.response.data
          });
        });
    },
    getUsuario() {
      this.loadingSkeleton = true;

      this.$axios
        .get(`/usuario/${this.idUsuario}`)
        .then(async (response) => {
          this.usuario = response.data;
          this.usuario.status = this.usuario.status === "ATIVO" ? true : false;
          this.usuario.cpf = this.$masks.cpf(this.usuario.cpf);
          this.usuario.telefone = this.$masks.telefone(this.usuario.telefone);
          this.todasEmpresas = await this.getEmpresas(this.usuario);
          this.pagination.total = this.todasEmpresas?.length;
          this.setPagina(this.pagination.page);
          this.loadingSkeleton = false;
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Consultar Usuário",
            text: error.response.data
          });
        });
    },
    getUsuarioByCPF() {
      if (!this.usuario.cpf || this.mode != "create") return;

      this.$axios
        .get(`/usuario/documento/${this.sanitizeString(this.usuario.cpf)}`)
        .then(async (response) => {
          this.openDialogMessage({
            type: "warning",
            title: "Aviso!",
            text: `Cpf já cadastrado para o usuário ${response.data.nome}.<br /> Atenção você está agora no modo edição deste usuário.`
          });

          this.usuario = response.data;
          this.usuario.cpf = this.$masks.cpf(this.usuario.cpf);
          this.todasEmpresas = await this.getEmpresas(this.usuario);
          this.pagination.total = this.todasEmpresas?.length;
          this.setPagina(this.pagination.page);
          this.$router.push({
            name: "editUsuario",
            params: {
              idUsuario: response.data.id
            }
          });
        });
    },

    setPagina(page) {
      const startIndex = (page - 1) * this.pagination.limit;
      const endIndex = startIndex + this.pagination.limit;
      this.empresasNaPagina = this.todasEmpresas.slice(startIndex, endIndex); // Atualiza as empresas da página atual
    },

    openModalRemoveEmpresa(empresa) {
      this.openDialogConfirm({
        title: "Remover Empresa",
        text: `Deseja realmente remover a empresa ${empresa.razao_social ? empresa.razao_social : empresa.nome}?`,
        confirma: this.removeEmpresa.bind(this, empresa),
        cancela: this.closeDialogConfirm.bind()
      });
    },
    async removeEmpresa(empresa) {
      this.closeDialogConfirm();
      await this.$axios.delete(`/usuario/${this.usuario.id}/empresa/${empresa.id}`)
        .then(()=>{

          this.$toast.add({
            severity: "success",
            summary: "Empresa removida com sucesso!",
            life: 2000
          });

          this.usuario = {
            ... this.usuario,
          };

          this.todasEmpresas = this.todasEmpresas.filter(empresaFilter => {
            return empresaFilter.id !== empresa.id;
          });

          this.pagination.total = this.todasEmpresas?.length;
          this.setPagina(this.pagination.page);

        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Remover empresa do usuário",
            text: error.response.data
          });
        });

    },

    confirmResetSenha() {
      this.openDialogConfirm({
        title: "Resetar Senha",
        text: `Deseja realmente resetar a senha deste usuário?`,
        confirma: this.resetSenha.bind(),
        cancela: this.closeDialogConfirm.bind()
      });
    },
    resetSenha() {
      this.$axios
        .put(`/usuario/${this.usuario.id}/resetar-senha`)
        .then(() => {
          this.openDialogMessage({
            type: "success",
            title: "Resetar Senha",
            text: `Um e-mail com uma nova senha foi enviado para a ${this.usuario.email}`
          });
          this.closeDialogConfirm();

          if (this.user.id === this.usuario.id) this.$store.commit("logout");
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Resetar Senha",
            text: error.response.data
          });
        });
    },
    async getEmpresas(usuario) {
      return await this.$axios.get(`/usuario/${usuario.id}/empresas`, {
        params: {
          first: this.pagination.first,
          limit: this.pagination.limit
        }
      })
      .then(response => {
        return response.data;
      });
    },
    async validaForm(currentTab) {

      if(!document.querySelector("form")){
        return
      }

      if (!document.querySelector("form").checkValidity()) {

        document.querySelector("form").reportValidity();

        // this.tab = this.previousTab;
        await this.$nextTick();
        this.tab = this.previousTab;
      } else {
        this.previousTab = currentTab;
      }

      //  alert(document.querySelector("form").checkValidity());
    },
  }
};
</script>

<style scoped>
.v-input--selection-controls{
  margin-top: 0 !important;
  padding-top: 0 !important;
}
.container {
  max-width: 85rem;
}
@media (max-width: 900px) {
  .box_mobile {
    display: block !important;
  }
  .box_mobile_info {
    max-width: 100% !important;
  }
}

</style>
