<template>
  <v-container fluid>
    <v-row class="ml-n12">
        <div class="espacado">
          <v-tabs v-model="tab" align-with-title @change="onTabChange">
            <v-tabs-slider color="secondary"></v-tabs-slider>
            <v-tab>
              <v-icon class="mr-3">mdi-cash-usd</v-icon>
              Antecipar
            </v-tab>
            <v-tab>
              <v-icon class="mr-3">mdi-format-list-bulleted</v-icon>
              Pedidos
            </v-tab>
          </v-tabs>
        </div>
    </v-row>

    <v-row>
      <v-col>
        <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-row justify="center">

            <v-col cols="6" v-if="tab != 0">
              <v-row class="grey lighten-5 px-16 mt-10">
                <v-col cols="6">
                  <v-skeleton-loader
                      :loading="true"
                      type="heading"
                  ></v-skeleton-loader>
                  <v-skeleton-loader
                      class="mt-3"
                      :loading="true"
                      type="text@4"
                  ></v-skeleton-loader>
                </v-col>
                <v-divider vertical></v-divider>
                <v-col cols="6">
                  <v-skeleton-loader
                      :loading="true"
                      type="heading"
                  ></v-skeleton-loader>
                  <v-skeleton-loader
                      class="mt-3"
                      :loading="true"
                      type="text@4"
                  ></v-skeleton-loader>
                </v-col>
              </v-row>
              <v-row class="px-16 mt-5">
                <v-col>
                  <v-skeleton-loader
                      :loading="true"
                      type="text"
                  ></v-skeleton-loader>
                  <v-skeleton-loader
                      :loading="true"
                      type="image"
                      height="50"
                  ></v-skeleton-loader>
                </v-col>
              </v-row>
            </v-col>
            <v-col v-if="tab == 0 && !getPedidoAntecipacaoProcessamento" class="col-8">
              <v-row>
                <v-col>
                  <CardRecebiveisBruto @total-bruto-recebivel="setMaximoRecebivel" />
                </v-col>
                <v-col>
                  <CardRecebiveisLiquido :totais="totais" />
                </v-col>
              </v-row>
              <v-row>
                <v-col class="centralizado">
                  <label class="d-flex titulo" for="minmaxfraction">
                    Valor desejado
                  </label>
                  <span class="p-input-icon-left p-input-icon-right">
                      <i style="width: fit-content;">R$</i>
                        <InputNumber
                          class="input-big"
                          inputmode="numeric"
                          mode="decimal"
                          v-model="valorDesejado"
                          :minFractionDigits="2"
                          :maxFractionDigits="2"
                          :max="maximoRecebivel"
                          aria-describedby="help"
                          @keyup="onInput"
                        />
                  </span>
                  <small class="d-flex" id="help">Valor total que será antecipado</small>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="centralizado">

                  <Button label="Confirmar"
                          :loading="executando"
                          :disabled="executando || valorDesejado <= 5"
                          class="p-button-success"
                          @click="openModalConfirmSolicitacao"/>

                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="getPedidoAntecipacaoProcessamento">
              <v-card>
                <v-card-title class="d-flex justify-space-between">
                  {{ STATUS_ANTECIPACAO[getPedidoAntecipacaoProcessamento.status].texto }} Pedido de Antecipação
                </v-card-title>
                <v-card-text>
                    <v-row dense>
                        <v-col>
                            <span class="d-flex">Data de solicitação</span>
                            {{ getPedidoAntecipacaoProcessamento.data_criacao | formatDate({ dateStyle: "short", timeStyle: "short" }) }}
                        </v-col>
                        <v-col>
                            <span class="d-flex">Data de liquidação</span>
                            {{ getPedidoAntecipacaoProcessamento.data_liquidacao | formatDate({ dateStyle: "short"}) }}
                        </v-col>
                        <v-col>
                            <span class="d-flex">Protocolo</span>
                            {{ getPedidoAntecipacaoProcessamento.id }}
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <span class="d-flex">Total antecipado</span>
                            R$ {{ getPedidoAntecipacaoProcessamento.valor_bruto_antecipado | formatMoney({ minimumFractionDigits: 2 })}}
                        </v-col>
                        <v-col>
                            <span class="d-flex">Total taxa de antecipação</span>
                            R$ {{ getPedidoAntecipacaoProcessamento.valor_taxa_antecipacao | formatMoney({ minimumFractionDigits: 2 })}}
                        </v-col>
                        <v-col>
                            <span class="d-flex">Valor total liquidação</span>
                            R$ {{ getPedidoAntecipacaoProcessamento.valor_liquido_antecipado | formatMoney({ minimumFractionDigits: 2 })}}
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col class="col-4">
                            <span class="d-flex">Recebedor</span>
                            <span v-if="getPedidoAntecipacaoProcessamento.recebedor.razao_social" class="d-flex">{{ getPedidoAntecipacaoProcessamento.recebedor.razao_social }}</span>
                            <span v-if="getPedidoAntecipacaoProcessamento.recebedor.nome" class="d-flex">{{ getPedidoAntecipacaoProcessamento.recebedor.nome }}</span>
                        </v-col>
                        <v-col>
                            <span class="d-flex">Solicitante</span>
                            <span class="d-flex">{{ getPedidoAntecipacaoProcessamento.solicitante.nome }}</span>
                        </v-col>
                    </v-row>

                    <v-row v-if="$vuetify.breakpoint.smAndUp">
                        <v-col>
                            <span class="d-flex">Parcelas antecipadas</span>
                            <DataTable :value="getPedidoAntecipacaoProcessamento.parcelas_afetadas"
                                    :scrollable="true" scrollHeight="300px"
                                    sortField="data_compensacao_original" :sortOrder="-1"
                                    dataKey="id">

                                <Column field="data_compensacao_original" :styles="{'min-width':'190px'}"
                                        header="Compensação original" sortable>
                                    <template #body="slot">
                                        {{  slot.data.data_compensacao_original | formatDate({ dateStyle: "short" }) }}
                                    </template>
                                </Column>

                                <Column field="valor" header="Valor bruto">
                                    <template #body="slot">
                                        R$ {{ slot.data.valor | formatMoney({ minimumFractionDigits: 2 })}}
                                    </template>
                                </Column>
                                <Column field="valor_taxa_antecipacao" header="Taxa antecipação">
                                    <template #body="slot">
                                        R$ {{ slot.data.valor_taxa_antecipacao | formatMoney({ minimumFractionDigits: 2 })}}
                                    </template>
                                </Column>

                                <Column field="valor_liquido" header="Valor líquido">
                                    <template #body="slot">
                                        R$ {{ slot.data.valor_liquido | formatMoney({ minimumFractionDigits: 2 })}}
                                    </template>
                                </Column>

                            </DataTable>
                        </v-col>
                    </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          </v-tab-item>

          <v-tab-item>
            <v-col v-if="loadingSkeleton">
                <v-skeleton-loader
                  :loading="true"
                  type="table">
                </v-skeleton-loader>
            </v-col>
            <v-col v-else>
              <DataTable :value="pedidos" responsiveLayout="stack" removableSort
                        sortField="data_criacao" :sortOrder="-1"
                        breakpoint="960px" scrollHeight="flex-row"
                        @row-select="onRowSelect" selectionMode="single"
                        dataKey="id">

                  <Column field="status" header="Status" sortable>
                    <template #body="slot">
                      <v-chip
                            class="ma-2 text-center"
                            :color="STATUS_ANTECIPACAO[slot.data.status].cor"
                            style="justify-content: center; min-width: 100px"
                            label text-color="white">
                            {{ STATUS_ANTECIPACAO[slot.data.status].texto }}
                      </v-chip>

                    </template>
                  </Column>

                  <Column field="data_criacao" header="Solicitado" sortable>
                    <template #body="slot">
                        {{  slot.data.data_criacao | formatDate({ dateStyle: "short", timeStyle: "short" }) }}
                    </template>
                  </Column>
                  <Column field="solicitante" header="Solicitante" sortable>
                    <template #body="slot">
                        {{  slot.data.solicitante.nome }}
                    </template>
                  </Column>
                  <Column field="data_liquidacao" header="Liquidação" sortable>
                    <template #body="slot">
                        {{  slot.data.data_liquidacao | formatDate({ dateStyle: "short" }) }}
                    </template>
                  </Column>

                  <Column field="valor_bruto_antecipado" header="Antecipado">
                    <template #body="slot">
                    R$ {{ slot.data.valor_bruto_antecipado | formatMoney({ minimumFractionDigits: 2 })}}
                    </template>
                  </Column>

                  <Column field="valor_taxa_antecipacao" header="Taxa">
                    <template #body="slot">
                    R$ {{ slot.data.valor_taxa_antecipacao | formatMoney({ minimumFractionDigits: 2 })}}
                    </template>
                  </Column>

                  <Column field="valor_liquido_antecipado" header="Liquidação">
                    <template #body="slot">
                      R$ {{ slot.data.valor_liquido_antecipado | formatMoney({ minimumFractionDigits: 2 })}}
                    </template>
                  </Column>

                  <template #empty>
                      <p class="centralizado">Nenhum pedido de antecipação pontual encontrado. </p>
                  </template>
              </DataTable>
              <v-pagination
                v-model="pagination.page"
                :length="totalPaginas"
                @input="next"
                :loading="loadingSkeleton"
                :total-visible="7">
              </v-pagination>
            </v-col>
          </v-tab-item>

        </v-tabs-items>
      </v-col>
    </v-row>



    <DialogPedidoAntecipacao :pedido="pedido" :fechar="()=> pedido = null" />
  </v-container>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import CardRecebiveisBruto from "@/components/Cards/CardRecebiveisBruto.vue";
import CardRecebiveisLiquido from "@/components/Cards/CardRecebiveisLiquido.vue";
import DialogPedidoAntecipacao from '@/components/Dialogs/DialogPedidoAntecipacao';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputNumber from "primevue/inputnumber";
import Button from 'primevue/button';
import { debounce } from 'lodash';
import EventBus from "@/plugins/evento"

function resetTotais(){
  return {
    valor_bruto: 0,
    valor_liquido: 0,
    total_taxas: 0
  }
}

export default {
  components: {
    CardRecebiveisBruto,
    CardRecebiveisLiquido,
    DialogPedidoAntecipacao,
    DataTable,
    Column,
    InputNumber,
    Button
  },
  data() {
    return {
      loadingSkeleton: false,
      tab: null,
      previousTab: 0,
      pedidos: [],
      pedido: null,
      maximoRecebivel: 0,
      valorDesejado: 0,
      totais: resetTotais(),
      executando: false,
      onInput : null,
      pagination: {
        page: 1,
        total: 0,
        limit: 7,
        first: 0
      }
    };
  },
  computed: {
    ... mapState(["empresaAtual"]),
    ... mapGetters(["getPedidoAntecipacaoProcessamento"]),
    totalPaginas() {
      return Math.ceil(this.pagination.total / this.pagination.limit);
    }
  },
  beforeUpdate() {
    if (this.valorDesejado >= this.maximoRecebivel) {
      this.valorDesejado = this.maximoRecebivel;
    }
  },
  created(){
    this.onInput = debounce((event)=>{
      if(event.key == 'Backspace' && this.valorDesejado <= 0){
        this.totais = resetTotais();
        return
      }
      this.getCalculaAntecipacao();

    }, 500)

    if(this.getPedidoAntecipacaoProcessamento){
      setTimeout(this.atualizaPedidoEmProcesamento, 5000)
    }
  },
  methods: {
    ...mapMutations([
      "openDialogMessage",
      "openDialogConfirm",
      "closeDialogConfirm",
      "setPedidoAntecipacaoProcessamento",
    ]),
    next(page) {
      this.pagination.page = page;
      this.pagination.first = (page - 1) * this.pagination.limit;
      this.getPedidos();
    },
    onTabChange(newTab) {
      if(newTab == 1){
        this.getPedidos();
      }
    },
    setMaximoRecebivel(value) {
      this.maximoRecebivel = value;
      this.valorDesejado = value;
      this.getCalculaAntecipacao();
    },
    onRowSelect(event){
      this.pedido = event.data;
    },
    getCalculaAntecipacao() {
      if (this.valorDesejado < 5) {
        this.totais = resetTotais()
        return;
      }
      this.executando = true;
      this.$axios
        .get(
          `/recebivel/empresa/${this.empresaAtual.id}/calcula-antecipacao?valor=${this.valorDesejado}`
        )
        .then(response => {
          this.totais = response.data;
        })
        .catch(error => {
          this.totais = [];
          this.openDialogMessage({
            type: "error",
            title: "Consultar os recebíveis",
            text: error.response.data
          });
        }).finally(() => {
          this.executando = false;
        });
    },
    getPedidos() {
      this.loadingSkeleton = true;
      this.$axios
        .get(
          `/recebivel/empresa/${this.empresaAtual.id}/pedidos-antecipacao`,
          {
            params: {
              first: this.pagination.first,
              limit: this.pagination.limit
            }
          }
        )
        .then(response => {
          this.pedidos = response.data.data;
          this.pagination.total = response.data.count;
        })
        .catch(error => {
          this.pedidos = [];
          this.openDialogMessage({
            type: "error",
            title: "Não foi possivel consultar os pedidos anteriores.",
            text: error.response.data
          });
        })
        .finally(() => {
          this.loadingSkeleton = false;
        });
    },
    openModalConfirmSolicitacao() {
      this.openDialogConfirm({
        title: "Atenção",
        text: `Deseja realmente solicitar a antecipação do valor total de R$ ${this.valorDesejado.toFixed(2)}? Esta ação não poderá ser desfeita.`,
        confirma: () => {
          this.solicitaAntecipacao();
          this.closeDialogConfirm();
        },
        cancela: this.closeDialogConfirm,
      });
    },
    solicitaAntecipacao() {
      this.executando = true;
      this.$axios
        .post(`/recebivel/empresa/${this.empresaAtual.id}/pedido-antecipacao`, {
          valor: this.valorDesejado
        })
        .then(response => {
          this.setPedidoAntecipacaoProcessamento(response.data);
          setTimeout(this.atualizaPedidoEmProcesamento, 15000)
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Não foi possivel solicitar a antecipação ",
            text: error.response.data
          });
        })
        .finally(() => {
          this.executando = false;
          EventBus.$emit('reload');
        });
    },
    getPedidoAntecipacao(id){
      return this.$axios
        .get(
          `/recebivel/empresa/${this.empresaAtual.id}/pedido-antecipacao/${id}`)
        .then(response => {
          return response.data;
        })
        .catch(error => {
          console.log('Não foi possivel consultar', error)
        })
    },
    async atualizaPedidoEmProcesamento(){
      if(this.getPedidoAntecipacaoProcessamento){
        const obj = await this.getPedidoAntecipacao(this.getPedidoAntecipacaoProcessamento.id);
        if(obj.status == 'PROCESSANDO'){
          setTimeout(this.atualizaPedidoEmProcesamento, 15000)
          return
        }
        this.setPedidoAntecipacaoProcessamento(null)
        this.pedido = obj;
      }

    }
  }
};
</script>

<style scoped>
.v-application .ma-2{
  margin: 0;
}
@media (max-width: 900px) {
  .col,
  .col-4 {
    flex: none;
    max-width: 25rem;
  }
}
.titulo {
  font-size: 1.5rem;
}

.p-button-success{
  background: #33b35b !important;
  border: 1px solid #33b35b !important;
  width: 100%;
  max-width: 20rem;
}

</style>
