<template>
  <v-container v-if="transacao?.id">
    <v-row>
      <v-col>
        <v-btn
          outlined
          color="primary"
          @click="generatePdf"
        >
          Gerar Carta de Estorno
        </v-btn>
      </v-col>
    </v-row>

    <!-- Componente para gerar o PDF -->
    <vue-html2pdf
      ref="html2Pdf"
      :show-layout="false"
      :float-layout="false"
      :enable-download="true"
      :filename="'CARTA_CANCELAMENTO_TRANSACAO_' + transacao?.id"
      :pdf-quality="2"
      pdf-format="a4"
      :paginate-elements-by-height="1400"
      pdf-orientation="portrait"
      :preview-modal="true"
      :manual-pagination="false"
      pdf-content-width="800px"
    >
      <!-- Conteúdo do PDF -->
      <section slot="pdf-content">
        <div class="carta-cancelamento">
          <div class="header">
            <img
              :src="getLogoImage"
              alt="Logo"
              class="logo"
            />
          </div>

          <h2 class="titulo">Carta de Cancelamento</h2>

          <p class="data-local">São Bernardo do Campo, {{ formatDate(new Date()) }}</p>

          <p>Prezado(a) cliente,</p>
          <p>
            Em {{ formatDate(transacao?.data_atualizacao) }}, recebemos a solicitação
            de cancelamento da transação abaixo identificada. Tendo em vista que o pedido de
            CANCELAMENTO atende ao prazo previsto em Contrato, iniciamos o processo de
            Cancelamento da transação de pagamento.
          </p>

          <div class="informacoes">
            <p><strong>Id do Cancelamento:</strong> {{ transacao?.nsu_cancelamento }}</p>
            <p><strong>Id da Transação:</strong> {{ transacao?.id }}</p>
            <p><strong>NSU</strong> {{ transacao?.nsu }}</p>
            <p><strong>Código do Estabelecimento:</strong> {{ transacao?.empresa?.mid }}</p>
            <p><strong>Nome Filial:</strong> {{ transacao?.empresa?.razao_social ? transacao?.empresa?.razao_social : transacao?.empresa?.nome }}</p>
            <p><strong>Data da Venda:</strong> {{ formatDate(transacao?.data_criacao) }}</p>
            <p><strong>Número do Cartão:</strong> {{ `${ transacao?.card_first_digits} ***** ${transacao?.card_last_digits}`  }}</p>
            <p><strong>Código de Autorização:</strong> {{ transacao?.codigo_autorizacao_cancelamento }}</p>
            <p><strong>Valor total da Venda:</strong> R$ {{ transacao?.valor | formatMoney({minimumFractionDigits: 2 }) }}</p>
            <p><strong>Valor Cancelado:</strong> R$ {{ transacao?.valor | formatMoney({minimumFractionDigits: 2 }) }}</p>
            <p><strong>Data do Cancelamento:</strong> {{ formatDate(transacao?.data_atualizacao) }}</p>
          </div>

          <div class="dados-favorecido">
            <p><strong>Dados do favorecido</strong></p>
            <p><strong>Cartão utilizado:</strong> {{ `${transacao?.card_first_digits} ***** ${transacao?.card_last_digits}` }}</p>
            <p><strong>Bandeira:</strong> {{ transacao?.card_brand }}</p>
          </div>

          <p>
            A transação acima foi estornada e a empresa administradora do cartão já foi notificada
            sobre o pedido de cancelamento. O valor referente a transação será creditado em sua
            fatura ou conta bancária a depender do modelo de transação. Caso sua fatura já tenha
            sido fechada o valor será creditado na próxima fatura.
          </p>

          <p class="msg-final"><strong>Sugerimos a guarda deste documento.</strong></p>
          <p>Sistematize Tecnologia da Informação LTDA.</p>
          <p>31.924.660/0001-92</p>

          <footer>
            <p>
              Rua Frei Gaspar, 941, Cj. 810 | Centro | São Bernardo do Campo/SP |
              09720-440 | Contato: 11 5199.1570
            </p>
            <p style="color: #0c296b">www.sistematize.me</p>
          </footer>
        </div>
      </section>
    </vue-html2pdf>
  </v-container>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
import { mapState } from "vuex";

export default {
  name: "CartaEstorno",
  props: {
    transacao: Object,
  },
  components: {
    VueHtml2pdf,
  },
  computed: {
    ...mapState(["user", "config"]),
    getLogoImage() {
      if (!this.config.images.logo) return;
      return this.config.images.logo;
    }
  },
  methods: {
    generatePdf() {
      try {
        this.$refs.html2Pdf.downloadPdf();
      } catch (error) {
        console.error("Erro ao gerar o PDF:", error);
      }
    },
    formatDate(date) {
      if (!date || isNaN(new Date(date))) {
        return "Data inválida";
      }
      return new Intl.DateTimeFormat("pt-BR", { dateStyle: "long" }).format(new Date(date));
    },
  },
};
</script>

<style scoped>
.carta-cancelamento {
  font-family: Arial, sans-serif;
  font-size: 12px;
  line-height: 1.6;
  color: #000;
  padding: 2rem;
  margin: 0 auto;
  background-color: white;
  width: 700px;
}

.header {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 1rem;
}

.logo {
  width: 200px;
  height: auto;
  padding: 0;
}

.data-local {
  text-align: right;
  font-size: 12px;
  margin-bottom: 1.5rem;
}

.titulo {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.informacoes {
  margin: 1.5rem 0;
}

.dados-favorecido {
  margin: 1.5rem 0;
}

.msg-final {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

footer {
  margin-top: 2rem;
  font-size: 10px;
  text-align: center;
  padding-top: 1rem;
}
footer p {
  margin: 0.1rem 0;
}
</style>
