import axios from "axios";
import store from "@/store";
import router from "@/router";
import CryptoJS from "crypto-js";

const messageAndLogout = (message: string): void => {
  store.commit("openDialogMessage", {
    type: "error",
    title: "Erro de Autenticação",
    text:message,
  });
  store.commit("logout");
}
const normalizeUrl = (url: string, params: object): string => {
  let paramsConverted = "";
  if (params) {
    paramsConverted = "?";
    Object.entries(params).forEach(([key, value]) => {
      paramsConverted += `${key}=${value}&`;
    });
    paramsConverted = paramsConverted.substring(0, paramsConverted.length - 1);
  }
  return `${url}${paramsConverted}`;
};

const checkToken =  (user: { id?: null; tipo?: null; senha?: null; email?: null; redirect?: boolean; token?: null; data_validade_token?: any; }) => {
    const providedDate = new Date(user.data_validade_token);

    const providedTimestamp = providedDate.getTime();

    const currentTimestamp = Date.now();

    const timeDifference = providedTimestamp - currentTimestamp;

    if (currentTimestamp > providedTimestamp) {
        return "expired";
    }

    if (timeDifference <= 300000) {
        return "renew";
    }

    return "valid";
}

const baseApi = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
});

baseApi.interceptors.request.use(
  async (config) => {

    if (!config.headers["noloading"]) {
      store.commit("setAppLoading", true);
    }

      if(config.data && config.data.skipAuth) {
          const currentPath = router.currentRoute.path;

          router.push(currentPath).catch(err => {
              if (err.name !== 'NavigationDuplicated' && err.name !== 'AbortError') {
                  throw err;
              }
          });
      }

    let user = store.state.user;

    //TODO router.push tela de login
    if (!user.token) return config;

    //Verificando validade do token
      const isValid = checkToken(user);

      if(isValid === 'expired') router.push("/login"); // Se a data tiver expired retorna para tela de login

      // Se a data tiver renew, esta proximo do vencimento, faz nova requisição para pegar novo token
      if(isValid === 'renew') {
          await store.dispatch("getNewToken", { user: user })
              .then((result) => {
                  store.commit("login", result);
                  user = store.state.user;
              })
              .catch(error => console.error('Error:', error));
      }

    if(user.tipo !== "USUARIO") {
      messageAndLogout("Usuários de aplicação não são autorizados a utilizar a plataforma web, por favor use um usuário comum.");
    }
    let key;
    if (!config.data) {
      key = normalizeUrl(`${config.baseURL}${config.url}`, config.params);
    } else {
      key = JSON.stringify(config.data);
    }

    if (config.headers["file-token"]) {
      key = config.headers["file-token"];
    }

    const pass = "sha1=" + CryptoJS.HmacSHA1(key, user.token).toString();

    config.headers.Authorization = "Basic " + btoa(user.email + ":" + pass);

    return config;
  },
  (error) => {
    store.commit("setAppLoading", false);
    return Promise.reject(error);
  }
);

baseApi.interceptors.response.use(
  (response) => {
    store.commit("setAppLoading", false);
    return response;
  },

  (error) => {
      if(error.response.status === 401) {
          router.push("/login");
      }
     console.log(error)
    store.commit("setAppLoading", false);

    // if (error.message === "Request failed with status code 401") {
    //   messageAndLogout("Houve um problema na autenticação da sua requisição, você será redirecionado para a tela de login.");
    // }

    return Promise.reject(error);
  }
);

export default baseApi;
