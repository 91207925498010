<template>
  <div v-if="user && user.permissoes.extrato_conta">
    <v-row>
      <v-col>
        <form @submit.prevent="getExtrato">
              <Calendar v-model="dataIntervalo" selectionMode="range"
                          @date-select="getExtrato" :showIcon="true"
                          dateFormat="dd/mm/yy" />
        </form>
      </v-col>
      <v-col cols="1" class="text-end">
        <v-btn type="button" @click="exportar" icon>
            <v-icon> mdi-download </v-icon>
        </v-btn>
      </v-col>
    </v-row>

   <v-row>
    <v-col>
      <ol class="timeline">
          <li v-for="movto in extrato" :key="movto.id">
            <div class="header">
              <div class="avatar">
                <v-icon class="icon">mdi-calendar</v-icon>
              </div>
              <div class="title">
                <span>{{ movto.data | formatDate({ dateStyle: "short"})}}</span>
              </div>
              <div class="saldoDia">
                <span class="label">Saldo</span>
                <span :class="movto.saldo_dia >= 0 ? 'green--text' : 'red--text'">
                  <span style="font-size: 0.6rem;" :class="movto.saldo_dia >= 0 ? 'green--text' : 'red--text'">R$</span>
                  {{ movto.saldo_dia | formatMoney({ minimumFractionDigits: 2 }) }}
                </span>
              </div>
            </div>
            <div class="content">
                <div class="sub-content" v-for="operacao in movto.operacoes" :key="operacao.id">
                  <div class="sub-content-title">
                    <div>
                      <v-icon class="icon" :color="TIPO_EXTRATO[operacao.tipo]?.cor || 'gray'">{{ operacao.valor >= 0.00 ?'mdi-plus':'mdi-minus' }}</v-icon>
                    </div>
                    <div>
                      <span :class="operacao.cancelado?'cancelado':''">{{ operacao.data_operacao | formatDateTimeShort() }} {{ TIPO_EXTRATO[operacao.tipo]?.texto || operacao.tipo }}</span>
                    </div>
                  </div>
                  <div class="sub-content-subtitle">
                   <p :class="[operacao.cancelado?'cancelado':'', 'tail']" v-if="operacao.remetente_nome">{{ operacao.remetente_nome }}</p>
                   <p :class="[operacao.cancelado?'cancelado':'', 'tail']" v-if="operacao.destinatario_nome">{{ operacao.destinatario_nome }}</p>
                   <p :class="[operacao.cancelado?'cancelado':'', 'tail']" v-if="operacao.estabelecimento">{{ operacao.estabelecimento }} - {{ operacao.categoria }}</p>
                    <p v-else></p>
                    <p style="white-space: nowrap;" :class="[operacao.valor >= 0 ? 'green--text' : 'red--text', operacao.cancelado?'cancelado':'']">
                      R$ {{operacao.valor | formatMoney({ minimumFractionDigits: 2 })}}
                    </p>
                  </div>
                  <div class="sub-content-detail">
                    <span v-if="operacao.detalhes" style="display: block;" class="tail">{{operacao.detalhes}}</span>
                    <span v-else style="display: block;" class="tail">{{operacao.descricao}}</span>
                  </div>

                </div>

                <div class="footer">

                </div>
            </div>
          </li>
      </ol>
    </v-col>
   </v-row>

  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import Calendar from 'primevue/calendar';
//import Tag from 'primevue/tag';

export default {
  name: "Extrato",
  components:{
    Calendar,
    //Tag
  },
  props: {
    contaCorrente: String
  },
  watch: {
    async contaCorrente(newValue, oldValue) {
      if (newValue) {
        this.getExtrato();
      }
    }
  },
  async mounted() {
    if(!this.user || !this.user.permissoes.extrato_conta){
      this.$store.commit("logout", false);
      return;
    }
    if (this.contaCorrente) {
      this.getExtrato();
    }
  },
  data() {
    return {
      dataIntervalo: [new Date(), new Date()],
      dataInicio: new Date().toISOString().split('T')[0],
      dataFim: new Date().toISOString().split('T')[1],
      extrato: [],
      carregando: false,
    };
  },
  computed: {
    ...mapState(["user"])
  },
  methods: {
    ...mapMutations(["openDialogMessage"]),
    getExtrato() {
      if (!this.contaCorrente) {
        this.openDialogMessage({
          type: "error",
          title: "Atenção",
          text: "Informe uma conta corrente"
        });
        return;
      }


      if(this.dataIntervalo[0]){
        this.dataInicio = this.dataIntervalo[0].toISOString().substr(0, 10);
      }

      if(this.dataIntervalo[1]){
        this.dataFim = this.dataIntervalo[1].toISOString().substr(0, 10);
      }

      if(!this.dataIntervalo[0] || !this.dataIntervalo[1]){
        return;
      }

      this.carregando = true;
      this.$axios.get( `/banco/conta/${this.contaCorrente}/extrato?data-inicio=${this.dataInicio}&data-fim=${this.dataFim}`)
        .then(response => {
          this.extrato = response.data;
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Consultar Extrato",
            text: error.response.data
          });
        }).finally(()=>{
          this.carregando = false;
        });

    },
    async exportar(){

      if(!this.extrato){
         this.openDialogMessage({
            type: "error",
            title: "Atenção",
            text: "Nenhum registro para exportação",
          });
      }

      const exportacao = []

      for(const e of this.extrato){
        for(const op of e.operacoes){
          const obj = {
            data_extrato: e.data.split('T')[0],
            saldo_dia: this.$masks.dinheiro(e.saldo_dia),
            data_operacao: op.data_operacao.split('T')[0],
            hora_operacao: op.data_operacao.split('T')[1],
            tipo: op.tipo,
            valor: this.$masks.dinheiro(op.valor),
            valor_bruto: this.$masks.dinheiro(op.valor_bruto),
            valor_bloqueado: this.$masks.dinheiro(op.valor_bloqueado),
            descricao: op.descricao??'',
            detalhes: op.detalhes??'',
            nsu: op.nsu??'',
            destinatario_documento: op.destinatario_documento??'',
            destinatario_nome: op.destinatario_nome??'',
            destinatario_agencia: op.destinatario_agencia??'',
            destinatario_numero_conta: op.destinatario_numero_conta??'',
            destinatario_nome_banco: op.destinatario_nome_banco??'',
            destinatario_ispb: op.destinatario_ispb??'',
            remetente_documento: op.remetente_documento??'',
            remetente_nome: op.remetente_nome??'',
            remetente_agencia: op.remetente_agencia??'',
            remetente_numero_conta: op.remetente_numero_conta??'',
            remetente_ispb: op.remetente_ispb??'',
            estabelecimento: op.estabelecimento??'',
            categoria: op.categoria??'',
            cancelado: op.cancelado?'SIM':'NÃO',
          }
          exportacao.push(obj)
        }
      }

      await this.toCSVeDownload("extrato.csv", exportacao);

    }
  }
};
</script>

<style scoped>

.timeline{
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.timeline li{
  display: flex;
  flex-direction: column;
  border-left: 1px solid rgb(175, 175, 175);
}
.timeline .header{
  position: relative;
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.header .avatar{
  max-width: 4rem;
  position: absolute;
  left: -20px;
}

.header .title{
  color: gray;
  margin-left: 1.5rem;
}
.header .saldoDia{
  text-align: end;
  margin-right: 1rem;
}
.header .saldoDia span{
  color: gray;
  font-size: 0.9rem;
}
.header .saldoDia .label{
  color: rgb(155, 155, 155);
  font-size: 0.7rem;
  display: block;
}
.footer{
  display: flex;
  flex-direction: column;
  align-items: end;
  margin-top: 0.8rem;
}

.footer span{
  color: gray;
  font-size: 0.9rem;
}
.footer p{
  font-size: 1.5rem;
}

.header .icon{
  color: white;
  background-color: var(--primary-color);
  padding: 0.5rem;
  border-radius: 50%;

}

.timeline .content {
  margin: 0.5rem 1rem 1rem 1rem;
  border-top: 1px solid rgb(221, 221, 221);
}
.sub-content{
  display: flex;
  flex-direction: column;
}
.sub-content-title{
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  font-size: 0.8rem;
  color: #888888;
}

.sub-content-title .icon{
  font-size: 1.5rem;
  padding: 0.5rem;
}
.sub-content-subtitle{
  display: flex;
  padding-left: 2.5rem;
  justify-content: space-between;
  text-align: end;
}
.sub-content-detail span{
  padding-left: 2.5rem;
  font-size: 0.8rem;
}
.tail{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.timeline p {
  margin-bottom: 0;
}

.cancelado {
  text-decoration: line-through;
}
</style>
