<template>
  <v-dialog v-model="showDialog" persistent max-width="1000">
    <v-card>
      <v-card-title>
        {{ title }}
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-card-text>
        <v-data-table
          v-model="empresasRetorno"
          :headers="headers"
          :items="empresas"
          :search="search"
          item-key="id"
          @click:row="empresaSelecionada"
          fixed-header
          :show-select="multiplaSelecao"
          :single-select="multiplaSelecao ? false : true"
          height="300"
          :items-per-page="mostrarTodasEmpresas ? -1 : 10"
        ></v-data-table>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="empresaAtual" color="btCancela" text @click="cancelaDialog"> Cancelar </v-btn>
        <v-btn v-if="empresaAtual" color="btConfirma" text @click="callBackEmpresa">
          Selecionar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations } from "vuex";
function initialState() {
  return {
    search: "",
    empresasRetorno: [],
    headers: [
      { text: "CNPJ", value: "cnpj" },
      { text: "Razão Social", value: "razao_social" },
      { text: "Nome Fantasia", value: "nome_fantasia" }
    ],
    empresas: []
  };
}
export default {
  props: {
    title: {
      type: String,
      default: "Empresas"
    },
    showDialog: {
      type: Boolean,
      required: true
    },
    valida: {
      type: Boolean,
      default: false
    },
    empresasVinculadas: {
      type: Array,
      default: null
    },
    multiplaSelecao: {
      type: Boolean,
      default: false
    },
    mostrarTodasEmpresas: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return initialState();
  },
  watch: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    showDialog(newValue, oldValue) {
      if (newValue === true) this.getEmpresas();
    }
  },
  computed: {
    ...mapState(["empresaAtual", "user"]),
  },
  methods: {
    ...mapMutations([
      "openDialogMessage",
      "openDialogMessage",
      "openDialogConfirm",
      "closeDialogConfirm"
    ]),
    async getEmpresas() {
      this.search = "";
      this.empresasRetorno = [];

      if (!this.user || !this.user.id) {
        this.empresas = [];
        return;
      }

      return this.$axios
        .get(`/usuario/${this.user.id}/empresas`)
        .then(response => {
          this.empresas = response.data.filter(
            emp =>
              !this.empresasVinculadas?.some(
                vinculada => vinculada.id === emp.id
              )
          );
        });
    },
    empresaSelecionada(item, tr) {
      if(!this.empresaAtual) {
        this.$emit("empresa-selecionada", item);
      }
      tr.select(true);
    },
    callBackEmpresa() {
      if (this.valida && this.empresasRetorno.length === 0) {
        this.openDialogMessage({
          type: "error",
          title: "Selecionar Empresa",
          text: "Selecione pelo menos uma empresa."
        });
        return;
      }
      if (this.multiplaSelecao) {
        this.$emit("empresa-selecionada", this.empresasRetorno);
        return;
      }
      this.$emit("empresa-selecionada", this.empresasRetorno[0]);
    },
    cancelaDialog() {
      Object.assign(this.$data, initialState());
      this.$emit("cancela-dialog");
    }
  }
};
</script>

<style>
tbody {
  cursor: pointer;
}
.v-data-table__selected {
  background: #3c89fc !important;
  font-weight: 600;
}
</style>
