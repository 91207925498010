<template>
  <v-dialog v-model="showDialog" persistent max-width="1500">
    <v-card style="overflow: hidden;">

      <v-row dense>
        <v-col class="text-start">
          <v-card-title> Adicionar Item </v-card-title>
        </v-col>
        <v-col class="text-end mt-3 mr-5">
          <v-icon @click="cancelar">mdi-close</v-icon>
        </v-col>
      </v-row>

      <v-card-text v-if="type === 'produtos'">
        <v-row>
          <div class="espacado">
            <Chips
              ref="chipcomponente"
              v-model="filtros.produto"
              separator=","
              @add="getProdutos(true)"
              @remove="getProdutos(true)"
              placeholder="Filtro por nome, código e descrição"
            />
          </div>
          <div class="espacado">
            <BotaoBuscarListas @buscar="buscar" />
          </div>
        </v-row>

        <v-row>
          <v-col>
            <div
                ref="scrollContainer"
                class="scroll-container"
                @scroll="handleScrollProdutos"
            >
              <DataTable
                  :value="produtos"
                  @row-select="onProdutoSelect"
                  selectionMode="single"
                  dataKey="id"
              >
                <Column
                    field="codigo_barras"
                    header="SKU"
                    :styles="{ width: '6rem' }"
                >
                  <template #body="slot">
                    <p class="sku">{{ slot.data.codigo_barras }}</p>
                  </template>
                </Column>
                <Column field="nome" header="Nome">
                  <template #body="slot">
                    <span>{{ slot.data.nome }}</span><br/>
                    <span v-if="slot.data.descricao" class="text-caption">
                  <span v-if="slot.data.descricao. length > 150">
                    {{ slot.data.descricao.slice(0, 150) }}...
                  </span>
                  <span v-else>
                    {{ slot.data.descricao }}
                  </span>
                  </span>
                  </template>
                </Column>
                <Column
                    field="valor"
                    header="Valor"
                    :styles="{ width: '6rem' }"
                >
                  <template #body="slot">
                    {{
                      slot.data.valor
                          | formatMoney({ minimumFractionDigits: 2 })
                    }}
                  </template>
                </Column>
              </DataTable>
            </div>
          </v-col>
        </v-row>

      </v-card-text>

      <v-card-text v-if="type === 'servicos'">
        <v-row>
          <div class="espacado">
            <Chips
              ref="chipcomponente"
              v-model="filtros.servico"
              separator=","
              @add="getServicos(true)"
              @remove="getServicos(true)"
              placeholder="Filtro por SKU, nome e descrição "
            />
          </div>
          <div class="espacado">
            <BotaoBuscarListas @buscar="buscar" />
          </div>
        </v-row>

        <v-row>
          <v-col>
            <div
                ref="scrollContainer"
                class="scroll-container"
                @scroll="handleScrollServicos"
            >
              <DataTable
                  :value="servicos"
                  @row-select="onServicoSelect"
                  selectionMode="single"
                  dataKey="id"
              >
                <Column
                    field="sku"
                    header="SKU"
                    :styles="{ width: '6rem' }"
                >
                  <template #body="slot">
                    <p class="sku">{{ slot.data.sku }}</p>
                  </template>
                </Column>
                <Column field="nome" header="Nome">
                  <template #body="slot">
                    <span>{{ slot.data.nome }}</span><br/>
                    <span v-if="slot.data.descricao" class="text-caption">
                  <span v-if="slot.data.descricao. length > 150">
                    {{ slot.data.descricao.slice(0, 150) }}...
                  </span>
                  <span v-else>
                    {{ slot.data.descricao }}
                  </span>
                  </span>
                  </template>
                </Column>
                <Column
                    field="valor"
                    header="Valor"
                    :styles="{ width: '6rem' }"
                >
                  <template #body="slot">
                    {{
                      slot.data.valor
                          | formatMoney({ minimumFractionDigits: 2 })
                    }}
                  </template>
                </Column>
              </DataTable>
            </div>
          </v-col>
        </v-row>

      </v-card-text>

      <v-card-actions class="">
        <v-spacer></v-spacer>
        <v-btn @click="cancelar" color="btConfirma" text type="submit" > Concluido </v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import Chips from "primevue/chips";
import BotaoBuscarListas from "@/components/BotaoBuscarListas.vue";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
export default {
  components: {
    BotaoBuscarListas, Chips,
    DataTable,
    Column,
  },
  props: {
    showDialog: {
      type: Boolean,
      required: true
    },
    type: String,
    idEmpresa: String,
  },
  computed: {
    ...mapState(["empresaAtual"]),
  },
  data() {
    return {
      itens: [],
      produtos: [],
      servicos: [],
      filtros: {
        produto: [],
        servico: [],
      },
      pagination: {
        page: 1,
        total: 0,
        limit: 10,
        first: 0
      },
      carregando: false,
    };
  },
  mounted() {
    this.getServicos();
    this.getProdutos();
  },
  methods: {
    ...mapMutations(["openDialogMessage"]),
    handleScrollServicos() {
      const container = this.$refs.scrollContainer;

      if (
          container.scrollTop + container.clientHeight >=
          container.scrollHeight - 10
      ) {
        this.getServicos();
      }
    },
    handleScrollProdutos() {
      const container = this.$refs.scrollContainer;

      if (
          container.scrollTop + container.clientHeight >=
          container.scrollHeight - 10
      ) {
        this.getProdutos();
      }
    },
    async getServicos(filtro = false) {

      if(filtro) {
        this.pagination.first = 0;
        this.pagination.page = 1;
        this.servicos = [];
      } else {
        this.pagination.first = (this.pagination.page - 1) * this.pagination.limit;
      }

      await this.$axios
        .get(`/servico/empresa/${this.idEmpresa}/listar`, {
          headers: {
            filtros: JSON.stringify({
              servico: this.filtros.servico.map(s => s.trim()),
              status: ["ATIVO"]
            })
          },
          params: {
            first: this.pagination.first,
            limit: this.pagination.limit,
            ordem: "DESC"
          }
        })
        .then(async response => {
          const servicos = [...this.servicos, ...response.data.data];

          this.servicos = servicos.map((item) => {
            return {
              ...item,
              valor_original: item.valor
            };
          });

          this.pagination.page++;
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Falha ao buscar serviços",
            text: error.response.data
          });
        });
    },

    async getProdutos(filtro = false) {

      if(filtro) {
        this.pagination.first = 0;
        this.pagination.page = 1;
        this.produtos = [];
      } else {
        this.pagination.first = (this.pagination.page - 1) * this.pagination.limit;
      }

      await this.$axios
        .get(`/produto/empresa/${this.idEmpresa}/listar`, {
          headers: {
            filtros: JSON.stringify({
              produto: this.filtros.produto.map(s => s.trim()),
              status: ["ATIVO"]
            })
          },
          params: {
            first: this.pagination.first,
            limit: this.pagination.limit,
            ordem: "DESC"
          }
        })
        .then(async response => {
          const produtos = [...this.produtos, ...response.data.data];

          this.produtos = produtos.map((item) => {
            return {
              ...item,
              valor_original: item.valor
            };
          });

          this.pagination.page++;

        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Falha ao buscar produtos",
            text: error.response.data
          });
        });
    },

    onServicoSelect(event) {
      const itemData = {
        servico: event.data,
        produto: null,
        quantidade: 1,
        valor: Number(parseFloat(event.data.valor).toFixed(2)),
        valor_original: Number(parseFloat(event.data.valor_original).toFixed(2))
      };

      this.$emit("salva-dados", itemData);
      this.itens.push(itemData);

      this.$toast.add({
        severity: "success",
        summary: `Serviço ${event.data.nome} adicionado!`,
        life: 2000
      });
    },
    onProdutoSelect(event) {
      const itemData = {
        produto: event.data,
        servico: null,
        quantidade: 1,
        valor: Number(parseFloat(event.data.valor).toFixed(2)),
        valor_original: Number(parseFloat(event.data.valor_original).toFixed(2))
      };

      this.$emit("salva-dados", itemData);
      this.itens.push(itemData);

      this.$toast.add({
        severity: "success",
        summary: `Produto ${event.data.nome} adicionado!`,
        life: 2000
      });
    },

    buscar() {
      const chip = this.$refs.chipcomponente;
      const newValue = chip.$refs.input.value;

      if(newValue) {

        if(this.type === 'produtos') this.filtros.produto.push(newValue);
        if(this.type === 'servicos') this.filtros.servico.push(newValue);

        chip.$refs.input.value = "";
        chip.$emit("add", newValue);
      } else {
        chip.$emit("add");
      }
    },

    next(page) {
      this.pagination.page = page;
      this.pagination.first = (page - 1) * this.pagination.limit;

      if(this.type === 'produtos')  this.getProdutos();
      if(this.type === 'servicos')  this.getServicos();

    },

    cancelar() {
      this.$emit("cancela-dialog");
    },
  }
};
</script>

<style>
.scroll-container {
  max-height: 400px;
  overflow-y: auto;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background-color: #e1e1e1;
}

::-webkit-scrollbar-thumb {
  background-color: #bebebe;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

</style>
