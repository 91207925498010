<template>
  <div class="mt-2">
    <v-row>
      <v-col class="d-flex justify-start">
        <Calendar
            v-model="dataIntervalo"
            selectionMode="range"
            @date-select="getDados"
            :showIcon="true"
            dateFormat="dd/mm/yy"
        />
        <BotaoBuscarListas class="ml-3" @buscar="getDados" />
      </v-col>
    </v-row>

    <!-- SKELETON -->
    <v-row v-if="loadingSkeleton">
      <v-col cols="12">
        <v-skeleton-loader
            :loading="true"
            type="image"
        ></v-skeleton-loader>
      </v-col>
    </v-row>

    <div v-else>
      <v-row class="mx-auto mt-5 flex-wrap" dense>
        <v-col class="d-flex" v-if="produtos && produtos.length > 0" cols="12" md="6">
          <v-card elevation="0" outlined class="py-0 px-0 billing-container flex-grow-1">
            <span class="billing-title text-caption">Produtos Mais Vendidos</span>
            <div class="mt-3">
              <v-btn
                outlined
                text
                :color="orderingProducts === 'qtd' ? 'blue' : 'blue-grey'"
                class="ma-2 white--text"
                @click="ordenarProdutos('qtd')"
              >
                <v-icon
                    left
                    dark
                >
                  mdi-filter
                </v-icon>
                Ordenar por quantidade
              </v-btn>
              <v-btn
                outlined
                text
                :color="orderingProducts === 'valor' ? 'blue' : 'blue-grey'"
                class="ma-2 white--text"
                @click="ordenarProdutos('valor')"
              >
                <v-icon
                    left
                    dark
                >
                  mdi-filter
                </v-icon>
                Ordenar por valor
              </v-btn>
            </div>
            <Chart type="bar" :data="graficoProdutos" :options="options" class="custom-chart" />
          </v-card>
        </v-col>
        <v-col class="d-flex" v-if="servicos && servicos.length > 0" cols="12" md="6">
          <v-card elevation="0" outlined class="py-0 px-0 billing-container flex-grow-1">
            <span class="billing-title text-caption">Vendas por serviço</span>
            <div class="mt-3">
              <v-btn
                  outlined
                  text
                  :color="orderingServices === 'qtd' ? 'blue' : 'blue-grey'"
                  class="ma-2 white--text"
                  @click="ordenarServicos('qtd')"
              >
                <v-icon
                    left
                    dark
                >
                  mdi-filter
                </v-icon>
                Ordenar por quantidade
              </v-btn>
              <v-btn
                  outlined
                  text
                  :color="orderingServices === 'valor' ? 'blue' : 'blue-grey'"
                  class="ma-2 white--text"
                  @click="ordenarServicos('valor')"
              >
                <v-icon
                    left
                    dark
                >
                  mdi-filter
                </v-icon>
                Ordenar por valor
              </v-btn>
            </div>
            <Chart type="bar" :data="graficoServicos" :options="options" class="custom-chart" />
          </v-card>
        </v-col>

        <v-col class="d-flex" v-if="produtos && produtos.length > 0" cols="12" md="6">
          <v-card height="570" elevation="0" outlined class="py-0 px-0 billing-container flex-grow-1">
            <span class="billing-title text-caption">Vendas por produto</span>
            <div class="data-table-container mt-5">
              <div class="text-end mr-4">
                <v-btn type="button"  @click="exportarProdutos" icon>
                  <v-icon> mdi-download </v-icon>
                </v-btn>
              </div>
              <DataTable
                  class="mx-5 my-5"
                  sortMode="multiple"
                  :value="produtosFiltrados"
                  responsiveLayout="stack"
                  :sortOrder="-1"
                  breakpoint="960px"
                  scrollHeight="flex-row"
              >

                <template #header>
                  <v-row class="d-flex justify-end mb-n10 filtro">
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <v-text-field
                          v-model="filterProduto"
                          @keyup="searchProdutos"
                          outlined
                          label="Filtrar"
                          prepend-inner-icon="mdi-magnify"
                          dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </template>

                <Column field="nome" header="Nome" sortable>
                  <template #body="slot">
                    {{ slot.data.nome }}
                  </template>
                </Column>

                <Column field="sku" header="SKU" sortable>
                  <template #body="slot">
                    {{ slot.data.sku }}
                  </template>
                </Column>

                <Column field="qtd_vendido" header="QTD" sortable>
                  <template #body="slot">
                    {{ slot.data.qtd_vendido }}
                  </template>
                </Column>

                <Column field="total_vendas" header="Total Vendas" sortable>
                  <template #body="slot">
                    {{ slot.data.total_vendas| formatMoney({ minimumFractionDigits: 2 }) }}
                  </template>
                </Column>


              </DataTable>
            </div>
          </v-card>
        </v-col>
        <v-col class="d-flex" v-if="servicos && servicos.length > 0" cols="12" md="6">
          <v-card height="570" elevation="0" outlined class="py-0 px-0 billing-container flex-grow-1">
            <span class="billing-title text-caption">Serviços Mais Vendidos</span>
            <div class="data-table-container mt-5">
              <div class="text-end mr-4">
                <v-btn type="button"  @click="exportarServicos" icon>
                  <v-icon> mdi-download </v-icon>
                </v-btn>
              </div>
              <DataTable
                sortMode="multiple"
                class="mx-5 my-5"
                :value="servicosFiltrados"
                responsiveLayout="stack"
                :sortOrder="-1"
                breakpoint="960px"
                scrollHeight="flex-row"
              >

                <template #header>
                  <v-row class="d-flex justify-end mb-n10 filtro">
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <v-text-field
                          v-model="filterServico"
                          @keyup="searchServicos"
                          outlined
                          label="Filtrar"
                          prepend-inner-icon="mdi-magnify"
                          dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </template>

                <Column field="nome" header="Nome" sortable>
                  <template #body="slot">
                    {{ slot.data.nome }}
                  </template>
                </Column>

                <Column field="sku" header="SKU" sortable>
                  <template #body="slot">
                    {{ slot.data.sku }}
                  </template>
                </Column>

                <Column field="qtd_vendido" header="QTD" sortable>
                  <template #body="slot">
                    {{ slot.data.qtd_vendido }}
                  </template>
                </Column>

                <Column field="total_vendas" header="Total Vendas" sortable>
                  <template #body="slot">
                    {{ slot.data.total_vendas| formatMoney({ minimumFractionDigits: 2 }) }}
                  </template>
                </Column>


              </DataTable>
            </div>
          </v-card>
        </v-col>

        <v-col class="d-flex" v-if="categorias && categorias.length > 0" cols="12" md="6">
          <v-card elevation="0" outlined class="py-0 px-0 billing-container flex-grow-1">
            <span class="billing-title text-caption">Vendas por categoria</span>
            <Chart type="pie" :data="graficoCategorias" :options="optionsPie" class="custom-chart mx-5 my-5" />
          </v-card>
        </v-col>
        <v-col class="d-flex" v-if="formasPagamento && formasPagamento.length > 0" cols="12" md="6">
          <v-card elevation="0" outlined class="py-0 px-0 billing-container flex-grow-1">
            <span class="billing-title text-caption">Formas de pagamento</span>
            <Chart type="pie" :data="graficoFormasPagamento" :options="optionsPie" class="custom-chart mx-5 my-5"  />
          </v-card>
        </v-col>

        <v-col class="d-flex" v-if="categorias && categorias.length > 0" cols="12" md="6">
          <v-card height="570" elevation="0" outlined class="py-0 px-0 billing-container flex-grow-1">
            <span class="billing-title text-caption">Vendas por categoria</span>
            <div class="data-table-container mt-5">
              <div class="text-end mr-4">
                <v-btn type="button"  @click="exportarCategorias" icon>
                  <v-icon> mdi-download </v-icon>
                </v-btn>
              </div>
              <DataTable
                  sortMode="multiple"
                  class="mx-5 my-5"
                  :value="categoriasFiltradas"
                  responsiveLayout="stack"
                  :sortOrder="-1"
                  breakpoint="960px"
                  scrollHeight="flex-row"
              >

                <template #header>
                  <v-row class="d-flex justify-end mb-n10 filtro">
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <v-text-field
                          v-model="filterCategoria"
                          @keyup="searchCategorias"
                          outlined
                          label="Filtrar"
                          prepend-inner-icon="mdi-magnify"
                          dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </template>

                <Column field="nome" header="Nome" sortable>
                  <template #body="slot">
                    {{ slot.data.categoria.nome }}
                  </template>
                </Column>

                <Column field="qtd_vendido" header="QTD" sortable>
                  <template #body="slot">
                    {{ slot.data.qtd_vendido }}
                  </template>
                </Column>

                <Column field="total_vendas" header="Total Vendas" sortable>
                  <template #body="slot">
                    {{ slot.data.total_vendas| formatMoney({ minimumFractionDigits: 2 }) }}
                  </template>
                </Column>

              </DataTable>
            </div>
          </v-card>
        </v-col>
        <v-col class="d-flex" v-if="vendasPorPDV.length > 0" cols="12" md="6">
          <v-card height="570" elevation="0" outlined class="py-0 px-0 billing-container flex-grow-1">
            <span class="billing-title text-caption">Vendas por PDV</span>
            <div class="data-table-container mt-5">
              <DataTable
                  sortMode="multiple"
                  class="mx-5 my-5"
                  :value="vendasPorPDV"
                  responsiveLayout="stack"
                  :sortOrder="-1"
                  breakpoint="960px"
                  scrollHeight="flex-row"
              >

                <Column field="nome" header="PDV" sortable>
                  <template #body="slot">
                    {{ slot.data.nome }}
                  </template>
                </Column>

                <Column field="valor" header="Valor" sortable>
                  <template #body="slot">
                    R$ {{ slot.data.valor | formatMoney({ minimumFractionDigits: 2 }) }}
                  </template>
                </Column>

              </DataTable>
            </div>
          </v-card>
        </v-col>
        <v-col class="d-flex" v-if="vendasPorTerminal" cols="12" md="6">
          <v-card height="570" elevation="0" outlined class="py-0 px-0 billing-container flex-grow-1">
            <span class="billing-title text-caption">Vendas por Terminal</span>
            <div class="data-table-container mt-5">
              <div class="text-end mr-4">
                <v-btn type="button"  @click="exportarTerminais" icon>
                  <v-icon> mdi-download </v-icon>
                </v-btn>
              </div>
              <DataTable
                  sortMode="multiple"
                  class="mx-5 my-5"
                  :value="vendasPorTerminal"
                  responsiveLayout="stack"
                  :sortOrder="-1"
                  breakpoint="960px"
                  scrollHeight="flex-row"
              >

                <Column field="pdv" header="Terminal" sortable>
                  <template #body="slot">
                    {{ slot.data.pdv }}
                  </template>
                </Column>

                <Column field="serial" header="Serial" sortable>
                  <template #body="slot">
                    {{ slot.data.serial }}
                  </template>
                </Column>

                <Column field="quantidade" header="QTD" sortable>
                  <template #body="slot">
                    {{ slot.data.quantidade }}
                  </template>
                </Column>

                <Column field="valor" header="Valor" sortable>
                  <template #body="slot">
                    R$ {{ slot.data.total | formatMoney({ minimumFractionDigits: 2 }) }}
                  </template>
                </Column>

              </DataTable>
            </div>
          </v-card>
        </v-col>


      </v-row>

      <v-row v-if="!produtos && !servicos && !categorias && formasPagamento.length === 0" class="mx-auto">
        <v-col class="d-flex justify-center">
          <v-card-title class="text-caption">Nenhum resultado encontrado!</v-card-title>
        </v-col>
      </v-row>

    </div>

  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import moment from "moment";
import Calendar from "primevue/calendar";
import BotaoBuscarListas from "@/components/BotaoBuscarListas.vue";
import Chart from "primevue/chart";
import DataTable from "primevue/datatable";
import Column from "primevue/column";

export default {
  name: "VendasPdv",
  components: {
    BotaoBuscarListas,
    Calendar,
    Chart,
    DataTable,
    Column

  },
  data() {
    return {
      loadingSkeleton: false,
      dataIntervalo: [new Date(new Date().setDate(new Date().getDate() - 7)), new Date()],
      dataInicio: null,
      dataFim: null,
      filterServico: null,
      filterProduto: null,
      filterCategoria: null,
      filterFormasPagamento: null,
      dados: [],
      produtos: [],
      servicos: [],
      servicosFiltrados: [],
      produtosFiltrados: [],
      categoriasFiltradas: [],
      formasPagamentoFiltradas: [],
      categorias: [],
      formasPagamento: [],
      vendasPorTerminal:[],
      vendasPorPDV: [],
      graficoProdutos: {
        labels: [],
        datasets: [],
      },
      graficoServicos: {
        labels: [],
        datasets: [],
      },
      graficoCategorias: {
        labels: [],
        datasets: [],
      },
      graficoFormasPagamento: {
        labels: [],
        datasets: [],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false
      },
      optionsPie: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: "right"
          }
        },
      },
      backgroundColor: [
        'rgba(249, 115, 22)',  // Laranja
        'rgba(6, 182, 212)',   // Azul
        'rgba(107, 114, 128)', // Cinza
        'rgba(139, 92, 246)',  // Roxo
        'rgba(234, 88, 12)',   // Vermelho
        'rgba(34, 197, 94)',   // Verde
        'rgba(168, 85, 247)',  // Violeta
        'rgba(251, 191, 36)',  // Amarelo
        'rgba(14, 165, 233)',  // Azul claro
        'rgba(236, 72, 153)'   // Rosa
      ],
      borderColor: [
        'rgb(249, 115, 22)',    // Laranja
        'rgb(6, 182, 212)',     // Azul
        'rgb(107, 114, 128)',   // Cinza
        'rgb(139, 92, 246)',    // Roxo
        'rgb(234, 88, 12)',     // Vermelho
        'rgb(34, 197, 94)',     // Verde
        'rgb(168, 85, 247)',    // Violeta
        'rgb(251, 191, 36)',    // Amarelo
        'rgb(14, 165, 233)',    // Azul claro
        'rgb(236, 72, 153)'     // Rosa
      ],
      orderingProducts: null,
      orderingServices: null,
    };
  },
  computed: {
    ...mapState(["empresaAtual", "user"]),
  },
  methods: {
    ...mapMutations([
      "openDialogMessage",
      "openDialogConfirm",
      "closeDialogConfirm"
    ]),
   async getDados() {
      this.loadingSkeleton = true;

      if (this.dataIntervalo[0]) {
        this.dataInicio = moment(this.dataIntervalo[0]).format("YYYY-MM-DD");
      }

      if (this.dataIntervalo[1]) {
        this.dataFim = moment(this.dataIntervalo[1]).format("YYYY-MM-DD");
      }

     if(!this.dataIntervalo[0] || !this.dataIntervalo[1]){
       return;
     }

      if (!this.empresaAtual || !this.empresaAtual.id) return;

     try {
       await Promise.all([
         this.getVendasProdutoServico(),
         this.getVendasCategoria(),
         this.getVendasFormaPagamento(),
         this.getVendasTerminal()
       ]);
     } catch (error) {
       console.error('Erro ao carregar dados:', error);
     } finally {
       this.loadingSkeleton = false;
     }


    },

    getVendasProdutoServico() {
      this.$axios
        .get(
            `/relatorio/venda/empresa/${this.empresaAtual.id}/produto-servico`,
            {
            params: {
              "data-inicio": this.dataInicio,
              "data-fim": this.dataFim
            }
          }
        )
        .then(response => {

          this.produtos = response.data.itens?.filter((item) => {
            return item.tipo === "PRODUTO";
          });

          this.servicos = response.data.itens?.filter((item) => {
            return item.tipo === "SERVICO";
          });

          this.servicosFiltrados = this.servicos;
          this.produtosFiltrados = this.produtos;

          let produtos = null;
          let servicos = null;

          if(this.produtos) produtos = this.produtos.slice(0, 10);
          if(this.servicos) servicos = this.servicos.slice(0, 10);

          if(produtos) {
            this.graficoProdutos.labels = produtos.map(obj => {
              return `${obj.nome.slice(0, 17)}...`;
            });

            this.graficoProdutos.datasets = [
              {
                label: "Top 10 Produtos",
                data: produtos.map(obj => {
                  return obj.total_vendas;
                }),
                backgroundColor: this.backgroundColor,
                borderColor: this.borderColor,
                borderWidth: 1
              }
            ];
          }

          if(servicos) {
            this.graficoServicos.labels = servicos.map(obj => {
              return `${obj.nome.slice(0, 17)}...`;
            });

            this.graficoServicos.datasets = [
              {
                label: "Top 10 Serviços",
                data: servicos.map(obj => {
                  return obj.total_vendas;
                }),
                backgroundColor: this.backgroundColor,
                borderColor: this.borderColor,
                borderWidth: 1
              }
            ];
          }

        })
        .catch(error => {
          this.produtos = [];
          this.servicos = [];
          this.servicosFiltrados = [];
          this.produtosFiltrados = [];
          this.openDialogMessage({
            type: "error",
            title: "Falha ao buscar serviços e produtos",
            text: error.response.data
          });
        });

    },

    getVendasCategoria() {
      this.$axios
        .get(
          `/relatorio/venda/empresa/${this.empresaAtual.id}/categoria`,
          {
          params: {
            "data-inicio": this.dataInicio,
            "data-fim": this.dataFim
          }
        }
        )
        .then(response => {
          this.categorias = response.data.categorias;
          this.categoriasFiltradas = this.categorias;
          let categorias = null;

          if(this.categorias) categorias = this.categorias.slice(0, 10);

          if(categorias) {
            this.graficoCategorias.labels = categorias.map(obj => {
              return `${obj.categoria.nome} R$ ${this.parseFloatToMoney(obj.total_vendas)}`;
            });

            this.graficoCategorias.datasets = [
              {
                data: categorias.map(obj => {
                  return obj.total_vendas;
                }),
                backgroundColor: this.backgroundColor,
                //borderColor: this.borderColor,
              }
            ];
          }

        })
        .catch(error => {
          this.categorias = [];
          this.categoriasFiltradas = [];
          this.openDialogMessage({
            type: "error",
            title: "Falha ao buscar categorias",
            text: error.response.data
          });
        });
    },

    getVendasFormaPagamento() {
      this.$axios
        .get(
            `/relatorio/venda/empresa/${this.empresaAtual.id}/forma-pagamento`,
            {
          params: {
            "data-inicio": this.dataInicio,
            "data-fim": this.dataFim
          }
        }
        )
        .then(response => {

          this.formasPagamento = Object.keys(response.data.total_forma_pagamento).map((key) => ({
            nome: key.charAt(0).toUpperCase() + key.slice(1).toLowerCase(),
            valor: response.data.total_forma_pagamento[key]
          }));

          this.formasPagamentoFiltradas = this.formasPagamento;

          let formasPagamento = null;

          if(this.formasPagamento) formasPagamento = this.formasPagamento.slice(0, 10);

          if(formasPagamento) {
            this.graficoFormasPagamento.labels = formasPagamento.map((obj) => {
              return `${obj.nome} R$ ${this.parseFloatToMoney(obj.valor)}`;
            });

            this.graficoFormasPagamento.datasets = [
              {
                data: formasPagamento.map(obj => {
                  return obj.valor;
                }),
                backgroundColor: this.backgroundColor,
                //borderColor: this.borderColor,
              }
            ];
          }

        })
        .catch(error => {
          this.formasPagamento = [];
          this.formasPagamentoFiltradas = [];
          this.openDialogMessage({
            type: "error",
            title: "Falha ao buscar formas de pagamento",
            text: error.response.data
          });
        });
    },

    getVendasTerminal() {
      this.$axios
        .get(
            `/relatorio/venda/empresa/${this.empresaAtual.id}/terminal`,
            {
          params: {
            "data-inicio": this.dataInicio,
            "data-fim": this.dataFim
          }
        }
        )
        .then(response => {
          this.vendasPorTerminal = response.data.vendas;
        })
        .catch(error => {
          this.vendasPorTerminal = [];
          this.openDialogMessage({
            type: "error",
            title: "Falha ao buscar vendas terminal",
            text: error.response.data
          });
        });
    },

    ordenarProdutos(tipo) {
      this.orderingProducts = tipo;
      let produtos = this.produtos.slice(0, 10);

      if(produtos && produtos.length > 0 && tipo === 'qtd') {
        produtos = produtos.sort((a, b) => {
          // Ordenando do maior para o menor
          return b.qtd_vendido - a.qtd_vendido;
        });

        this.graficoProdutos.labels = produtos.map(obj => {
          return `${obj.nome.slice(0, 17)}...`;
        });

        this.graficoProdutos.datasets = [
          {
            label: "Top 10 Produtos",
            data: produtos.map(obj => {
              return obj.qtd_vendido;
            }),
            backgroundColor: this.backgroundColor,
            borderColor: this.borderColor,
            borderWidth: 1
          }
        ];
      }

      if(produtos && produtos.length > 0 && tipo === 'valor') {
        produtos = produtos.sort((a, b) => {
          // Ordenando do maior para o menor
          return b.total_vendas - a.total_vendas;
        });

        this.graficoProdutos.labels = produtos.map(obj => {
          return `${obj.nome.slice(0, 17)}...`;
        });

        this.graficoProdutos.datasets = [
          {
            label: "Top 10 Produtos",
            data: produtos.map(obj => {
              return obj.total_vendas;
            }),
            backgroundColor: this.backgroundColor,
            borderColor: this.borderColor,
            borderWidth: 1
          }
        ];
      }

    },

    ordenarServicos(tipo) {
      this.orderingServices = tipo;
      let servicos = this.servicos.slice(0, 10);

      if(servicos && servicos.length > 0 && tipo === 'qtd') {
        servicos = servicos.sort((a, b) => {
          // Ordenando do maior para o menor
          return b.qtd_vendido - a.qtd_vendido;
        });

        this.graficoServicos.labels = servicos.map(obj => {
          return `${obj.nome.slice(0, 17)}...`;
        });

        this.graficoServicos.datasets = [
          {
            label: "Top 10 Produtos",
            data: servicos.map(obj => {
              return obj.qtd_vendido;
            }),
            backgroundColor: this.backgroundColor,
            borderColor: this.borderColor,
            borderWidth: 1
          }
        ];
      }

      if(servicos && servicos.length > 0 && tipo === 'valor') {
        servicos = servicos.sort((a, b) => {
          // Ordenando do maior para o menor
          return b.total_vendas - a.total_vendas;
        });

        this.graficoServicos.labels = servicos.map(obj => {
          return `${obj.nome.slice(0, 17)}...`;
        });

        this.graficoServicos.datasets = [
          {
            label: "Top 10 Produtos",
            data: servicos.map(obj => {
              return obj.total_vendas;
            }),
            backgroundColor: this.backgroundColor,
            borderColor: this.borderColor,
            borderWidth: 1
          }
        ];
      }

    },

    searchServicos() {
      if (this.filterServico.trim()) {
        const queryText = this.filterServico.toLowerCase();
        this.servicosFiltrados = this.servicos.filter(item => {
          const nome = item.nome ? item.nome.toLowerCase() : "";
          const sku = item.sku ? item.sku.toLowerCase() : "";

          return (
              nome.includes(queryText) ||
              sku.includes(queryText)
          );
        });
      } else {
        this.servicosFiltrados = [...this.servicos];
      }

    },

    searchProdutos() {
      if (this.filterProduto.trim()) {
        const queryText = this.filterProduto.toLowerCase();
        this.produtosFiltrados = this.produtos.filter(item => {
          const nome = item.nome ? item.nome.toLowerCase() : "";
          const sku = item.sku ? item.sku.toLowerCase() : "";

          return (
              nome.includes(queryText) ||
              sku.includes(queryText)
          );
        });
      } else {
        this.produtosFiltrados = [...this.produtos];
      }

    },

    searchCategorias() {
      if (this.filterCategoria.trim()) {
        const queryText = this.filterCategoria.toLowerCase();
        this.categoriasFiltradas = this.categorias.filter(item => {
          const nome = item.categoria.nome ? item.categoria.nome.toLowerCase() : "";

          return (
              nome.includes(queryText)
          );
        });
      } else {
        this.categoriasFiltradas = [...this.categorias];
      }

    },

    searchFormasPagamento() {
      if (this.filterFormasPagamento.trim()) {
        const queryText = this.filterFormasPagamento.toLowerCase();
        this.formasPagamentoFiltradas = this.formasPagamento.filter(item => {
          const nome = item.nome ? item.nome.toLowerCase() : "";

          return (
              nome.includes(queryText)
          );
        });
      } else {
        this.formasPagamentoFiltradas = [...this.formasPagamento];
      }

    },

    exportarProdutos() {
      this.exportarTabela(this.produtos, "produtos", [
        { chave: "nome", titulo: "Nome" },
        { chave: "sku", titulo: "SKU" },
        { chave: "qtd_vendido", titulo: "QTD" },
        { chave: "total_vendas", titulo: "Total Vendas" },
      ]);
    },

    exportarServicos() {
      this.exportarTabela(this.servicos, "servicos", [
        { chave: "nome", titulo: "Nome" },
        { chave: "sku", titulo: "SKU" },
        { chave: "qtd_vendido", titulo: "QTD" },
        { chave: "total_vendas", titulo: "Total Vendas" },
      ]);
    },

    exportarCategorias() {
      this.exportarTabela(this.categorias, "categorias", [
        { chave: "categoria.nome", titulo: "Nome" }, // Note o uso de `categoria.nome`
        { chave: "qtd_vendido", titulo: "QTD" },
        { chave: "total_vendas", titulo: "Total Vendas" },
      ]);
    },

    exportarTerminais() {
      this.exportarTabela(this.vendasPorTerminal, "terminais", [
        { chave: "pdv", titulo: "Terminal" },
        { chave: "serial", titulo: "Serial" },
        { chave: "quantidade", titulo: "QTD" },
        { chave: "total", titulo: "Valor" },
      ]);
    },


    exportarTabela(array, nomeArquivo, colunas) {
      if (!array || array.length === 0) {
        this.openDialogMessage({
          type: "error",
          title: "Atenção",
          text: "Nenhum dado para exportação",
        });
        return;
      }

      // Formatar os dados dinamicamente com base nas colunas fornecidas
      const lista = array.map((item) => {
        const obj = {};
        colunas.forEach(({ chave, titulo }) => {
          obj[titulo] = chave === "Valor_Total" || chave === "Valor"
            ? this.parseFloatToMoney(item[chave] || 0)
            : item[chave] || "";
        });
        return this.replaceUndefinedWithEmptyString(obj);
      });

      this.toCSVeDownload(`${nomeArquivo}.csv`, lista);
    },


    // Função auxiliar para substituir valores undefined
    replaceUndefinedWithEmptyString(obj) {
      const newObj = {};
      Object.keys(obj).forEach((key) => {
        newObj[key] = obj[key] === undefined ? "" : obj[key];
      });
      return newObj;
    },

    // Função para gerar e baixar CSV
    toCSVeDownload(nomeArquivo, dados) {
      const csvContent =
        "data:text/csv;charset=utf-8," +
        [Object.keys(dados[0]).join(";"), ...dados.map((row) => Object.values(row).join(";"))].join("\n");

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", nomeArquivo);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

  },
  mounted() {
    this.getDados();
  }
};
</script>

<style scoped>

.border-right {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}
.border-left {
  border-left: 1px solid rgba(0, 0, 0, 0.2);
}
.border-full {
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.border-top {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}
.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.custom-chart {
  height: 400px;
}

.billing-container {
  padding: 10px;
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 5px;
  background: #fff;
}

.billing-title {
  background: #fff;
  padding: 0 10px;
  position: absolute;
  top: -10px;
  left: 20px;
}

.data-table-container {
  max-height: 543px;
  overflow-y: auto;
  height: 543px;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background-color: #e1e1e1;
}

::-webkit-scrollbar-thumb {
  background-color: #bebebe;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}



</style>
