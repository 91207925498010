<template>
  <div>

    <!-- SKELETON -->
    <v-container v-if="loadingSkeleton" fluid>
      <v-row class="grey lighten-5 px-16">
        <v-col>
          <v-skeleton-loader
              :loading="true"
              type="image"
              height="50"
          ></v-skeleton-loader>
        </v-col>
      </v-row>

      <v-row class="grey lighten-5 px-16 mt-10">
        <v-col cols="4">
          <v-skeleton-loader
              :loading="true"
              type="heading"
          ></v-skeleton-loader>
          <v-skeleton-loader
              class="mt-3"
              :loading="true"
              type="text@4"
              width="50%"
          ></v-skeleton-loader>
        </v-col>
        <v-divider vertical></v-divider>
        <v-col cols="4">
          <v-skeleton-loader
              :loading="true"
              type="heading"
          ></v-skeleton-loader>
          <v-skeleton-loader
              class="mt-3"
              :loading="true"
              type="text@4"
              width="50%"
          ></v-skeleton-loader>
        </v-col>
        <v-divider vertical></v-divider>
        <v-col cols="4">
          <v-skeleton-loader
              :loading="true"
              type="heading"
          ></v-skeleton-loader>
          <v-skeleton-loader
              class="mt-3"
              :loading="true"
              type="text@4"
              width="50%"
          ></v-skeleton-loader>
        </v-col>
      </v-row>

      <v-row class="px-16 mt-10">
        <v-col cols="6">
          <v-skeleton-loader
              :loading="true"
              type="heading"
          ></v-skeleton-loader>
          <v-skeleton-loader
              class="mt-3"
              :loading="true"
              type="text@4"
              width="50%"
          ></v-skeleton-loader>
          <v-skeleton-loader
              :loading="true"
              class="mt-3"
              type="heading"
          ></v-skeleton-loader>
          <v-skeleton-loader
              class="mt-3"
              :loading="true"
              type="text@4"
              width="50%"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="6">
          <v-skeleton-loader
              :loading="true"
              type="image"
          ></v-skeleton-loader>
        </v-col>
      </v-row>

      <v-row class="px-16 mt-10">
        <v-col cols="12">
          <v-skeleton-loader
              :loading="true"
              type="image"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-else fluid>
      <v-row class="grey lighten-5 px-16">
        <v-col>
          <Menubar
            ref="menu"
            :model="menu"
            v-if="assinatura.status != 'CANCELADA'"
          ></Menubar>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <Dialog
            header="Alterar data de vencimento"
            :visible="showDialogAlterarVencimento"
            :closable="false"
            modal
          >
            <Calendar
              :showIcon="true"
              :inline="true"
              selectionMode="single"
              :numberOfMonths="2"
              :value="dataValidadeAtual"
              @date-select="onDataValidadeSelected"
              dateFormat="dd/mm/yyyy"
              :minDate="dataValidadeAtual"
            />
            <template #footer>
              <v-row>
                <v-col>
                  <Button
                    label="Fechar"
                    icon="pi pi-times"
                    class="p-button-text"
                    @click="
                      () => {
                        showDialogAlterarVencimento = false;
                      }
                    "
                  />
                </v-col>
              </v-row>
            </template>
          </Dialog>
        </v-col>
      </v-row>
      <v-row
        class=" grey lighten-5 align-center table_mobile"
        style="border: 1px solid purple;"
      >
        <v-col cols="3" class="d-flex flex-column py-3">
          <span class="headline font-weight-bold ">
            Protocolo #{{ assinatura.protocolo }}
          </span>
          <span class="caption">
            Recorrência
            {{
              assinatura.quantidade_cobranca == 0
                ? "ilimitada"
                : `${assinatura.quantidade_cobranca} cobranças`
            }}
          </span>
          <span class="caption">
            Criado
            {{
              assinatura.data_cadastro
                | formatDate({ dateStyle: "short", timeStyle: "short" })
            }}
          </span>
          <span class="caption">
            Atualizado
            {{
              assinatura.data_atualizada
                | formatDate({ dateStyle: "short", timeStyle: "short" })
            }}
          </span>
          <span class="caption">
            Válidade
            {{ assinatura.data_validade | formatDate({ dateStyle: "short" }) }}
          </span>
          <span class="caption" v-if="assinatura.data_fim_degustacao">
            Termino degustação
            {{
              assinatura.data_fim_degustacao
                | formatDate({ dateStyle: "short" })
            }}
          </span>
        </v-col>

        <v-divider vertical></v-divider>
        <v-col cols="6" class="text-center pa-0">
          <p class="headline font-weight-bold">
            {{ assinatura.plano.descricao }}
          </p>
          <span class="caption font-weight-bold green--text">R$</span>
          <span class="text-h5 font-weight-bold">
            {{ valorPlano | formatMoney({ minimumFractionDigits: 2 }) }}</span
          >
          <p v-if="tagValidade">
            <v-chip
              class="ma-2 text-center"
              color="red"
              style="justify-content: center; min-width: 100px"
              label
              text-color="white"
            >
              Vencida
            </v-chip>
          </p>
        </v-col>

        <v-divider vertical></v-divider>

        <v-col class="text-center pa-0">
          <v-chip
            class="ma-2 text-center"
            :color="STATUS_ASSINATURA[assinatura.status].cor"
            style="justify-content: center; min-width: 100px"
            label
            text-color="white"
          >
            {{ STATUS_ASSINATURA[assinatura.status].texto }}
          </v-chip>
          <p class="text-h6 font-weight-bold">
            <i
              v-if="assinatura.forma_pagamento === 'CARTAO'"
              class="far fa-credit-card"
            ></i>
            <i
              v-if="assinatura.forma_pagamento === 'BOLETO'"
              class="far fa-file-alt"
            ></i>
            {{ assinatura.forma_pagamento === "CARTAO" ? "Cartão" : "Boleto" }}
          </p>
          <p
            v-if="assinatura.forma_pagamento === 'CARTAO' && assinatura.cartao"
          >
            {{ assinatura.cartao.first_digits }} *** **** ****
            {{ assinatura.cartao.last_digits }}
          </p>
        </v-col>
      </v-row>

      <v-row class="table_mobile">
        <v-col cols="6" class="d-flex flex-column py-3">
          <p class="text-h6 font-weight-bold">Titular</p>
          <div class="d-flex flex-column custom-card" v-if="assinatura.titular">
            <span>
              {{ assinatura.titular.nome }}
            </span>
            <span class="text-caption">
              {{ $masks.telefone(assinatura.titular.telefone_celular) }}
            </span>
            <span class="text-caption">
              {{ assinatura.titular.email }}
            </span>
            <span class="text-caption">
              {{ assinatura.titular.endereco }},
              {{ assinatura.titular.numero }} -
              {{ assinatura.titular.cidade }} ,
              {{ assinatura.titular.uf }}
            </span>
          </div>

          <p class="text-h6 font-weight-bold" style="margin-top: 0.5rem">
            Emissor
          </p>
          <div
            v-if="assinatura.empresa"
            class="d-flex flex-column custom-card mb-4"
          >
            <span>
              {{ assinatura.empresa.nome_fantasia }}
            </span>
            <span v-if="assinatura.empresa.razao_social">
              {{ assinatura.empresa.razao_social }}
            </span>
            <span v-if="assinatura.empresa.nome">
              {{ assinatura.empresa.nome }}
            </span>
            <span v-if="assinatura.empresa.cnpj" class="text-caption">
              {{ assinatura.empresa.cnpj }}
            </span>
            <span v-if="assinatura.empresa.cpf" class="text-caption">
              {{ assinatura.empresa.cpf }}
            </span>
            <span class="text-caption">
              {{ assinatura.empresa.endereco }},
              {{ assinatura.empresa.numero }} -
              {{ assinatura.empresa.cidade }} ,
              {{ assinatura.empresa.uf }}
            </span>
          </div>
        </v-col>
        <v-col cols="6" class="text-center">
          <p class="text-h6 font-weight-bold">
            {{ assinatura.ultima_cobranca ? "Ultima Transação" : "" }}
          </p>

          <v-card
            outlined
            color="green"
            v-if="
              assinatura.ultima_cobranca &&
                assinatura.ultima_cobranca.tipo === 'BOLETO'
            "
          >
            <v-card-text class="grey lighten-4 pa-0">
              <v-row dense class="text-start">
                <v-col class="text-start px-4" cols="3">
                  <span class="text-subtitle-1 font-italic">Método</span>
                  <v-divider width="200px" class="mb-2"></v-divider>
                  <span class="text-subtitle-2 font-weight-bold">{{
                    assinatura.ultima_cobranca.tipo
                  }}</span>
                </v-col>
                <v-col class="text-start px-4" cols="3">
                  <span class="text-subtitle-1 font-italic">Status</span>
                  <v-divider width="200px" class="mb-2"></v-divider>
                  <span class="text-subtitle-2 font-weight-bold">{{
                    assinatura.ultima_cobranca.status
                  }}</span>
                </v-col>
                <v-col class="text-start px-4" cols="3">
                  <span class="text-subtitle-1 font-italic">Valor</span>
                  <v-divider width="200px" class="mb-2"></v-divider>
                  <span class="text-subtitle-2 font-weight-bold"
                    >R$
                    {{
                      assinatura.ultima_cobranca.valor
                        | formatMoney({ minimumFractionDigits: 2 })
                    }}</span
                  >
                </v-col>
              </v-row>

              <v-row dense class="text-start">
                <v-col class="text-start px-4" cols="3">
                  <span class="text-subtitle-1 font-italic">Vencimento</span>
                  <v-divider width="200px" class="mb-2"></v-divider>
                  <span class="text-subtitle-2 font-weight-bold">
                    {{
                      assinatura.ultima_cobranca.vencimento_boleto
                        | formatDate({ dateStyle: "short" })
                    }}
                  </span>
                </v-col>
                <v-col class="text-start px-4" cols="2">
                  <span class="text-subtitle-1 font-italic">Juros</span>
                  <v-divider width="200px" class="mb-2"></v-divider>
                  <span class="text-subtitle-2 font-weight-bold">
                    {{ assinatura.ultima_cobranca.juros_atraso_boleto }}%
                  </span>
                </v-col>
                <v-col class="text-start px-4" cols="2">
                  <span class="text-subtitle-1 font-italic">Multa</span>
                  <v-divider width="200px" class="mb-2"></v-divider>
                  <span class="text-subtitle-2 font-weight-bold">
                    {{ assinatura.ultima_cobranca.multa_atraso_boleto }}%
                  </span>
                </v-col>
              </v-row>
              <v-row dense class="text-start">
                <v-col class="text-start px-4" cols="3">
                  <span class="text-subtitle-1 font-italic">Documento</span>
                  <v-divider width="200px" class="mb-2"></v-divider>
                  <span class="text-subtitle-2 font-weight-bold">
                    {{ assinatura.ultima_cobranca.cliente.cpf
                    }}{{ assinatura.ultima_cobranca.cliente.cnpj }}
                  </span>
                </v-col>
                <v-col class="text-start px-4">
                  <span class="text-subtitle-1 font-italic">Cliente</span>
                  <v-divider class="mb-2 mr-2"></v-divider>
                  <span class="text-subtitle-2 font-weight-bold">
                    {{ assinatura.ultima_cobranca.cliente.nome }}
                  </span>
                </v-col>
              </v-row>
              <v-row dense class="text-start">
                <v-col class="text-start px-4" cols="3">
                  <span class="text-subtitle-1 font-italic">Telefone</span>
                  <v-divider class="mb-2"></v-divider>
                  <span class="text-subtitle-2 font-weight-bold">
                    {{ assinatura.ultima_cobranca.cliente.telefone_celular }}
                  </span>
                </v-col>

                <v-col class="text-start px-4">
                  <span class="text-subtitle-1 font-italic">E-mail</span>
                  <v-divider class="mb-2 mr-2"></v-divider>
                  <span class="text-subtitle-2 font-weight-bold">
                    {{ assinatura.ultima_cobranca.cliente.email }}
                  </span>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <Button
                    icon="pi pi-external-link"
                    label="Link de pagamento"
                    @click="openUrl"
                    style="width:20rem;margin: 0.5rem;"
                    class="p-button-outlined "
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <v-col class="text-center px-4">
                  <span class="text-subtitle-1 font-weight-bold">{{
                    assinatura.ultima_cobranca.bar_code_boleto
                  }}</span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card
            outlined
            color="green"
            class="cartao"
            v-if="
              assinatura.ultima_cobranca &&
                assinatura.ultima_cobranca.tipo === 'CREDITO'
            "
          >
            <v-card-text class="grey lighten-4 pa-0">
              <v-row dense>
                <v-col class="text-start pl-6 py-2">
                  <i
                    class="far fa-credit-card green--text"
                    style="font-size: 2.5em"
                  ></i>
                </v-col>
              </v-row>

              <v-row dense class="text-start">
                <v-col cols="3" class="text-start px-4">
                  <span class="overline font-italic">Transação</span>
                  <v-divider class="mb-2 mr-2"></v-divider>
                  <span class="text-subtitle-1 font-weight-bold">{{
                    assinatura.ultima_cobranca.id
                  }}</span>
                </v-col>

                <v-col cols="3" class="text-start px-4">
                  <span class="overline font-italic">Status</span>
                  <v-divider class="mb-2 mr-2"></v-divider>
                  <span class="text-subtitle-2">{{
                    assinatura.ultima_cobranca.status
                  }}</span>
                </v-col>

                <v-col cols="3" class="text-start px-4">
                  <span class="overline font-italic">Valor</span>
                  <v-divider class="mb-2 mr-2"></v-divider>
                  <span class="text-subtitle-2"
                    >R$
                    {{
                      assinatura.ultima_cobranca.valor
                        | formatMoney({ minimumFractionDigits: 2 })
                    }}</span
                  >
                </v-col>

                <v-col class="text-start px-4">
                  <span class="overline font-italic">Data</span>
                  <v-divider class="mb-2 mr-2"></v-divider>
                  <span class="text-subtitle-2">{{
                    assinatura.ultima_cobranca.data_criacao
                      | formatDate({ dateStyle: "short", timeStyle: "short" })
                  }}</span>
                </v-col>
              </v-row>

              <v-row dense class="text-start">
                <v-col cols="6" class="text-start px-4">
                  <span class="overline font-italic">Portador</span>
                  <v-divider class="mb-2 mr-2"></v-divider>
                  <span class="text-subtitle-1 font-weight-bold">{{
                    assinatura.ultima_cobranca.card_holder_name
                  }}</span>
                </v-col>

                <v-col class="text-start px-4">
                  <span class="overline font-italic">Número</span>
                  <v-divider class="mb-2 mr-2"></v-divider>
                  <span class="text-subtitle-2"
                    >{{ assinatura.ultima_cobranca.card_first_digits }} *** ****
                    ****{{ assinatura.ultima_cobranca.card_last_digits }}</span
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card outlined class="cartao" v-if="!assinatura.ultima_cobranca">
            <v-row>
              <v-col>
                <Button
                  icon="pi pi-external-link"
                  label="Link de pagamento"
                  @click="openUrl"
                  class="p-button-outlined button_pagamento"
                />
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="px-16">
        <v-col v-if="assinatura.splits && assinatura.splits.length" cols="6">
          <v-row>
            <v-col class="text-center">
              <p class="text-h6 font-weight-bold">
                <v-icon class="black--text mr-2">mdi-axis-arrow</v-icon>SPLIT /

                {{ assinatura.splits.length }}
              </p>
              <v-divider class="mb-4"></v-divider>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center">
              <div>
                <div
                  style="width: 250px"
                  class="green white--text rounded-lg pa-3"
                >
                  <span style="font-size: 2rem">
                    R$
                    {{ valorPlano | formatMoney({ minimumFractionDigits: 2 }) }}
                  </span>
                </div>

                <v-timeline dense>
                  <v-timeline-item
                    color="green"
                    v-for="split in assinatura.splits"
                    :key="split.recebedor.id"
                  >
                    <template v-slot:icon></template>
                    <v-card>
                      <v-card-text>
                        <v-row>
                          <v-col class="text-center d-flex flex-column">
                            <span class="text-subtitle-1 font-weight-bold">
                              {{ split.recebedor.nome_fantasia }}
                            </span>
                            <span v-if="split.recebedor.razao_social" class="text-caption font-weight-bold">
                              {{ $masks.cnpj(split.recebedor.cnpj) }} -
                              {{ split.recebedor.razao_social }}
                            </span>
                            <span v-if="split.recebedor.nome" class="text-caption font-weight-bold">
                              {{ $masks.cpf(split.recebedor.cpf) }} -
                              {{ split.recebedor.nome }}
                            </span>
                          </v-col>
                        </v-row>


                        <v-row dense>
                          <v-col class="col-3 text-start">
                            <span>Paga Taxa Transação </span>
                          </v-col>
                          <v-col class="text-start">
                            <span class="font-weight-bold">{{ split.paga_taxa_transacao | formatBoolean }}</span>
                          </v-col>
                          <v-col class="col-3 text-start">
                            <span>Paga Resto Divisão </span>
                          </v-col>
                          <v-col class="text-start">
                            <span class="font-weight-bold">{{ split.paga_resto_divisao_taxa | formatBoolean }}</span>
                          </v-col>
                          <v-col class="text-start">
                            <span>Valor </span>
                          </v-col>
                          <v-col class="text-start">
                            <span class="font-weight-bold">
                              {{ split.valor | formatMoney({ minimumFractionDigits: 2 }) }}
                              {{ split.metodo === "PERCENTUAL" ? "%" : null }}
                            </span>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-timeline-item>
                </v-timeline>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="6"
          v-if="assinatura.transacoes && assinatura.transacoes.length"
        >
          <v-row class="text-center">
            <v-col class="text-center">
              <p class="text-h6 font-weight-bold">
<!--                <i class="fas fa-history mr-2"></i>Histórico de Transações-->
                <v-icon class="mr-2"> mdi-history </v-icon>Histórico de Transações
              </p>
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-center">
              <table-transacoes
                style="max-width: 800px"
                :transacoes="assinatura.transacoes"
                :elevation="0"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <DialogPlano
      :showDialog="showPlanos"
      @onSelect="onPlanoSelect"
      @closeDialog="() => (showPlanos = false)"
    >
    </DialogPlano>
    <DialogFormaPagamento :showDialog="showForma" @onSelected="onFormaSelected">
    </DialogFormaPagamento>
  </div>
</template>
<script>
import TableTransacoes from "@/components/Tables/TableTransacoes.vue";
import {mapMutations} from "vuex";
import Menubar from "primevue/menubar";
import DialogPlano from "@/components/Dialogs/DialogPlano.vue";
import DialogFormaPagamento from "@/components/Dialogs/DialogFormaPagamento";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import moment from "moment";

import Calendar from "primevue/calendar";

export default {
  components: {
    TableTransacoes,
    Menubar,
    DialogPlano,
    Button,
    DialogFormaPagamento,
    Dialog,
    Calendar
  },
  props: {
    idAssinatura: String
  },
  computed: {
    valorPlano() {
      let total = 0.0;
      if (this.assinatura.plano && this.assinatura.plano.itens) {
        this.assinatura.plano.itens.forEach(item => {
          total =
            parseFloat(total) +
            (parseFloat(item.quantidade * item.valor) - item.desconto);
        });
      }
      return total.toFixed(2);
    }
  },
  data() {
    return {
      loadingSkeleton: true,
      assinatura: {
        id: null,
        protocolo: null,
        status: "PENDENTE",
        titular: {},
        plano: {
          itens: []
        },
        cobrancas: [],
        ultima_cobranca: {},
        splits: []
      },
      showDialogAlterarVencimento: false,
      dataValidadeAtual: null,
      tagValidade: false,

      menu: [
        {
          label: "Assinatura",
          icon: "pi pi-box",
          items: [
            {
              label: "Troca de forma",
              icon: "pi pi-credit-card",
              command: () => {
                this.showForma = true;
              }
            },
            {
              label: "Renovar",
              icon: "pi pi-sync",
              command: this.openModalRenovarAssinatura
            },
            {
              label: "Vencimento",
              icon: "pi pi-calendar-times",
              command: this.OpenModalAlterarVencimento
            },
            {
              label: "Cancelar",
              icon: "pi pi-ban",
              command: this.openModalCancelaAssinatura
            }
          ]
        },
        {
          label: "Plano",
          icon: "pi pi-pencil",
          command: () => {
            this.showPlanos = true;
          }
        },
        {
          label: "Titular",
          icon: "pi pi-user-edit",
          command: this.editarCliente
        },
        {
          label: "Link",
          icon: "pi pi-link",
          command: this.openUrl
        }
      ],
      showPlanos: false,
      showForma: false
    };
  },
  methods: {
    ...mapMutations([
      "openDialogMessage",
      "openDialogConfirm",
      "closeDialogConfirm"
    ]),
    checkValidity(validity) {
      const expirationDate = new Date(validity);
      const currentDate = new Date();

      return expirationDate < currentDate;
    },
    async getAssinatura() {
      await this.$axios
        .get(`/assinatura/${this.idAssinatura}`)
        .then(response => {
          this.assinatura = response.data;
          this.loadingSkeleton = false;
          this.tagValidade = this.checkValidity(this.assinatura.data_validade);
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Consultar Assinatura",
            text: error.response.data
          });
          this.showDialogEditPagto = false;
        });
    },
    openModalCancelaAssinatura() {
      this.openDialogConfirm({
        title: "Atenção",
        text: `Ao cancelar a assinatura nenhuma transação será estornada, apenas será interrompido novas cobranças, deseja continuar?`,
        confirma: this.cancelar,
        cancela: this.closeDialogConfirm
      });
    },
    openModalRenovarAssinatura() {
      this.openDialogConfirm({
        title: "Atenção",
        text: `Ao forçar a renovação, será criada nova cobrança e adicionará 1 mês de validade, deseja continuar?`,
        confirma: this.renovar,
        cancela: this.closeDialogConfirm
      });
    },
    OpenModalAlterarVencimento() {
      this.showDialogAlterarVencimento = true;
      this.dataValidadeAtual = new Date(this.assinatura.data_validade);
    },
    cancelar() {
      this.closeDialogConfirm();
      this.$axios
        .put(`/assinatura/${this.assinatura.id}/cancelar`)
        .then(() => {
          this.getAssinatura();
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Cancelar Assinatura",
            text: error.response.data
          });
        });
    },
    trocarPlano(obj) {
      this.closeDialogConfirm();
      this.$axios
        .put(`/assinatura/${this.assinatura.id}/plano/${obj.id}`)
        .then(() => {
          this.getAssinatura();
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Troca de plano",
            text: error.response.data.falha
              ? error.response.data.falha
              : error.response.data
          });
        });
    },
    renovar() {
      this.closeDialogConfirm();
      this.$axios
        .put(`/assinatura/${this.assinatura.id}/renovar`)
        .then(() => {
          this.getAssinatura();
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Renovar Assinatura",
            text: error.response.data.falha
              ? error.response.data.falha
              : error.response.data
          });
        });
    },
    onPlanoSelect(obj) {
      this.showPlanos = false;
      this.openDialogConfirm({
        title: "Atenção",
        text: `A troca de plano pode gerar ccobranças, deseja realmente efetuar a troca do plano?`,
        confirma: () => this.trocarPlano(obj),
        cancela: this.closeDialogConfirm
      });
    },
    openUrl() {
      // eslint-disable-next-line no-var, prefer-const
      let url = `http://${window.location.host}/assinaturas/${this.assinatura.id}/pagamento`;
      navigator.clipboard?.writeText(url);
      const win = window.open(url, "_blank");

      if (win) {
        win.focus();
      } else {
        alert("Desabilite o bloqueio de pop-ups para abrir o boleto");
      }
    },
    editarCliente() {
      this.$router.push({
        name: "AssinaturaEditarCliente",
        params: {
          backRoute: "viewAssinatura",
          idCliente: this.assinatura.titular.id
        }
      });
    },
    onFormaSelected(forma) {
      this.showForma = false;
      if (forma == null) return;

      this.openDialogConfirm({
        title: "Atenção",
        text: `Você realmente deseja alterar a forma de pagamento para ${forma}?`,
        confirma: () => this.trocarFormaPagamento(forma),
        cancela: this.closeDialogConfirm
      });
    },
    trocarFormaPagamento(forma) {
      this.closeDialogConfirm();
      this.$axios
        .put(`/assinatura/${this.assinatura.id}/forma-pagamento`, {
          forma_pagamento: forma
        })
        .then(() => {
          this.getAssinatura();
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Troca de forma de pagamento",
            text: error.response.data.falha
              ? error.response.data.falha
              : error.response.data
          });
        });
    },
    onDataValidadeSelected(novaData) {
      this.showDialogAlterarVencimento = false;
      const dataValidadeAtualFormatada = moment(
        this.assinatura.data_validade
      ).format("DD/MM/YYYY");
      const novaDataValidadeFormatada = moment(novaData).format("DD/MM/YYYY");
      this.openDialogConfirm({
        title: "Atenção",
        text: `Tem certeza que deseja alterar a data de vencimento de ${dataValidadeAtualFormatada}
        para a data ${novaDataValidadeFormatada}, está ação não pode ser desfeita?`,
        confirma: () => {
          this.alterarDataValidade(novaData);
          this.closeDialogConfirm();
        },
        cancela: this.closeDialogConfirm
      });
    },
    alterarDataValidade(novaData) {
      this.$axios
        .put(`/assinatura/${this.assinatura.id}/alterar-validade`, {
          validade: novaData.toISOString().split("T")[0]
        })
        .then(response => {
          this.assinatura = response.data;
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Troca de validade",
            text: error.response.data
              ? error.response.data
              : error.response.data
          });
        });
    }
  },
  async created() {
    await this.getAssinatura();
  }
};
</script>

<style>
.boleto-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  border: solid rgba(0, 0, 0, 0.3);
  border-width: 1px 2px 2px 1px;
  border-radius: 10px;
  height: 150px;
  margin: 0 auto 20px auto;
  padding: 20px;
}

.boleto {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.7)
    ),
    url(../../assets/img/boleto.png) no-repeat;
  background-size: 100% 100%;

  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.button_pagamento {
  width: 20rem;
}
.table_mobile {
  padding-right: 64px !important;
  padding-left: 64px !important;
}
.p-datepicker-calendar {
  margin: 0 !important;
}
.p-datepicker-calendar > tbody > tr > td {
  padding: 0 !important;
}
@media (max-width: 900px) {
  .table_mobile {
    display: block !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
</style>
