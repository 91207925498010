/*REFERÊNCIA DO CRIADOR DO SCRIPT http://wbruno.com.br/expressao-regular/diversas-mascaras-com-er/
 * e http://elcio.com.br/ajax/mascara/
 *
 * REFERÊNCIA PARA A CRIAÇÃO DA MÁSCARA DE PLACA:
 * https://code.google.com/p/jsc/source/browse/trunk/jsc/WebContent/mascaras.js?spec=svn24&r=24
 * */

/* Máscaras ER */
const masks = {
  cep: (v: any) => {
    if (!v) return null;
    v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
    v = v.replace(/^(\d{5})(\d)/, "$1-$2"); //Esse é tão fácil que não merece explicações
    return v;
  },
  telefone: (v: any) => {
    if (!v) return null;
    v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
    if (v.length > 11) v = v.substring(0, 11); //Garante que o número não exceda 11 dígitos
    v = v.replace(/^(\d{2})(\d)/g, "$1 $2"); //Coloca parênteses em volta dos dois primeiros dígitos
    v = v.replace(/(\d)(\d{4})$/, "$1-$2"); //Coloca hífen entre o quarto e o quinto dígitos
    return v;
  },
  cnpj: (v: any) => {
    if (!v) return null;
    v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
    v = v.replace(/^(\d{2})(\d)/, "$1.$2"); //Coloca ponto entre o segundo e o terceiro dígitos
    v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3"); //Coloca ponto entre o quinto e o sexto dígitos
    v = v.replace(/\.(\d{3})(\d)/, ".$1/$2"); //Coloca uma barra entre o oitavo e o nono dígitos
    v = v.replace(/(\d{4})(\d)/, "$1-$2"); //Coloca um hífen depois do bloco de quatro dígitos
    return v;
  },
  cpf: (v: any) => {
    if (!v) return null;
    v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
    v = v.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
    v = v.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
    //de novo (para o segundo bloco de números)
    v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2"); //Coloca um hífen entre o terceiro e o quarto dígitos
    return v;
  },
  dinheiro(value: any) {
    if (!value) return "";
    value = value + "";
    value = value.replace(/\D/g, ""); //Remove tudo o que não é dígito
    value = value.replace(/(\d)(\d{8})$/, "$1.$2"); //coloca o ponto dos milhões
    value = value.replace(/(\d)(\d{5})$/, "$1.$2"); //coloca o ponto dos milhares
    value = value.replace(/(\d)(\d{2})$/, "$1,$2"); //coloca a virgula antes dos 2 últimos dígitos
    return value;
  },
  dinheiroNew(value: any) {
    if (!value) return "";
    value = Number(value).toFixed(2).toString();
    value = value.replace(/\D/g, "").replace(/(\d{2})$/, ".$1");
    value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    value = value.replace(/\.(\d{2})$/, ",$1");
    return value;
  },
  rg: (v: any) => {
    if (!v) return null;
    v = v.replace(/\D/g, "");
    v = v.replace(/(\d{2})(\d{3})(\d{3})(\d{1})$/, "$1.$2.$3-$4");
    return v;
  },
  data: (v: any) => {
    if (!v) return null;
    v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
    v = v.replace(/(\d{2})(\d)/, "$1/$2");
    v = v.replace(/(\d{2})(\d)/, "$1/$2");

    v = v.replace(/(\d{2})(\d{2})$/, "$1$2");
    return v;
  },
  unMask: (v: any) => {
    if (!v) return null;
    v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
    return v;
  }
};

export default masks;
//Para usar as máscaras, basta fazer o seguinte nos campos de input
//onkeyup="mascara(this, mvalor);"
