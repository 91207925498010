<template>
  <div>

    <!-- SKELETON -->
    <v-container v-if="loadingSkeleton" fluid>
      <v-row class="grey lighten-5 px-16">
        <v-col>
          <v-skeleton-loader
              :loading="true"
              type="image"
              height="50"
          ></v-skeleton-loader>
        </v-col>
      </v-row>

      <v-row class="grey lighten-5 px-16 mt-10">
        <v-col cols="12">
          <v-skeleton-loader
              :loading="true"
              type="heading"
          ></v-skeleton-loader>
          <v-skeleton-loader
              class="mt-3"
              :loading="true"
              type="text@20"
          ></v-skeleton-loader>
        </v-col>
      </v-row>

      <v-row class="px-16 mt-10">
        <v-col cols="12">
          <v-skeleton-loader
              :loading="true"
              type="image"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-else fluid>
      <v-row dense>
        <v-col>
          <v-tabs v-model="tab" align-with-title @change="handleChangeTab">
            <v-tabs-slider color="secondary"></v-tabs-slider>
            <v-tab
            ><v-icon class="mr-3">mdi-warehouse</v-icon> Dados Gerais
            </v-tab>
            <v-tab> <v-icon class="mr-3">mdi-call-split</v-icon> Splits </v-tab>
          </v-tabs>
        </v-col>
      </v-row>
      <form action="" name="formEmpresa" @submit.prevent="handleSave">
        <v-row>
          <v-col>
            <v-tabs-items v-model="tab" style="min-height: 370px">
              <v-container fluid>
                <!-- Dados Gerais -->
                <v-tab-item>
                  <v-row dense>
                    <v-col cols="2">
                      <v-switch
                          :disabled="mode === 'view'"
                          v-model="servico.status"
                          :label="servico.status ? 'Ativo' : 'Inativo'"
                      ></v-switch>
                    </v-col>
                  </v-row>
                  <v-row class="box_mobile">
                    <v-col cols="2" class="box_mobile_info">
                      <v-text-field
                          :readonly="mode == 'view'"
                          required
                          dense
                          v-model="servico.sku"
                          label="SKU"
                      >
                      </v-text-field>
                    </v-col>

                    <v-col>
                      <v-text-field
                          :readonly="mode == 'view'"
                          required
                          dense
                          v-model="servico.nome"
                          label="Nome"
                      >
                      </v-text-field>
                    </v-col>

                    <v-col cols="12" sm="2" class="box_mobile_info">
                      <v-autocomplete
                          dense
                          no-data-text="Cadastre os setores"
                          :items="setores"
                          :readonly="mode == 'view'"
                          :item-text="item => `${item.nome}`"
                          item-value="id"
                          v-model="servico.setor.id"
                          label="Setor"
                      >
                      </v-autocomplete>
                    </v-col>

                    <v-col cols="2" class="box_mobile_info">
                      <v-autocomplete
                          dense
                          no-data-text="Cadastre as categorias"
                          :items="categorias"
                          :readonly="mode == 'view'"
                          :item-text="item => `${item.nome}`"
                          item-value="id"
                          v-model="servico.categoria.id"
                          label="Categoria"
                      >
                      </v-autocomplete>
                    </v-col>

                    <v-col cols="2" class="box_mobile_info">
                      <v-text-field
                          :readonly="mode == 'view'"
                          required
                          @keyup="servico.valor = $masks.dinheiro(servico.valor)"
                          dense
                          v-model="servico.valor"
                          label="Valor"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="box_mobile">
                    <v-col cols="2" class="box_mobile_info">
                      <v-text-field
                          :readonly="mode == 'view'"
                          dense
                          v-model="servico.cnae"
                          label="CNAE"
                      >
                      </v-text-field>
                    </v-col>

                    <v-col cols="2" class="box_mobile_info">
                      <v-text-field
                          :readonly="mode == 'view'"
                          dense
                          v-model="servico.item_lista_servico"
                          label="Item List. Serv."
                      >
                      </v-text-field>
                    </v-col>

                    <v-col cols="2" class="box_mobile_info">
                      <v-text-field
                          :readonly="mode == 'view'"
                          dense
                          v-model="servico.codigo_tributario_municipio"
                          label="Cód. Trib. Mun."
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-autocomplete
                          dense
                          no-data-text="Adicione as categorias no ERP"
                          :items="categoriasContabil"
                          :readonly="mode == 'view'"
                          :item-text="item => `${item.codigo} - ${item.nome}`"
                          item-value="id"
                          v-model="servico.categoria_contabil.id"
                          label="Tipo de entrada no contas a receber"
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>

                  <v-row class="box_mobile">
                    <v-col cols="3" class="box_mobile_info">
                    <span
                    >Base calculo ISS
                      {{ servico.perc_base_calculo_iss }}%</span
                    >
                      <Slider
                          v-model="servico.perc_base_calculo_iss"
                          :disabled="mode == 'view'"
                      />
                    </v-col>

                    <v-col cols="2" class="box_mobile_info">
                      <v-text-field
                          :readonly="mode == 'view'"
                          dense
                          @keyup="
                        servico.aliquota_iss = $masks.dinheiro(
                          servico.aliquota_iss
                        )
                      "
                          v-model="servico.aliquota_iss"
                          label="Alíquota ISS %"
                      >
                      </v-text-field>
                    </v-col>

                    <v-col cols="1" style="padding:0;" class="box_mobile_info">
                      <v-switch
                          v-model="servico.iss_retido"
                          label="ISS Retido"
                      ></v-switch>
                    </v-col>

                    <v-col cols="3" class="box_mobile_info">
                    <span
                    >Base calculo INSS
                      {{ servico.perc_base_calculo_inss }}%</span
                    >
                      <Slider
                          v-model="servico.perc_base_calculo_inss"
                          :disabled="mode == 'view'"
                      />
                    </v-col>

                    <v-col cols="2" class="box_mobile_info">
                      <v-text-field
                          :readonly="mode == 'view'"
                          dense
                          @keyup="
                        servico.aliquota_inss = $masks.dinheiro(
                          servico.aliquota_inss
                        )
                      "
                          v-model="servico.aliquota_inss"
                          label="Alíquota INSS %"
                      >
                      </v-text-field>
                    </v-col>

                    <v-col cols="1" style="padding:0;" class="box_mobile_info">
                      <v-switch
                          v-model="servico.inss_retido"
                          label="INSS Retido"
                      ></v-switch>
                    </v-col>
                  </v-row>

                  <v-row class="d-flex flex-nowrap box_mobile">
                    <v-text-field
                        :readonly="mode == 'view'"
                        dense
                        class="flex-grow-1 mx-2 mt-4"
                        @keyup="servico.aliquota_pis = $masks.dinheiro(servico.aliquota_pis)"
                        v-model="servico.aliquota_pis"
                        label="Alíquota PIS %"
                    ></v-text-field>

                    <v-switch
                        class="flex-grow-1 mx-2"
                        v-model="servico.pis_retido"
                        label="PIS Retido"
                    ></v-switch>

                    <v-text-field
                        :readonly="mode == 'view'"
                        dense
                        class="flex-grow-1 mx-2 mt-4"
                        @keyup="servico.aliquota_cofins = $masks.dinheiro(servico.aliquota_cofins)"
                        v-model="servico.aliquota_cofins"
                        label="Alíquota COFINS %"
                    ></v-text-field>

                    <v-switch
                        class="flex-grow-1 mx-2"
                        v-model="servico.cofins_retido"
                        label="COFINS Retido"
                    ></v-switch>

                    <v-text-field
                        :readonly="mode == 'view'"
                        dense
                        class="flex-grow-1 mx-2 mt-4"
                        @keyup="servico.aliquota_ir = $masks.dinheiro(servico.aliquota_ir)"
                        v-model="servico.aliquota_ir"
                        label="Alíquota IR %"
                    ></v-text-field>

                    <v-switch
                        class="flex-grow-1 mx-2"
                        v-model="servico.ir_retido"
                        label="IR Retido"
                    ></v-switch>

                    <v-text-field
                        :readonly="mode == 'view'"
                        dense
                        class="flex-grow-1 mx-2 mt-4"
                        @keyup="servico.aliquota_csll = $masks.dinheiro(servico.aliquota_csll)"
                        v-model="servico.aliquota_csll"
                        label="Alíquota CSLL %"
                    ></v-text-field>

                    <v-switch
                        class="flex-grow-1 mx-2"
                        v-model="servico.csll_retido"
                        label="CSLL Retido"
                    ></v-switch>
                  </v-row>



                  <!--                  <v-row class="box_mobile">-->
<!--                    <v-col cols="2" class="box_mobile_info">-->
<!--                      <v-text-field-->
<!--                          :readonly="mode == 'view'"-->
<!--                          dense-->
<!--                          @keyup="-->
<!--                        servico.aliquota_pis = $masks.dinheiro(-->
<!--                          servico.aliquota_pis-->
<!--                        )-->
<!--                      "-->
<!--                          v-model="servico.aliquota_pis"-->
<!--                          label="Alíquota PIS %"-->
<!--                      >-->
<!--                      </v-text-field>-->
<!--                    </v-col>-->

<!--                    <v-col cols="2" style="padding:0;" class="box_mobile_info">-->
<!--                      <v-switch-->
<!--                          v-model="servico.pis_retido"-->
<!--                          label="PIS Retido"-->
<!--                      ></v-switch>-->
<!--                    </v-col>-->

<!--                    <v-col cols="2" class="box_mobile_info">-->
<!--                      <v-text-field-->
<!--                          :readonly="mode == 'view'"-->
<!--                          dense-->
<!--                          @keyup="-->
<!--                        servico.aliquota_cofins = $masks.dinheiro(-->
<!--                          servico.aliquota_cofins-->
<!--                        )-->
<!--                      "-->
<!--                          v-model="servico.aliquota_cofins"-->
<!--                          label="Alíquota COFINS %"-->
<!--                      >-->
<!--                      </v-text-field>-->
<!--                    </v-col>-->

<!--                    <v-col cols="2" style="padding:0;" class="box_mobile_info">-->
<!--                      <v-switch-->
<!--                          v-model="servico.cofins_retido"-->
<!--                          label="COFINS Retido"-->
<!--                      ></v-switch>-->
<!--                    </v-col>-->
<!--                  </v-row>-->
<!--                  <v-row class="box_mobile">-->
<!--                    <v-col cols="2" class="box_mobile_info">-->
<!--                      <v-text-field-->
<!--                          :readonly="mode == 'view'"-->
<!--                          dense-->
<!--                          @keyup="-->
<!--                        servico.aliquota_ir = $masks.dinheiro(-->
<!--                          servico.aliquota_ir-->
<!--                        )-->
<!--                      "-->
<!--                          v-model="servico.aliquota_ir"-->
<!--                          label="Alíquota IR %"-->
<!--                      >-->
<!--                      </v-text-field>-->
<!--                    </v-col>-->

<!--                    <v-col cols="2" style="padding:0;" class="box_mobile_info">-->
<!--                      <v-switch-->
<!--                          v-model="servico.ir_retido"-->
<!--                          label="IR Retido"-->
<!--                      ></v-switch>-->
<!--                    </v-col>-->

<!--                    <v-col cols="2" class="box_mobile_info">-->
<!--                      <v-text-field-->
<!--                          :readonly="mode == 'view'"-->
<!--                          dense-->
<!--                          @keyup="-->
<!--                        servico.aliquota_csll = $masks.dinheiro(-->
<!--                          servico.aliquota_csll-->
<!--                        )-->
<!--                      "-->
<!--                          v-model="servico.aliquota_csll"-->
<!--                          label="Alíquota CSLL %"-->
<!--                      >-->
<!--                      </v-text-field>-->
<!--                    </v-col>-->

<!--                    <v-col cols="2" style="padding:0;" class="box_mobile_info">-->
<!--                      <v-switch-->
<!--                          v-model="servico.csll_retido"-->
<!--                          label="CSLL Retido"-->
<!--                      ></v-switch>-->
<!--                    </v-col>-->
<!--                  </v-row>-->

                  <v-row>
                    <v-col cols="3">
                      <v-text-field
                          maxlength="40"
                          :readonly="mode == 'view'"
                          dense
                          v-model="servico.id_externo"
                          label="ID Externo"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-textarea
                          outlined
                          counter="2000"
                          :readonly="mode == 'view'"
                          label="Descrição do serviço"
                          hint="Detalhamento do serviço que deve ser exibido no corpo da nota fiscal"
                          v-model="servico.descricao"
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <!-- Split -->
                <v-tab-item>
                  <v-row>
                    <v-col>
                      <v-row dense>
                        <v-col class="text-end text-subtitle-2">
                          Valor da Cobrança
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col class="text-end">
                        <span class="text-caption" style="display:inline;"
                        >R$</span
                        >
                          <span class="text-h3">{{ this.servico.valor }}</span>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex justify-center">
                    <v-col>
                      <v-row dense>
                        <v-col>
                          <span class="text-h5"> Splits </span>
                        </v-col>
                      </v-row>
                      <v-row dense v-if="!user.permissoes.adm_split">
                        <v-col>
                          <v-alert colored-border type="info" elevation="2">
                            Seu usuário não possui permissão para configurar
                            splits, clique em avançar para pular esta etapa.
                          </v-alert>
                        </v-col>
                      </v-row>
                      <v-row dense v-if="user.permissoes.adm_split && mode !== 'view'">
                        <v-col cols="10">
                          <v-autocomplete
                              :items="empresasMarketPlace"
                              item-value="id"
                              item-text="razao_social"
                              v-model="destinatarioSplit"
                              label="Destinatarios"
                              dense
                              return-object
                          >
                            <template v-slot:selection="{ item }">
                            <span v-if="item.razao_social">
                              {{ $masks.cnpj(item.cnpj) }} -
                              {{ item.razao_social }}
                            </span>
                              <span v-if="item.nome">
                              {{ $masks.cpf(item.cpf) }} -
                              {{ item.nome }}
                            </span>
                            </template>
                            <template v-slot:item="{ item }">
                              <v-list-item-content>
                                <v-list-item-title>{{
                                    item.nome_fantasia
                                  }}</v-list-item-title>
                                <v-list-item-subtitle v-if="item.razao_social">
                                  {{ $masks.cnpj(item.cnpj) }} -
                                  {{ item.razao_social }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle v-if="item.nome">
                                  {{ $masks.cpf(item.cpf) }} -
                                  {{ item.nome }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <v-col>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                                  fab
                                  dark
                                  small
                                  color="primary"
                                  @click="addSplit"
                              >
                                <v-icon>mdi-plus</v-icon>
                              </v-btn>
                            </template>
                            <span>Adicionar Split</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                      <v-row dense v-if="user.permissoes.adm_split">
                        <v-col>
                          <v-simple-table>
                            <template v-slot:default>
                              <thead>
                              <tr>
                                <th class="text-left" width="450">Empresa</th>
                                <th class="text-left" width="120">
                                  Percentual
                                </th>
                                <th class="text-left">Paga Taxa</th>
                                <th class="text-left">Responsavel Divisão</th>
                                <th class="text-left">Total</th>
                                <th v-if="mode === 'create'" class="text-left">Remover</th>
                              </tr>
                              </thead>
                              <tbody v-if="servico">
                              <tr
                                  v-for="(split, index) in servico.splits"
                                  :key="split.id"
                              >
                                <td>
                                  <div>
                                    <div v-if="split.recebedor.cnpj">
                                      {{ split.recebedor.cnpj }} -
                                      <span class="nome-fantasia">{{
                                          split.recebedor.nome_fantasia
                                        }}</span>
                                    </div>
                                    <div v-if="split.recebedor.cpf">
                                      {{ split.recebedor.cpf }} -
                                      <span class="nome-fantasia">{{
                                          split.recebedor.nome_fantasia
                                        }}</span>
                                    </div>
                                    <div v-if="split.recebedor.razao_social">
                                      {{ split.recebedor.razao_social }}
                                    </div>
                                    <div v-if="split.recebedor.nome">
                                      {{ split.recebedor.nome }}
                                    </div>
                                    <div>
                                      <span class="endereco">{{
                                          split.recebedor.enderecoCompleto
                                        }}</span>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <InputNumber
                                      :disabled="mode === 'view'"
                                      mode="decimal"
                                      :minFractionDigits="2"
                                      :maxFractionDigits="2"
                                      v-model="split.valor"
                                      suffix=" %"
                                      :max="100"
                                      :min="0"
                                      :allowEmpty="false"
                                  />
                                </td>
                                <td>
                                  <v-switch
                                      :disabled="mode === 'view'"
                                      v-model="split.paga_taxa_transacao"
                                      :label="
                                      split.paga_taxa_transacao ? 'Sim' : 'Não'
                                    "
                                  />
                                </td>
                                <td>
                                  <v-switch
                                      :disabled="mode === 'view'"
                                      v-model="split.paga_resto_divisao_taxa"
                                      :label="
                                      split.paga_resto_divisao_taxa
                                        ? 'Sim'
                                        : 'Não'
                                    "
                                  />
                                </td>
                                <td>
                                  <div>
                                    R$
                                    {{
                                      valorSplit(split)
                                          | formatMoney({
                                        minimumFractionDigits: 2
                                      })
                                    }}
                                  </div>
                                </td>
                                <td v-if="mode !== 'view'">
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                          v-bind="attrs"
                                          v-on="on"
                                          text
                                          small
                                          @click="
                                          openModalRemoveSplit(split, index)
                                        "
                                      >
                                        <v-icon>mdi-delete</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Remover Item</span>
                                  </v-tooltip>
                                </td>
                              </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="2" class="d-flex align-self-center">
                      <v-progress-circular
                          :rotate="360"
                          :size="200"
                          :width="10"
                          :value="totalPercSplit"
                          :color="totalPercSplit <= 100 ? 'primary' : 'red'"
                      >
                      <span class="text-h5 font-weight-bold">
                        {{ totalPercSplit }}%
                      </span>
                      </v-progress-circular>
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-container>
            </v-tabs-items>
            <v-row>
              <v-col class="text-end">
                <v-btn
                    dark
                    color="primary"
                    type="submit"
                    v-if="mode != 'view' && user.permissoes.adm_servico"
                >
                  Salvar
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </form>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Slider from "primevue/slider";
import InputNumber from "primevue/inputnumber";

export default {
  props: {
    mode: String,
    idServico: {
      type: String
    }
  },
  components: {
    Slider,
    InputNumber
  },
  data() {
    return {
      loadingSkeleton: false,
      tab: null,
      servico: {
        descricao: "",
        status: true,
        valor: 0,
        aliquota_iss: 0,
        aliquota_inss: 0,
        aliquota_pis: 0,
        aliquota_csll: 0,
        aliquota_ir: 0,
        perc_base_calculo_iss: 0,
        perc_base_calculo_inss: 0,
        aliquota_cofins: 0,
        categoria_contabil: {},
        categoria: {},
        setor: {},
        splits: [],
        empresa: null,
        plano: null,
        id_externo: null
      },
      destinatarioSplit: null,
      categoriasContabil: [],
      categorias: [],
      setores: [],
      empresasMarketPlace: [],
      itens: [],
      empresaSelecionada: null,
      empresasUsuario: [],
      clientesUsuario: [],
      planos: [],
      recorrente: true,
      link: "",
      erros: [],
      item: []
    };
  },
  mounted() {
    //this.getEmpresasMarketPlace();
    this.getCategoriasContabil();
    this.getCategorias();
    this.getSetores();

    if (this.mode != "create") this.getServico();
  },
  computed: {
    ...mapState(["empresaAtual", "user"]),
    totalCobranca() {
      let totalCobranca = 0;
      this.itens.map(item => {
        if (!item.quantidade) {
          item.total = 0;
        } else {
          item.total = item.quantidade * this.parseToFloat(item.valor);
        }

        totalCobranca += item.total;
        return item;
      });

      return parseFloat(totalCobranca.toFixed(2));
    },
    totalPercSplit() {
      if (this.servico == null || !this.servico.splits) return 0;

      const total = this.servico.splits
        .reduce((accumulator, obj) => {
          return accumulator + this.parseToFloat(obj.valor ? obj.valor : 0);
        }, 0.0)
        .toFixed(2);

      return total == 100.0 ? 100 : total;
    }
  },
  methods: {
    ...mapMutations([
      "openDialogMessage",
      "openDialogConfirm",
      "closeDialogConfirm"
    ]),
    handleChangeTab() {
      if (this.tab === 1) this.getEmpresasMarketPlace();
    },
    getServico() {
      if (this.mode != "create") {
        this.loadingSkeleton = true;
        this.$axios
          .get(`/servico/${this.idServico}`)
          .then(response => {
            this.servico = response.data;

            if (!this.servico.categoria_contabil) {
              this.servico.categoria_contabil = {};
            }

            if (!this.servico.categoria) {
              this.servico.categoria = {};
            }

            if (!this.servico.setor) {
              this.servico.setor = {};
            }

            this.servico.status =
              this.servico.status === "ATIVO" ? true : false;
            if (this.servico.valor) {
              this.servico.valor = this.$masks.dinheiro(
                this.servico.valor.toFixed(2)
              );
            }

            //ISS
            if (this.servico.aliquota_iss) {
              this.servico.aliquota_iss = this.$masks.dinheiro(
                this.servico.aliquota_iss.toFixed(2)
              );
            }

            //INSS
            if (this.servico.aliquota_inss) {
              this.servico.aliquota_inss = this.$masks.dinheiro(
                this.servico.aliquota_inss.toFixed(2)
              );
            }

            //PIS
            if (this.servico.aliquota_pis) {
              this.servico.aliquota_pis = this.$masks.dinheiro(
                this.servico.aliquota_pis.toFixed(2)
              );
            }
            //COFINS
            if (this.servico.aliquota_cofins) {
              this.servico.aliquota_cofins = this.$masks.dinheiro(
                this.servico.aliquota_cofins.toFixed(2)
              );
            }

            //IR
            if (this.servico.aliquota_ir) {
              this.servico.aliquota_ir = this.$masks.dinheiro(
                this.servico.aliquota_ir.toFixed(2)
              );
            }

            //CSLL
            if (this.servico.aliquota_csll) {
              this.servico.aliquota_csll = this.$masks.dinheiro(
                this.servico.aliquota_csll.toFixed(2)
              );
            }
            this.loadingSkeleton = false;
          })
          .catch(error => {
            this.loadingSkeleton = false;
            this.openDialogMessage({
              type: "error",
              title: "Erro - Buscar serviço",
              text: error.response.data
            });
          });
      }
    },
    getCategoriasContabil() {
      this.$axios
        .get(`/categoria-contabil/empresa/${this.empresaAtual.id}?tipo=ENTRADA`)
        .then(response => {
          this.categoriasContabil = response.data;
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Buscar categoria",
            text: error.response.data
          });
        });
    },
    getCategorias() {
      this.$axios
        .get(`/categoria/empresa/${this.empresaAtual.id}`)
        .then(response => {
          this.categorias = response.data;
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Buscar categoria",
            text: error.response.data
          });
        });
    },
    getSetores() {
      this.$axios
          .get(`/setor/empresa/${this.empresaAtual.id}`)
          .then(response => {
            this.setores = response.data;
          })
          .catch(error => {
            this.openDialogMessage({
              type: "error",
              title: "Erro - Buscar setor",
              text: error.response.data
            });
          });
    },
    async handleSave() {
      this.servico.status = this.servico.status ? "ATIVO" : "INATIVO";
      this.servico.empresa_id = this.empresaAtual.id;
      this.servico.valor = this.parseToFloat(
        this.servico.valor ? this.servico.valor : 0
      );

      this.servico.perc_base_calculo_iss = this.parseToFloat(
        this.servico.perc_base_calculo_iss
          ? this.servico.perc_base_calculo_iss
          : 0
      );
      this.servico.aliquota_iss = this.parseToFloat(
        this.servico.aliquota_iss ? this.servico.aliquota_iss : 0
      );

      this.servico.perc_base_calculo_inss = this.parseToFloat(
        this.servico.perc_base_calculo_inss
          ? this.servico.perc_base_calculo_inss
          : 0
      );
      this.servico.aliquota_inss = this.parseToFloat(
        this.servico.aliquota_inss ? this.servico.aliquota_inss : 0
      );

      this.servico.aliquota_pis = this.parseToFloat(
        this.servico.aliquota_pis ? this.servico.aliquota_pis : 0
      );
      this.servico.aliquota_cofins = this.parseToFloat(
        this.servico.aliquota_cofins ? this.servico.aliquota_cofins : 0
      );
      this.servico.aliquota_ir = this.parseToFloat(
        this.servico.aliquota_ir ? this.servico.aliquota_ir : 0
      );
      this.servico.aliquota_csll = this.parseToFloat(
        this.servico.aliquota_csll ? this.servico.aliquota_csll : 0
      );

      this.mode === "create" ? await this.save() : await this.edit();
    },

    async save() {
      const data = { ...this.servico };

      this.$axios
        .post("/servico", data)
        .then(async response => {
          this.servico = response.data;
          this.$toast.add({
            severity: "success",
            summary: `Serviço cadastrado com sucesso.`,
            life: 2000
          });
          this.$router.push({
            name: "Servicos"
          });
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Cadastrar Serviço",
            text: error.response.data
          });
        });
    },

    async edit() {
      this.servico.empresa_id = this.empresaAtual.id;
      this.$axios
        .put("servico", this.servico)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: `Serviço atualizado com sucesso.`,
            life: 2000
          });
          this.$router.push({
            name: "Servicos"
          });
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Atualizar Serviço",
            text: error.response.data
          });
        });
    },
    addSplit() {
      if (!this.destinatarioSplit || !this.destinatarioSplit.id) {
        this.openDialogMessage({
          type: "warning",
          title: "Atenção",
          text: "Selecione a empresa de destino do split"
        });
        return;
      }

      const findSplit = this.servico.splits.some(
        split => split.recebedor.id === this.destinatarioSplit.id
      );
      if (findSplit) {
        this.openDialogMessage({
          type: "warning",
          title: "Atenção",
          text: "Empresa já está adicionada à lista de splits."
        });
        return;
      }
      const splitData = {
        recebedor: {
          id: this.destinatarioSplit.id,
          nome: this.destinatarioSplit.nome,
          razao_social: this.destinatarioSplit.razao_social,
          nome_fantasia: this.destinatarioSplit.nome_fantasia,
          cnpj: this.destinatarioSplit.cnpj,
          cpf: this.destinatarioSplit.cpf,
          enderecoCompleto: this.destinatarioSplit.enderecoCompleto
        },
        valor: 0,
        metodo: "PERCENTUAL",
        paga_taxa_transacao: this.destinatarioSplit.id == this.empresaAtual.id,
        paga_resto_divisao_taxa:
          this.destinatarioSplit.id == this.empresaAtual.id
      };
      this.servico.splits.push(splitData);
      this.destinatarioSplit = null;
    },
    removeSplit(index) {
      this.servico.splits.splice(index, 1);
      this.closeDialogConfirm();
    },
    openModalRemoveSplit(split, index) {
      this.openDialogConfirm({
        title: "Remover Split",
        text: `Deseja realmente o split da empresa ${split.recebedor.razao_social ? split.recebedor.razao_social : split.recebedor.nome}?`,
        confirma: this.removeSplit.bind(this, index),
        cancela: this.closeDialogConfirm.bind()
      });
    },

    valorSplit(split) {
      if (!split || !split.valor) return 0.0;

      let valor = this.servico.valor;

      if (typeof this.servico.valor === "string") {
        valor = this.servico.valor.replace(/\./g, '').replace(',', '.');
      }
      return ((split.valor * parseFloat(valor)) / 100).toFixed(2);
    },
    async getEmpresasMarketPlace() {
      if (!this.empresaAtual || !this.empresaAtual.id) {
        this.empresasMarketPlace = [];
      }

      return this.$axios
        .get(`/empresa/${this.empresaAtual.id}/marketplace?status=ATIVA`)
        .then(response => {
          this.empresasMarketPlace = response.data.map(empresa => {
            empresa.telefone = this.$masks.telefone(empresa.telefone);
            empresa.cnpj = this.$masks.cnpj(empresa.cnpj);
            return empresa;
          });
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Consultar Empresas",
            text: error.response.data
          });
        });
    }
  }
};
</script>
<style>
@media (max-width: 900px) {
  .box_mobile {
    display: block !important;
  }
  .box_mobile_info {
    max-width: 100% !important;
  }
}
</style>
