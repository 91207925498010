<template>
  <v-container>
    <v-dialog v-model="showDialog" max-width="500" persistent>
      <form @submit.prevent="addEmail">
        <v-card>
          <v-card-title class="d-flex justify-space-between">
            Nota Fiscal
            <v-btn fab small text @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="4">
                <v-chip
                  class="white--text"
                  :color="STATUS_NOTA[notaLocal.status].cor"
                  :key="notaLocal.id">
                  {{ STATUS_NOTA[notaLocal.status].texto }}
                </v-chip>
              </v-col>
              <v-col cols="8">
                {{ nota.msg_erro }}
                {{ nota.mensagem_sefaz }}
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="4">
                <span>Emissão</span>
                <p>
                  {{
                    notaLocal.data_emissao
                      | formatDate({ dateStyle: "short", timeStyle: "short" })
                  }}
                </p>
              </v-col>
              <v-col cols="2" v-if="notaLocal.tipo == 'NFSE'">
                  <span>Série</span>
                  <p>{{ notaLocal.serie_rps }}</p>
              </v-col>
              <v-col>
                <div v-if="notaLocal.tipo == 'NFSE'">
                  <span>N° RPS</span>
                  <p>{{ notaLocal.numero_rps }}</p>
                </div>
                <div v-else>
                  <span>Protocolo</span>
                  <p>{{ notaLocal.protocolo }}</p>
                </div>
              </v-col>
              <v-col>
                <div v-if="notaLocal.numero">
                  <span>Número</span>
                  <p>{{ notaLocal.numero }}</p>
                </div>
              </v-col>
             
            </v-row>
            <v-row v-if="notaLocal.empresa" dense>
              <v-col>
                <span>Emissor</span>
                <p>{{ notaLocal.empresa?.nome_fantasia }}</p>
              </v-col>
              <v-col>
                <span>CNPJ</span>
                <p>{{ $masks.cnpj(notaLocal?.empresa?.cnpj) }}</p>
              </v-col>
            </v-row>

            <v-row v-if="notaLocal.tomador" dense>
              <v-col>
                <span>Tomador</span>
                <p>{{ notaLocal?.tomador?.nome }}</p>
                <span>E-mail</span>
                <p>{{ notaLocal?.tomador?.email }}</p>
              </v-col>
              <v-col>
                <span>CPF/CNPJ</span>
                <p>
                  {{ $masks.cpf(notaLocal.tomador?.cpf) }}
                  {{ $masks.cnpj(notaLocal.tomador?.cnpj) }}
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <span>Valor Bruto</span>
                <p>
                  R$ {{ notaLocal.valorTotalBruto | formatMoney({ minimumFractionDigits: 2 }) }}
                </p>
              </v-col>
              <v-col>
                <span>Valor líquido</span>
                <p>
                  R$ {{ notaLocal.valorTotalLiquido | formatMoney({ minimumFractionDigits: 2 }) }}
                </p>
              </v-col>
              <v-col v-if="notaLocal.valor_total_tributos">
                <span>Tributos</span>
                <p>
                  R$ {{ notaLocal.valor_total_tributos | formatMoney({ minimumFractionDigits: 2 }) }}
                </p>
              </v-col>
              
            </v-row>
           
            <v-row dense>
              <v-col>
                <span>Itens</span>
                <v-list-item two-line v-for="(item, i) in notaLocal.itens" :key="i" >

                  <v-list-item-content v-if="item.servico">
                        <v-list-item-title>
                          {{ item.quantidade }} X {{ item.servico.nome }} R$
                          {{ valorItem(item) | formatMoney({ minimumFractionDigits: 2 }) }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ item.servico.descricao }}
                        </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content v-else>
                        <v-list-item-title>
                          {{ item.quantidade }} X {{ item.produto.nome }} R$
                          {{ valorItem(item) | formatMoney({ minimumFractionDigits: 2 }) }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ item.produto.descricao }}
                        </v-list-item-subtitle>
                  </v-list-item-content>

                </v-list-item>
              </v-col>
            </v-row>
            <v-card-actions class="mt-4">
              <v-btn
                color="red"
                width="50%"
                class="white--text"
                v-if="
                  notaLocal.status != 'CANCELADO' &&
                    user.permissoes &&
                    user.permissoes.cancelar_nfs
                "
                @click="dialogConfirmaCancelamento"
              >
                Cancelar
              </v-btn>
              <v-btn
                color="green"
                width="50%"
                class="white--text"
                v-if="notaLocal.status == 'ERRO_AUTORIZACAO'"
                @click="transmitirNotaFiscal"
              >
                Transmitir
              </v-btn>
              <v-btn
                color="green"
                width="50%"
                class="white--text"
                v-if="notaLocal.numero"
                @click="openUrl(notaLocal.url)"
              >
                Ver
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </form>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  name: "DialogNotaFiscal",
  components: {},
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    nota: {
      type: Object,
      default: () => ({})
    },
    close: Function
  },
  data() {
    return {
      notaLocal: { ...this.nota }
    };
  },
  computed: {
    ...mapState(["user"]),
    totalNota() {
      if (!this.notaLocal.itens) return 0;

      const total = this.notaLocal.itens.reduce(
        (prev, curr) => (prev += curr.quantidade * curr.valor - curr.desconto),
        0
      );
      return total.toFixed(2);
    }
  },
  methods: {
    ...mapMutations([
      "openDialogMessage",
      "openDialogConfirm",
      "closeDialogConfirm"
    ]),
    openUrl(url) {
      const win = window.open(url, "_blank");
      if (win) {
        win.focus();
      } else {
        alert("Desabilite o bloqueio de pop-ups para abrir a nota");
      }
    },
    dialogConfirmaCancelamento() {
      this.openDialogConfirm({
        title: "Atenção",
        text: `Esta ação não pode ser desfeita, deseja realmente cancelar?`,
        confirma: this.cancelar,
        cancela: this.closeDialogConfirm
      });
    },
    cancelar() {
      this.closeDialogConfirm();
      this.$axios
        .delete(`/notafiscal/${this.notaLocal.id}/cancela`)
        .then(obj => {
          this.notaLocal.status = obj.status;
          this.close();
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Cancelar Nota Fiscal",
            text: error.response.data
          });
        });
    },

    async transmitirNotaFiscal() {
      try {
        this.$toast.add({
          severity: "info",
          summary: "Transmitindo Nota...",
          detail: "A nota foi enviada para a prefeitura. Aguarde o retorno.",
          life: 2000,
        });

        const endpoint = this.notaLocal.tipo === "NFSE"
          ? `/notafiscal/${this.notaLocal.id}/envia-prefeitura`
          : `/notafiscal/${this.notaLocal.id}/envia-sefaz`;

        const response = await this.$axios.post(endpoint);

        this.notaLocal = response.data;

        this.$toast.add({
          severity: "success",
          summary: "Nota Transmitida",
          detail: "A nota foi transmitida com sucesso!",
          life: 3000,
        });
      } catch (error) {
        console.error("Erro ao transmitir a nota fiscal:", error);

        this.openDialogMessage({
          type: "error",
          title: "Erro ao Transmitir",
          text: error.response?.data?.message || "Ocorreu um erro ao transmitir a nota."
        });
      }
    },

    valorItem(item) {
      return (item.quantidade * item.valor - item.desconto).toFixed(2);
    }
  }
};
</script>
<style scoped></style>
