<template>
  <v-dialog v-model="showDialog" persistent max-width="1500">
    <v-card style="overflow: hidden;">

      <v-row dense>
        <v-col class="text-start">
          <v-card-title> Lista de Notas </v-card-title>
        </v-col>
        <v-col class="text-end mt-3 mr-5">
          <v-icon @click="cancelar">mdi-close</v-icon>
        </v-col>
      </v-row>

      <v-card-text>

        <v-row>
          <v-col>
            <DataTable
                :value="listaNotas"
                selectionMode="single"
                dataKey="id"
            >
              <Column field="data_emissao" header="Emissão">
                <template #body="slot">
                  <span>{{ slot.data.data_emissao | formatDate({ dateStyle: "short", timeStyle: "short" }) }}</span>
                </template>
              </Column>
              <Column field="status" header="Status">
                <template #body="slot">
                  <span>
                    <v-chip
                      class="white--text"
                      :color="STATUS_NOTA[slot.data.status].cor"
                      :key="slot.data.id"
                    >
                      {{ STATUS_NOTA[slot.data.status].texto }}
                    </v-chip>
                  </span>
                </template>
              </Column>
              <Column field="serie_rps" header="Série">
                <template #body="slot">
                  <span>{{ slot.data.serie_rps }}</span>
                </template>
              </Column>
              <Column field="numero_rps" header="Nº RPS">
                <template #body="slot">
                  <span>{{ slot.data.numero_rps }}</span>
                </template>
              </Column>
              <Column field="tipo" header="Tipo">
                <template #body="slot">
                  <span>{{ slot.data.tipo }}</span>
                </template>
              </Column>
              <Column header="Empresa">
                <template #body="slot">
                  <span v-if="slot.data.empresa">{{ slot.data.empresa.razao_social ? slot.data.empresa.razao_social : slot.data.empresa.nome }}</span>
                  <span v-else> - </span>
                </template>
              </Column>
              <Column header="CNPJ/CPF">
                <template #body="slot">
                  <span v-if="slot.data.empresa">{{ slot.data.empresa.cnpj ? slot.data.empresa.cnpj : slot.data.empresa.cpf }}</span>
                  <span v-else> - </span>
                </template>
              </Column>
              <Column field="valorTotalLiquido" header="Valor">
                <template #body="slot">
                  <span>{{ slot.data.valorTotalLiquido | formatMoney({ minimumFractionDigits: 2 }) }}</span>
                </template>
              </Column>
              <Column header="Ação">
                <template #body="slot">
                  <v-btn
                    v-if="slot.data.status === 'ERRO_AUTORIZACAO' && slot.data.tipo === 'NFCE'"
                    @click="gerarNF"
                    color="btConfirma"
                    outlined
                  >
                    Tentar novamente
                  </v-btn>
                  <Button
                    v-if="slot.data.url && slot.data.status !== 'ERRO_AUTORIZACAO'"
                    icon="pi pi-print"
                    class="p-button-rounded p-button-outlined"
                    @click="openUrl(slot.data.url)"
                  />
                </template>
              </Column>
            </DataTable>
          </v-col>
        </v-row>

      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="showBtnNFCE && empresaAtual.emite_nfce" @click="gerarNF" color="btConfirma" text type="submit" > Gerar NFC-E </v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import Button from "primevue/button";
export default {
  components: {
    DataTable,
    Column,
    Button
  },
  props: {
    showDialog: {
      type: Boolean,
      required: true
    },
    idVenda: Number,
    idEmpresa: String,
    notas: Array,
    itensVenda: Array
  },
  watch: {
    notas: {
      deep: true,
      handler() {
        this.verificandoNFCE();
        this.getNotas();
      }
    }
  },
  computed: {
    ...mapState(["empresaAtual"]),
  },
  data() {
    return {
      carregando: false,
      showBtnNFCE: false,
      listaNotas: [],
    };
  },
  methods: {
    ...mapMutations([
      "openDialogMessage",
      "openDialogConfirm",
      "closeDialogConfirm"
    ]),
    openUrl(url) {
      const win = window.open(url, "_blank");
      if (win) {
        win.focus();
      } else {
        alert("Desabilite o bloqueio de pop-ups para abrir a nota");
      }
    },
    cancelar() {
      this.$emit("cancela-dialog");
    },
    verificandoNFCE() {
      const notas = this.notas.filter(item => item.tipo === "NFCE");
      const produtos = this.itensVenda.filter(item => item.produto !== undefined);

      this.showBtnNFCE = notas.length === 0 && produtos.length > 0;
    },
    getNotas() {
      this.listaNotas = this.notas;
    },

    gerarNF() {
      this.openDialogConfirm({
        title: "Atenção",
        text: "Deseja gerar NFC-e?",
        confirma: this.gerarNFCE,
        cancela: this.closeDialogConfirm
      });
    },

    async gerarNFCE() {
      this.closeDialogConfirm();

      await this.$axios
        .post(
          `/venda/${this.idVenda}/nfce`,
          {}
         )
        .then(() => {
          this.$emit("recarregar-notas");
        })
        .catch(() => {
          this.$emit("recarregar-notas");
        });
    },
  },
  mounted() {
    this.verificandoNFCE();
    this.getNotas();
  }
};
</script>

<style>

</style>
