<template>
  <v-dialog v-model="showDialog" dense persistent max-width="1200">
    <v-card>
      <div class="d-flex">
        <v-card-title>
          Editar dados fiscais
        </v-card-title>
        <v-spacer></v-spacer>
        <v-btn color="black" class="mt-2 mr-2" icon @click="cancelar">
          <v-icon size="30">mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-card outlined class="px-3 py-3">
          <form @submit.prevent="handleSave">

            <v-row class="mt-n5">
              <v-col cols="12" xl="3" lg="6" md="6" sm="6">
                <v-switch
                    color="green"
                    v-model="form.emite_nfse"
                    label="Emissão de NFS-e"
                >
                </v-switch>
              </v-col>
              <v-col
                  class="coluna-emissao-nfc"
                  cols="12"
                  xl="3"
                  lg="6"
                  md="6"
                  sm="6"
              >
                <v-switch
                    color="green"
                    v-model="form.emite_nfce"
                    label="Emissão de NFC-e"
                    @change="handleChangeNFC"
                >
                </v-switch>
              </v-col>
              <v-col
                  :class="$vuetify.breakpoint.lgAndDown ? 'mt-n10' : ''"
                  cols="12"
                  xl="3"
                  lg="6"
                  md="6"
                  sm="6"
              >
                <v-switch
                    color="green"
                    v-model="form.optante_simples_nacional"
                    label="Simples Nacional"
                >
                </v-switch>
              </v-col>
              <v-col
                  :class="$vuetify.breakpoint.lgAndDown ? 'mt-n10' : ''"
                  cols="12"
                  xl="3"
                  lg="6"
                  md="6"
                  sm="6"
              >
                <v-switch
                    color="green"
                    v-model="form.incentivador_cultural"
                    label="Incentivador Cultural"
                >
                </v-switch>
              </v-col>
            </v-row>

            <v-row class="mt-n3">
              <v-col v-if="showInputsNFC" cols="12" xl="4" lg="4" md="6">
                <div class="mt-n5">
                  <v-card-subtitle class="subtitle-text"
                  >Token NFC-e</v-card-subtitle
                  >
                  <v-card-text class="mt-n5">
                    <v-text-field
                        required
                        dense
                        v-model="form.id_token_nfce"
                        maxlength="8"
                    ></v-text-field>
                  </v-card-text>
                </div>
              </v-col>
              <v-col
                  v-if="showInputsNFC"
                  class="col-csc-ncf"
                  cols="12"
                  xl="6"
                  lg="6"
                  md="6"
              >
                <div class="mt-n5">
                  <v-card-subtitle class="subtitle-text"
                  >Código de Segurança NFC-e</v-card-subtitle
                  >
                  <v-card-text class="mt-n5">
                    <v-text-field
                        required
                        dense
                        v-model="form.csc_nfce"
                    ></v-text-field>
                  </v-card-text>
                </div>
              </v-col>
              <v-col cols="12" xl="4" lg="4" md="6">
                <div class="mt-n5">
                  <v-card-subtitle class="subtitle-text"
                  >Série RPS</v-card-subtitle
                  >
                  <v-card-text class="mt-n5">
                    <v-text-field
                        dense
                        v-model="form.serie_rps"
                    ></v-text-field>
                  </v-card-text>
                </div>
              </v-col>
              <v-col
                  class="col-regime-especial-tributacao"
                  cols="12"
                  xl="6"
                  lg="6"
                  md="6"
              >
                <div class="mt-n5">
                  <v-card-subtitle class="subtitle-text"
                  >Regime Especial Tributação</v-card-subtitle
                  >
                  <v-card-text class="mt-n5">
                    <v-select
                        :items="regime_especial_tributacao"
                        item-text="texto"
                        item-value="valor"
                        required
                        dense
                        v-model="form.regime_especial_tributacao"
                        clearable
                    ></v-select>
                  </v-card-text>
                </div>
              </v-col>
              <v-col cols="12" md="10">
                <div class="mt-n5">
                  <v-card-subtitle class="subtitle-text"
                  >Regime Tributário</v-card-subtitle
                  >
                  <v-card-text class="mt-n5">
                    <v-select
                        :items="regime_tributario"
                        required
                        dense
                        item-text="texto"
                        item-value="valor"
                        v-model="form.regime_tributario"
                        clearable
                    ></v-select>
                  </v-card-text>
                </div>
              </v-col>
              <v-col class="col-tributacao-rps" cols="12" md="10">
                <div class="mt-n5">
                  <v-card-subtitle class="subtitle-text"
                  >Tributação RPS</v-card-subtitle
                  >
                  <v-card-text class="mt-n5">
                    <v-select
                        :items="tributacao_rps"
                        item-text="texto"
                        item-value="valor"
                        required
                        dense
                        v-model="form.tributacao_rps"
                        type="number"
                        clearable
                    ></v-select>
                  </v-card-text>
                </div>
              </v-col>
              <v-col class="ml-4" cols="12" xl="4" lg="4" md="6">
                <v-text-field
                    dense
                    v-model="form.inscricao_municipal"
                    label="Inscrição Municipal"
                ></v-text-field>
              </v-col>
              <v-col cols="12" xl="4" lg="4" md="6">
                <v-text-field
                    dense
                    v-model="form.inscricao_estadual"
                    label="Inscrição Estadual"
                ></v-text-field>
              </v-col>

              <v-col v-if="empresa?.emite_nfse || empresa?.emite_nfce || form?.emite_nfse || form?.emite_nfce" cols="12" xl="8" lg="8" md="8" class="col-btn-certificado">
                <v-btn
                  color="blue"
                  outlined
                  @click="openDialogEnviarCertificado()"
                >
                  {{ empresa.validade_certificado ? 'Alterar certificado' : 'Enviar certificado' }}
                </v-btn>
              </v-col>

              <v-col cols="12" xl="4" lg="4" md="4">
                <v-btn
                  dark
                  small
                  color="primary"
                  type="submit"
                >
                  Salvar
                </v-btn>
              </v-col>

            </v-row>
          </form>
        </v-card>
      </v-card-text>
    </v-card>


    <DialogEnviarCertificado
      :showDialog="enviarCerficiadoDialog.showDialog"
      @cancela-dialog="enviarCerficiadoDialog.showDialog = false"
      :empresa="form"
      @close-dialog="enviarCerficiadoDialog.showDialog = false"
    />

  </v-dialog>
</template>

<script>

import {mapMutations, mapState} from "vuex";
import DialogEnviarCertificado from "@/components/Dialogs/DialogEnviarCertificado.vue";

export default {
  props: {
    showDialog: Boolean,
    empresa: Object,
    regime_tributario: Array,
    regime_especial_tributacao: Array,
    tributacao_rps: Array
  },
  components: {
    DialogEnviarCertificado
  },
  data() {
    return {
      form: {},
      showInputsNFC: false,
      showBtnCertificadoDigital: false,
      enviarCerficiadoDialog: {
        showDialog: false
      },
    }
  },
  computed: {
    ...mapState(["user", "appLoading", "config"]),
  },
  watch: {
    showDialog(newValue) {
      if (newValue) {
        this.getEmpresa();
      }
    }
  },
  methods: {
    ...mapMutations(["openDialogMessage"]),
    getEmpresaOld() {
      //this.form = this.empresa;
    },

    getEmpresa() {
      this.loadingSkeleton = true;
      this.$axios
          .get(`/empresa/${this.empresa.id}`)
          .then(response => {
            this.form = response.data;
          })
          .catch(error => {
            this.openDialogMessage({
              type: "error",
              title: "Erro ao consultar Empresa",
              text: error.response.data
            });

            this.$router.push({
              name: "Empresas"
            });
          });
    },

    cancelar() {
      this.$emit("cancela-dialog");
    },


    handleChangeNFC() {
      this.showInputsNFC = this.form.emite_nfce;
    },

   async openDialogEnviarCertificado() {
      await this.handleSave();
     this.enviarCerficiadoDialog = {
       showDialog: true
     };
    },

    limparObjeto(obj) {
      Object.keys(obj).forEach(key => {
        if (obj[key] === null || obj[key] === "" || obj[key] === undefined) {
          delete obj[key];
        } else if (typeof obj[key] === 'object' && !Array.isArray(obj[key]) && obj[key] !== null) {
          this.limparObjeto(obj[key]);
        }
      });
      return obj;
    },

    async handleSave() {
      if ((this.form.emite_nfce || this.form.emite_nfse) && !this.empresa.integrador_rps_id) {
        if (!this.empresa.validade_certificado) {
          this.openDialogMessage({
            type: "error",
            title: "Certificado obrigatório",
            text: "Para ativar a emissão de Nota Fiscal, é necessário enviar o certificado digital.",
          });
          return;
        }
      }

      const empresaObj = { ...this.form };

      empresaObj.marketplace = { id: this.config.id_marketplace };
      empresaObj.usuarios = [
        {
          id: this.user.id
        }
      ];

      const data = this.limparObjeto(empresaObj);

      await this.$axios
          .put("/empresa", data)
          .then(() => {
            this.$toast.add({
              severity: "success",
              summary: `Empresa Atualizada com sucesso!`,
              life: 2000
            });
            this.getEmpresa();
          })
          .catch(error => {
            this.openDialogMessage({
              type: "error",
              title: "Atualizar",
              text: error.response.data
            });
          });


      // empresaObj.cnpj = this.$masks.unMask(empresaObj.cnpj);
      // empresaObj.data_abertura = empresaObj.data_abertura
      //     .split("/")
      //     .reverse()
      //     .join("-");
      // empresaObj.faturamento_medio = this.parseToFloat(
      //     empresaObj.faturamento_medio
      // );
      //
      // empresaObj.marketplace = { id: this.config.id_marketplace };
      // empresaObj.usuarios = [
      //   {
      //     id: this.user.id
      //   }
      // ];
      //
      // const parts = empresaObj.cnae.split(" - ");
      // empresaObj.cnae = parts[0];
      //
      // if(empresaObj.validade_certificado) {
      //   empresaObj.validade_certificado = empresaObj.validade_certificado
      //       .split("/")
      //       .reverse()
      //       .join("-");
      // }
      //
      // await this.$axios
      //     .put("/empresa", empresaObj)
      //     .then(() => {
      //       this.$toast.add({
      //         severity: "success",
      //         summary: `Empresa Atualizada com sucesso!`,
      //         life: 2000
      //       });
      //       this.getEmpresa();
      //     })
      //     .catch(error => {
      //       this.openDialogMessage({
      //         type: "error",
      //         title: "Atualizar",
      //         text: error.response.data
      //       });
      //     });
    },
  }

};
</script>
