<template>
  <v-container fluid>
    <form @submit.prevent="getCobrancas(true)">
      <v-row>
        <div class="espacado">
          <Calendar
            v-model="dataIntervalo"
            selectionMode="range"
            @date-select="getCobrancas(true)"
            :showIcon="true"
            dateFormat="dd/mm/yy"
            :showButtonBar="true"
            placeholder="Filtro por data de criação"
            @clear-click="limparFiltro"
          />
        </div>
        <div class="espacado">
          <MultiSelect
            v-model="filtros.status"
            :options="status"
            @change="getCobrancas(true)"
            optionLabel="name"
            placeholder="Filtro por status"
            display="chip"
          />
        </div>
        <div class="espacado">
          <MultiSelect
            v-model="filtros.forma_pagamento"
            :options="formasPagamento"
            @change="getCobrancas(true)"
            optionLabel="name"
            placeholder="Filtro por forma"
            display="chip"
          />
        </div>
        <div class="espacado">
          <Chips
            ref="chipcomponente"
            v-model="filtros.cliente"
            separator=","
            @add="getCobrancas(true)"
            @remove="getCobrancas(true)"
            placeholder="Filtro por nome, telefone, email"
          />
        </div>
        <div class="espacado">
          <BotaoBuscarListas @buscar="buscar" />
        </div>

        <v-col class="text-end">
          <v-tooltip bottom v-if="user.permissoes.adm_cobranca">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                fab
                dark
                small
                color="primary"
                :to="{ name: 'CriaCobranca' }"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Criar</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </form>
    <v-row>
      <v-col>
        <v-skeleton-loader
            :loading="loadingSkeleton"
            type="table"
        >
          <v-data-table
              :fixed-header="true"
              :headers="headerTableContratos"
              :items="cobrancas"
              :options="{ itemsPerPage: pagination.limit }"
              hide-default-footer
          >
            <template #[`item.status`]="{ item }">
              <v-chip
                  class="white--text"
                  :color="STATUS_COBRANCA[item.status].cor"
                  :key="item.id"
              >
                {{ STATUS_COBRANCA[item.status].texto }}
              </v-chip>
            </template>

            <template #[`item.parcelas`]="{ item }">
              Total {{  (item.parcelas? item.parcelas : '1') }}
              Pago {{ item.qtd_parcelas_pago }}
              Pendente {{  item.qtd_parcelas_pendente }}
              Atrasadas {{ item.qtd_parcelas_atrasado }}
            </template>

            <template #[`item.data_criacao`]="{ item }">
              {{ item.data_criacao | formatDate({ dateStyle: "short" }) }}
            </template>
            <template #[`item.forma_pagamento`]="{ item }">
              {{ item.forma_pagamento ? OPCOES_PAGTO[item.forma_pagamento].texto : 'Não definida' }}
            </template>

            <template #[`item.valor_total`]="{ item }">
              {{ item.valor_total | formatMoney({ minimumFractionDigits: 2 }) }}
            </template>

            <template #[`item.total_pago`]="{ item }">
              {{ item.total_pago | formatMoney({ minimumFractionDigits: 2 }) }}
            </template>
            <template #[`item.total_atrasado`]="{ item }">
              {{ item.total_atrasado | formatMoney({ minimumFractionDigits: 2 }) }}
            </template>

            <template v-slot:[`item.enviar`]="{ item }">
              <v-btn type="button" @click="reenviar(item)" icon>
                <v-icon> mdi-send </v-icon>
              </v-btn>
            </template>

            <template v-slot:[`item.acoes`]="{ item }">
              <v-btn
                  type="button"
                  :to="{ name: 'VerCobranca', params: { id: item.id } }"
                  icon
              >
                <v-icon> mdi-magnify </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-skeleton-loader>

        <div class="text-end">
          <v-btn type="button" @click="exportar" icon>
            <v-icon> mdi-download </v-icon>
          </v-btn>
        </div>
        <v-pagination
          v-model="pagination.page"
          :length="totalPaginas"
          @input="next"
          :total-visible="10"
        ></v-pagination>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Chips from "primevue/chips";
import MultiSelect from "primevue/multiselect";
import { mapState, mapMutations } from "vuex";
import Calendar from "primevue/calendar";
import moment from "moment";
import BotaoBuscarListas from "@/components/BotaoBuscarListas.vue";

export default {
  name: "Cobrancas",
  components: {
    MultiSelect,
    Chips,
    Calendar,
    BotaoBuscarListas
  },
  data() {
    return {
      loadingSkeleton: false,
      filtros: {
        status: this.$store.state.filtrosCobrancas.status,
        cliente: this.$store.state.filtrosCobrancas.cliente,
        forma_pagamento: this.$store.state.filtrosCobrancas.forma_pagamento
      },
      status: [
        { name: "Ativo", code: "ATIVO" },
        { name: "Atrasado", code: "ATRASADO" },
        { name: "Quitado", code: "QUITADO" },
        { name: "Regular", code: "REGULAR" },
        { name: "Cancelado", code: "CANCELADO" },
        { name: "Expirado", code: "EXPIRADO" }
      ],
      formasPagamento: [
        {name:"Boleto", code:"BOLETO"},
        {name:"Cartão", code:"CARTAO"},
        {name:"Pix", code:"PIX"}
      ],
      cobrancas: [],
      pagination: {
        page: 1,
        total: 0,
        limit: 9,
        first: 0
      },
      headerTableContratos: [
        {
          text: "Número",
          value: "numero",
          align: "center"
        },
        {
          text: "Status",
          value: "status",
          align: "center"
        },
        {
          text: "Criação",
          value: "data_criacao",
          align: "center"
        },
        {
          text: "Cliente",
          value: "cliente.nome",
          align: "center"
        },
        {
          text: "Forma de pagamento",
          value: "forma_pagamento",
          align: "center"
        },
        {
          text: "Situação das parcelas",
          value: "parcelas",
          align: "center"
        },
        {
          text: "Total",
          value: "valor_total",
          align: "center"
        },
        {
          text: "Pago",
          value: "total_pago",
          align: "center"
        },
        {
          text: "Atrasado",
          value: "total_atrasado",
          align: "center"
        },
        {
          text: "Enviar e-mail",
          align: "center",
          value: "enviar"
        },
        {
          text: "Ver",
          align: "center",
          value: "acoes"
        }
      ],
      dataIntervalo: this.$store.state.filtrosCobrancas.dataIntervalo,
      dataInicio: new Date().toISOString().substr(0, 10),
      dataFim: new Date().toISOString().substr(0, 10),
      menuDataInicio: false,
      menuDataFim: false
    };
  },
  mounted() {
    this.getCobrancas();
  },
  computed: {
    ...mapState(["empresaAtual", "user"]),
    totalPaginas() {
      return Math.ceil(this.pagination.total / this.pagination.limit);
    }
  },
  methods: {
    ...mapMutations([
      "openDialogMessage",
      "openDialogConfirm",
      "closeDialogConfirm"
    ]),
    async getCobrancas(filtro = false) {

      this.loadingSkeleton = true;

      if(filtro) {
        this.pagination.first = 0;
        this.pagination.page = 1;
      }

      this.$store.commit("setFiltrosCobrancas", {
        status: this.filtros.status,
        cliente: this.filtros.cliente,
        forma_pagamento: this.filtros.forma_pagamento,
        dataIntervalo: this.dataIntervalo
      });

      if(!this.dataIntervalo[0] || !this.dataIntervalo[1]){
        return;
      }

      if (!this.dataIntervalo) {
        this.dataInicio = "";
        this.dataFim = "";
      }
      if (this.dataIntervalo?.[0]) {
        this.dataInicio = moment(this.dataIntervalo[0]).format("YYYY-MM-DD");
      }

      if (this.dataIntervalo?.[1]) {
        this.dataFim = moment(this.dataIntervalo[1]).format("YYYY-MM-DD");
      }

      if (!this.empresaAtual || !this.empresaAtual.id) return;

      this.$axios
        .get(`/cobranca/empresa/${this.empresaAtual.id}/listar`, {
          headers: {
            filtros: JSON.stringify({
              cliente: this.filtros.cliente,
              status: this.filtros.status.map(m => m.code),
              forma_pagamento: this.filtros.forma_pagamento.map(f => f.code)
            })
          },
          params: {
            "data-inicio": this.dataInicio,
            "data-fim": this.dataFim,
            first: this.pagination.first,
            limit: this.pagination.limit,
            ordem: "DESC"
          }
        })
        .then(response => {
          this.cobrancas = response.data.data;
          this.pagination.total = response.data.count;
        })
        .catch(error => {
          this.cobrancas = [];
          this.openDialogMessage({
            type: "error",
            title: "Erro ao buscar cobranças",
            text: error.response.data
          });
        })
        .finally(() => {
          this.loadingSkeleton = false;
        });
    },
    reenviar(obj) {
      this.$axios
        .post(`/cobranca/${obj.id}/reenviar-email`)
        .then(() => {
          this.openDialogMessage({
            type: "success",
            title: "Sucesso",
            text: "E-mail enviado!"
          });
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Criar cobranca",
            text: error.response.data
          });
        });
    },
    exportar() {

      if(!this.cobrancas){
        this.openDialogMessage({
          type: "error",
          title: "Atenção",
          text: "Nenhuma cobrança para exportação",
        });
      }

      this.$axios.get(`/cobranca/empresa/${this.empresaAtual.id}/exportar`,
          {
            params: {
              "data-inicio": this.dataInicio,
              "data-fim": this.dataFim,
              "ordem": 'ASC'
            },
          })
          .then((response) => {
            const dados = response.data;
            if (!dados || dados.length === 0) {
              this.openDialogMessage({
                type: "warning",
                title: "Nenhum dado encontrado",
                text: "Nenhuma cobrança foi encontrada no período selecionado.",
              });
              return;
            }

            const bom = "\uFEFF";
            const csvContent = bom + dados;

            const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `cobrancas_${this.dataInicio}_a_${this.dataFim}.csv`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
          .catch((error) => {
            this.openDialogMessage({
              type: "error",
              title: "Erro ao buscar cobranças",
              text: error.response?error.response.data:error.message,
            });
          });

      // this.$axios
      //   .get(`/cobranca/empresa/${this.empresaAtual.id}/listar`, {
      //     headers: {
      //       filtros: JSON.stringify({
      //         cliente: this.filtros.cliente,
      //         status: this.filtros.status.map(m => m.code)
      //       })
      //     }
      //   })
      //   .then(response => {
      //     console.log(response.data)
      //     const copy = response.data.data.map(item => {
      //       const obj = {};
      //       obj.empresa_cnpj = item.empresa.cnpj;
      //       obj.empresa_nome = item.empresa.nome_fantasia;
      //       obj.cliente_documento = item.cliente.cnpj
      //         ? item.cliente.cnpj
      //         : item.cliente.cpf;
      //       obj.cliente_nome = item.cliente.nome;
      //       obj.cliente_telefone = item.cliente.telefone_celular;
      //       obj.cliente_email = item.cliente.email;
      //       obj.cliente_cep = item.cliente.cep;
      //       obj.cobranca_numero = item.numero;
      //       obj.cobranca_status = item.status;
      //       obj.cobranca_data_criacao = item.data_criacao;
      //       obj.cobranca_data_atualizacao = item.data_atualizacao;
      //       obj.cobranca_data_limite_pagamento = item.data_limite_pagamento;
      //       obj.cobranca_valor_total = item.valor_total;
      //       obj.cobranca_forma_pagamento = item.forma_pagamento;
      //       obj.cobranca_emitir_nfse = item.emitir_nfse ? "SIM" : "Não";
      //       obj.cobranca_opcoes_pagamento = item.opcoes_pagamento;
      //       obj.cobranca_total_pago = item.total_pago;
      //       obj.cobranca_total_atrasado = item.total_atrasado;
      //       obj.cobranca_qtd_parcelas_pago = item.qtd_parcelas_pago;
      //       obj.cobranca_qtd_parcelas_pendente = item.qtd_parcelas_pendente;
      //       obj.cobranca_qtd_parcelas_atrasado = item.qtd_parcelas_atrasado;
      //       obj.cobranca_data_primeiro_boleto_vencido = item.data_primeiro_boleto_vencido;
      //       return obj;
      //     });
      //
      //     this.toCSVeDownload("cobrancas.csv", copy);
      //   })
      //   .catch(error => {
      //     this.openDialogMessage({
      //       type: "error",
      //       title: "Erro ao buscar cobranças",
      //       text: error.response.data
      //     });
      //   });
    },
    next(page) {
      this.pagination.page = page;
      this.pagination.first = (page - 1) * this.pagination.limit;
      this.getCobrancas();
    },
    limparFiltro() {
      this.getCobrancas(true);
    },
    buscar() {
      const chip = this.$refs.chipcomponente;
      const newValue = chip.$refs.input.value;

      if(newValue) {
        this.filtros.cliente.push(newValue);
        chip.$refs.input.value = "";
        chip.$emit("add", newValue);
      } else {
        chip.$emit("add");
      }
    },
  }
};
</script>

<style scoped></style>
