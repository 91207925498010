<template>
    <v-container v-if="transacao?.id">
        <v-row style="margin-top: 1rem;">
            <v-col>
                <Button icon="pi pi-save" label="Download"
                                @click="generateReport"
                                class="p-button-outlined" />
            </v-col>
            <v-col>
                <v-alert  dense text type="error" v-if="erro">
                    {{ erro }}
                </v-alert>
            </v-col>
        </v-row>

        <vue-html2pdf
            :show-layout="false"
            :float-layout="false"
            :enable-download="true"
            :preview-modal="true"
            :paginate-elements-by-height="1400"
            :filename="'Boleto_'+transacao?.id"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            pdf-orientation="portrait"
            pdf-content-width="800px"
            ref="html2Pdf">

            <section slot="pdf-content">
                <div id="pdf" class="folha">
                    <v-row>
                        <v-col>
                            <div class="logo">
                                <img :src="getLogoImage" alt="Logo" width="200"/>
                            </div>
                            <div class="dados-empresa">
                                <p v-if="transacao?.empresa?.razao_social">{{transacao?.empresa?.razao_social}}</p>
                              <p v-if="transacao?.empresa?.nome">{{transacao?.empresa?.nome}}</p>
                                <p>{{transacao?.empresa?.nome_fantasia}}</p>
                                <p v-if="transacao?.empresa?.cnpj">{{$masks.cnpj(transacao?.empresa?.cnpj)}}</p>
                                <p v-if="transacao?.empresa?.cpf">{{$masks.cpf(transacao?.empresa?.cpf)}}</p>
                                <p>{{transacao?.empresa?.email}}</p>
                                <p>{{$masks.telefone(transacao?.empresa?.telefone)}}</p>
                            </div>
                        </v-col>
                        <v-col cols="3" class="text-center" style="text-align: center;">
                            <VueQr :text="transacao?.qrcode" :size="150"/>
                            <p>Pague com PIX</p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <span class="corte">Recibo do Pagador</span>
                            <v-row>
                                <v-col cols="3">
                                    <img :src="'/images/bb.png'"  class="logo-bb"/>
                                </v-col>
                                <v-col class="text-center border-left border-right" style="text-align: center;">
                                    <p class="cabecalho">001-9</p>
                                </v-col>
                                <v-col class="text-center">
                                    <p class="cabecalho">{{transacao?.linha_digitavel_boleto}}</p>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="border-full">
                                    <span>Nome do Pagador CPF/CNPJ Endereço</span>
                                    <p>{{transacao?.cliente?.tipo == 'PESSOA_JURIDICA'?transacao?.cliente?.razao_social:transacao?.cliente?.nome}} {{$masks.cpf(transacao?.cliente?.cpf)}}{{transacao?.cliente?.cnpj}}</p>
                                    <span>Sacador / Avalista</span>
                                    <p style="font-size: 0.7rem">SISTEMATIZE TECNOLOGIA DA INFORMAÇÃO LTDA 31.924.660/0001-92</p>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="border-left border-right">
                                    <span>Nosso-Número</span>
                                    <p>{{transacao?.nsu}}</p>
                                </v-col>
                                <v-col class="border-right">
                                    <span>N° Documento</span>
                                    <p>{{transacao?.id}}</p>
                                </v-col>
                                <v-col class="border-right">
                                    <span>Data Vencimento</span>
                                    <p>{{transacao?.vencimento_boleto | formatDate({ dateStyle: "short" })}}</p>
                                </v-col>
                                <v-col class="border-right">
                                    <span>Valor do Documento</span>
                                    <p>R$ {{transacao?.valor | formatMoney({ minimumFractionDigits: 2 })}}</p>
                                </v-col>
                                <v-col class="border-right" >
                                    <span>(=) Valor Pago</span>
                                    <p>R$  </p>
                                </v-col>

                            </v-row>
                            <v-row>
                                <v-col class="border-full">
                                    <span>Nome do Beneficiário CPF/CNPJ Endereço</span>
                                    <p v-if="transacao?.empresa?.razao_social">{{transacao?.empresa?.razao_social}} {{$masks.cnpj(transacao?.empresa?.cnpj)}}</p>
                                    <p v-if="transacao?.empresa?.nome">{{transacao?.empresa?.nome}} {{$masks.cpf(transacao?.empresa?.cpf)}}</p>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="border-top" style="vertical-align: top;">
                                    <span>Agência/Código do Beneficiário</span>
                                </v-col>
                                <v-col cols="5" class="border-top" style="vertical-align: top;">
                                    <span>Autenticação Mecânica</span>
                                </v-col>

                            </v-row>
                            <v-row>
                                <v-col>
                                    <span class="corte"></span>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="3">
                                    <img :src="'/images/bb.png'"  class="logo-bb"/>
                                </v-col>
                                <v-col class="text-center border-left border-right" style="text-align: center;">
                                    <p class="cabecalho">001-9</p>
                                </v-col>
                                <v-col class="text-center">
                                    <p class="cabecalho">{{transacao?.linha_digitavel_boleto}}</p>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col class="border-top border-left border-right">
                                    <span>Local de Pagamento</span>
                                    <p>Pagável em qualquer banco</p>
                                </v-col>
                                <v-col cols="3" class="border-top border-right">
                                    <span>Data de Vencimento</span>
                                    <p>{{transacao?.vencimento_boleto | formatDate({ dateStyle: "short" })}}</p>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="border-top border-left border-right">
                                    <span>Nome do Beneficiário CPF/CNPJ Endereço</span>
                                    <p v-if="transacao?.empresa?.razao_social">{{transacao?.empresa?.razao_social}} {{$masks.cnpj(transacao?.empresa?.cnpj)}}</p>
                                    <p v-if="transacao?.empresa?.nome">{{transacao?.empresa?.nome}} {{$masks.cpf(transacao?.empresa?.cpf)}}</p>
                                </v-col>
                                <v-col cols="3" class="border-top border-right">
                                    <span>Agência/Código do Beneficiário</span>
                                    <p>3517828</p>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="2" class="border-top border-left border-right">
                                    <span>Data do Documento</span>
                                    <p>{{transacao?.data_criacao | formatDate({ dateStyle: "short" })}}</p>
                                </v-col>
                                <v-col cols="2" class="border-top border-right">
                                    <span>N° do Documento</span>
                                    <p>{{transacao?.id}}</p>
                                </v-col>
                                <v-col class="border-top border-right">
                                    <span>Especie DOC</span>
                                    <p>DM</p>
                                </v-col>
                                <v-col class="border-top border-right">
                                    <span>Aceite</span>
                                    <p>N</p>
                                </v-col>
                                <v-col class="border-top border-right">
                                    <span>Proessamento</span>
                                    <p>{{transacao?.data_criacao | formatDate({ dateStyle: "short" })}}</p>
                                </v-col>
                                <v-col cols="3" class="border-top border-right">
                                    <span>Nosso-Número</span>
                                    <p>{{transacao?.nsu}}</p>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="2" class="border-top border-left border-right" style="vertical-align: top;">
                                    <span>Uso do Banco</span>
                                </v-col>
                                <v-col cols="2" class="border-top border-right" style="vertical-align: top;">
                                    <span>Carteira</span>
                                    <p>17/019</p>
                                </v-col>
                                <v-col class="border-top border-right">
                                    <span>Especie</span>
                                    <p>R$</p>
                                </v-col>
                                <v-col class="border-top border-right" style="vertical-align: top;">
                                    <span>Quantidade</span>
                                </v-col>
                                <v-col class="border-top border-right" style="vertical-align: top;">
                                    <span>xValor</span>
                                </v-col>
                                <v-col cols="3" class="border-top border-right">
                                    <span>(=) Valor do Documento</span>
                                    <p>R$ {{transacao?.valor | formatMoney({ minimumFractionDigits: 2 })}}</p>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="border-top border-left border-right">
                                    <span>Informações de Responsabilidade do beneficiário</span>
                                    <p>{{transacao?.descricao}}</p>
                                </v-col>
                                <v-col cols="3" class="border-top border-right">
                                    <span>(-) Desconto/Abatimento</span>
                                    <p>R$ {{0.00 | formatMoney({ minimumFractionDigits: 2 })}}</p>
                                    <span>(+) Juros/Multa</span>
                                    <p>R$ {{ jurosMulta | formatMoney({ minimumFractionDigits: 2 })}}</p>
                                    <span>(=) Valor cobrado </span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="border-full">
                                    <span>Nome do Pagador CPF/CNPJ Endereço</span>
                                    <p>{{transacao?.cliente?.tipo == 'PESSOA_JURIDICA'?transacao?.cliente?.razao_social:transacao?.cliente?.nome}} {{$masks.cpf(transacao?.cliente?.cpf)}}{{transacao?.cliente?.cnpj}}</p>
                                    <span>Sacador / Avalista</span>
                                    <p style="font-size: 0.7rem">SISTEMATIZE TECNOLOGIA DA INFORMAÇÃO LTDA 31.924.660/0001-92</p>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="border-top" style="vertical-align: top;" >
                                    <barcode :value="transacao?.barcode_boleto"
                                            width="1" height="60"
                                            format="ITF" display-value="false">
                                        {{transacao?.barcode_boleto}}
                                    </barcode>
                                </v-col>
                                <v-col class="border-top" style="vertical-align: top;">
                                    <span>Autenticação Mecânica</span>
                                </v-col>
                            </v-row>

                        </v-col>
                    </v-row>
                </div>
            </section>

        </vue-html2pdf>

    </v-container>
</template>

<script>
import { mapState } from "vuex";
import VueQr from "vue-qr";
import VueBarcode from 'vue-barcode';
import Button from 'primevue/button';
import VueHtml2pdf from 'vue-html2pdf'

export default {

name: "Boleto",
  props: {
    hash: String
  },
  components: {
    VueQr,
    'barcode':VueBarcode,
    Button,
    VueHtml2pdf
  },
  data(){
    return {
        transacao:{},
        jurosMulta: 0.00,
        erro: null
    }
  },
  computed:{
    ...mapState(["config"]),
    getLogoImage() {
      if (!this.config.images.logo) return;
      return this.config.images.logo;
    },

  },
  async mounted(){
   await this.getTransacao()
   await this.calculaJurosMulta()
  },
  methods:{
    async calculaJurosMulta(){
        await this.$axios
        .get(`/public/boleto/${this.hash}/calcula-juros-multa`, { data: { skipAuth: true } })
        .then((response) => {
            this.jurosMulta = response.data.jurosMulta;
        })
        .catch((error) => {
            this.erro = `Falha ao calcular juros: ${error.message}`;
        });
    },
    async getTransacao() {
      await this.$axios
        .get(`/public/boleto/${this.hash}`, { data: { skipAuth: true } })
        .then((response) => {
          this.transacao = response.data;
        })
        .catch((error) => {
            this.$router.push({ name: "TemaNaoConfigurado" });
        });
    },
    generateReport () {
        this.$refs.html2Pdf.generatePdf()
    }
  }

}
</script>

<style scoped>
    .container {
        width: unset;
        max-width: none;
        font-family: 'Arial';
        font-size: 0.8rem;
    }
    .folha{
        width: 42rem;
        margin: 2rem;
        background: #fff;
    }
    .logo {
        width: 200px;
        height: auto;
    }
    .logo img {
        width: 100%;
        height: auto;
    }
    .dados-empresa p{
        margin-bottom: 0;
        text-align: start;
    }
    .logo-bb{
        width: 160px;
    }
    .cabecalho{
        font-size: 1rem;
        font-weight: bold;
        margin: 0.5rem;
    }
    .v-application p{
        margin: 0;
    }
    .col {
        padding: 0 0.4rem 0.2rem 0.4rem;
    }
    span{
        font-size: 0.6rem;
        font-weight: bold;
    }

    .corte{
        width: 100%;
        display: inline-block;
        font-size: 0.6rem;
        border-bottom: 1px dashed black;
        margin-bottom: 0.7rem;
        margin-top: 0.7rem;
    }
    .border-full {
        border: 1px solid #000000;
    }
    .border-right {
        border-right: 1px solid #000000;
    }
    .border-left {
        border-left: 1px solid #000000;
    }

    .border-top {
        border-top: 1px solid #000000;
    }

</style>
