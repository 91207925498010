<template>
  <div class="mt-n2">
    <v-row v-if="vendaCancelada" class="mx-auto mb-n9">
      <v-col>
        <v-alert text outlined dense type="error">
          Venda cancelada.
        </v-alert>
      </v-col>
    </v-row>

    <v-row class="no-gutters flex-wrap">
      <v-col cols="6" class="d-flex">
       <v-row>
         <v-col cols="12">
           <v-card elevation="0" outlined class="py-0 px-0 billing-container flex-grow-1">
             <span class="billing-title text-caption">Empresa</span>
             <v-row dense class="mt-3 mb-3 mr-3 ml-3">
               <v-col class="d-flex">
                 <AutoComplete
                     :disabled="vendaCancelada || venda.pago"
                     class="autocomplete-input"
                     v-model="venda.empresa"
                     :suggestions="empresasFiltradas"
                     @complete="searchEmpresa"
                     forceSelection
                     :field="itemTextEmpresa"
                     placeholder="Pesquise aqui..."
                 >
                   <template #item="slotProps">
                     <div>
                       <div>{{ slotProps.item.nome }}</div>
                       <div>{{ slotProps.item.razao_social }}</div>
                       <div>
                         CPF/CNPJ: {{ $masks.cpf(slotProps.item.cpf) }}
                         {{ $masks.cnpj(slotProps.item.cnpj) }} Tel:
                         {{
                           $masks.telefone(
                               slotProps.item.telefone_celular
                           )
                         }}
                         E-mail: {{ slotProps.item.email }}
                       </div>
                     </div>
                   </template>
                 </AutoComplete>
                 <div v-if="venda.empresa && !vendaCancelada && !venda.pago" class="div-clearable" @click="venda.empresa = null">
                   <v-icon>mdi-close</v-icon>
                 </div>
               </v-col>
             </v-row>
           </v-card>
         </v-col>
         <v-col class="mt-n13" cols="12">
           <v-card elevation="0" outlined class="py-0 px-0 billing-container flex-grow-1">
             <span class="billing-title text-caption">Prestador</span>
             <v-row dense class="mt-3 mb-3 mr-3 ml-3">
               <v-col class="d-flex">
                 <AutoComplete
                     :disabled="vendaCancelada || venda.pago"
                     class="autocomplete-input"
                     v-model="venda.prestador"
                     :suggestions="empresasFiltradas"
                     @complete="searchEmpresa"
                     forceSelection
                     :field="itemTextEmpresa"
                     placeholder="Pesquise aqui..."
                 >
                   <template #item="slotProps">
                     <div>
                       <div>{{ slotProps.item.nome }}</div>
                       <div>{{ slotProps.item.razao_social }}</div>
                       <div>
                         CPF/CNPJ: {{ $masks.cpf(slotProps.item.cpf) }}
                         {{ $masks.cnpj(slotProps.item.cnpj) }} Tel:
                         {{
                           $masks.telefone(
                               slotProps.item.telefone_celular
                           )
                         }}
                         E-mail: {{ slotProps.item.email }}
                       </div>
                     </div>
                   </template>
                 </AutoComplete>
                 <div v-if="venda.prestador && !vendaCancelada && !venda.pago" class="div-clearable" @click="venda.prestador = null">
                   <v-icon>mdi-close</v-icon>
                 </div>
               </v-col>
             </v-row>
           </v-card>
         </v-col>
       </v-row>
      </v-col>

      <v-col cols="6" class="d-flex">
        <v-card elevation="0" outlined class="py-0 px-0 billing-container flex-grow-1">
          <span class="billing-title text-caption">Último adicionado</span>
          <v-row class="mt-2" dense>

            <v-col class="d-flex justify-center">
              <v-card v-if="ultimoItemAdicionado && venda.itens.length > 0" elevation="0" width="95%">


                <div v-if="ultimoItemAdicionado.produto">
                  <v-row class="mx-auto">
                    <v-col cols="3">
                      <v-card elevation="0" outlined class="mt-1 mb-3 py-3">
                        <v-img
                            :src="ultimoItemAdicionado.produto.foto_url ? ultimoItemAdicionado.produto.foto_url : require(`@/assets/img/produto-sem-foto.png`)"
                            contain
                            height="110px"
                        ></v-img>
                      </v-card>
                    </v-col>
                    <v-col cols="9">
                      <v-row class="mt-n5 ml-n8">
                        <v-col cols="12">
                          <v-card-title>{{ ultimoItemAdicionado.produto.nome }}</v-card-title>
                          <v-card-subtitle class="text-caption truncate">{{ ultimoItemAdicionado.produto.descricao }}</v-card-subtitle>
                        </v-col>
                      </v-row>
                      <v-row dense class="mt-n10">
                        <v-col class="text-right mr-7" style="margin-top: 1rem;">
                          <span class="text-caption preco-item">Valor un.</span><br/>
                          <span class="text-caption preco-item" style="display:inline;">R$</span>
                          <span class="text-h3 preco-item">{{ultimoItemAdicionado.produto.valor | formatMoney({ minimumFractionDigits: 2 }) }}</span>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </div>

                <div v-if="ultimoItemAdicionado.servico">
                  <v-row class="mx-auto">
                    <v-col cols="3">
                      <v-card elevation="0" outlined class="mt-1 mb-3 py-3">
                        <v-img
                            :src="ultimoItemAdicionado.servico.foto_url ? ultimoItemAdicionado.servico.foto_url : require(`@/assets/img/produto-sem-foto.png`)"
                            contain
                            height="110px"
                        ></v-img>
                      </v-card>
                    </v-col>
                    <v-col cols="9">
                      <v-row class="mt-n5 ml-n8">
                        <v-col cols="12">
                          <v-card-title>{{ ultimoItemAdicionado.servico.nome }}</v-card-title>
                          <v-card-subtitle class="text-caption truncate">{{ ultimoItemAdicionado.servico.descricao }}</v-card-subtitle>
                        </v-col>
                      </v-row>
                      <v-row dense class="mt-n10">
                        <v-col class="text-right mr-7" style="margin-top: 1rem;">
                          <span class="text-caption preco-item">Valor un.</span><br/>
                          <span class="text-caption preco-item" style="display:inline;">R$</span>
                          <span class="text-h3 preco-item">{{ultimoItemAdicionado.servico.valor | formatMoney({ minimumFractionDigits: 2 }) }}</span>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </div>


              </v-card>
              <v-card-title class="text-caption" v-else>
                Nennhum Item adicionado.
              </v-card-title>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="no-gutters flex-wrap mt-6">
      <v-col cols="6" class="d-flex">
        <v-row>
          <v-col class="mt-n13" cols="12">
            <v-card elevation="0" outlined class="py-0 px-0 billing-container flex-grow-1">
              <span class="billing-title text-caption">Cliente</span>
              <v-row dense class="mt-3 mb-3 mr-3 ml-3">
                <v-col class="d-flex">
                  <AutoComplete
                      :disabled="vendaCancelada || venda.pago"
                      class="autocomplete-input"
                      v-model="venda.cliente"
                      :suggestions="clientesFiltrados"
                      @complete="searchCliente"
                      forceSelection
                      :field="itemTextCliente"
                      placeholder="Pesquise aqui..."
                  >
                    <template #item="slotProps">
                      <div>
                        <div>{{ slotProps.item.nome }}</div>
                        <div>{{ slotProps.item.razao_social }}</div>
                        <div>
                          CPF/CNPJ: {{ $masks.cpf(slotProps.item.cpf) }}
                          {{ $masks.cnpj(slotProps.item.cnpj) }} Tel:
                          {{
                            $masks.telefone(
                                slotProps.item.telefone_celular
                            )
                          }}
                          E-mail: {{ slotProps.item.email }}
                        </div>
                      </div>
                    </template>
                  </AutoComplete>
                  <div v-if="venda.cliente && !vendaCancelada && !venda.pago" class="div-clearable" @click="venda.cliente = null">
                    <v-icon>mdi-close</v-icon>
                  </div>
                  <v-tooltip v-if="!vendaCancelada && !venda.pago" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          v-bind="attrs"
                          v-on="on"
                          fab
                          dark
                          x-small
                          color="primary"
                          :class="venda.cliente ? 'ml-10' : 'ml-3'"
                          class="mt-1"
                          @click="venda.cliente && venda.cliente.id ? openDialogCliente('edit', venda.cliente) : openDialogCliente('create')"
                      >
                        <v-icon v-if="venda.cliente && venda.cliente.id">mdi-pencil</v-icon>
                        <v-icon v-else>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <span v-if="venda.cliente && venda.cliente.id">Editar</span>
                    <span v-else>Criar</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col class="mt-n13" cols="12">
            <v-card height="180" elevation="0" outlined class="py-0 px-0 billing-container">
              <span class="billing-title text-caption">Pesquisar</span>
              <v-row dense class="mt-3">
                <v-col>
                  <v-tabs v-model="tab" align-with-title>
                    <v-tabs-slider color="secondary"></v-tabs-slider>
                    <v-tab>
                      <span class="text-caption">Produtos</span>
                    </v-tab>
                    <v-tab>
                      <span class="text-caption">Serviços</span>
                    </v-tab>
                  </v-tabs>
                </v-col>
              </v-row>
              <v-row dense class="mt-3 mb-3 mr-3 ml-3">
                <v-col class="d-flex">
                  <AutoComplete
                      :disabled="vendaCancelada || venda.pago"
                      class="autocomplete-input"
                      v-model="inputPesquisar"
                      :suggestions="produtosServicosFiltrados"
                      @complete="searchProdutosServicos"
                      forceSelection
                      :field="itemTextProdutoServico"
                      placeholder="Pesquise aqui..."
                  >
                    <template #item="slotProps">
                      <div @click="onSelect(slotProps.item)">
                        <div>{{ slotProps.item.nome }}</div>
                        <div>
                          R$
                          {{
                            slotProps.item.valor
                                | formatMoney({ minimumFractionDigits: 2 })
                          }}
                        </div>
                      </div>
                    </template>
                  </AutoComplete>
                  <div v-if="inputPesquisar" class="div-clearable" @click="inputPesquisar = null">
                    <v-icon>mdi-close</v-icon>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col class="mt-n13" cols="12">
            <v-card elevation="0" outlined class="py-0 px-0 billing-container">
              <span class="billing-title text-caption">Taxas</span>
              <v-row dense class="mb-n6 ml-15">
                <v-col cols="4">
                  <div style="width: 70%">
                    <v-card-subtitle class="subtitle-text">Entrega</v-card-subtitle>
                    <v-card-text class="mt-n5">
                      <v-text-field
                          v-model="venda.taxa_entrega"
                          outlined
                          dense
                          @keyup="venda.taxa_entrega = $masks.dinheiro(venda.taxa_entrega)"
                      ></v-text-field>
                    </v-card-text>
                  </div>
                </v-col>
                <v-col cols="4">
                  <div style="width: 70%">
                    <v-card-subtitle class="subtitle-text">Prestador</v-card-subtitle>
                    <v-card-text class="mt-n5">
                      <v-text-field
                          v-model="venda.adicional_prestador"
                          outlined
                          dense
                          @keyup="venda.adicional_prestador = $masks.dinheiro(venda.adicional_prestador)"
                      ></v-text-field>
                    </v-card-text>
                  </div>
                </v-col>
                <v-col cols="4">
                  <div style="width: 70%">
                    <v-card-subtitle class="subtitle-text">Transação</v-card-subtitle>
                    <v-card-text class="mt-n5">
                      <v-text-field
                          v-model="venda.totalPagoTaxa"
                          outlined
                          dense
                          @keyup="venda.totalPagoTaxa = $masks.dinheiro(venda.totalPagoTaxa)"
                          disabled
                      ></v-text-field>
                    </v-card-text>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col class="mt-n13" cols="12">
            <v-card height="120" :disabled="vendaCancelada" elevation="0" outlined class="py-0 px-0 billing-container">
              <span class="billing-title text-caption">Funções</span>
              <v-row class="d-flex justify-space-around mx-auto mt-8 mb-3" dense>

                <v-col :cols="mode !== 'create' ? 1 : 2">
                  <v-card :disabled="venda.pago" elevation="0" color="#64DD17" class="function-buttons" @click="openDialogFormadePagamento">
                    <v-icon dark color="white" size="25" class="d-flex justify-center">mdi-cash</v-icon>
                    <span class="text-caption white--text d-flex justify-center">Pagar</span>
                  </v-card>
                </v-col>

                <v-col v-if="mode !== 'create'" cols="1">
                  <v-card :disabled="venda.pago" elevation="0" color="red" class="function-buttons" @click="openCancelarVenda">
                    <v-icon dark color="white" size="25" class="d-flex justify-center">mdi-close</v-icon>
                    <span class="text-caption white--text d-flex justify-center">Cancelar</span>
                  </v-card>
                </v-col>

                <v-col v-if="mode !== 'create'" cols="1">
                  <v-card :disabled="vendaCancelada" elevation="0" color="teal" class="function-buttons" @click="openNotaFiscal">
                    <v-icon dark color="white" size="25" class="d-flex justify-center">mdi-note</v-icon>
                    <span class="text-caption white--text d-flex justify-center">Nota Fiscal</span>
                  </v-card>
                </v-col>

                <v-col :cols="mode !== 'create' ? 1 : 2">
                  <v-card :disabled="venda.pago" elevation="0" color="#EC407A" class="function-buttons" @click="handleSave">
                    <v-icon dark color="white" size="25" class="d-flex justify-center">mdi-download</v-icon>
                    <span class="text-caption white--text d-flex justify-center">Salvar</span>
                  </v-card>
                </v-col>

                <v-col :cols="mode !== 'create' ? 1 : 2">
                  <v-card elevation="0" color="primary" class="function-buttons" :to="{ name: 'VendasPdv' }">
                    <v-icon dark color="white" size="25" class="d-flex justify-center">mdi-export</v-icon>
                    <span class="text-caption white--text d-flex justify-center">Fechar</span>
                  </v-card>
                </v-col>

                <!--            <v-col cols="1">-->
                <!--              <v-card elevation="0" color="#BA68C8" class="function-buttons">-->
                <!--                <v-icon dark color="white" size="25" class="d-flex justify-center">mdi-printer</v-icon>-->
                <!--                <span class="text-caption white&#45;&#45;text d-flex justify-center">Imprimir</span>-->
                <!--              </v-card>-->
                <!--            </v-col>-->

              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="6" class="d-flex">
        <v-card height="515" elevation="0" outlined class="py-0 px-0 billing-container flex-grow-1 mt-n8">
          <span class="billing-title text-caption">Itens</span>
          <v-row dense>
            <v-col class="d-flex justify-center">
              <v-card class="mt-3 mb-3" elevation="0" width="95%">
                <div class="data-table-container">
                  <div v-if="mobileView">
                    <div v-for="(item, index) in venda.itens" :key="index">
                      <v-card elevation="0">
                        <v-card-text class="d-flex card-itens">
                          <div class="div-tabela-item">
                            <span><b>Item</b></span><br/>
                            <span v-if="item.produto">
                          {{ item.produto.nome }}
                        </span>
                            <span v-if="item.servico">
                          {{ item.servico.nome }}
                        </span>
                          </div>
                          <div class="ml-5 div-tabela-remover">
                            <span><b>Remover</b></span><br/>
                            <span>
                            <Button
                                :disabled="vendaCancelada || venda.pago"
                                style="width: 30px; height: 30px"
                                icon="pi pi-trash"
                                class="p-button-rounded p-button-outlined"
                                @click="() => removeItem(item)"
                            />
                        </span>
                          </div>
                        </v-card-text>
                        <v-card-text class="d-flex card-itens mt-n7">
                          <div class="div-tabela-quantidade">
                            <span><b>Valor unitário</b></span><br/>
                            <span>
                            <InputNumber
                                :disabled="vendaCancelada || venda.pago || !user.permissoes.alterar_valor_item"
                                class="inputs-itens-tabela"
                                v-model="item.valor"
                                :min="0"
                                mode="currency"
                                currency="BRL"
                                locale="pt-BR"
                                :minFractionDigits="2"
                                :maxFracionDigits="2"
                            />
                          </span>
                          </div>
                          <div class="ml-5 div-tabela-quantidade">
                            <span><b>Quantidade</b></span><br/>
                            <span>
                            <InputNumber
                                :disabled="vendaCancelada|| venda.pago"
                                class="inputs-itens-tabela"
                                mode="decimal"
                                :min="1"
                                v-model="item.quantidade"
                                showButtons
                                buttonLayout="horizontal"
                                :step="1"
                                decrementButtonClass="p-button-danger"
                                incrementButtonClass="p-button-success"
                                incrementButtonIcon="pi pi-plus"
                                decrementButtonIcon="pi pi-minus"
                                inputClass="text-center"
                            />
                          </span>
                          </div>
                          <div class="ml-5 div-tabela-total">
                            <span><b>Total</b></span><br/>
                            <span class="valor-total">R${{(item.quantidade * item.valor)| formatMoney({ minimumFractionDigits: 2 }) }}</span>
                          </div>
                        </v-card-text>
                      </v-card>
                      <v-divider class="mt-8" ></v-divider>
                    </div>
                  </div>
                  <DataTable
                      v-else
                      :value="venda.itens"
                      responsiveLayout="stack"
                      breakpoint="660px"
                  >
                    <Column header="Quantidade" :styles="{ width: '9rem' }">
                      <template #body="slot">
                        <InputNumber
                            :disabled="vendaCancelada|| venda.pago"
                            mode="decimal"
                            :min="1"
                            v-model="slot.data.quantidade"
                            showButtons
                            buttonLayout="horizontal"
                            :step="1"
                            decrementButtonClass="p-button-danger"
                            incrementButtonClass="p-button-success"
                            incrementButtonIcon="pi pi-plus"
                            decrementButtonIcon="pi pi-minus"
                            inputClass="text-center"
                        />
                      </template>
                    </Column>

                    <Column header="Item">
                      <template #body="slot">
                        <div v-if="slot.data.servico">
                          <span>{{ slot.data.servico.nome }}</span><br/>
                          <span v-if="slot.data.servico.descricao" class="text-caption">
                            <span v-if="slot.data.servico.descricao. length > 30">
                              {{ slot.data.servico.descricao.slice(0, 30) }}...
                            </span>
                            <span v-else>
                              {{ slot.data.servico.descricao }}
                            </span>
                          </span>
                        </div>
                        <div v-if="slot.data.produto">
                          <span>{{ slot.data.produto.nome }}</span><br/>
                          <span v-if="slot.data.produto.descricao" class="text-caption">
                            <span v-if="slot.data.produto.descricao.length > 30">
                              {{ slot.data.produto.descricao.slice(0, 30) }}...
                            </span>
                            <span v-else>
                              {{ slot.data.produto.descricao }}
                            </span>
                          </span>
                        </div>
                      </template>
                    </Column>

                    <Column
                        header="Valor unitário"
                        :styles="{ width: '9rem' }"
                    >
                      <template #body="slot">
                        <InputNumber
                            :disabled="vendaCancelada || venda.pago || !user.permissoes.alterar_valor_item"
                            v-model="slot.data.valor"
                            :min="0"
                            mode="currency"
                            currency="BRL"
                            locale="pt-BR"
                            :minFractionDigits="2"
                            :maxFracionDigits="2"
                        />
                      </template>
                    </Column>

                    <Column header="Total" :styles="{ width: '9rem' }">
                      <template #body="slot">
                        <p>
                          R$
                          {{
                            (slot.data.quantidade * slot.data.valor)
                                | formatMoney({ minimumFractionDigits: 2 })
                          }}
                        </p>
                      </template>
                    </Column>

                    <Column header="Remover" :styles="{ width: '1rem' }">
                      <template #body="slot">
                        <Button
                            :disabled="vendaCancelada || venda.pago"
                            icon="pi pi-trash"
                            class="p-button-rounded p-button-outlined"
                            @click="() => removeItem(slot.data)"
                        />
                      </template>
                    </Column>
                  </DataTable>
                </div>
                <div class="d-flex justify-center mt-5">
                  <v-card class="text-center" elevation="0" width="95%">
                    <v-divider></v-divider>
                  </v-card>
                </div>

                <v-row dense class="mr-3">
                  <v-col class="text-right" style="margin-top: 1rem;">
                    <span class="text-caption">Subtotal ...... R$ {{ totalItens | formatMoney({ minimumFractionDigits: 2 }) }}</span><br/>
                    <span class="text-caption">Entrega ...... R$
                      <span v-if="venda.taxa_entrega && !venda.taxa_entrega.includes(',')">{{ venda.taxa_entrega | formatMoney({ minimumFractionDigits: 2 }) }}</span>
                       <span v-if="venda.taxa_entrega && venda.taxa_entrega.includes(',')">{{ venda.taxa_entrega }}</span>
                      <span v-if="!venda.taxa_entrega">0,00</span>
                    </span><br/>
                    <span class="text-caption">Prestador ...... R$
                      <span v-if="venda.adicional_prestador && !venda.adicional_prestador.includes(',')">{{ venda.adicional_prestador | formatMoney({ minimumFractionDigits: 2 }) }}</span>
                       <span v-if="venda.adicional_prestador && venda.adicional_prestador.includes(',')">{{ venda.adicional_prestador }}</span>
                      <span v-if="!venda.adicional_prestador">0,00</span>
                    </span><br/>
                    <span class="text-caption">Transação ...... R$
                      <span v-if="venda.totalPagoTaxa && !venda.totalPagoTaxa.includes(',')">{{ venda.totalPagoTaxa | formatMoney({ minimumFractionDigits: 2 }) }}</span>
                       <span v-if="venda.totalPagoTaxa && venda.totalPagoTaxa.includes(',')">{{ venda.totalPagoTaxa }}</span>
                      <span v-if="!venda.totalPagoTaxa">0,00</span>
                    </span><br/>
                    <span class="text-h6 preco-item">Total ...... R$ {{ totalComTaxas | formatMoney({ minimumFractionDigits: 2 }) }}</span><br/>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>





<!--    <v-row class="no-gutters flex-wrap mt-15">-->
<!--      <v-col cols="6" class="d-flex">-->
<!--        <v-card elevation="0" outlined class="py-0 px-0 billing-container flex-grow-1">-->
<!--          <span class="billing-title text-caption">Cliente</span>-->
<!--          <v-row dense class="mt-3 mb-3 mr-3 ml-3">-->
<!--            <v-col class="d-flex">-->
<!--              <AutoComplete-->
<!--                :disabled="vendaCancelada || venda.pago"-->
<!--                class="autocomplete-input"-->
<!--                v-model="venda.cliente"-->
<!--                :suggestions="clientesFiltrados"-->
<!--                @complete="searchCliente"-->
<!--                forceSelection-->
<!--                :field="itemTextCliente"-->
<!--                placeholder="Pesquise aqui..."-->
<!--              >-->
<!--                <template #item="slotProps">-->
<!--                  <div>-->
<!--                    <div>{{ slotProps.item.nome }}</div>-->
<!--                    <div>{{ slotProps.item.razao_social }}</div>-->
<!--                    <div>-->
<!--                      CPF/CNPJ: {{ $masks.cpf(slotProps.item.cpf) }}-->
<!--                      {{ $masks.cnpj(slotProps.item.cnpj) }} Tel:-->
<!--                      {{-->
<!--                        $masks.telefone(-->
<!--                            slotProps.item.telefone_celular-->
<!--                        )-->
<!--                      }}-->
<!--                      E-mail: {{ slotProps.item.email }}-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </template>-->
<!--              </AutoComplete>-->
<!--              <div v-if="venda.cliente && !vendaCancelada && !venda.pago" class="div-clearable" @click="venda.cliente = null">-->
<!--                <v-icon>mdi-close</v-icon>-->
<!--              </div>-->
<!--              <v-tooltip v-if="!vendaCancelada && !venda.pago" bottom>-->
<!--                <template v-slot:activator="{ on, attrs }">-->
<!--                  <v-btn-->
<!--                    v-bind="attrs"-->
<!--                    v-on="on"-->
<!--                    fab-->
<!--                    dark-->
<!--                    x-small-->
<!--                    color="primary"-->
<!--                    :class="venda.cliente ? 'ml-10' : 'ml-3'"-->
<!--                    class="mt-1"-->
<!--                    @click="venda.cliente && venda.cliente.id ? openDialogCliente('edit', venda.cliente) : openDialogCliente('create')"-->
<!--                  >-->
<!--                    <v-icon v-if="venda.cliente && venda.cliente.id">mdi-pencil</v-icon>-->
<!--                    <v-icon v-else>mdi-plus</v-icon>-->
<!--                  </v-btn>-->
<!--                </template>-->
<!--                <span v-if="venda.cliente && venda.cliente.id">Editar</span>-->
<!--                <span v-else>Criar</span>-->
<!--              </v-tooltip>-->
<!--            </v-col>-->
<!--          </v-row>-->
<!--        </v-card>-->
<!--      </v-col>-->
<!--      <v-col cols="6" class="d-flex">-->
<!--        <v-card elevation="0" outlined class="py-0 px-0 billing-container flex-grow-1">-->
<!--          <span class="billing-title text-caption">Prestador</span>-->
<!--          <v-row dense class="mt-3 mb-3 mr-3 ml-3">-->
<!--            <v-col class="d-flex">-->
<!--              <AutoComplete-->
<!--                :disabled="vendaCancelada || venda.pago"-->
<!--                class="autocomplete-input"-->
<!--                v-model="venda.prestador"-->
<!--                :suggestions="empresasFiltradas"-->
<!--                @complete="searchEmpresa"-->
<!--                forceSelection-->
<!--                :field="itemTextEmpresa"-->
<!--                placeholder="Pesquise aqui..."-->
<!--              >-->
<!--                <template #item="slotProps">-->
<!--                  <div>-->
<!--                    <div>{{ slotProps.item.nome }}</div>-->
<!--                    <div>{{ slotProps.item.razao_social }}</div>-->
<!--                    <div>-->
<!--                      CPF/CNPJ: {{ $masks.cpf(slotProps.item.cpf) }}-->
<!--                      {{ $masks.cnpj(slotProps.item.cnpj) }} Tel:-->
<!--                      {{-->
<!--                        $masks.telefone(-->
<!--                            slotProps.item.telefone_celular-->
<!--                        )-->
<!--                      }}-->
<!--                      E-mail: {{ slotProps.item.email }}-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </template>-->
<!--              </AutoComplete>-->
<!--              <div v-if="venda.prestador && !vendaCancelada && !venda.pago" class="div-clearable" @click="venda.prestador = null">-->
<!--                <v-icon>mdi-close</v-icon>-->
<!--              </div>-->
<!--            </v-col>-->
<!--          </v-row>-->
<!--        </v-card>-->
<!--      </v-col>-->
<!--    </v-row>-->
<!--    <v-row class="no-gutters mt-n7">-->
<!--      <v-col cols="6">-->

<!--        <v-row dense>-->
<!--          <v-col>-->
<!--            <v-card height="350" elevation="0" outlined class="py-0 px-0 billing-container">-->
<!--              <span class="billing-title text-caption">Último adicionado</span>-->
<!--              <v-row class="mt-2" dense>-->
<!--                <v-col class="d-flex justify-center">-->
<!--                  <v-card v-if="ultimoItemAdicionado && venda.itens.length > 0" elevation="0" width="95%">-->
<!--                    <div v-if="ultimoItemAdicionado.produto">-->
<!--                      <v-card-title class="d-flex justify-center text-caption"><h1>{{ ultimoItemAdicionado.produto.nome }}</h1></v-card-title>-->
<!--                      <v-img-->
<!--                          :src="ultimoItemAdicionado.produto.foto_url ? ultimoItemAdicionado.produto.foto_url : require(`@/assets/img/produto-sem-foto.png`)"-->
<!--                          contain-->
<!--                          height="130px"-->
<!--                      ></v-img>-->
<!--                      <v-card-subtitle class="text-caption truncate">{{ ultimoItemAdicionado.produto.descricao }}</v-card-subtitle>-->
<!--                      <div class="d-flex justify-center">-->
<!--                        <v-card class="text-center" elevation="0" width="95%">-->
<!--                          <v-divider></v-divider>-->
<!--                        </v-card>-->
<!--                      </div>-->
<!--                      <v-row dense>-->
<!--                        <v-col class="text-right mr-7" style="margin-top: 1rem;">-->
<!--                          <span class="text-caption preco-item">Valor un.</span><br/>-->
<!--                          <span class="text-caption preco-item" style="display:inline;">R$</span>-->
<!--                          <span class="text-h3 preco-item">{{ultimoItemAdicionado.produto.valor | formatMoney({ minimumFractionDigits: 2 }) }}</span>-->
<!--                        </v-col>-->
<!--                      </v-row>-->
<!--                    </div>-->
<!--                    <div v-if="ultimoItemAdicionado.servico">-->
<!--                      <v-card-title class="d-flex justify-center"><h1>{{ ultimoItemAdicionado.servico.nome }}</h1></v-card-title>-->
<!--                      <v-img-->
<!--                          :src="ultimoItemAdicionado.servico.foto_url ? ultimoItemAdicionado.servico.foto_url : require(`@/assets/img/produto-sem-foto.png`)"-->
<!--                          contain-->
<!--                          height="130px"-->
<!--                      ></v-img>-->
<!--                      <v-card-subtitle class="text-caption truncate">{{ ultimoItemAdicionado.servico.descricao }}</v-card-subtitle>-->
<!--                      <div class="d-flex justify-center">-->
<!--                        <v-card class="text-center" elevation="0" width="95%">-->
<!--                          <v-divider></v-divider>-->
<!--                        </v-card>-->
<!--                      </div>-->
<!--                      <v-row dense>-->
<!--                        <v-col class="text-right mr-7" style="margin-top: 1rem;">-->
<!--                          <span class="text-caption preco-item">Valor un.</span><br/>-->
<!--                          <span class="text-caption preco-item" style="display:inline;">R$</span>-->
<!--                          <span class="text-h3 preco-item">{{ultimoItemAdicionado.servico.valor | formatMoney({ minimumFractionDigits: 2 }) }}</span>-->
<!--                        </v-col>-->
<!--                      </v-row>-->
<!--                    </div>-->
<!--                  </v-card>-->
<!--                  <v-card-title class="text-caption" v-else>-->
<!--                    Nennhum Item adicionado.-->
<!--                  </v-card-title>-->
<!--                </v-col>-->
<!--              </v-row>-->
<!--            </v-card>-->
<!--          </v-col>-->
<!--        </v-row>-->

<!--        <v-row class="no-gutters mt-n8">-->
<!--          <v-col>-->
<!--            <v-card elevation="0" outlined class="py-0 px-0 billing-container">-->
<!--              <span class="billing-title text-caption">Pesquisar</span>-->
<!--              <v-row dense class="mt-3">-->
<!--                <v-col>-->
<!--                  <v-tabs v-model="tab" align-with-title>-->
<!--                    <v-tabs-slider color="secondary"></v-tabs-slider>-->
<!--                    <v-tab>-->
<!--                      <span class="text-caption">Produtos</span>-->
<!--                    </v-tab>-->
<!--                    <v-tab>-->
<!--                      <span class="text-caption">Serviços</span>-->
<!--                    </v-tab>-->
<!--                  </v-tabs>-->
<!--                </v-col>-->
<!--              </v-row>-->
<!--              <v-row dense class="mt-3 mb-3 mr-3 ml-3">-->
<!--                <v-col class="d-flex">-->
<!--                  <AutoComplete-->
<!--                    :disabled="vendaCancelada || venda.pago"-->
<!--                    class="autocomplete-input"-->
<!--                    v-model="inputPesquisar"-->
<!--                    :suggestions="produtosServicosFiltrados"-->
<!--                    @complete="searchProdutosServicos"-->
<!--                    forceSelection-->
<!--                    :field="itemTextProdutoServico"-->
<!--                    placeholder="Pesquise aqui..."-->
<!--                  >-->
<!--                    <template #item="slotProps">-->
<!--                      <div @click="onSelect(slotProps.item)">-->
<!--                        <div>{{ slotProps.item.nome }}</div>-->
<!--                        <div>-->
<!--                          R$-->
<!--                          {{-->
<!--                            slotProps.item.valor-->
<!--                                | formatMoney({ minimumFractionDigits: 2 })-->
<!--                          }}-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </template>-->
<!--                  </AutoComplete>-->
<!--                  <div v-if="inputPesquisar" class="div-clearable" @click="inputPesquisar = null">-->
<!--                    <v-icon>mdi-close</v-icon>-->
<!--                  </div>-->
<!--                </v-col>-->
<!--              </v-row>-->
<!--            </v-card>-->
<!--          </v-col>-->
<!--        </v-row>-->

<!--      </v-col>-->
<!--      <v-col cols="6">-->
<!--        <v-card height="502" elevation="0" outlined class="py-0 px-0 billing-container">-->
<!--          <span class="billing-title text-caption">Itens</span>-->
<!--          <v-row dense>-->
<!--            <v-col class="d-flex justify-center">-->
<!--              <v-card class="mt-3 mb-3" elevation="0" width="95%">-->
<!--                <div class="data-table-container">-->
<!--                  <div v-if="mobileView">-->
<!--                    <div v-for="(item, index) in venda.itens" :key="index">-->
<!--                      <v-card elevation="0">-->
<!--                        <v-card-text class="d-flex card-itens">-->
<!--                          <div class="div-tabela-item">-->
<!--                            <span><b>Item</b></span><br/>-->
<!--                            <span v-if="item.produto">-->
<!--                          {{ item.produto.nome }}-->
<!--                        </span>-->
<!--                            <span v-if="item.servico">-->
<!--                          {{ item.servico.nome }}-->
<!--                        </span>-->
<!--                          </div>-->
<!--                          <div class="ml-5 div-tabela-remover">-->
<!--                            <span><b>Remover</b></span><br/>-->
<!--                            <span>-->
<!--                            <Button-->
<!--                                :disabled="vendaCancelada || venda.pago"-->
<!--                                style="width: 30px; height: 30px"-->
<!--                                icon="pi pi-trash"-->
<!--                                class="p-button-rounded p-button-outlined"-->
<!--                                @click="() => removeItem(item)"-->
<!--                            />-->
<!--                        </span>-->
<!--                          </div>-->
<!--                        </v-card-text>-->
<!--                        <v-card-text class="d-flex card-itens mt-n7">-->
<!--                          <div class="div-tabela-quantidade">-->
<!--                            <span><b>Valor unitário</b></span><br/>-->
<!--                            <span>-->
<!--                            <InputNumber-->
<!--                                :disabled="vendaCancelada || venda.pago || !user.permissoes.alterar_valor_item"-->
<!--                                class="inputs-itens-tabela"-->
<!--                                v-model="item.valor"-->
<!--                                :min="0"-->
<!--                                mode="currency"-->
<!--                                currency="BRL"-->
<!--                                locale="pt-BR"-->
<!--                                :minFractionDigits="2"-->
<!--                                :maxFracionDigits="2"-->
<!--                            />-->
<!--                          </span>-->
<!--                          </div>-->
<!--                          <div class="ml-5 div-tabela-quantidade">-->
<!--                            <span><b>Quantidade</b></span><br/>-->
<!--                            <span>-->
<!--                            <InputNumber-->
<!--                                :disabled="vendaCancelada|| venda.pago"-->
<!--                                class="inputs-itens-tabela"-->
<!--                                mode="decimal"-->
<!--                                :min="1"-->
<!--                                v-model="item.quantidade"-->
<!--                                showButtons-->
<!--                                buttonLayout="horizontal"-->
<!--                                :step="1"-->
<!--                                decrementButtonClass="p-button-danger"-->
<!--                                incrementButtonClass="p-button-success"-->
<!--                                incrementButtonIcon="pi pi-plus"-->
<!--                                decrementButtonIcon="pi pi-minus"-->
<!--                                inputClass="text-center"-->
<!--                            />-->
<!--                          </span>-->
<!--                          </div>-->
<!--                          <div class="ml-5 div-tabela-total">-->
<!--                            <span><b>Total</b></span><br/>-->
<!--                            <span class="valor-total">R${{(item.quantidade * item.valor)| formatMoney({ minimumFractionDigits: 2 }) }}</span>-->
<!--                          </div>-->
<!--                        </v-card-text>-->
<!--                      </v-card>-->
<!--                      <v-divider class="mt-8" ></v-divider>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <DataTable-->
<!--                      v-else-->
<!--                      :value="venda.itens"-->
<!--                      responsiveLayout="stack"-->
<!--                      breakpoint="660px"-->
<!--                  >-->
<!--                    <Column header="Quantidade" :styles="{ width: '9rem' }">-->
<!--                      <template #body="slot">-->
<!--                        <InputNumber-->
<!--                            :disabled="vendaCancelada|| venda.pago"-->
<!--                            mode="decimal"-->
<!--                            :min="1"-->
<!--                            v-model="slot.data.quantidade"-->
<!--                            showButtons-->
<!--                            buttonLayout="horizontal"-->
<!--                            :step="1"-->
<!--                            decrementButtonClass="p-button-danger"-->
<!--                            incrementButtonClass="p-button-success"-->
<!--                            incrementButtonIcon="pi pi-plus"-->
<!--                            decrementButtonIcon="pi pi-minus"-->
<!--                            inputClass="text-center"-->
<!--                        />-->
<!--                      </template>-->
<!--                    </Column>-->

<!--                    <Column header="Item">-->
<!--                      <template #body="slot">-->
<!--                        <div v-if="slot.data.servico">-->
<!--                          <span>{{ slot.data.servico.nome }}</span><br/>-->
<!--                          <span v-if="slot.data.servico.descricao" class="text-caption">-->
<!--                            <span v-if="slot.data.servico.descricao. length > 30">-->
<!--                              {{ slot.data.servico.descricao.slice(0, 30) }}...-->
<!--                            </span>-->
<!--                            <span v-else>-->
<!--                              {{ slot.data.servico.descricao }}-->
<!--                            </span>-->
<!--                          </span>-->
<!--                        </div>-->
<!--                        <div v-if="slot.data.produto">-->
<!--                          <span>{{ slot.data.produto.nome }}</span><br/>-->
<!--                          <span v-if="slot.data.produto.descricao" class="text-caption">-->
<!--                            <span v-if="slot.data.produto.descricao.length > 30">-->
<!--                              {{ slot.data.produto.descricao.slice(0, 30) }}...-->
<!--                            </span>-->
<!--                            <span v-else>-->
<!--                              {{ slot.data.produto.descricao }}-->
<!--                            </span>-->
<!--                          </span>-->
<!--                        </div>-->
<!--                      </template>-->
<!--                    </Column>-->

<!--                    <Column-->
<!--                        header="Valor unitário"-->
<!--                        :styles="{ width: '9rem' }"-->
<!--                    >-->
<!--                      <template #body="slot">-->
<!--                        <InputNumber-->
<!--                            :disabled="vendaCancelada || venda.pago || !user.permissoes.alterar_valor_item"-->
<!--                            v-model="slot.data.valor"-->
<!--                            :min="0"-->
<!--                            mode="currency"-->
<!--                            currency="BRL"-->
<!--                            locale="pt-BR"-->
<!--                            :minFractionDigits="2"-->
<!--                            :maxFracionDigits="2"-->
<!--                        />-->
<!--                      </template>-->
<!--                    </Column>-->

<!--                    <Column header="Total" :styles="{ width: '9rem' }">-->
<!--                      <template #body="slot">-->
<!--                        <p>-->
<!--                          R$-->
<!--                          {{-->
<!--                            (slot.data.quantidade * slot.data.valor)-->
<!--                                | formatMoney({ minimumFractionDigits: 2 })-->
<!--                          }}-->
<!--                        </p>-->
<!--                      </template>-->
<!--                    </Column>-->

<!--                    <Column header="Remover" :styles="{ width: '1rem' }">-->
<!--                      <template #body="slot">-->
<!--                        <Button-->
<!--                            :disabled="vendaCancelada || venda.pago"-->
<!--                            icon="pi pi-trash"-->
<!--                            class="p-button-rounded p-button-outlined"-->
<!--                            @click="() => removeItem(slot.data)"-->
<!--                        />-->
<!--                      </template>-->
<!--                    </Column>-->
<!--                  </DataTable>-->
<!--                </div>-->
<!--                <div class="d-flex justify-center mt-5">-->
<!--                  <v-card class="text-center" elevation="0" width="95%">-->
<!--                    <v-divider></v-divider>-->
<!--                  </v-card>-->
<!--                </div>-->

<!--                <v-row dense>-->
<!--                  <v-col class="text-right" style="margin-top: 1rem;">-->
<!--                    <span class="text-caption preco-item">Total</span><br/>-->
<!--                    <span class="text-caption preco-item" style="display:inline;">R$</span>-->
<!--                    <span class="text-h3 preco-item">{{-->
<!--                        totalItens-->
<!--                            | formatMoney({ minimumFractionDigits: 2 })-->
<!--                      }}</span>-->
<!--                  </v-col>-->
<!--                </v-row>-->
<!--              </v-card>-->
<!--            </v-col>-->
<!--          </v-row>-->
<!--        </v-card>-->
<!--      </v-col>-->
<!--    </v-row>-->

<!--    <v-row class="no-gutters mt-n7">-->
<!--      <v-col cols="12">-->
<!--        <v-card :disabled="vendaCancelada" elevation="0" outlined class="py-0 px-0 billing-container">-->
<!--          <span class="billing-title text-caption">Funções</span>-->
<!--          <v-row class="d-flex justify-space-around mx-auto mt-3 mb-3" dense>-->

<!--            <v-col cols="1">-->
<!--              <v-card :disabled="venda.pago" elevation="0" color="blue" class="function-buttons" @click="openDialogItemVendaPdv('produtos')">-->
<!--                <v-icon dark color="white" size="25" class="d-flex justify-center">mdi-cube</v-icon>-->
<!--                <span class="text-caption white&#45;&#45;text d-flex justify-center">Produtos</span>-->
<!--              </v-card>-->
<!--            </v-col>-->

<!--            <v-col cols="1">-->
<!--              <v-card :disabled="venda.pago" elevation="0" color="#FF9800" class="function-buttons" @click="openDialogItemVendaPdv('servicos')">-->
<!--                <v-icon dark color="white" size="25" class="d-flex justify-center">mdi-cards-outline</v-icon>-->
<!--                <span class="text-caption white&#45;&#45;text d-flex justify-center">Serviços</span>-->
<!--              </v-card>-->
<!--            </v-col>-->

<!--            <v-col cols="1">-->
<!--              <v-card :disabled="venda.pago" elevation="0" color="#64DD17" class="function-buttons" @click="openDialogFormadePagamento">-->
<!--                <v-icon dark color="white" size="25" class="d-flex justify-center">mdi-cash</v-icon>-->
<!--                <span class="text-caption white&#45;&#45;text d-flex justify-center">Pagar</span>-->
<!--              </v-card>-->
<!--            </v-col>-->

<!--            <v-col v-if="mode !== 'create'" cols="1">-->
<!--              <v-card :disabled="venda.pago" elevation="0" color="red" class="function-buttons" @click="openCancelarVenda">-->
<!--                <v-icon dark color="white" size="25" class="d-flex justify-center">mdi-close</v-icon>-->
<!--                <span class="text-caption white&#45;&#45;text d-flex justify-center">Cancelar</span>-->
<!--              </v-card>-->
<!--            </v-col>-->

<!--            <v-col v-if="mode !== 'create'" cols="1">-->
<!--              <v-card :disabled="vendaCancelada" elevation="0" color="teal" class="function-buttons" @click="openNotaFiscal">-->
<!--                <v-icon dark color="white" size="25" class="d-flex justify-center">mdi-note</v-icon>-->
<!--                <span class="text-caption white&#45;&#45;text d-flex justify-center">Nota Fiscal</span>-->
<!--              </v-card>-->
<!--            </v-col>-->

<!--            <v-col cols="1">-->
<!--              <v-card :disabled="venda.pago" elevation="0" color="#EC407A" class="function-buttons" @click="handleSave">-->
<!--                <v-icon dark color="white" size="25" class="d-flex justify-center">mdi-download</v-icon>-->
<!--                <span class="text-caption white&#45;&#45;text d-flex justify-center">Salvar</span>-->
<!--              </v-card>-->
<!--            </v-col>-->

<!--            <v-col cols="1">-->
<!--              <v-card elevation="0" color="primary" class="function-buttons" :to="{ name: 'VendasPdv' }">-->
<!--                <v-icon dark color="white" size="25" class="d-flex justify-center">mdi-export</v-icon>-->
<!--                <span class="text-caption white&#45;&#45;text d-flex justify-center">Fechar</span>-->
<!--              </v-card>-->
<!--            </v-col>-->

<!--&lt;!&ndash;            <v-col cols="1">&ndash;&gt;-->
<!--&lt;!&ndash;              <v-card elevation="0" color="#BA68C8" class="function-buttons">&ndash;&gt;-->
<!--&lt;!&ndash;                <v-icon dark color="white" size="25" class="d-flex justify-center">mdi-printer</v-icon>&ndash;&gt;-->
<!--&lt;!&ndash;                <span class="text-caption white&#45;&#45;text d-flex justify-center">Imprimir</span>&ndash;&gt;-->
<!--&lt;!&ndash;              </v-card>&ndash;&gt;-->
<!--&lt;!&ndash;            </v-col>&ndash;&gt;-->

<!--          </v-row>-->
<!--        </v-card>-->
<!--      </v-col>-->
<!--    </v-row>-->


    <DialogItemVendaPdv
      :showDialog="dialogItemVendaPdv.showDialog"
      :type="dialogItemVendaPdv.type"
      :id-empresa="empresaAtual.id"
      @salva-dados="saveDialogItemVendaPdv"
      @cancela-dialog="cancelaDialogItemVendaPdv"
    />

    <DialogCliente
      :showDialog="clienteDialog.showDialog"
      :id-empresa="empresaAtual.id"
      :id="clienteDialog.id"
      :mode="clienteDialog.mode"
      @salva-dados="saveCliente"
      @cancela-dialog="clienteDialog.showDialog = false"
    />

    <DialogFormadePagamentoVendaPdv
      v-if="totalItens > 0"
      :showDialog="formaPagamentoDialog.showDialog"
      :total-venda="totalComTaxas"
      @salva-dados="saveFormaPagamento"
      @cancela-dialog="cancelDialogFormadePagamento"
    />

    <DialogListaNotaFiscal
      :notas="venda.notasfiscais"
      :itensVenda="venda.itens"
      :idVenda="idVenda"
      v-if="showNota"
      :showDialog="showNota"
      @cancela-dialog="fechaDialogNotaFiscal"
      @recarregar-notas="getVenda"
    />

  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import AutoComplete from "primevue/autocomplete";
import InputNumber from "primevue/inputnumber";
import DataTable from "primevue/datatable";
import Button from "primevue/button";
import Column from "primevue/column";
import DialogItemVendaPdv from "@/components/Dialogs/DialogItemVendaPdv.vue";
import DialogCliente from "@/components/Dialogs/DialogCliente.vue";
import DialogFormadePagamentoVendaPdv from "@/components/Dialogs/DialogFormadePagamentoVendaPdv.vue";
import DialogListaNotaFiscal from "@/components/Dialogs/DialogListaNotaFiscal.vue";

export default {
  name: "CriarVenda",
  props: {
    mode: String,
    idVenda: Number
  },
  data() {
    return {
      tab: 0,
      vendaCancelada: false,
      mobileView: false,
      clienteSelecionado: null,
      inputPesquisar: null,
      venda: {
        prestador: null,
        empresa: null,
        cliente: null,
        itens: [],
        pagamentos: [],
        taxa_entrega: 0,
        adicional_prestador: 0,
        totalPagoTaxa: 0,
      },
      produtos: [],
      ultimoItemAdicionado: null,
      clientesFiltrados: [],
      produtosServicosFiltrados: [],
      empresasFiltradas: [],
      empresasUsuario: [],
      dialogItemVendaPdv: {
        showDialog: false,
        type: null
      },
      clienteDialog: {
        id: null,
        showDialog: false,
        mode: ""
      },
      formaPagamentoDialog: {
        showDialog: false
      },
      keydownListener: null,
      showNota: false,
    };
  },
  components: {
    Column, Button, DataTable, InputNumber,
    AutoComplete,
    DialogItemVendaPdv,
    DialogCliente,
    DialogFormadePagamentoVendaPdv,
    DialogListaNotaFiscal
  },
  async created() {
    await this.getEmpresas();
  },
  mounted() {
    if (this.mode != "create") this.getVenda();
    this.responsive();
  },
  computed: {
    ...mapState(["user", "empresaAtual"]),
    totalItens() {
      let totalItens = 0;
      this.venda.itens.map(item => {
        if (!item.quantidade) {
          item.total = 0;
        } else {
          item.total = item.quantidade * this.parseToFloat(item.valor);
        }

        totalItens += item.total;

        return item;
      });

      return parseFloat(totalItens.toFixed(2));
    },
    totalComTaxas() {
      const entrega = this.venda.taxa_entrega ? this.parseCurrency(this.venda.taxa_entrega) : 0;
      const prestador = this.venda.adicional_prestador ? this.parseCurrency(this.venda.adicional_prestador) : 0;
      const transacao = this.venda.totalPagoTaxa ? this.parseCurrency(this.venda.totalPagoTaxa) : 0;

      const soma = this.totalItens + entrega + prestador + transacao;

      return parseFloat(soma.toFixed(2));

    }
  },
  methods: {
    ...mapMutations([
      "openDialogMessage",
      "openDialogConfirm",
      "closeDialogConfirm"
    ]),
    getVenda() {
      this.$axios
        .get(`/venda/empresa/${this.empresaAtual.id}/venda/${this.idVenda}`)
        .then(response => {
          const venda = response.data;

          venda.taxa_entrega = venda.taxa_entrega ? this.$masks.dinheiroNew(venda.taxa_entrega) : 0;
          venda.adicional_prestador = venda.adicional_prestador ? this.$masks.dinheiroNew(venda.adicional_prestador) : 0;
          venda.totalPagoTaxa = venda.totalPagoTaxa ?  this.$masks.dinheiroNew(venda.totalPagoTaxa) : 0;

          venda.itens = venda.itens.map((item) => {
            return {
              ...item,
              valor_original: item.valor
            };
          });

          this.ultimoItemAdicionado = venda.itens[0];
          this.vendaCancelada = !!venda.data_cancelamento;

          this.venda = venda;

        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Consultar Venda",
            text: error.response.data
          });
        });
    },
    async getEmpresas() {
      return this.$axios
        .get(`/usuario/${this.user.id}/empresas`)
        .then(response => {
          this.empresasUsuario = response.data
              .map(empresa => {
                empresa.telefone = this.$masks.telefone(empresa.telefone);
                empresa.cnpj = this.$masks.cnpj(empresa.cnpj);
                return empresa;
              });

          const empresa = this.empresasUsuario.filter((item) => {
            return item.id === this.empresaAtual.id
          });

          this.venda.empresa = empresa[0];

          this.empresasFiltradas = this.empresasUsuario;

        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Consultar Empresas",
            text: error.response.data
          });
        });
    },

    searchEmpresa(event) {
      if (event.query.trim()) {
        const queryText = event.query.toLowerCase();
        this.empresasFiltradas = this.empresasUsuario.filter(item => {
          const razaoSocial = item.razao_social
              ? item.razao_social.toLowerCase()
              : "";
          const cnpj = item.cnpj
              ? this.$masks.cnpj(item.cnpj).toLowerCase()
              : "";
          const nomeFantasia = item.nome_fantasia
              ? item.nome_fantasia.toLowerCase()
              : "";
          const nome = item.nome ? item.nome.toLowerCase() : "";
          const cpf = item.cpf ? this.$masks.cpf(item.cpf).toLowerCase() : "";

          return (
            razaoSocial.includes(queryText) ||
            cnpj.includes(queryText) ||
            nomeFantasia.includes(queryText) ||
            nome.includes(queryText) ||
            cpf.includes(queryText)
          );
        });
      } else {
        this.empresasFiltradas = [...this.empresasUsuario];
      }

    },

    itemTextEmpresa(item) {
      if (item.cpf) {
        return `${item.nome ? item.nome : item.razao_social} - ${this.$masks.cpf(item.cpf)}`;
      }

      if (item.cnpj) {
        return `${item.razao_social} - ${this.$masks.cnpj(item.cnpj)}`;
      }

    },

    itemTextProdutoServico(item) {

      return "";

      // if(item.descricao.length > 80) {
      //   return `${item.nome} - ${item.descricao.slice(0, 80)}...`;
      // } else {
      //   return `${item.nome} - ${item.descricao}`;
      // }

    },

    onSelect(item) {

      let itemData = {};

      if(this.tab === 0) {
        itemData = {
          servico: null,
          produto: item,
          quantidade: 1,
          valor: Number(parseFloat(item.valor).toFixed(2)),
          valor_original: Number(parseFloat(item.valor_original).toFixed(2))
        };

        const adicionadoProduto = this.venda.itens.filter(obj => obj.produto?.id === item.id).length;

        if(adicionadoProduto > 0){
          this.openDialogMessage({
            type: "warning",
            title: "Atenção",
            text: "Este produto já foi adicionado"
          });
          return;
        }

        this.$toast.add({
          severity: "success",
          summary: `Produto ${item.nome} adicionado!`,
          life: 2000
        });

      }

      if(this.tab === 1) {
        itemData = {
          servico: item,
          produto: null,
          quantidade: 1,
          valor: Number(parseFloat(item.valor).toFixed(2)),
          valor_original: Number(parseFloat(item.valor_original).toFixed(2))
        };

        const adicionadoServico = this.venda.itens.filter(obj => obj.servico?.id === item.id).length;

        if(adicionadoServico > 0) {
          this.openDialogMessage({
            type: "warning",
            title: "Atenção",
            text: "Este servico já foi adicionado"
          });
          return;
        }

        this.$toast.add({
          severity: "success",
          summary: `Serviço ${item.nome} adicionado!`,
          life: 2000
        });

      }

      this.venda.itens.unshift(itemData);
      this.ultimoItemAdicionado = itemData;
      this.inputPesquisar = null;
    },

    searchCliente(event) {
      const search = event.query.trim();

      if(search) {
        this.getClientes(search);
      }


    },

    searchProdutosServicos(event) {
      const search = event.query.trim();

      if(search) {

        if(this. tab === 0) this.getProdutos(search);
        if(this. tab === 1) this.getServicos(search);

      }
    },

    getServicos(filtro) {
      return this.$axios
        .get(`/servico/empresa/${this.empresaAtual.id}/listar`,{
          headers: {
            filtros: JSON.stringify({
              servico: filtro ? [filtro.trim()]: [],
              status: ["ATIVO"]
            }),
            noloading: true
          },
          params: {
            first: 0,
            limit: 20,
            ordem: "DESC"
          }
        })
        .then(response => {

          const servicos = response.data.data.map((item) => {
            return {
              ...item,
              valor_original: item.valor
            };
          });

          this.produtosServicosFiltrados = servicos;

          //Se existir somente um registro ja adicionar direto nos itens.
          if(servicos.length === 1) this.registerKeyListener(servicos[0]);

        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Consultar Clientes",
            text: error.response.data
          });
        });
    },

    getProdutos(filtro) {
      return this.$axios
        .get(`/produto/empresa/${this.empresaAtual.id}/listar`,{
          headers: {
            filtros: JSON.stringify({
              produto: filtro ? [filtro.trim()]: [],
              status: ["ATIVO"]
            }),
            noloading: true
          },
          params: {
            first: 0,
            limit: 20,
            ordem: "DESC"
          }
        })
        .then(response => {

          const produtos = response.data.data.map((item) => {
            return {
              ...item,
              valor_original: item.valor
            };
          });

          this.produtosServicosFiltrados = produtos;


          //Se existir somente um registro ja adicionar direto nos itens.
          if(produtos.length === 1) this.registerKeyListener(produtos[0]);

        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Consultar Clientes",
            text: error.response.data
          });
        });
    },

    getClientes(filtro) {
      return this.$axios
        .get(`/cliente/empresa/${this.empresaAtual.id}/listar`,{
          headers: {
            filtros: JSON.stringify({
              cliente: filtro ? [filtro.trim()]: [],
              status: ['ATIVO']
            }),
            noloading: true
          },
          params: {
            first: 0,
            limit: 20,
            ordem: "DESC"
          }
        })
        .then(response => {
          this.clientesFiltrados = response.data.data;
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Consultar Clientes",
            text: error.response.data
          });
        });
    },

    itemTextCliente(item) {
      if (item.cpf) {
        return `${this.$masks.cpf(item.cpf)} - ${item.nome} - ${this.$masks.telefone(item.telefone_celular)}`;
      }

      if (item.cnpj) {
        return `${this.$masks.cnpj(item.cnpj)} - ${item.nome} - ${this.$masks.telefone(item.telefone_celular)}`;
      }

    },

    removeItem(item) {
      this.venda.itens.splice(this.venda.itens.indexOf(item), 1);
      this.$toast.add({
        severity: "info",
        summary: `Item ${
            item.servico ? item.servico.nome : item.produto.nome
        } removido!`,
        life: 2000
      });
    },

    saveDialogItemVendaPdv(item) {

      let adicionadoServico = [];
      let adicionadoProduto = [];

      if (item.servico) {
        adicionadoServico = this.venda.itens.filter(obj => obj.servico?.id === item.servico?.id).length;
      }

      if (item.produto) {
        adicionadoProduto = this.venda.itens.filter(obj => obj.produto?.id === item.produto?.id).length;
      }

      if(adicionadoServico > 0){
        this.openDialogMessage({
          type: "warning",
          title: "Atenção",
          text: "Este serviço já foi adicionado"
        });
        return;
      }

      if(adicionadoProduto > 0){
        this.openDialogMessage({
          type: "warning",
          title: "Atenção",
          text: "Este produto já foi adicionado"
        });
        return;
      }

      this.venda.itens.unshift(item);
      this.ultimoItemAdicionado = item;
    },

    openDialogItemVendaPdv(type) {
      this.dialogItemVendaPdv.type = type;
      this.dialogItemVendaPdv.showDialog = true;
    },

    openDialogFormadePagamento() {

      if(this.venda.itens.length === 0) {
        this.$toast.add({
          severity: "error",
          summary: `Adicione itens para pagar.`,
          life: 2000
        });
        return;
      }

      this.formaPagamentoDialog.showDialog = true;
    },

    cancelDialogFormadePagamento() {
      this.formaPagamentoDialog.showDialog = false;
    },

    cancelaDialogItemVendaPdv() {
      this.dialogItemVendaPdv.showDialog = false;
    },

    openDialogCliente(mode, item) {
      this.clienteDialog = {
        id: item ? item.id : null,
        showDialog: true,
        mode: mode
      };
    },
    saveCliente(cliente) {
      this.venda.cliente = cliente;
      this.clienteDialog.showDialog = false;
    },

    saveFormaPagamento(pagamento) {
      if(this.idVenda) {
        this.savePagamento(this.idVenda, pagamento);
      } else {
        this.saveVendaComPagamento(pagamento);
      }
      // this.venda.pagamentos = pagamento;
      // this.handleSave();
    },

    registerKeyListener(item) {
      if (this.keydownListener === null) {  // Certifica que o listener não está já registrado
        this.keydownListener = (event) => {
          if (event.key === "Enter") {

            let itemData = {};

            if(this.tab === 0) {

              const adicionadoProduto = this.venda.itens.filter(obj => obj.produto?.id === item.id).length;

              if(adicionadoProduto > 0) {
                this.openDialogMessage({
                  type: "warning",
                  title: "Atenção",
                  text: "Este produto já foi adicionado"
                });

                this.produtosServicosFiltrados = [];
                this.removeKeyListener();

                return;
              }

              itemData = {
                servico: null,
                produto: item,
                quantidade: 1,
                valor: Number(parseFloat(item.valor).toFixed(2)),
                valor_original: Number(parseFloat(item.valor_original).toFixed(2))
              };

              this.$toast.add({
                severity: "success",
                summary: `Produto ${item.nome} adicionado!`,
                life: 2000
              });

              this.produtosServicosFiltrados = [];

            }

            if(this.tab === 1) {

              const adicionadoServico = this.venda.itens.filter(obj => obj.servico?.id === item.id).length;

              if(adicionadoServico > 0) {
                this.openDialogMessage({
                  type: "warning",
                  title: "Atenção",
                  text: "Este servico já foi adicionado"
                });

                this.produtosServicosFiltrados = [];
                this.removeKeyListener();

                return;
              }

              itemData = {
                servico: item,
                produto: null,
                quantidade: 1,
                valor: Number(parseFloat(item.valor).toFixed(2)),
                valor_original: Number(parseFloat(item.valor_original).toFixed(2))
              };

              this.$toast?.add({
                severity: "success",
                summary: `Serviço ${item.nome} adicionado!`,
                life: 2000
              });

              this.produtosServicosFiltrados = [];

            }

            this.venda.itens.unshift(itemData);
            this.ultimoItemAdicionado = itemData;
            this.inputPesquisar = null;

            this.removeKeyListener();

          }
        };

        document.addEventListener("keydown", this.keydownListener);
      }
    },

    removeKeyListener() {
      document.removeEventListener("keydown", this.keydownListener);
      this.keydownListener = null;
    },

    async savePagamento(id, data) {
      await this.$axios
          .post(`/venda/${id}/pagamento`, data)
          .then(() => {
            this.$toast.add({
              severity: "success",
              summary: `Venda salva com sucesso.`,
              life: 2000
            });
            //this.$router.push({ name: "VendasPdv" });
          })
          .catch(error => {
            this.openDialogMessage({
              type: "error",
              title: "Erro - Salvar Venda",
              text: error.response.data
            });
          });
    },

    async saveVendaComPagamento(pagamento) {

      const data = {
        empresa: { id: this.empresaAtual.id },
        adicional_prestador: this.parseCurrency(this.venda.adicional_prestador),
        taxa_entrega: this.parseCurrency(this.venda.taxa_entrega),
        totalPagoTaxa: this.parseCurrency(this.venda.totalPagoTaxa),
        itens: this.venda.itens.map(item => {

          if (!this.user.permissoes.alterar_valor_item) {
            item.valor = item.valor_original;
          }

          (item.servico = item.servico ? { id: item.servico.id } : null),
              (item.produto = item.produto ? { id: item.produto.id } : null),
              (item.valor = this.parseToFloat(item.valor));
          item.total = (item.quantidade * item.valor).toFixed(2);
          return item;
        }),
        pagamentos: this.venda.pagamentos
      };

      if(this.venda.empresa && this.venda.empresa.id) {
        data.empresa = {
          id: this.venda.empresa.id
        }
      }

      if(this.venda.prestador && this.venda.prestador.id) {
        data.prestador = {
          id: this.venda.prestador.id
        }
      }

      if(this.venda.empresa && this.venda.empresa.id) {
        data.empresa = {
          id: this.venda.empresa.id
        }
      }

      if(this.venda.cliente && this.venda.cliente.id) {
        data.cliente = {
          id: this.venda.cliente.id
        };
      }

      await this.$axios
          .post("/venda", data)
          .then((response) => {
            this.savePagamento(response.data.id, pagamento);
          })
          .catch(error => {
            this.openDialogMessage({
              type: "error",
              title: "Erro - Salvar Venda",
              text: error.response.data
            });
          });
    },

    async save() {

      const data = {
        empresa: { id: this.empresaAtual.id },
        adicional_prestador: this.parseCurrency(this.venda.adicional_prestador),
        taxa_entrega: this.parseCurrency(this.venda.taxa_entrega),
        totalPagoTaxa: this.parseCurrency(this.venda.totalPagoTaxa),
        itens: this.venda.itens.map(item => {

          if (!this.user.permissoes.alterar_valor_item) {
            item.valor = item.valor_original;
          }

          (item.servico = item.servico ? { id: item.servico.id } : null),
            (item.produto = item.produto ? { id: item.produto.id } : null),
            (item.valor = this.parseToFloat(item.valor));
          item.total = (item.quantidade * item.valor).toFixed(2);
          return item;
        }),
        pagamentos: this.venda.pagamentos
      };

      if(this.venda.empresa && this.venda.empresa.id) {
        data.empresa = {
          id: this.venda.empresa.id
        }
      }

      if(this.venda.prestador && this.venda.prestador.id) {
        data.prestador = {
          id: this.venda.prestador.id
        }
      }

      if(this.venda.empresa && this.venda.empresa.id) {
        data.empresa = {
          id: this.venda.empresa.id
        }
      }

      if(this.venda.cliente && this.venda.cliente.id) {
        data.cliente = {
          id: this.venda.cliente.id
        };
      }

      await this.$axios
        .post("/venda", data)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: `Venda salva com sucesso.`,
            life: 2000
          });
          //this.$router.push({ name: "VendasPdv" });
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Salvar Venda",
            text: error.response.data
          });
        });
    },

    async edit() {

      const data = {
        id: this.idVenda,
        adicional_prestador: this.parseCurrency(this.venda.adicional_prestador),
        taxa_entrega: this.parseCurrency(this.venda.taxa_entrega),
        totalPagoTaxa: this.parseCurrency(this.venda.totalPagoTaxa),
        empresa: { id: this.empresaAtual.id },
        itens: this.venda.itens.map(item => {
          if (!this.user.permissoes.alterar_valor_item) {
            item.valor = item.valor_original;
          }


          (item.servico = item.servico ? { id: item.servico.id } : null),
            (item.produto = item.produto ? { id: item.produto.id } : null),
            (item.valor = this.parseToFloat(item.valor));
          item.total = (item.quantidade * item.valor).toFixed(2);
          return item;
        }),
        pagamentos: this.venda.pagamentos
      };

      if(this.venda.prestador && this.venda.prestador.id) {
        data.prestador = {
          id: this.venda.prestador.id
        }
      }

      if(this.venda.empresa && this.venda.empresa.id) {
        data.empresa = {
          id: this.venda.empresa.id
        }
      }

      if(this.venda.cliente && this.venda.cliente.id) {
        data.cliente = {
          id: this.venda.cliente.id
        };
      }

      await this.$axios
        .put("/venda", data)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: `Venda Editada com sucesso.`,
            life: 2000
          });
          //this.$router.push({ name: "VendasPdv" });
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Editar Venda",
            text: error.response.data
          });
        });
    },



    async handleSave() {

      if(this.venda.itens.length === 0) {
        this.$toast.add({
          severity: "error",
          summary: `Adicione itens para salvar a venda.`,
          life: 2000
        });
        return;
      }

      if(!this.venda.empresa) {
        this.$toast.add({
          severity: "error",
          summary: `Escolha a empresa para salvar a venda.`,
          life: 2000
        });
        return;
      }

      this.mode === "create" ? await this.save() : await this.edit();

    },

    handleView() {
      (window.innerWidth < 1450 ? this.mobileView = true : this.mobileView = false)
    },

    responsive() {
      this.handleView();
      window.addEventListener("resize", this.handleView);
    },

    openCancelarVenda() {
      this.openDialogConfirm({
        title: "Cancelar venda",
        text: "Tem certeza que deseja cancelar a venda?",
        confirma: this.cancelarVenda.bind(),
        cancela: this.closeDialogConfirm.bind()
      });
    },

    cancelarVenda() {
      this.closeDialogConfirm();
      this.$axios
        .delete(`/venda/${this.idVenda}/cancela`)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: `Venda Cancelada com sucesso.`,
            life: 2000
          });
          //this.$router.push({ name: "VendasPdv" });
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Cancelar Venda",
            text: error.response.data
          });
        });
    },

    openNotaFiscal() {
      if(this.venda.notasfiscais && this.venda.notasfiscais.length > 0) {

        this.showNota = true;

      } else {

        this.openDialogConfirm({
          title: "Atenção",
          text: "Deseja gerar nota dessa venda?",
          confirma: this.gerarNF,
          cancela: this.closeDialogConfirm
        });

      }
    },

   async gerarNFSE() {
      const data = {
        id: this.idVenda,
        empresa: { id: this.empresaAtual.id },
        itens: this.venda.itens.map(item => {
          (item.servico = item.servico ? { id: item.servico.id } : null),
              (item.produto = item.produto ? { id: item.produto.id } : null),
              (item.valor = this.parseToFloat(item.valor));
          item.total = (item.quantidade * item.valor).toFixed(2);
          return item;
        }),
        pagamentos: this.venda.pagamentos
      };

      if(this.venda.prestador && this.venda.prestador.id) {
        data.prestador = {
          id: this.venda.prestador.id
        }
      }

      if(this.venda.cliente && this.venda.cliente.id) {
        data.cliente = {
          id: this.venda.cliente.id
        };
      }

      await this.$axios
        .put("/venda", data, {
          headers: {
            noloading: true
          }
        })
        .then((response) => {
          this.venda.notasfiscais = response.data.notasfiscais;
        })
        .catch(error => {
          console.log("NFSE------>",error);
        });
    },

   async gerarNFCE() {
      await this.$axios
       .post(`/venda/${this.idVenda}/nfce`, {}, {
         headers: {
           noloading: true
         }
       })
       .then((response) => {
         this.venda.notasfiscais = response.data.notasfiscais;
       })
       .catch(error => {
         this.getVenda();
       });
    },

   async gerarNF() {
      this.closeDialogConfirm();

      if(this.empresaAtual.emite_nfse) {
       await this.gerarNFSE();
      }

      if(this.empresaAtual.emite_nfce) {
       await this.gerarNFCE();
      }

     this.$toast.add({
       severity: "success",
       summary: `Nota Fiscal gerada com sucesso!`,
       life: 2000
     });

    },

    fechaDialogNotaFiscal() {
      this.showNota = false;
    },

    parseCurrency(value) {
      if(value && typeof value === 'string') {
        const normalized = value.replace(/\./g, '').replace(',', '.');
        return parseFloat(normalized);
      }
      return value;
    }


  }
};
</script>

<style scoped>

.billing-container {
  padding: 10px;
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 5px;
  background: #fff;
}

.billing-title {
  background: #fff;
  padding: 0 10px;
  position: absolute;
  top: -10px;
  left: 20px;
}

.autocomplete-input {
  width: 100%;
}

.function-buttons {
  cursor: pointer;
}

.preco-item {
  color: #43A047;
}

.data-table-container {
  max-height: 330px;
  overflow-y: auto;
  height: 330px;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background-color: #e1e1e1;
}

::-webkit-scrollbar-thumb {
  background-color: #bebebe;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.div-clearable {
  margin-left: -50px;
  margin-top: 8px;
  cursor: pointer;
}

.inputs-itens-tabela {
  width: 9rem;
  height: 20px;
}

.div-tabela-item {
  width: auto;
}

.div-tabela-quantidade, .inputs-itens-tabela, .div-tabela-total {
  height: 40px;
  line-height: 40px;
}

.card-itens {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.valor-total {
  font-size: large;
}

@media (max-width: 920px) {
  .inputs-itens-tabela {
    width: 7rem;
  }

  .div-tabela-item {
    width: 245px;
  }
}

@media (max-width: 823px) {
  .card-itens {
    display: flex;
    flex-direction: column;
  }

  .card-itens .div-tabela-quantidade, .card-itens .div-tabela-remover, .card-itens .div-tabela-total {
    margin: 0!important;
  }

  .inputs-itens-tabela {
    width: 10rem;
  }

}

@media (max-width: 900px) {
  .col,
  .col-4 {
    flex: none;
    max-width: 25rem;
  }
}
</style>
